<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div class="virtuals-widget">
      <VirtualsWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
      />
      <WidgetField
        class="virtuals-widget__iframe"
        :widget="widget"
        :field="fields.iframe_container.field"
      >
        This is iframe
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();
const widgetsStore = useWidgetsStore();

const fields = useWidgetFields(props.widget);

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { initWidgetOptions } = useCreateFields(props.widget);

/*
  Initialize widget with initial options
*/

widgetsStore.updateWidgetOptions(props.widget, initWidgetOptions());
</script>

<style lang="scss">
.virtuals-widget {
  &__iframe {
    padding: 24px;
    background: white;
    font-weight: bold;
    text-align: center;
  }
}
</style>
