import { pipeSync } from "~~/helpers/pipe";
import type { IPageContentWidget } from "~~/models/page.model";
import type {
  ICustomField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { FillType } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateCssClassWithContent } from "../compiler/default-css-compiler";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateCardCssString,
  generateFlexibleImageCssString,
} from "../utils/form-helper-functions";
import { generateFlexAlignment, generateSize } from "../helpers";

import {
  generateIconColorCssString,
  generatePaginationColorCssString,
  generateWidgetCssString,
  getSliderPaddingData,
} from "./text-slider-widget-css";
import {
  generateButtonCssString,
  generateButtonsGroupCssString,
} from "./card-widget-css";

const generateSlidesCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const items = widget.content["items"];

    (items.value as ICustomField[]).forEach(slide => {
      slide.options._cssClass = generateClassName("slide");
      slide.options._textContainerCssClass =
        generateClassName("slideTextContainer");

      let backgroundOptions: IWidgetOptions = {};

      if (slide.options.fillImageColor?.fillType === FillType.COLOR) {
        backgroundOptions = slide.options.fillImageColor;
      } else {
        backgroundOptions = {
          ...slide.options.fillImageColor,
          value: null,
        };
      }

      cssString += generateCardCssString({
        ...slide,
        options: {
          ...slide.options,
          padding: getSliderPaddingData(widget),
          fillImageColor: backgroundOptions,
        },
      })("");

      cssString += generateCssClassWithContent({
        className: slide.options._cssClass,
        content:
          `height: 100%;align-items:${generateFlexAlignment(
            slide.options.alignment
          )};` + generateSize(widget.options.slideOptions.size),
      });

      cssString += generateCssClassWithContent({
        className: widget.options._skeletonCssClass,
        content: generateSize(widget.options.slideOptions.size),
      });

      slide?.fields?.forEach(slideField => {
        slideField.options._cssClass = generateClassName(slideField.name);

        switch (slideField.name) {
          case FieldName.TITLE:
            cssString += generateStringDefault({
              options: {
                ...slideField.options,
                alignment: slide.options.alignment,
              },
            })("");
            break;

          case FieldName.IMAGE:
            cssString += generateFlexibleImageCssString(slideField)("");
            break;

          case FieldName.DESCRIPTION_1:
            cssString += generateStringDefault(slideField)("");
            break;

          case FieldName.BUTTONS_GROUP:
            cssString += generateButtonsGroupCssString({
              ...slideField,
              options: {
                ...slideField.options,
                alignment: slide.options.alignment,
              },
            })("");
            break;

          case FieldName.BUTTON_1:
            slideField.options.iconSettings._cssClass =
              generateClassName("btn_1_iconSettings");
            cssString += generateButtonCssString(slideField)("");
            break;

          case FieldName.BUTTON_2:
            slideField.options.iconSettings._cssClass =
              generateClassName("btn_2_iconSettings");

            cssString += generateButtonCssString(slideField)("");
            break;
        }
      });
    });

    return cssString;
  };

export const generateBannerSliderWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("BannerSliderWidget");
  widget.options.sliderPagination._cssClass =
    generateClassName("sliderPagination");
  widget.options.sliderArrows._cssClass = generateClassName("sliderArrows");
  widget.options._skeletonCssClass = generateClassName("slider-skeleton");

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateSlidesCssString(widget),
    generatePaginationColorCssString(
      widget.options.sliderPagination._cssClass,
      widget.options.sliderPagination.color
    ),
    generateIconColorCssString(
      widget.options.sliderArrows._cssClass,
      widget.options.sliderArrows.color
    )
  )("");
};
