<template>
  <div
    class="dropdown-trigger-field"
    :style="wrapperStyle"
  >
    <SpacingHighlight
      :is-target-selected="isFieldSelected"
      :padding="field.options.padding || field.options.spacing.padding"
      :margin="field.options.margins || field.options.spacing.margin"
    />
    <span
      v-if="iconSettings?._active && arrowSettings"
      :style="iconStyleValue"
    >
      <slot name="icon">
        <CommonIcon :name="iconSettings.value" />
      </slot>
    </span>

    <BasicTitleField
      v-if="field.options.text._active && !field.options.placeholder?._active"
      :value="value"
      :field="field"
      :style="titleFieldStyles"
      :options="textOptions"
    />

    <BasicTitleField
      v-else-if="
        field.options.placeholder && field.options.placeholder?._active
      "
      :value="placeholderValue"
      :field="field"
      :style="titleFieldStyles"
      :options="placeholderOptions"
    />

    <span
      v-if="iconSettings?._active && !arrowSettings"
      :style="iconStyleValue"
      class="dropdown-trigger-field__icon"
    >
      <CommonIcon :name="iconSettings.value" />
    </span>
    <span
      v-if="arrowSettings && arrowSettings._active"
      :style="arrowStyleValue"
      class="dropdown-trigger-field__arrow"
    >
      <CommonIcon
        :name="iconMapping[arrowSettings.value] || arrowSettings.value"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue";

import {
  getColorFromHex,
  getFontSizeStyle,
  getWidthStyles,
} from "~~/assets/utils/widget-settings";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { iconMapping } from "~~/constants/icons-mapping";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);

const props = defineProps<{
  field: IWidgetField;
  widget: IWidgetWithFields;
  state: State;
  value?: Record<string, string> | string;
  alignment?: Alignment;
  placeholderValue?: Record<string, string> | string;
  isHug?: boolean;
}>();

const activeStateOptions = computed<IWidgetOptions>(() => {
  return props.state === State.DEFAULT
    ? props.field.options
    : props.field.options.states[props.state];
});

const iconSettings = computed(() => props.field.options.icon);

const wrapperStyle = computed<ElementStyle>(() => {
  const options = props.field.options;
  return {
    width: props.field.options.display?.resizing
      ? getWidthStyles(props.field.options.display.resizing).width
      : "100%",
    ...getCommonStyles({
      ...options,
      ...activeStateOptions.value,
    }),
    flexDirection:
      activeStateOptions.value.icon?.position === Alignment.RIGHT
        ? "row-reverse"
        : "row",
    justifyContent:
      props.alignment ||
      options.display?.alignment ||
      props.widget.options.display?.alignment ||
      Alignment.LEFT,
  };
});

const titleFieldStyles = computed<CSSProperties>(() => {
  return {
    width: props.isHug ? "auto" : "100%",
    textAlign:
      props.field.options.display?.alignment ||
      props.widget.options.display?.alignment,
  };
});

const iconStyleValue = computed<ElementStyle>(() => {
  const style: ElementStyle = {
    marginRight: `${iconSettings.value.gap}px`,
    ...getFontSizeStyle(iconSettings.value.iconSize),
  };

  if (activeStateOptions.value.icon) {
    style.color = getColorFromHex(
      activeStateOptions.value.icon.textColor
    ) as string;
  }

  return style;
});

const arrowSettings = computed(() => props.field.options.arrow);

const arrowStyleValue = computed<{ [key: string]: string }>(() => {
  return {
    color: getColorFromHex(activeStateOptions.value?.arrow.textColor) as string,
  };
});

const isFieldSelected = computed(
  () => props.field.id === selectedField.value?.id
);

const textOptions = computed<IWidgetOptions>(() => ({
  ...props.field.options.text,
  ...activeStateOptions.value.text,
}));

const placeholderOptions = computed<IWidgetOptions>(() => {
  if (props.placeholderValue) {
    return {
      theme: props.field.options.text.theme,
      fontFamily: props.field.options.text.fontFamily,
      ...props.field.options.placeholder,
      ...activeStateOptions.value.placeholder,
    };
  }
  return {};
});
</script>
<style lang="scss">
.dropdown-trigger-field {
  position: relative;
  display: flex;
  align-items: center;

  &__arrow,
  &__icon {
    margin-left: $space-s;
  }
}
</style>
