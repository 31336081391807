<template>
  <div :style="styles">
    <slot />
  </div>
</template>

<script setup lang="ts">
import {
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import {
  TEXT_SLIDER_PAGINATION_HEIGHT,
  TEXT_SLIDER_ARROWS_WIDTH,
} from "~~/constants/configs/text-slider/settings";
import {
  SliderItemsPosition,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import type {
  ICustomField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  slideField: ICustomField;
  states: Record<string, State>;
  widget: IWidgetWithFields;
  showPagination: boolean;
  showArrows: boolean;
}>();

const activeStateOptions = computed<any>(() => {
  return props.states.slide !== State.DEFAULT
    ? props.slideField.options.states[props.states.slide]
    : props.slideField.options;
});

const { fillBackgroundStyling } = useFillBackground(activeStateOptions);

const styles = computed<{ [key: string]: string | number }>(() => {
  const fill = fillBackgroundStyling.value;
  const border = getBorderStyle(activeStateOptions.value.border);
  const shadow = getBoxShadowStyle(activeStateOptions.value.shadow);
  const cornerRadius = getCornerRadiusStyle(
    props.slideField.options.cornerRadius
  );
  const paddingData = { ...props.widget.options.slidePadding };

  if (
    props.showPagination &&
    props.widget.options.sliderPagination.position ===
      SliderItemsPosition.INSIDE
  )
    paddingData.bottom = paddingData.bottom + TEXT_SLIDER_PAGINATION_HEIGHT;

  if (
    props.showArrows &&
    props.widget.options.sliderArrows.position === SliderItemsPosition.INSIDE
  ) {
    paddingData.left = paddingData.left + TEXT_SLIDER_ARROWS_WIDTH;
    paddingData.right = paddingData.right + TEXT_SLIDER_ARROWS_WIDTH;
  }

  const padding = getSpacing(paddingData, SpacingKeyName.PADDING);

  return {
    ...border,
    ...shadow,
    ...cornerRadius,
    ...fill,
    ...padding,
  };
});
</script>
