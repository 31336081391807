import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getCornerRadiusStyle,
  getDecorationValue,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import {
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { getPxValueFromNumber } from "~~/assets/utils";

export const useBetslipHeaderInlineStyles = (
  headerOptions: Ref<IWidgetOptions>,
  headerCountersOptions: Ref<IWidgetOptions>,
  state: Ref<State>,
  cashOutOptions?: Ref<IWidgetOptions>
) => {
  const isHeaderDefaultState = computed<boolean>(() => {
    return state.value === State.DEFAULT;
  });

  const activeHeaderContainerStateOptions = computed<IWidgetOptions>(() => {
    if (isHeaderDefaultState.value) {
      return headerOptions.value;
    }

    return headerOptions.value.states[state.value];
  });

  const headerContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(headerOptions.value),
      ...getSpacing(headerOptions.value.margins, SpacingKeyName.MARGIN),
      ...getSpacing(headerOptions.value.padding, SpacingKeyName.PADDING),
      gap: `${headerOptions.value.spaceBetween}px`,
      justifyContent: generateFlexAlignment(
        headerOptions.value.buttonDisplaySettings.alignment
      ),
    };
  });

  const headerContentGapInlineStyles = computed<ElementStyle>(() => {
    return {
      gap: `${activeHeaderContainerStateOptions.value.gapBetweenTextAndCounter}px`,
    };
  });

  const headerContentInlineStyles = computed<ElementStyle>(() => {
    const isFill =
      headerOptions.value.buttonDisplaySettings.resizing === ResizingType.FILL;

    return {
      color: getColorFromHex(activeHeaderContainerStateOptions.value.color)!,
      fontSize: `${headerOptions.value.theme.value}px`,
      fontFamily: headerOptions.value.fontFamily,
      ...getBackgroundColorStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.fill
      ),
      ...getBorderStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.border
      ),
      ...getBoxShadowStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.shadow
      ),
      ...getSpacing(headerOptions.value.padding, SpacingKeyName.PADDING),
      ...getSpacing(
        headerOptions.value.headerElementsSettings.padding,
        SpacingKeyName.PADDING
      ),
      ...getCornerRadiusStyle(
        headerOptions.value.headerElementsSettings.cornerRadius
      ),
      width: isFill ? "100%" : "fit-content",
      justifyContent: isFill
        ? generateFlexAlignment(
            headerOptions.value.buttonDisplaySettings.alignment
          )
        : "center",
    };
  });

  const headerLabelInlineDecorationStyles = computed<ElementStyle>(() => {
    return {
      ...getDecorationValue(headerOptions.value.decoration),
    };
  });

  const headerCashOutStyles = computed(() => {
    return {
      color: getColorFromHex(cashOutOptions?.value.color)!,
      fontSize: getPxValueFromNumber(cashOutOptions?.value.theme.value),
      fontFamily: cashOutOptions?.value.fontFamily,
      textAlign: cashOutOptions?.value.alignment,
      ...getSpacing(cashOutOptions?.value.padding, SpacingKeyName.PADDING),
      ...getDecorationValue(cashOutOptions?.value.decoration),
    };
  });

  const headerCounterInlineStyles = computed<ElementStyle>(() => {
    let headerCountersStatesOptions;

    if (!isHeaderDefaultState.value) {
      headerCountersStatesOptions =
        headerCountersOptions.value.states[state.value];
    }

    return {
      color: isHeaderDefaultState.value
        ? getColorFromHex(headerCountersOptions.value.color)!
        : getColorFromHex(headerCountersStatesOptions.color)!,
      fontSize: `${headerCountersOptions.value.theme.value}px`,
      fontFamily: headerCountersOptions.value.fontFamily,
      ...getBorderStyle(headerCountersOptions.value.border),
      backgroundColor: isHeaderDefaultState.value
        ? getColorFromHex(headerCountersOptions.value.fill)!
        : getColorFromHex(headerCountersStatesOptions.fill)!,
      ...getSpacing(
        headerCountersOptions.value.padding,
        SpacingKeyName.PADDING
      ),
      ...getDecorationValue(headerCountersOptions.value.decoration),
      ...getCornerRadiusStyle(headerCountersOptions.value.cornerRadius),
    };
  });

  return {
    headerContainerInlineStyles,
    headerContentGapInlineStyles,
    headerContentInlineStyles,
    headerCounterInlineStyles,
    headerLabelInlineDecorationStyles,
    state,
    headerCashOutStyles,
  };
};
