<script lang="ts" setup>
//@ts-ignore
import Papa from "papaparse";
import isObject from "lodash/isObject";
import { useNotification } from "@kyvg/vue3-notification";

import {
  FILE_PROCESSING_SUCCESS,
  FILE_UPLOADING_SUCCESS,
} from "~~/constants/notification-messages";
import { useGridConfig } from "~~/store/grid";
import { useLanguagesStore } from "~~/store/languages";

import { useWidgetContentChanges } from "../composables/useWidgetContentChanges";

interface UploadFile {
  uid: string;
  name: string;
  status?: "uploading" | "done" | "error" | "removed";
  response?: any;
  url?: string;
  thumbUrl?: string;
  originFileObj?: File;
  percent?: number;
}

interface IOsgTranslations {
  dragger: UploadFile[];
}

interface IOsgTranslationsParseResult {
  keyName: string;
  translation: string;
}

const gridStore = useGridConfig();
const notification = useNotification();
const languagesStore = useLanguagesStore();
const widgetChanges = useWidgetContentChanges();

const loading = ref<boolean>(false);
const osgTranslations = reactive<IOsgTranslations>({
  dragger: [],
});
const uploadedFile = ref<File | null>(null);

const handleChange = ({
  file,
  fileList: newFileList,
}: {
  file: UploadFile;
  fileList: UploadFile[];
}) => {
  osgTranslations.dragger = newFileList;
  // optionally handle file status updates
  if (file.status === "done") {
    console.info(`${file.name} file uploaded successfully.`);
    notification.notify({
      text: FILE_UPLOADING_SUCCESS.replace("File", file.name),
      type: "success",
    });
  } else if (file.status === "error") {
    resetFormData(`${file.name} file upload failed.`);
  }
};

const customRequest = ({
  file,
  onSuccess,
}: {
  file: File;
  onSuccess: (arg0: null, arg1: File) => void;
}) => {
  uploadedFile.value = file; // store the uploaded file for later processing
  setTimeout(() => onSuccess(null, file), 1000); // simulate successful upload
};

const beforeUpload = (file: File) => {
  const isCsv = file.type === "text/csv" || file.name.endsWith(".csv");
  if (!isCsv) {
    resetFormData("You can only upload CSV files!");
  }
  return isCsv;
};

const applyDefaultLangValue = (
  value: string | Record<string, string>,
  findValue: string,
  translationKey: string
): string | void => {
  if (!isObject(value)) {
    value =
      typeof value === "string" &&
      value.trim().toLowerCase() === findValue.trim().toLowerCase()
        ? translationKey
        : value;
    return value;
  }
  for (const lang in languagesStore.availableLanguagesCodes) {
    value[lang] = applyDefaultLangValue(
      value[lang],
      findValue,
      translationKey
    ) as string;
  }
};

const processFile = () => {
  loading.value = true;

  if (!uploadedFile.value) {
    resetFormData("No file uploaded to process.");
    return;
  }

  const reader = new FileReader();
  reader.onload = e => {
    const csvData = e.target?.result as string;
    const jsonData = Papa.parse<IOsgTranslationsParseResult>(csvData, {
      header: true,
    });

    if (!jsonData.data || !jsonData.data.length) {
      resetFormData("No data in CSV file!");
      return;
    }

    const keys = Object.keys(jsonData.data[0]);

    if (keys.length !== 2) {
      resetFormData("Invalid CSV file. Invalid keys.");
      return;
    }

    if (keys[0] !== "keyName" || keys[1] !== "translation") {
      resetFormData("Invalid CSV file. Should be keyName, translation only.");
      return;
    }

    try {
      processOsgTranslations(jsonData.data);
    } catch (error) {
      console.error(error);
      resetFormData("The problem occurred during the parsing process.");
    }

    notification.notify({
      text: FILE_PROCESSING_SUCCESS,
      type: "success",
    });

    setTimeout(() => {
      resetFormData();
    }, 1500);
  };

  reader.onerror = () => {
    resetFormData("Failed to read file.");
  };

  reader.readAsText(uploadedFile.value);
};

const processOsgTranslations = (
  osgTranslations: IOsgTranslationsParseResult[]
): void => {
  if (!osgTranslations) return;

  const osgTranslationsDict = osgTranslations.reduce(
    (acc: Record<string, string>, item) => {
      // like in task description (i.e. osg.50501.name.e-sports -> osg.name.e-sports)
      const key = `[:osg.${item.keyName.split(".").slice(2).join(".")}]`;
      if (item.translation) {
        acc[item.translation] = key;
      }
      return acc;
    },
    {}
  );

  for (const widget of gridStore.pageWidgets) {
    for (const translation in osgTranslationsDict) {
      const key = osgTranslationsDict[translation];

      if (translation) {
        widgetChanges.processWidgetTextContent(
          widget,
          (value: string | Record<string, string>) => {
            applyDefaultLangValue(value, translation, key);
          },
          { skipHtml: true }
        );
        // TODO - change only in BreadcrumbsWidget; needs better solution
        if (["BreadcrumbsWidget"].includes(widget.name)) {
          widgetChanges.processWidgetDataParamsContent(
            widget,
            (value: string | Record<string, string>) => {
              applyDefaultLangValue(value, translation, key);
            }
          );
        }
      }
    }
  }
};

function resetFormData(message?: string, messageType = "error") {
  if (message) {
    console.debug(message);
    notification.notify({
      text: message,
      type: messageType,
    });
  }
  loading.value = false;
  osgTranslations.dragger = [];
}
</script>

<template>
  <a-typography-text>OSG translations file</a-typography-text>

  <a-form-item>
    <a-upload-dragger
      v-model:fileList="osgTranslations.dragger"
      :custom-request="customRequest"
      :before-upload="beforeUpload"
      :max-count="1"
      accept=".csv"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">Support for a single upload.</p>
    </a-upload-dragger>
  </a-form-item>

  <a-form-item>
    <a-button
      class="config__action-button"
      type="primary"
      :loading="loading"
      @click="processFile"
    >
      Process
    </a-button>
  </a-form-item>
</template>
