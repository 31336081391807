import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  HEADER_CONTAINER_INITIAL,
  HEADER_COUNTERS_INITIAL,
  BETS_CONTAINER_INITIAL,
  BETS_TYPE_INITIAL,
  BET_AMOUNTS_INITIAL,
  MY_BETS_WARNING_MESSAGE_INITIAL,
  RESULT_BUTTON_WITHOUT_ICON_INITIAL,
} from "~~/constants/configs/betslip-common/initial-constants";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetsStore } from "~~/store/widgets";

import {
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
} from "../text/useTextWidgetConfig";
import { IFields } from "../useWidgetFields";

export const initWidgetFieldsOptions = (fields: ComputedRef<IFields>): void => {
  const widgetsStore = useWidgetsStore();

  const initialHeaderTabsContainerOptions = prefillEmptyOptions(
    fields.value.header_tabs_container.field.options,
    getInitialOptions({
      data: HEADER_CONTAINER_INITIAL(),
    }).options
  );

  const initialHeaderTabsCountersOptions = prefillEmptyOptions(
    fields.value.header_tabs_container_counters.field.options,
    getInitialOptions({
      data: HEADER_COUNTERS_INITIAL(),
    }).options
  );

  const initialBetslipTabsOptions = prefillEmptyOptions(
    fields.value.betslip_tabs_container.field.options,
    getInitialOptions({
      data: HEADER_CONTAINER_INITIAL(),
      exclude: ["gapBetweenTextAndCounter"],
    }).options
  );

  const initialBetsContainerOptions = prefillEmptyOptions(
    fields.value.bets_container.field.options,
    getInitialOptions({
      data: BETS_CONTAINER_INITIAL(),
      exclude: ["margins"],
    }).options
  );

  const initialCardsContainerOptions = prefillEmptyOptions(
    fields.value.cards_container.field.options,
    getInitialOptions({
      data: BETS_CONTAINER_INITIAL(),
    }).options
  );

  const initialEventContainerOptions = prefillEmptyOptions(
    fields.value.event_container.field.options,
    getInitialOptions({
      data: BETS_CONTAINER_INITIAL(),
    }).options
  );

  const initialTimeTitleOptions = prefillEmptyOptions(
    fields.value.time_title.field.options,
    getInitialOptions({
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
    }).options
  );

  const initialBetsTypeOptions = prefillEmptyOptions(
    fields.value.bets_type_title.field.options,
    getInitialOptions({
      data: BETS_TYPE_INITIAL(),
    }).options
  );

  const initialLiveStatusOptions = prefillEmptyOptions(
    fields.value.live_status_title.field.options,
    getInitialOptions({
      data: BETS_TYPE_INITIAL(),
      exclude: ["states"],
    }).options
  );

  const initialNotAvailableStatusOptions = prefillEmptyOptions(
    fields.value.not_available_status_title.field.options,
    getInitialOptions({
      data: BETS_TYPE_INITIAL(),
      exclude: ["states"],
    }).options
  );

  const initialEventNameOptions = prefillEmptyOptions(
    fields.value.event_name_title.field.options,
    getInitialOptions({
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
    }).options
  );

  const formInitialOptions = prefillEmptyOptions(
    fields.value.form.field.options,
    getInitialOptions({
      data: BETS_CONTAINER_INITIAL(),
      exclude: ["spaceBetween", "margins"],
    }).options
  );

  const initialCloseIconOptions = prefillEmptyOptions(
    fields.value.close_icon.field.options,
    getInitialOptions({
      data: {
        options: {
          _active: true,
          color: {
            color: "#000000",
            opacity: 100,
          },
          iconSize: 16,
        },
      },
    }).options
  );

  const initialMarketTitleOptions = prefillEmptyOptions(
    fields.value.market_title.field.options,
    getInitialOptions({
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
    }).options
  );

  const initialBetNameTitleOptions = prefillEmptyOptions(
    fields.value.bet_name_title.field.options,
    getInitialOptions({
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
    }).options
  );

  const initialBetAmountsContainerOptions = prefillEmptyOptions(
    fields.value.bet_amounts_container.field.options,
    getInitialOptions({
      data: BET_AMOUNTS_INITIAL(),
      exclude: ["margins"],
    }).options
  );

  const initialCoefOptions = prefillEmptyOptions(
    fields.value.coefficient_title.field.options,
    getInitialOptions({
      data: BETS_TYPE_INITIAL(),
      exclude: ["states"],
      additionalData: {
        options: {
          arrow: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      },
      values: {
        states: {
          [State.UP]: {
            fill: {
              color: "",
              opacity: 100,
            },
            border: getDefaultBorderValue(),
            color: {
              color: "#123123",
              opacity: 100,
            },
            arrow: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
          [State.DOWN]: {
            fill: {
              color: "",
              opacity: 100,
            },
            border: getDefaultBorderValue(),
            color: {
              color: "#000000",
              opacity: 100,
            },
            arrow: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
        },
      },
    }).options
  );

  const initialResultContainerOptions = prefillEmptyOptions(
    fields.value.result_container.field.options,
    getInitialOptions({
      data: BETS_CONTAINER_INITIAL(),
      exclude: ["spaceBetween"],
    }).options
  );

  const initialMyBetsWarningOptions = prefillEmptyOptions(
    fields.value.my_bets_warning_message.field.options,
    getInitialOptions({
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
    }).options
  );

  const initialResultTotalAmountsOptions = prefillEmptyOptions(
    fields.value.result_container_total_amounts.field.options,
    getInitialOptions({
      data: BET_AMOUNTS_INITIAL(),
      exclude: ["margins"],
    }).options
  );

  const initialResultWarningMessageOptions = prefillEmptyOptions(
    fields.value.result_container_warning_message_title.field.options,
    getInitialOptions({
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
    }).options
  );

  const initialResultBetButtonOptions = prefillEmptyOptions(
    fields.value.result_container_bet_button.field.options,
    getInitialOptions({
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL(),
    }).options
  );

  const initialResultRemoveButtonOptions = prefillEmptyOptions(
    fields.value.result_container_remove_button.field.options,
    getInitialOptions({
      data: BUTTON_INITIAL(),
    }).options
  );

  const initialResultCopyButtonOptions = prefillEmptyOptions(
    fields.value.result_container_copy_button.field.options,
    getInitialOptions({
      data: BUTTON_INITIAL(),
    }).options
  );

  const initialSuccessMessageOptions = prefillEmptyOptions(
    fields.value.success_message_title.field.options,
    getInitialOptions({
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
      additionalData: {
        options: {
          printSettings: {
            fontFamily: getDefaultFontFamily(),
            theme: 24,
            color: getDefaultFillValue("#ffffff"),
          },
        },
      },
    }).options
  );

  const initialDescriptionOptions = prefillEmptyOptions(
    fields.value.no_bets_description.field.options,
    getInitialOptions({
      data: DESCRIPTION_INITIAL(),
      values: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    }).options
  );

  const initialSaveChoiseButtonOptions = prefillEmptyOptions(
    fields.value.bets_result_save_choice_button.field.options,
    getInitialOptions({
      data: BUTTON_INITIAL(),
    }).options
  );

  const initialContinueButtonOptions = prefillEmptyOptions(
    fields.value.bets_result_continue_button.field.options,
    getInitialOptions({
      data: BUTTON_INITIAL(),
    }).options
  );

  const initialPrimaryButtonOptions = prefillEmptyOptions(
    fields.value.my_bets_primary_button.field.options,
    getInitialOptions({
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL(),
    }).options
  );

  const initialSecondaryButtonOptions = prefillEmptyOptions(
    fields.value.my_bets_secondary_button.field.options,
    getInitialOptions({
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL(),
    }).options
  );

  widgetsStore.updateFieldOptions(
    fields.value.header_tabs_container.field,
    initialHeaderTabsContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.header_tabs_container_counters.field,
    initialHeaderTabsCountersOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.betslip_tabs_container.field,
    initialBetslipTabsOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bets_container.field,
    initialBetsContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.cards_container.field,
    initialCardsContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.event_container.field,
    initialEventContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.time_title.field,
    initialTimeTitleOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bets_type_title.field,
    initialBetsTypeOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.event_name_title.field,
    initialEventNameOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.live_status_title.field,
    initialLiveStatusOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.not_available_status_title.field,
    initialNotAvailableStatusOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.close_icon.field,
    initialCloseIconOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.market_title.field,
    initialMarketTitleOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bet_name_title.field,
    initialBetNameTitleOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bet_amounts_container.field,
    initialBetAmountsContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.coefficient_title.field,
    initialCoefOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.my_bets_warning_message.field,
    initialMyBetsWarningOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container.field,
    initialResultContainerOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container_total_amounts.field,
    initialResultTotalAmountsOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container_warning_message_title.field,
    initialResultWarningMessageOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container_bet_button.field,
    initialResultBetButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container_remove_button.field,
    initialResultRemoveButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.result_container_copy_button.field,
    initialResultCopyButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.success_message_title.field,
    initialSuccessMessageOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.no_bets_description.field,
    initialDescriptionOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bets_result_save_choice_button.field,
    initialSaveChoiseButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.bets_result_continue_button.field,
    initialContinueButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.my_bets_primary_button.field,
    initialPrimaryButtonOptions
  );

  widgetsStore.updateFieldOptions(
    fields.value.my_bets_secondary_button.field,
    initialSecondaryButtonOptions
  );

  widgetsStore.updateFieldOptions(fields.value.form.field, formInitialOptions);
};
