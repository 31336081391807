<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <LiveBetsListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="live-bets-list-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="live-bets-list-widget__header-title"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <BetsListTabs
        :widget="widget"
        :states="states"
      />

      <WidgetField
        v-if="fields.event_cards.active"
        :widget="widget"
        :field="fields.event_cards.field"
      >
        <SportsbookLiveEventCards
          :widget="widget"
          :field="fields.event_cards.field"
          :suspended-field="fields.event_suspended_status.field"
          :cards="eventCards"
          :event-cards-state="states.event_cards"
          :style="eventCardsContainerStyles"
        >
          <template #empty>
            <BasicTitleField :field="fields.no_events_title.field" />
          </template>

          <template #suspended>
            <WidgetField
              :widget="widget"
              :field="fields.event_suspended_status.field"
            >
              <SportsBookLiveSuspended
                :field="fields.event_suspended_status.field"
                value="Suspended"
              />
            </WidgetField>
          </template>

          <template #time>
            <WidgetField
              :widget="widget"
              :field="fields.event_time.field"
            >
              <SportsBookLiveTime :field="fields.event_time.field">
                00:00
              </SportsBookLiveTime>
            </WidgetField>
          </template>

          <template #league>
            <WidgetField
              :widget="widget"
              :field="fields.event_league.field"
            >
              <BasicTitleField
                :field="fields.event_league.field"
                value="League"
              />
            </WidgetField>
          </template>

          <template #event_info>
            <WidgetField
              :widget="widget"
              :field="fields.event_info.field"
            >
              <SportsbookLiveEventInfo
                :widget="widget"
                :field="fields.event_info.field"
                :team-player-field="fields.event_team_player.field"
                :score-field="fields.event_score.field"
              />
            </WidgetField>
          </template>

          <template #bet_items="{ items }">
            <WidgetField
              :widget="widget"
              :field="fields.bet_items.field"
            >
              <SportsbookLiveBetItems
                :field="fields.bet_items.field"
                :items="items"
                :state="states.bet_items"
              />
            </WidgetField>
          </template>
        </SportsbookLiveEventCards>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.show_more_title.field"
      >
        <BasicButtonField
          :field="fields.show_more_title.field"
          :current-state="states.show_more_title"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useLiveBetsListWidgetInitData } from "~~/composables/widgets/live-bets-list/useLiveBetsListWidgetInitData";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import { getDisplayGridStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useLiveBetsListWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const eventCards = [
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
];

const eventCardsContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.event_cards.field?.options;

  if (!settings) {
    return {};
  }

  return {
    ...getDisplayGridStyles(settings.gridSettings),
  };
});

initFields();
</script>

<style lang="scss">
.live-bets-list-widget {
  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__header {
    @include flex(unset, space-between, 0px);
  }

  &__header-title {
    flex: 1;
  }
}
</style>
