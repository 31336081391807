import { useWidgetsStore } from "~~/store/widgets";
import {
  VerticalAlignment,
  State,
  TextStyle,
  DisplayOrientation,
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultButtonTextSettings,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultShadow,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  BUTTON_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES: Record<string, string> = {
    widget: State.ACTIVE_EVENTS,
    sports_container: State.DEFAULT,
    title: State.DEFAULT,
    title_button: State.DEFAULT,
    event_cards: State.DEFAULT,
    racing_event_cards: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    racing_event_button: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({ ...DEFAULT_STATES });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("no_events_title", {
      data: TITLE_INITIAL(),
    });

    const titleState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(""),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue(""),
        icon: {
          color: getDefaultFillValue(""),
        },
      };
    };

    addFieldInitialOptions("title", {
      exclude: ["spacing"],
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 4,
          },
          ...TITLE_INITIAL().options,
          icon: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            size: 20,
          },
          padding: getDefaultSpacing({
            bottom: 12,
          }),
          states: {
            [State.HOVER]: titleState(),
          },
        },
      },
    });

    addFieldInitialOptions("title_button", {
      data: BUTTON_INITIAL(),
      values: {
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.CENTER,
        },
        buttonTextSettings: getDefaultButtonTextSettings({
          _active: false,
        }),
        fill: getDefaultFillValue(""),
        iconSettings: getDefaultIconSettings({
          default_value: "mdi:chevron-right",
          textColor: getDefaultFillValue("#000000"),
        }),
      },
    });

    const cardState = (): IWidgetOptions => {
      return {
        fill: getDefaultFillValue(""),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
      };
    };

    addFieldInitialOptions("event_cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          color: getDefaultFillValue(),
          display: {
            layout: 1,
            distance: 8,
          },
          states: {
            [State.ACTIVE]: cardState(),
            [State.HOVER]: cardState(),
            [State.DISABLED]: cardState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(12),
        },
      },
    });

    addFieldInitialOptions("event_suspended_status", {
      data: {
        options: {},
      },
    });

    addFieldInitialOptions("event_time", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          icon: {
            _active: true,
            value: "mdi:clock",
            size: 16,
            color: getDefaultFillValue("#69B1FF"),
          },
        },
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("event_league", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          display: ResizingType.HUG,
        },
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("event_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: DisplayOrientation.VERTICAL,
            distance: 8,
          },
        },
      },
    });

    addFieldInitialOptions("market_name", {
      data: { options: {} },
    });

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      };
    };

    addFieldInitialOptions("bet_items", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          displayMode: DisplayOrientation.VERTICAL,
          distance: 8,
          fontFamily: getDefaultFontFamily(),
          padding: getDefaultSpacing(8),
          name: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [],
          },
          coef: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [TextStyle.BOLD],
          },
          arrow: {
            up: {
              color: getDefaultFillValue("#1677ff"),
            },
            down: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
          states: {
            [State.HOVER]: initialBetItemState(),
            [State.ACTIVE]: initialBetItemState(),
            [State.DISABLED]: initialBetItemState(),
            [State.UP]: initialBetItemState(),
            [State.DOWN]: initialBetItemState(),
            suspended: initialBetItemState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#e6f4ff"),
        cornerRadius: getDefaultCornerRadiusValue(8),
      },
    });

    addFieldInitialOptions("event_button", {
      data: BUTTON_INITIAL(),
      values: {
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.CENTER,
        },
        buttonTextSettings: getDefaultButtonTextSettings({
          _active: false,
          textColor: getDefaultFillValue("#000000", 45),
        }),
        iconSettings: getDefaultIconSettings({
          textColor: getDefaultFillValue("#000000", 45),
          default_value: "mdi:chevron-right",
        }),

        fill: getDefaultFillValue(""),
        cornerRadius: getDefaultCornerRadiusValue(8),
        padding: getDefaultSpacing({
          top: 4,
          bottom: 4,
          left: 12,
          right: 12,
        }),
        margins: getDefaultSpacing({
          top: 8,
        }),
      },
    });

    addFieldInitialOptions("favourites_button", {
      data: {
        options: {
          size: 20,
          color: getDefaultFillValue("#FFC53D"),
        },
      },
    });

    addFieldInitialOptions("stream_icon", {
      data: {
        options: {
          textColor: getDefaultFillValue("#52C41A"),
          iconSize: 20,
          value: "ant-design:play-circle-filled",
        },
      },
    });

    const sportsContainerState = (): IWidgetOptions => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    addFieldInitialOptions("sports_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distanceBetweenSports: 20,
            distanceBetweenLeagueEvents: 16,
          },
          states: {
            [State.HOVER]: sportsContainerState(),
          },
        },
      },
    });

    addFieldInitialOptions("leagues_titles", {
      data: DESIGN_INITIAL(),
      exclude: ["alignment", "spacing"],
      additionalData: {
        options: TITLE_INITIAL().options,
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("sport_events_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: 1,
            distance: 12,
          },
        },
      },
    });

    addFieldInitialOptions("event_header", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: DisplayOrientation.VERTICAL,
            position: "top",
            iconAlignment: VerticalAlignment.TOP,
            distance: 4,
          },
        },
      },
    });

    addFieldInitialOptions("event_period", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          display: ResizingType.HUG,
        },
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("event_name", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
      },
    });

    addFieldInitialOptions("event_score", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          ...DESIGN_INITIAL().options,
        },
      },
      exclude: ["link", "shadow", "alignment", "size"],
      additionalData: {
        options: {},
      },
      values: {
        theme: 13,
        spacing: {
          padding: getDefaultSpacing({
            top: 8,
            bottom: 8,
            left: 12,
            right: 12,
          }),
          margin: getDefaultSpacing(0),
        },
        fill: getDefaultFillValue("#4096FF"),
        color: getDefaultFillValue("#ffffff"),
        decoration: [TextStyle.BOLD],
      },
    });

    addFieldInitialOptions("racing_events_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: 1,
            distance: 12,
          },
        },
      },
    });

    addFieldInitialOptions("racing_event_cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          color: getDefaultFillValue(),
          display: {
            layout: DisplayOrientation.HORIZONTAL,
            distance: 8,
          },
          states: {
            [State.ACTIVE]: cardState(),
            [State.HOVER]: cardState(),
            [State.DISABLED]: cardState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(12),
        },
      },
    });

    addFieldInitialOptions("racing_event_stream_icon", {
      data: {
        options: {
          _active: true,
          textColor: getDefaultFillValue("#52C41A"),
          iconSize: 20,
          value: "ant-design:play-circle-filled",
        },
      },
    });

    addFieldInitialOptions("racing_event_name", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
      },
    });

    addFieldInitialOptions("racing_event_status", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          ...DESIGN_INITIAL().options,
        },
      },
      exclude: ["link", "shadow", "padding", "alignment"],
      values: {
        theme: 13,
        fill: getDefaultFillValue("#FFF1F0"),
        color: getDefaultFillValue("#F62F39"),
        cornerRadius: getDefaultCornerRadiusValue(4),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#F62F39"),
        }),
        spacing: {
          padding: getDefaultSpacing({
            left: 8,
            right: 8,
            top: 2,
            bottom: 2,
          }),
          margin: getDefaultSpacing({
            right: 8,
          }),
        },
      },
    });

    addFieldInitialOptions("racing_event_favourites_button", {
      data: {
        options: {
          _active: true,
          size: 20,
          color: getDefaultFillValue("#FFC53D"),
        },
      },
    });

    addFieldInitialOptions("racing_event_button", {
      data: BUTTON_INITIAL(),
      exclude: ["spacing"],
      values: {
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.CENTER,
        },
        buttonTextSettings: getDefaultButtonTextSettings({
          _active: false,
          textColor: getDefaultFillValue("#000000", 45),
        }),
        iconSettings: getDefaultIconSettings({
          textColor: getDefaultFillValue("#000000", 45),
          default_value: "mdi:chevron-right",
        }),

        fill: getDefaultFillValue(""),
        cornerRadius: getDefaultCornerRadiusValue(8),
        padding: getDefaultSpacing({
          top: 4,
          bottom: 4,
          left: 12,
          right: 12,
        }),
        margins: getDefaultSpacing({
          top: 8,
        }),
      },
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,

    init,
  };
};
