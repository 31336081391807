import { useLanguagesStore } from "~~/store/languages";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 64;
//

const applyDefaultLangValue = (
  value: IWidgetField["value"]
): Record<string, any> => {
  const languagesStore = useLanguagesStore();

  const defaultLang: keyof IWidgetField["value"] =
    languagesStore.defaultLanguage!
      .codeWithRegion as keyof IWidgetField["value"];

  if (!value) {
    return {};
  }

  const defaultValue = value![defaultLang];

  for (const lang in languagesStore.availableLanguagesCodes) {
    value![lang as keyof IWidgetField["value"]] = defaultValue;
  }

  return value as Record<string, any>;
};

// const isMultiLangField = (value: any) => {
//   if (typeof value !== "object" || !value) {
//     return false;
//   }

//   const languagesStore = useLanguagesStore();

//   const defaultLang: keyof IWidgetField["value"] =
//     languagesStore.defaultLanguage!
//       .codeWithRegion as keyof IWidgetField["value"];

//   if (!Object.hasOwn(value, defaultLang)) {
//     return false;
//   }

//   return true;
// };

// const updateOptionsLangValue = (options: IWidgetOptions): void => {
//   for (const optionName in options) {
//     if (isMultiLangField(options[optionName])) {
//       options[optionName] = applyDefaultLangValue(options[optionName]);
//       continue;
//     }

//     if (typeof options[optionName] === "object" && options[optionName]) {
//       updateOptionsLangValue(options[optionName]);
//     }
//   }
// };

const v64WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (widget.options.link) {
    if (widget.options.link?.value) {
      widget.options.link.value = applyDefaultLangValue(
        widget.options.link.value
      );
    }

    if (widget.options.link?.authorizeValue) {
      widget.options.link.authorizeValue = applyDefaultLangValue(
        widget.options.link.authorizeValue
      );
    }
  }

  Object.values(widget.content).forEach((field: any) => {
    if (
      [
        WIDGET_FIELD_TYPES.IMAGE_ELEMENT,
        WIDGET_FIELD_TYPES.TEXT_ELEMENT,
        WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
        WIDGET_FIELD_TYPES.UNKNOWN,
      ].includes(field.type)
    ) {
      if (field.options.link) {
        if (field.options.link?.value) {
          field.options.link.value = applyDefaultLangValue(
            field.options.link.value
          );
        }

        if (field.options.link?.authorizeValue) {
          field.options.link.authorizeValue = applyDefaultLangValue(
            field.options.link.authorizeValue
          );
        }
      }
    } else if (
      [
        WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
        WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
      ].includes(field.type)
    ) {
      for (let index = 0; index < field.value.length; index++) {
        const itemsFieldValue = field.value[index]?.fields;
        if (Array.isArray(itemsFieldValue)) {
          itemsFieldValue.forEach((item: any) => {
            if (item.options.link?.value) {
              if (item.options.link.value) {
                item.options.link.value = applyDefaultLangValue(
                  item.options.link.value
                );
              }

              if (item.options.link?.authorizeValue) {
                item.options.link.authorizeValue = applyDefaultLangValue(
                  item.options.link.authorizeValue
                );
              }
            }
          });
        }
      }
    }
  });

  widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};
export default v64WidgetMigration;
