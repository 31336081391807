<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PopularLeaguesWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="popular-leagues-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <div
        v-if="states.widget === State.DEFAULT"
        :style="itemsStyles"
      >
        <WidgetField
          :widget="widget"
          :field="fields.menu_level_1.field"
        >
          <a-menu mode="inline">
            <div
              v-for="item in 3"
              :key="`item-${item}`"
              class="popular-leagues-widget__container_1"
            >
              <div class="popular-leagues-widget__element_1">
                <a-sub-menu class="popular-leagues-widget__menu_level_1">
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="popular-leagues-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>
      </div>

      <BasicTitleField
        v-else
        :field="fields.no_events_message.field"
      />
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSportsMenuStyles } from "~~/composables/widgets/sports-menu/useSportsMenuStyles";
import { useSportsMenuSharedLogic } from "~~/composables/widgets/sports-menu/useSportsMenuSharedLogic";
import { useStates } from "~~/composables/widgets/common/useStates";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const DEFAULT_STATES = {
  widget: State.DEFAULT,
  menu_level_1: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const { itemsStyles } = useSportsMenuStyles(props.widget, states, false);
const widgetSettingsStore = useWidgetSettingsStore();
const widgetsStore = useWidgetsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { itemFirstLevelIcon, fields } = useSportsMenuSharedLogic(
  ref(props.widget),
  states,
  false
);

const initialNoEventsMessageOptions = prefillEmptyOptions(
  fields.value.no_events_message.field.options,
  getInitialOptions({
    data: TITLE_INITIAL(),
    values: {
      theme: 13,
      color: {
        color: "#ffffff",
        opacity: 100,
      },
    },
    exclude: ["link"],
  }).options
);

widgetsStore.updateFieldOptions(
  fields.value.no_events_message.field,
  initialNoEventsMessageOptions
);
</script>

<style lang="scss">
.popular-leagues-widget {
  .ant-menu,
  .ant-sub-menu {
    background-color: transparent !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    max-width: 100% !important;
    height: initial !important;
    line-height: 1 !important;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-menu {
    border: none !important;
  }

  .ant-menu-item {
    &::after {
      display: none !important;
    }
  }

  .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled,
  .ant-menu-submenu-title {
    cursor: pointer !important;
    transition: all 0s !important;
    line-height: 1 !important;
  }

  .ant-menu-title-content {
    margin: 0 !important;
  }

  &__menu_level_1 {
    & > .ant-menu-submenu-title {
      background-color: var(--menu_level_1-backgroundColor) !important;
      padding-top: var(--menu_level_1-padding-top) !important;
      padding-left: var(--menu_level_1-padding-left) !important;
      padding-bottom: var(--menu_level_1-padding-bottom) !important;
      padding-right: var(--menu_level_1-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_1-borderRadius) !important;
      border-width: var(--menu_level_1-borderWidth) !important;
      border-color: var(--menu_level_1-borderColor) !important;
      border-style: var(--menu_level_1-borderStyle) !important;
      box-shadow: var(--menu_level_1-boxShadow) !important;

      /* Content */
      color: var(--menu_level_1-color) !important;
      font-size: var(--menu_level_1-fontSize) !important;
      font-family: var(--menu_level_1-fontFamily) !important;
      font-weight: var(--menu_level_1-fontWeight) !important;
      font-style: var(--menu_level_1-fontStyle) !important;
      text-decoration: var(--menu_level_1-textDecoration) !important;

      .ant-menu-item-icon {
        margin-right: var(--menu_level_1-spaceBetweenTextAndIcon) !important;
      }

      svg {
        fill: var(--menu_level_1-iconColor) !important;
        width: var(--menu_level_1-iconSize) !important;
        height: var(--menu_level_1-iconSize) !important;
      }

      & > .popular-leagues-widget__expand-icon {
        font-size: var(--menu_level_1-fontSize) !important;
        position: absolute !important;
        right: var(--menu_level_1-padding-right) !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        color: var(--menu_level_1-arrowIconColor) !important;
      }
    }
  }

  &__container_1 {
    padding-bottom: var(--menu_level_1-marginBottom) !important;
  }
}
</style>
