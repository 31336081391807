import { nanoid } from "nanoid";
import isObject from "lodash/isObject";

import { configAppApi, dashboardApi, cdnApi } from "~~/services/http";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useLanguagesStore } from "~~/store/languages";
import { useMetaStore } from "~~/store/meta";
import {
  GridWithMeta,
  ITemplateResponse,
} from "~~/models/stores/grid-store.model";
import { createContentWidget } from "~~/assets/utils/widget";
import { useGridConfig } from "~~/store/grid";
import {
  IGridWidgetsData,
  IPageContentResponse,
  IPageContentWidget,
} from "~~/models/page.model";
import runWidgetMigrations from "~~/migrations/runWidgetMigrations";

export type PageItemOptions = {
  cellsOptions: IWidgetOptions;
  cssFileId: string;
  tags?: string[];
};

export type Page = {
  id: string;
  name: string;
  route: string;
  template_id: string;
  published: 0 | 1;
  options: PageItemOptions;
};

export type PageResponse = {
  data: Page[];
};

export type PageContent = {
  [cellId: string]: {
    cellId: string;
    widgets: IPageContentWidget[];
  };
};

export type PageItemData = {
  options: PageItemOptions;
  pageContent: PageContent;
};

export type PagesContentData = {
  [pageId: string]: PageItemData;
};

export type PageWidgetsData = Record<
  string,
  { widgetId: string; cellId: string }[]
>;

export type WidgetWithMeta = IPageContentWidget & {
  key: string | number;
  page: any;
};

export type PageMeta = {
  id: string;
  name: string;
  options: PageItemOptions;
  template: string;
};

function series(items: any[], fn: (dataItem: any) => Promise<any>) {
  const result: Promise<any>[] = [];

  return items
    .reduce((acc, item) => {
      acc = acc.then(() => {
        return fn(item).then(res => result.push(res));
      });
      return acc;
    }, Promise.resolve())
    .then(() => result);
}

function splitToChunks(items: any[], chunkSize = 4) {
  const result = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    result.push(items.slice(i, i + chunkSize));
  }
  return result;
}

function all(dataItems: any[], fn: (dataItem: any) => Promise<any>) {
  const promises = dataItems.map(item => fn(item));
  return Promise.all(promises);
}

function chunks(
  items: any[],
  fn: (dataItem: any) => Promise<any>,
  chunkSize = 2
): Promise<any> {
  let result: Promise<any>[] = [];
  const chunks = splitToChunks(items, chunkSize);

  return series(chunks, chunk => {
    return all(chunk, fn).then(res => (result = result.concat(res)));
  }).then(() => result);
}

export const useGlobalPages = (isActive: Ref<boolean>) => {
  const languagesStore = useLanguagesStore();
  const metaStore = useMetaStore();
  const gridStore = useGridConfig();

  const pagesList = ref<Page[]>([]);
  const pagesContentData = ref<PagesContentData>({});
  const pagesData = ref<Record<string, PageMeta>>({});
  const templatesData = ref<Record<string, GridWithMeta>>({});
  const pagesWithErrors = ref<Record<string, Page>>({});

  const fetchAllPages = (skipUnpublished = false): Promise<Page[]> => {
    return configAppApi
      .get(
        `/project-pages?language=${languagesStore.defaultLanguage?.codeWithRegion}`
      )
      .then((response: PageResponse) => {
        if (skipUnpublished) {
          pagesList.value = response.data.filter(page => page.published);
          return response.data;
        }
        pagesList.value = response.data;

        const allPagesData: Record<string, PageMeta> = {};

        pagesList.value.forEach(page => {
          allPagesData[page.id] = {
            id: page.id,
            name: page.name,
            options: page.options,
            template: page.template_id,
          };
        });

        pagesData.value = allPagesData;

        return response.data;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  };

  const fetchPagesContent = async () => {
    const allPagesData: Record<string, any> = {};

    const config = {
      headers: {
        "Accept-Language": languagesStore.currentLanguage?.codeWithRegion,
      },
    };

    await chunks(pagesList.value, page => {
      if (!isActive.value) {
        return Promise.resolve();
      }

      /*
        Try to fetch page content from cdn,
        if error => fetch from api
      */
      return (
        cdnApi
          .get(
            `/projects/${metaStore.projectId}/pages/${
              page.id
            }.json?query=${Date.now()}`
          )
          // .then(res => {
          //   allPagesData[page.id] = res.data;
          // })
          .catch(() => {
            return dashboardApi.get(`/pages/${page.id}/get-content`, config);
            // .then(res => {
            //   allPagesData[page.id] = res.data;
            // });
          })
          .then(response => {
            const data = (response as unknown as IPageContentResponse).data;
            if (!data?.pageId) {
              console.error("ERROR", response);
              return;
            }

            for (const cellId in data.pageContent) {
              const widgetsContentList = data.pageContent[cellId].widgets;

              data.pageContent[cellId].widgets = widgetsContentList.map(
                widget => {
                  try {
                    const migratedWidget = runWidgetMigrations(widget);

                    return migratedWidget;
                  } catch (e) {
                    console.error(
                      `Error during migration on page ${page.route}`
                    );
                    console.log(e);

                    pagesWithErrors.value[page.id] = page;

                    return widget;
                  }
                }
              );
            }

            allPagesData[page.id] = data;
          })
      );
    });

    pagesContentData.value = allPagesData;

    return allPagesData;
  };

  const fetchTemplatesData = async (): Promise<
    Record<string, GridWithMeta>
  > => {
    const allTemplatesData: Record<string, GridWithMeta> = {};
    const templates: string[] = [];

    /*
      Get unique templates list
    */
    for (const pageId in pagesData.value) {
      const page = pagesData.value[pageId];

      if (templates.includes(page.template)) {
        continue;
      }

      templates.push(page.template);
    }

    /*
      Fetch templates details
    */

    for await (const templateId of templates) {
      if (!isActive.value) {
        break;
      }

      await dashboardApi
        .get(`/template/${templateId}`)
        .then((response: ITemplateResponse) => {
          allTemplatesData[templateId] = response.data;
        });
    }

    templatesData.value = allTemplatesData;
    return allTemplatesData;
  };

  const fetchPagesData = async (): Promise<Record<string, PageMeta>> => {
    const allPagesData: Record<string, PageMeta> = {};

    await chunks(pagesList.value, (page: any) => {
      if (!isActive.value) {
        return Promise.resolve();
      }

      return dashboardApi.get(`/pages/${page.id}`).then(res => {
        allPagesData[page.id] = res.data;
      });
    });

    // for await (const page of pagesList.value) {
    //   if (!isActive.value) {
    //     break;
    //   }

    //   await dashboardApi.get(`/pages/${page.id}`).then(res => {
    //     allPagesData[page.id] = res.data;
    //   });
    // }

    pagesData.value = allPagesData;
    return allPagesData;
  };

  const findSimilarTagWidgetsInPage = (
    widgetsList: IPageContentWidget[],
    widget: IWidgetWithFields
  ): IPageContentWidget[] => {
    if (!widget.options._tag) {
      return [];
    }

    return widgetsList.filter(
      currWidget => currWidget.options._tag === widget.options._tag
    );
  };

  const findSimilarTagWidgetsInGrid = (
    pageContent: PagesContentData,
    widget: IWidgetWithFields | undefined | null
  ): WidgetWithMeta[] => {
    if (!widget) {
      console.log("Widget is not selected");
      return [];
    }

    const similarWidgets = [] as WidgetWithMeta[];

    for (const pageId in pageContent) {
      const page = pageContent[pageId];

      for (const cellId in page.pageContent) {
        const widgets = findSimilarTagWidgetsInPage(
          page.pageContent[cellId].widgets,
          widget
        );

        similarWidgets.push(
          ...widgets.map(currWidget => {
            return {
              ...currWidget,
              page,
              key: `${pageId}==${cellId}==${currWidget.id}`,
            };
          })
        );
      }
    }

    return similarWidgets;
  };

  const findPageIdByWidget = (
    widget: IWidgetWithFields | IPageContentWidget
  ): string | null => {
    for (const pageId in pagesContentData.value) {
      const page = pagesContentData.value[pageId];

      for (const cellId in page.pageContent) {
        const widgetFound = page.pageContent[cellId].widgets.find(
          pageWidget => pageWidget.id === widget.id
        );
        if (widgetFound) {
          return pageId;
        }
      }
    }

    return null;
  };

  interface IApplyOperationParams {
    isApplyDataForCopiedWidgets: boolean;
  }

  /*
    Replace widget with same tag on
    new widget(currently selected)
  */
  const replaceWidgetInContent = (data: {
    widgetData: IPageContentWidget;
    pageData: PageItemData;
    cellId: string;
    widgetId: string;
    operationParams: IApplyOperationParams;
  }) => {
    const { widgetData, pageData, cellId, widgetId } = data;

    const cellData = pageData.pageContent[cellId];

    const widgetToReplaceIndex = cellData.widgets.findIndex(
      currWidget => currWidget.id === widgetId
    );

    if (widgetToReplaceIndex < 0) {
      return pageData;
    }

    const oldWidget = cellData.widgets[widgetToReplaceIndex];

    const widgetCopy = JSON.parse(JSON.stringify(widgetData));

    widgetCopy.id = null;
    widgetCopy.cellId = oldWidget.cellId;
    widgetCopy.position = oldWidget.position;

    if (
      isObject(widgetCopy.options.bindingParams) &&
      Object.keys(widgetCopy.options.bindingParams).length
    ) {
      Object.keys(widgetCopy.options.bindingParams).forEach(paramName => {
        if (!(paramName in oldWidget.options.bindingParams)) {
          oldWidget.options.bindingParams[paramName] =
            widgetCopy.options.bindingParams[paramName];

          oldWidget.options.bindingParams[paramName].value = null;

          if (widgetCopy.options.bindingParams[paramName]?.children) {
            oldWidget.options.bindingParams[paramName].children = {};
          }
        }
      });
    }

    if (
      !data.operationParams.isApplyDataForCopiedWidgets &&
      oldWidget.options.bindingParams
    ) {
      widgetCopy.options.bindingParams = oldWidget.options.bindingParams;
    }

    cellData.widgets[widgetToReplaceIndex] = widgetCopy;

    return pageData;
  };

  /*
    Run through selected items.
    Each item is a string with meta info,
    sepated with "=="
    (pageId==cellId==widgetId)

    Gather widgets by page(if page contains few widgets with same tag)
  */
  const getWidgetsByPage = (idsList: string[]): PageWidgetsData => {
    const widgetsByPage = idsList
      .filter(currIdItem => {
        const [pageId] = currIdItem.split("==");

        if (pagesWithErrors.value[pageId]) {
          return false;
        }

        return true;
      })
      .reduce((result: PageWidgetsData, currIdItem) => {
        const [pageId, cellId, widgetId] = currIdItem.split("==");

        if (!result[pageId]) {
          result[pageId] = [];
        }

        result[pageId].push({
          widgetId,
          cellId,
        });

        return result;
      }, {});

    return widgetsByPage;
  };

  const applyWidgetChangesToList = async (
    widget: IWidgetWithFields,
    idsList: string[],
    operationParams: IApplyOperationParams
  ) => {
    const currContentData: PagesContentData = JSON.parse(
      JSON.stringify(pagesContentData.value)
    );

    /*
      Create widget of format:
      {
        id: ...,
        content: {[fieldName]: [fieldDetails]}
      }

      (Instead of {fields: [...]})
    */
    const formattedWidgetData = createContentWidget(
      widget,
      widget.cell_id! as string
    );

    const widgetsByPage: PageWidgetsData = getWidgetsByPage(idsList);

    for await (const pageId of Object.keys(widgetsByPage)) {
      if (!isActive.value) {
        break;
      }

      const pageData = pagesData.value[pageId];
      if (!pageData) {
        throw new Error(`pageData not found for pageID: ${pageId}`);
      }

      const templateData = templatesData.value[pageData.template];
      if (!templateData) {
        throw new Error(
          `templatesData not found for pageID: ${pageId}, templateId: ${pageData.template}`
        );
      }

      const widgetItems = widgetsByPage[pageData.id];
      if (!widgetItems) {
        throw new Error(`widgetItems not found for pageID: ${pageData.id}`);
      }

      widgetItems.forEach(widgetItem => {
        const { widgetId, cellId } = widgetItem;

        /*
          Replace similar widget inside specified cell
        */
        currContentData[pageId] = replaceWidgetInContent({
          widgetData: formattedWidgetData,
          pageData: currContentData[pageId],
          widgetId: widgetId,
          cellId: cellId,
          operationParams,
        });
      });

      const pageContent = currContentData[pageId];
      const cellsGrid = templateData.grid;

      const cssFileId = nanoid();

      const pageContentData: IGridWidgetsData = {
        templateId: pageData.template,
        pageContent: pageContent.pageContent,
      };

      const generateCss = async () => {
        try {
          const res = gridStore.generateWidgetsGridCss({
            gridLayout: cellsGrid,
            page: pageData,
            widgetsGridData: pageContentData,
            cssFileId,
            cellsOptions: pageContent.options.cellsOptions,
          });

          return Promise.resolve(res);
        } catch (e) {
          return Promise.reject();
        }
      };

      // const currPage = pagesList.value.find(
      //   pageValue => pageValue.id === pageData.id
      // );

      // pagesWithErrors.value[pageData.id] = currPage!;

      try {
        const gridCss = await generateCss();

        await gridStore.saveContentPage({
          pageId: pageId,
          widgetsData: pageContentData,
          options: pageContent.options,
          cellsOptions: pageContent.options.cellsOptions,
          cssFileId: cssFileId,
        });

        await gridStore.saveWidgetsGridCss(gridCss);

        await new Promise(resolve => {
          /*
              Wait 7sec after page save
            */
          setTimeout(() => {
            resolve(true);
          }, 7000);
        });
      } catch (e) {
        const currPage = pagesList.value.find(
          pageValue => pageValue.id === pageData.id
        );

        pagesWithErrors.value[pageData.id] = currPage!;
      }
    }
  };

  return {
    pagesContentData,
    pagesData,
    templatesData,
    pagesList,
    pagesWithErrors,

    fetchAllPages,
    fetchPagesContent,
    fetchPagesData,
    fetchTemplatesData,

    findPageIdByWidget,
    findSimilarTagWidgetsInGrid,

    applyWidgetChangesToList,
  };
};
