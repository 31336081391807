import type { Ref } from "vue";

import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const WIDGET_SETTINGS_ITEMS = [
  {
    label: "Header",
    value: "header_tabs_container",
  },
  {
    label: "Bets container",
    value: "bets_container",
  },
  {
    label: "Result",
    value: "result_container",
  },
];

const WIDGET_SETTINGS_MY_BETS_ITEMS = [
  {
    label: "Header",
    value: "header_tabs_container",
  },
  {
    label: "Tabs",
    value: "betslip_tabs_container",
  },
  {
    label: "Bets container",
    value: "bets_container",
  },
];

const WIDGET_SETTINGS_NO_BETS_ITEMS = [
  {
    label: "Header",
    value: "header_tabs_container",
  },
  {
    label: "Bets container",
    value: "bets_container",
  },
];

const BETS_SETTINGS_BETSLIP_ITEMS = [
  {
    label: "Collapse",
    value: "collapse",
  },
  {
    label: "Cards",
    value: "cards_container",
  },
  {
    label: "Fields",
    value: "form",
  },
];

const BETS_SETTINGS_BETS_RESULT_ITEMS = [
  {
    label: "Success message",
    value: "success_message_title",
  },
  {
    label: "Cards",
    value: "cards_container",
  },
];

const BETS_SETTINGS_DEFAULT_ITEMS = [
  {
    label: "Cards",
    value: "cards_container",
  },
];

const BETS_CARDS_DEFAULT_SETTINGS_ITEMS = [
  {
    label: "Live status",
    value: "live_status_title",
  },
  {
    label: "Not available status",
    value: "not_available_status_title",
  },
  {
    label: "Close Icon",
    value: "close_icon",
  },
  {
    label: "Event container",
    value: "event_container",
  },
];

const BETS_CARDS_MY_BETS_SETTINGS_ITEMS = [
  {
    label: "Bets type",
    value: "bets_type_title",
  },
  {
    label: "Live status",
    value: "live_status_title",
  },
  {
    label: "Time",
    value: "time_title",
  },
  {
    label: "Event container",
    value: "event_container",
  },
  {
    label: "Bet amounts",
    value: "bet_amounts_container",
  },
  {
    label: "Warning message",
    value: "my_bets_warning_message",
  },
  {
    label: "Primary button",
    value: "my_bets_primary_button",
  },
  {
    label: "Secondary button",
    value: "my_bets_secondary_button",
  },
];

const BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS = [
  {
    label: "Bets type",
    value: "bets_type_title",
  },
  {
    label: "Live status",
    value: "live_status_title",
  },
  {
    label: "Time",
    value: "time_title",
  },
  {
    label: "Event container",
    value: "event_container",
  },
  {
    label: "Bet amounts",
    value: "bet_amounts_container",
  },
];

const RESULT_SETTINGS_ITEMS_DEFAULT = [
  {
    label: "Total amounts",
    value: "result_container_total_amounts",
  },
  {
    label: "Warning message",
    value: "result_container_warning_message_title",
  },
  {
    label: "Action button",
    value: "result_container_bet_button",
  },
  {
    label: "Remove button",
    value: "result_container_remove_button",
  },
  {
    label: "Copy button",
    value: "result_container_copy_button",
  },
];

const RESULT_SETTINGS_ITEMS_BETS_RESULT = [
  {
    label: "Total amounts",
    value: "result_container_total_amounts",
  },
  {
    label: "Save choice button",
    value: "bets_result_save_choice_button",
  },
  {
    label: "Continue button",
    value: "bets_result_continue_button",
  },
];

const EVENT_CONTAINER_ITEMS = [
  {
    label: "Event Name",
    value: "event_name_title",
  },
  {
    label: "Bet market",
    value: "market_title",
  },
  {
    label: "Bet Name",
    value: "bet_name_title",
  },
  {
    label: "Coefficient",
    value: "coefficient_title",
  },
];

// Need this dividing, since for each view we need to generate different dropdown items.
const betslipItems = [
  generateDropdownItem(
    "Cards settings",
    "cards_container",
    "cards_container_betslip"
  ),
  generateDropdownItem(
    "Live status",
    "live_status_title",
    "cards_container_betslip"
  ),

  generateDropdownItem(
    "Event Name",
    "event_name_title",
    "cards_container_betslip"
  ),
  generateDropdownItem("Bet market", "market_title", "cards_container_betslip"),
  generateDropdownItem("Bet Name", "bet_name_title", "cards_container_betslip"),
  generateDropdownItem(
    "Coefficient",
    "coefficient_title",
    "cards_container_betslip"
  ),
  generateDropdownItem(
    "Event container",
    "event_container",
    "cards_container_betslip"
  ),
  generateDropdownItem("Result settings", "result_container", "result_betslip"),
  generateDropdownItem(
    "Total amounts",
    "result_container_total_amounts",
    "result_betslip"
  ),
  generateDropdownItem(
    "Warning message",
    "result_container_warning_message_title",
    "result_betslip"
  ),
  generateDropdownItem(
    "Action button",
    "result_container_bet_button",
    "result_betslip"
  ),
  generateDropdownItem(
    "Remove button",
    "result_container_remove_button",
    "result_betslip"
  ),
  generateDropdownItem(
    "Copy button",
    "result_container_copy_button",
    "result_betslip"
  ),
  generateDropdownItem(
    "Accept changes",
    "accept_changes_container",
    "result_betslip"
  ),
];

const myBetsItems = [
  generateDropdownItem(
    "Cards settings",
    "cards_container",
    "cards_container_my_bets"
  ),
  generateDropdownItem(
    "Live status",
    "live_status_title",
    "cards_container_my_bets"
  ),
  generateDropdownItem(
    "Event Name",
    "event_name_title",
    "cards_container_my_bets"
  ),
  generateDropdownItem("Bet market", "market_title", "cards_container_my_bets"),
  generateDropdownItem("Bet Name", "bet_name_title", "cards_container_my_bets"),
  generateDropdownItem(
    "Coefficient",
    "coefficient_title",
    "cards_container_my_bets"
  ),
  generateDropdownItem("Time", "time_title", "cards_container_my_bets"),
  generateDropdownItem(
    "Bets type",
    "bets_type_title",
    "cards_container_my_bets"
  ),
  generateDropdownItem(
    "Bet amounts",
    "bet_amounts_container",
    "cards_container_my_bets"
  ),

  generateDropdownItem(
    "Event container",
    "event_container",
    "cards_container_my_bets"
  ),
];

const betsResultItems = [
  generateDropdownItem(
    "Cards settings",
    "cards_container",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Live status",
    "live_status_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Event Name",
    "event_name_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Bet market",
    "market_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Bet Name",
    "bet_name_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Coefficient",
    "coefficient_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Result settings",
    "result_container",
    "result_bets_result"
  ),
  generateDropdownItem(
    "Total amounts",
    "result_container_total_amounts",
    "cards_container_bets_result"
  ),
  generateDropdownItem("Time", "time_title", "cards_container_bets_result"),
  generateDropdownItem(
    "Bets type",
    "bets_type_title",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Bet amounts",
    "bet_amounts_container",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Event container",
    "event_container",
    "cards_container_bets_result"
  ),
  generateDropdownItem(
    "Success message",
    "success_message_title",
    "success_bets_result"
  ),
  generateDropdownItem(
    "Save choice",
    "bets_result_save_choice_button",
    "bets_result_buttons"
  ),
  generateDropdownItem(
    "Continue",
    "bets_result_continue_button",
    "bets_result_buttons"
  ),
  generateDropdownItem(
    "System bet name",
    "bets_result_system_name",
    "bets_result_buttons"
  ),
];

const sharedItems = [
  generateDropdownItem("Collapse", "collapse"),
  generateDropdownItem("Tooltip", "multibet_tooltip"),
  generateDropdownItem("Header settings", "header_tabs_container", "header"),
  generateDropdownItem("Counters", "header_tabs_container_counters", "header"),
  generateDropdownItem("Cash out", "cashout", "header"),
  generateDropdownItem("Tabs settings", "betslip_tabs_container"),
  generateDropdownItem("Bets container", "bets_container", "bets_container"),
  generateDropdownItem(
    "Not available status",
    "not_available_status_title",
    "cards_container_betslip"
  ),
  generateDropdownItem("Close icon", "close_icon", "cards_container_betslip"),
  generateDropdownItem("Fields settings", "form", "fields_container_betslip"),
  generateDropdownItem("Description", "no_bets_description", "description"),
  generateDropdownItem(
    "Bets warning message",
    "my_bets_warning_message",
    "cards_container_my_bets"
  ),
  generateDropdownItem(
    "Primary button",
    "my_bets_primary_button",
    "buttons_my_bets"
  ),
  generateDropdownItem(
    "Secondary button",
    "my_bets_secondary_button",
    "buttons_my_bets"
  ),

  generateDropdownItem("Each way bet", "each_way_bet", "each_way_bet"),
  generateDropdownItem("Checkbox", "each_way_bet_checkbox", "each_way_bet"),
  generateDropdownItem("Tooltip", "each_way_bet_tooltip", "each_way_bet"),
  generateDropdownItem("Stake info", "stake_info", "each_way_bet"),
  generateDropdownItem("Total stake", "total_stake", "each_way_bet"),
];

export const useFenixBetslipItems = (widget: Ref<IWidgetWithFields>) => {
  const BETSLIP_VIEW_DROPDOWN_ITEMS = [...sharedItems, ...betslipItems];

  const MY_BETS_VIEW_DROPDOWN_ITEMS = [...sharedItems, ...myBetsItems];

  const BETS_RESULT_VIEW_DROPDOWN_ITEMS = [...sharedItems, ...betsResultItems];

  const BETSLIP_DROPDOWN_ITEMS = computed(() => {
    return [
      ...BETSLIP_VIEW_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  const MY_BETS_DROPDOWN_ITEMS = computed(() => {
    return [
      ...MY_BETS_VIEW_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  const BETS_RESULT_DROPDOWN_ITEMS = computed(() => {
    return [
      ...BETS_RESULT_VIEW_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    header_tabs_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    header_tabs_container_counters: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    betslip_tabs_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bets_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Preview", "preview"),
    ],
    cards_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_status_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    not_available_status_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    coefficient_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    result_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    result_container_warning_message_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    result_container_bet_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    result_container_remove_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    result_container_copy_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bets_type_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    my_bets_warning_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bets_result_save_choice_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bets_result_continue_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    my_bets_primary_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    my_bets_secondary_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    success_message_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    form: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    fields_styling: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    collapse: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    multibet_tooltip: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    each_way_bet: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    each_way_bet_checkbox: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    each_way_bet_tooltip: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    stake_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    total_stake: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    accept_changes_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  return {
    BETSLIP_DROPDOWN_ITEMS,
    MY_BETS_DROPDOWN_ITEMS,
    BETS_RESULT_DROPDOWN_ITEMS,
    TABS,
    WIDGET_SETTINGS_ITEMS,
    WIDGET_SETTINGS_MY_BETS_ITEMS,
    WIDGET_SETTINGS_NO_BETS_ITEMS,
    BETS_SETTINGS_BETSLIP_ITEMS,
    BETS_SETTINGS_BETS_RESULT_ITEMS,
    BETS_SETTINGS_DEFAULT_ITEMS,
    BETS_CARDS_DEFAULT_SETTINGS_ITEMS,
    BETS_CARDS_MY_BETS_SETTINGS_ITEMS,
    BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS,
    RESULT_SETTINGS_ITEMS_DEFAULT,
    RESULT_SETTINGS_ITEMS_BETS_RESULT,
    EVENT_CONTAINER_ITEMS,
  };
};
