import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultSpacing,
} from "../common/widget-initial";

export const HEADER_CONTAINER_INITIAL = () => ({
  value: "Header",
  options: {
    _active: true,
    fill: getDefaultFillValue("#000000"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(""),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    spaceBetween: 0,
    gapBetweenTextAndCounter: 12,
    padding: getDefaultSpacing({
      top: 5,
      bottom: 5,
    }),
    margins: getDefaultSpacing(0),
    buttonDisplaySettings: {
      resizing: "hug",
      alignment: "center",
    },
    headerElementsSettings: {
      fill: getDefaultFillValue(""),
      border: getDefaultBorderValue(),
      shadow: {
        fill: getDefaultFillValue(""),
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      padding: {
        top: 5,
        bottom: 5,
        left: 5,
        right: 5,
      },
      cornerRadius: getDefaultCornerRadiusValue(6),
    },
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    alignment: "center",
    decoration: [],
    color: getDefaultFillValue("#ffffff"),
    states: {
      [State.HOVER]: {
        headerElementsSettings: {
          fill: getDefaultFillValue(""),
          border: getDefaultBorderValue(),
          shadow: {
            fill: getDefaultFillValue(""),
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
        color: getDefaultFillValue("#ffffff"),
      },
      [State.ACTIVE]: {
        headerElementsSettings: {
          fill: getDefaultFillValue(""),
          border: getDefaultBorderValue(),
          shadow: {
            fill: getDefaultFillValue(""),
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
        color: getDefaultFillValue("#ffffff"),
      },
      [State.DISABLED]: {
        headerElementsSettings: {
          fill: getDefaultFillValue(""),
          border: getDefaultBorderValue(),
          shadow: {
            fill: getDefaultFillValue(""),
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
        color: getDefaultFillValue("#ffffff"),
      },
    },
  },
});

export const HEADER_COUNTERS_INITIAL = () => ({
  value: "Counters",
  options: {
    _active: true,
    fill: getDefaultFillValue("#000000"),
    border: getDefaultBorderValue(),
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 1,
      bottom: 1,
      left: 4,
      right: 4,
    },
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    color: getDefaultFillValue("#ffffff"),
    states: {
      [State.HOVER]: {
        fill: getDefaultFillValue(""),
        color: getDefaultFillValue("#ffffff"),
      },
      [State.ACTIVE]: {
        fill: getDefaultFillValue(""),
        color: getDefaultFillValue("#ffffff"),
      },
      [State.DISABLED]: {
        fill: getDefaultFillValue(""),
        color: getDefaultFillValue("#ffffff"),
      },
    },
  },
});

export const BETS_CONTAINER_INITIAL = () => ({
  value: "Bets",
  options: {
    _active: true,
    fill: getDefaultFillValue("#B5B5B5"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(""),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    spaceBetween: 0,
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const BETS_TYPE_INITIAL = () => ({
  value: "Header",
  options: {
    _active: true,
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
    },
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    alignment: "center",
    decoration: [],
    color: {
      color: "#000000",
      opacity: 100,
    },
    states: {
      [State.MULTI_BETS]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 100,
        },
      },
      [State.SYSTEM_BETS]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 100,
        },
      },
    },
  },
});

export const BET_AMOUNTS_INITIAL = () => ({
  value: "Header",
  options: {
    _active: true,
    gap: 2,
    fontFamily: getDefaultFontFamily(),
    labelSettings: {
      theme: 16,
      decoration: [],
      color: getDefaultFillValue("#ffffff"),
    },
    valueSettings: {
      theme: 16,
      decoration: [],
      color: getDefaultFillValue("#ffffff"),
    },
    position: DisplayOrientation.VERTICAL,
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
    },
  },
});

export const MY_BETS_WARNING_MESSAGE_INITIAL = () => ({
  value: "Header",
  options: {
    _active: true,
    fill: getDefaultFillValue("#1677FF"),
    border: getDefaultBorderValue(),
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    fontFamily: getDefaultFontFamily(),
    theme: 24,
    decoration: [],
    color: getDefaultFillValue("#ffffff"),
    iconSettings: {
      _active: true,
      iconSize: 20,
      gap: 8,
      textColor: getDefaultFillValue("#ffffff"),
    },
  },
});

export const RESULT_BUTTON_WITHOUT_ICON_INITIAL = () => ({
  value: "Button",
  options: {
    _active: true,
    fill: getDefaultFillValue("#1677FF"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    buttonTextSettings: {
      _active: true,
      textColor: getDefaultFillValue("#ffffff"),
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    buttonDisplaySettings: {
      resizing: "fill",
      alignment: "center",
    },
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    states: {
      [State.HOVER]: {
        fill: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          textColor: getDefaultFillValue("#ffffff"),
        },
      },
      [State.DISABLED]: {
        fill: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          textColor: getDefaultFillValue("#ffffff"),
        },
      },
    },
  },
});
