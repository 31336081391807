import { defineStore } from "pinia";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { ICell } from "~~/models/grid.interface";
import {
  type HistoryItem,
  getWidgetCell,
  getPreviousEntity,
  compareHistoryItems,
  getHistoryItemType,
} from "~~/assets/utils/widget-settings/settings-sidebar-utils";
import { useGridConfig } from "~~/store/grid";
import {
  findCellContainer,
  findCellRootElement,
} from "~~/assets/utils/grid/grid-tree";
import { getFieldByName } from "~~/assets/utils/widget";
import { WidgetDefaultSettingsDropdownItem } from "~~/models/widgets/widget.core/widget.model";
import { getWidgetsFromLayout } from "~~/assets/utils";

interface IWidgetSettingsStore {
  selectedWidget: IWidgetWithFields | null;
  selectedField: IWidgetField | null;
  selectedCell: ICell | null;
  selectedDropdownItem: string | null;
  hoveredControl: string | null;
  isLoadingWidgetId: string | null;
  hovered: {
    widget: IWidgetWithFields | null;
    field: IWidgetField | null;
    control: string | null;
  };
  historyStack: HistoryItem[];
  showTagDrawer: boolean;
  widgetsTagMode: boolean;
}

export const useWidgetSettingsStore = defineStore("widget-settings", {
  state: (): IWidgetSettingsStore => ({
    /* 
      Current selected widget
    */
    selectedWidget: null,
    /* 
      Current selected field
    */
    selectedField: null,
    /* 
      Current selected cell
    */
    selectedCell: null,
    /* 
      Current selected dropdown item(name)
    */
    selectedDropdownItem: null,
    /* 
      Current hovered settings control
      (needed for some cases, as showing spacing positions, when configuring padding/margin)
    */
    hoveredControl: null,

    isLoadingWidgetId: null,

    hovered: {
      widget: null,
      field: null,
      control: null,
    },

    /* 
      History of selected items
    */
    historyStack: [],

    /* 
      show widget tag drawe
    */
    showTagDrawer: false,

    /* 
      Show widgets with tags
    */
    widgetsTagMode: false,
  }),
  getters: {
    /* 
      List grid widgets
    */
    tagWidgets(): (string | number)[] {
      if (!this.widgetsTagMode) {
        return [];
      }

      const widgetsLayout = useGridConfig().currentWidgetsLayout;

      return getWidgetsFromLayout(widgetsLayout).map(widget => widget.id);
    },
    selectedColumn({ selectedCell }): ICell | null {
      return findCellContainer(
        useGridConfig().currentWidgetsLayout,
        selectedCell
      );
    },

    selectedContainer({ selectedCell }): ICell | null {
      return findCellRootElement(
        useGridConfig().currentWidgetsLayout,
        selectedCell
      );
    },

    hoveredWidget({ hovered }): IWidgetWithFields | null {
      return hovered.widget;
    },

    hoveredField({ hovered }): IWidgetField | null {
      return hovered.field;
    },
  },
  actions: {
    toggleWidgetsTagMode(): void {
      this.widgetsTagMode = !this.widgetsTagMode;
    },
    updateTagDrawerValue(value: boolean): void {
      this.showTagDrawer = value;
    },
    selectPreviousHistoryItem(): void {
      const prevItemIndex = this.historyStack.length - 2;
      const prevItem = this.historyStack[prevItemIndex];

      if (!prevItem) {
        return;
      }

      const prevEntity = getPreviousEntity({
        widget: this.selectedWidget!,
        cell: this.selectedCell!,
        prevHistoryItem: prevItem,
      });

      this._setActiveEntity(prevEntity);

      this.setDropdownItem({
        name: prevItem.value,
        customValue: prevItem.customValue,
        addToHistory: false,
      });

      /* 
        Remove last item
      */
      this.historyStack.splice(this.historyStack.length - 1, 1);
    },
    clearHistoryStack(): void {
      this.historyStack = [];
    },
    handleHistoryStack({
      name,
      customValue,
    }: {
      name: string;
      customValue?: string;
    }): void {
      const newHistoryType = getHistoryItemType(name);

      const newHistoryItem = {
        value: name,
        type: newHistoryType,
        customValue,
      };

      const lastHistoryItem = this.historyStack[this.historyStack.length - 1];

      const sameAsPrev = compareHistoryItems(newHistoryItem, lastHistoryItem);

      if (sameAsPrev) {
        return;
      }

      this.historyStack.push(newHistoryItem);
    },
    setDropdownItem({
      name,
      customValue,
      onlyDropdownItem,
      addToHistory = true,
    }: {
      name: string | null;
      customValue?: string;
      /* 
        Just change dropdown item without field selection
      */
      onlyDropdownItem?: boolean;
      addToHistory?: boolean;
    }): void {
      const currentName = customValue || name;

      this.selectedDropdownItem = currentName;

      if (addToHistory && name) {
        this.handleHistoryStack({ name, customValue });
      }

      if (!this.selectedWidget || onlyDropdownItem) {
        return;
      }

      /* 
        Current active element is field.
        We need to change active field to selected one
      */
      const newField = getFieldByName(
        this.selectedWidget,
        currentName as string
      );

      if (!newField) {
        return;
      }

      this.selectedField = newField;
    },

    _setActiveEntity({
      field = null,
      widget = null,
      cell = null,
    }: {
      widget: IWidgetWithFields | null;
      field: IWidgetField | null;
      cell?: ICell | null;
    }): void {
      /* 
        If cell is provided - pick it. If not - find cell by widget.cell_id
      */
      const currentCell =
        cell ||
        getWidgetCell({
          widget: widget,
          selectedWidget: this.selectedWidget,
          selectedCell: this.selectedCell,
          widgetsLayout: useGridConfig().currentWidgetsLayout,
        });

      this.selectedField = field;
      this.selectedWidget = widget;
      this.selectedCell = currentCell;
    },

    setActiveElement(
      widget: IWidgetWithFields | null = null,
      field: IWidgetField | null = null,
      cell?: ICell,
      customDropdownValue?: string
    ): void {
      /* 
        Clear history on widget change
      */
      if (this.selectedWidget?.id !== widget?.id) {
        this.clearHistoryStack();
      }

      this._setActiveEntity({ field, widget, cell });

      if (field?.name) {
        this.setDropdownItem({
          name: field.name,
          customValue: customDropdownValue,
          onlyDropdownItem: true,
        });
        return;
      }

      if (widget?.name) {
        this.setDropdownItem({
          name: WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS,
          onlyDropdownItem: true,
          customValue: customDropdownValue,
        });
        return;
      }

      this.setDropdownItem({
        name: null,
      });
    },

    setHoveredWidget(widget: IWidgetWithFields | null) {
      this.hovered.widget = widget;
    },

    setHoveredField(field: IWidgetField | null) {
      this.hovered.field = field;
    },

    setHoveredControl(controlName: string | null): void {
      this.hovered.control = controlName;
    },

    updateSelectedWidgetData<K extends keyof IWidgetWithFields>(
      key: K,
      value: IWidgetWithFields[K]
    ): void {
      if (!this.selectedWidget) return;

      this.selectedWidget[key] = value;
    },

    selectWidget(widget: IWidgetWithFields | null): void {
      this.selectedWidget = widget;
      this.selectedField = null;
      this.selectedDropdownItem =
        WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS;
    },
  },
});
