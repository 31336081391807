<template>
  <div
    class="config-image-uploader p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container"
  >
    <a-upload-dragger
      v-model:fileList="fileList"
      :class="{ 'config-image-uploader__upload--error': isUploadingError }"
      name="file"
      :custom-request="handleImageUpload"
      :before-upload="beforeUpload"
      :multiple="false"
      :max-count="1"
    >
      <div v-if="value && imageSrc">
        <img
          :src="imageSrc"
          class="config-image-uploader__preview-image"
        />
        <div class="config-image-uploader__preview-image-controls">
          <CommonIcon
            name="ant-design:reload-outlined"
            class="config-image-uploader__icon"
          />
          <CommonIcon
            name="ant-design:delete-outlined"
            class="config-image-uploader__icon"
            @click.stop="handleImageRemove"
          />
        </div>
      </div>

      <div v-else>
        <p class="f-base">Click or drag photo to this area to upload</p>
        <p class="f-small config-image-uploader__hint">
          Size: not more than 1 MB
        </p>
        <p class="f-small config-image-uploader__hint">
          File format: PNG, JPG, JPEG.
        </p>
      </div>
    </a-upload-dragger>
    <p
      v-if="errorMessage"
      class="f-base config-image-uploader__error-message"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { UploadRequestOption } from "ant-design-vue/lib/vc-upload/interface";

import { OneMBSize } from "~~/constants/file-sizes";
import {
  IMAGE_VALID_ACCEPTED_FORMATS,
  IMAGE_VALID_FORMATS,
} from "~~/constants/input-validation";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import useImageCdnChange from "~~/composables/useImageCdnChange";

const props = withDefaults(
  defineProps<{
    value: string | undefined;
    uploadFunction: (file: File) => Promise<string>;
    fieldName?: string;
    maxImageSize?: number;
    meta?: {
      isContentImage?: boolean;
      isValueOption?: boolean;
    };
  }>(),
  {
    meta: () => ({
      isContentImage: false,
    }),
    maxImageSize: OneMBSize,
    fieldName: "",
  }
);

const emit = defineEmits<{
  (e: "update:value", event: string | undefined): void;
}>();

const errorMessage = ref<string>("");
const isUploadingError = ref<boolean>(false);
const fileList = ref<File[] | []>([]);

const { getLocalizedValue } = useLocalizedValue();

const currentField = computed<IWidgetField | undefined>(() => {
  return getCurrentField(props.fieldName).value;
});

const imageSrc = computed<string>(() => {
  if (props.meta.isContentImage) {
    const localizedValue = getLocalizedValue.value(currentField.value?.value);

    if (currentField.value && localizedValue) {
      return useImageCdnChange(localizedValue);
    }
    if (currentField.value && !localizedValue) {
      return "";
    }
  }

  if (!props.value || typeof props.value === "object") return "";

  return useImageCdnChange(props.value);
});

const beforeUpload = (file: File): boolean => {
  const isValidFormat = IMAGE_VALID_ACCEPTED_FORMATS.includes(file.type);

  if (!isValidFormat) {
    errorMessage.value = `You can only upload files with the following formats: ${IMAGE_VALID_FORMATS.join(
      ", "
    )}`;
    isUploadingError.value = true;
    return false;
  }

  const isValidFileSize = file.size <= props.maxImageSize;

  if (!isValidFileSize) {
    errorMessage.value = "Image must smaller than 1MB";
    isUploadingError.value = true;
    return false;
  }

  errorMessage.value = "";
  isUploadingError.value = false;
  return true;
};

const handleImageUpload = (e: UploadRequestOption): void => {
  const file = e.file;
  props.uploadFunction(file as File).then((resp: string) => {
    handleUpdate(resp);
  });
};

const handleImageRemove = (): void => {
  handleUpdate(undefined);
};

const handleUpdate = (value: string | undefined) => {
  emit("update:value", value);
};
</script>

<style lang="scss">
.config-image-uploader {
  padding: $space-m;

  &__preview-image-controls {
    @include flex(center, center, 8px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__preview-image-controls:hover {
    opacity: 1;
  }

  &__icon {
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    margin: 6px;
  }

  &__preview-image:hover + &__preview-image-controls {
    @include flex(center, center, 8px);
  }

  &__preview-image {
    max-width: 200px;
    max-height: 88px;
    border-radius: 6px;
  }

  &__error-message {
    color: $c-icon-font-red;
  }

  &__upload {
    &--error {
      border-color: $c-icon-font-red !important;
    }
  }

  &__hint {
    color: $c-grey-45;
  }

  .ant-upload-list {
    display: none;
  }

  .ant-upload {
    padding: 0 $space-m;
    max-height: 120px;
  }
}
</style>
