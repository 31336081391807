<template>
  <div
    :class="{
      'widget-cell-item--selected': selectedWidget?.id === widget.id,
    }"
    :style="cellItemStyle"
    class="pointer widget-cell-item"
    @click.stop="setActiveWidget(widget, cell)"
  >
    <a-popover
      :align="{
        points: ['tl', 'tr'],
        offset: [0, -2],
      }"
      :visible="
        selectedWidget?.id === widget.id &&
        (widgetSettingsSelected ||
          activeSideMenuTab === SideMenuTab.WIDGETS_TREE)
      "
      :get-popup-container="getPopupContainer"
      overlay-class-name="widget-cell__controls-container"
    >
      <template #content>
        <div class="widget-cell-item__controls">
          <a-tooltip
            trigger="hover"
            placement="bottom"
          >
            <template #title> Duplicate </template>

            <CommonIcon
              name="ant-design:copy-outlined"
              class="widget-cell-item__controls-icon pointer"
              @click.stop="handleDuplicateWidget(widget, cell)"
            />
          </a-tooltip>

          <a-tooltip
            trigger="hover"
            placement="bottom"
          >
            <template #title> Copy widget to clipboard </template>

            <CommonIcon
              name="ant-design:export-outlined"
              class="widget-cell-item__controls-icon pointer"
              @click.stop="widgetClipboardCopyHandler.copyWidget(cell, index)"
            />
          </a-tooltip>

          <a-tooltip
            trigger="hover"
            placement="bottom"
          >
            <template #title> Replace by widget from clipboard </template>

            <CommonIcon
              name="ant-design:import-outlined"
              class="widget-cell-item__controls-icon pointer"
              @click.stop="widgetClipboardCopyHandler.pasteWidget(cell, index)"
            />
          </a-tooltip>

          <a-tooltip
            trigger="hover"
            placement="bottom"
          >
            <template #title> Change on all pages </template>

            <CommonIcon
              name="ant-design:cluster-outlined"
              class="widget-cell-item__controls-icon pointer"
              @click.stop="showWidgetTagDrawer"
            />
          </a-tooltip>

          <a-tooltip
            trigger="hover"
            placement="bottom"
          >
            <template #title> Delete </template>

            <CommonIcon
              name="ant-design:delete-outlined"
              class="widget-cell-item__controls-icon widget-cell-item__controls-icon--red pointer"
              @click.stop="handleDeleteWidget(widget, cell)"
            />
          </a-tooltip>
        </div>
      </template>

      <WidgetWrapper
        :cell="cell"
        :widget="widget"
      />
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";

import { Dimension, ICell, ICellOptions } from "~~/models/grid.interface";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  IWidgetWithFields,
  WidgetDefaultSettingsDropdownItem,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetClipboardCopy } from "~~/composables/widgets/useWidgetClipboardCopy";
import { ElementStyle } from "~~/models/common";
import { WidgetDimension } from "~~/models/widgets/widget-controls.model";
import { CellSizeType, CellLayout } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useQueryStore } from "~~/helpers/query-store";
import { useUiStore } from "~~/store/ui";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
  index: number;
}>();

const notification = useNotification();
const widgetStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const uiStore = useUiStore();

const activeWidget = useQueryStore("active_widget");

const { selectedWidget, selectedDropdownItem } =
  storeToRefs(widgetSettingsStore);
const { activeSideMenuTab } = storeToRefs(uiStore);

const { cellsOptions } = useGridConfig();

const currentCellOptions = computed<ICellOptions>(() => {
  return cellsOptions[props.cell.cellId];
});

const setActiveWidget = (widget: IWidgetWithFields, cell: ICell): void => {
  widgetSettingsStore.setActiveElement(widget, null, cell);
};

const showWidgetTagDrawer = (): void => {
  widgetSettingsStore.updateTagDrawerValue(true);
};

const widgetSettingsSelected = computed<boolean>(() => {
  return (
    selectedDropdownItem.value ===
    WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS
  );
});

const getPopupContainer = (trigger: HTMLElement) => {
  return trigger.parentElement?.parentElement?.parentElement;
};

const handleDeleteWidget = (widget: IWidgetWithFields, cell: ICell) => {
  widgetStore.deleteWidget(widget, cell);

  notification.notify("Delete widget");
};

const widgetClipboardCopyHandler = useWidgetClipboardCopy();

const handleDuplicateWidget = (widget: IWidgetWithFields, cell: ICell) => {
  widgetStore.duplicateWidget(widget, cell);

  notification.notify("Duplicate widget");
};

const cellItemWidthStyle = computed<ElementStyle>(() => {
  const width = props.widget.options.size?.width as WidgetDimension;

  /*
    Default behavior is CellSizeType.FILL
  */
  if (!width || width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (
    width.type === CellSizeType.FIXED &&
    width.value?.type === Dimension.PERCENT
  ) {
    return {
      width: `${width.value.value}${width.value.type}`,
    };
  }

  return {};
});

const cellItemHeightStyle = computed<ElementStyle>(() => {
  const height = props.widget.options.size?.height as WidgetDimension;
  const cellContentSettings = currentCellOptions.value?.cellContentSettings;

  if (!height) {
    return {};
  }

  if (height.type !== CellSizeType.FILL) {
    return {};
  }

  if (
    !cellContentSettings?.layout ||
    cellContentSettings?.layout === CellLayout.VERTICAL
  ) {
    return {
      flex: 1,
    };
  }

  return {
    alignSelf: "stretch",
  };
});

const cellItemStyle = computed<ElementStyle>(() => {
  return {
    ...cellItemHeightStyle.value,
    ...cellItemWidthStyle.value,
  };
});

watch(selectedWidget, newValue => {
  activeWidget.value = newValue?.id;
});
</script>

<style lang="scss">
.widget-cell-item {
  position: relative;
  z-index: 3;
  /*
      Add bigger z-index to show relative positioned elements 
    */
  &--selected {
    z-index: 5;
  }
  &--active {
    border: 2px solid rgba($c-primary-base, 1);
    z-index: 5;
  }

  &__controls {
    @include flex-column(center, center, 12px);

    &-container {
      padding-left: 4.77px;
      padding-right: 4.77px;

      .ant-popover-inner-content {
        padding: calc($space-s + $space-xs);

        .ant-popover-inner {
          border-radius: 4px;
        }
      }

      .ant-popover-arrow {
        display: none;
      }
    }

    &-icon {
      @include flex(center, center, 0);
      height: 24px;
      width: 24px;
      background-color: transparent;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $c-grey-06;
      }

      &--red {
        color: $c-icon-font-red;
      }
    }
  }
}
</style>
