<template>
  <div
    :class="{
      'constructor': true,
      'constructor--layout-view': activeStep === Step.LAYOUTS,
      'constructor--widgets-view': activeStep === Step.WIDGETS,
      'constructor--no-main-sidebar': isSideMenuConfigOpened,
      'constructor--no-settings-sidebar': !showConfigSideBar,
      'constructor--no-widget-setting-sidebar':
        activeSideMenuTab !== SideMenuTab.CONTENT,
    }"
  >
    <div
      :class="{
        'constructor__content': true,
        [`constructor__content--${activeScreen}`]: currentLayout.length > 0,
      }"
    >
      <div class="constructor__container">
        <router-view />
      </div>
    </div>
  </div>

  <ConfigSidebar v-if="showConfigSideBar" />
</template>

<script setup lang="ts">
import { Step } from "~~/models/step.enum";
import { useUiStore } from "~~/store/ui";
import { useGridConfig } from "~~/store/grid";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";

const uiStore = useUiStore();
const gridStore = useGridConfig();

const { currentLayout, isLayoutSaved, isTemplateEditable } =
  storeToRefs(gridStore);

const { isSideMenuConfigOpened, activeScreen, activeStep, activeSideMenuTab } =
  storeToRefs(uiStore);

const showConfigSideBar = computed(
  () =>
    activeStep.value === Step.WIDGETS ||
    (!isLayoutSaved.value && isTemplateEditable.value)
);
</script>

<style lang="scss">
$padding: 24px;

.constructor {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: ($header-height + $space-lg + $space-l)
    ($settings-sidebar-width + $space-l) $space-l ($sidebar-size + $space-l);
  transition: padding $base-transition;
  background-color: $c-grey-06;
  overflow-x: auto;

  &--no-main-sidebar {
    padding: ($header-height + $space-l * 3)
      ($settings-sidebar-width + $space-l) $space-l
      ($propertybar-width + $sidebar-size + $space-l);
  }

  &--no-widget-setting-sidebar,
  &--no-settings-sidebar {
    padding-right: $space-l;
  }

  &--layout-view {
    padding-top: ($sidebar-size + $space-lg);

    .constructor__container {
      background-color: unset;
    }
  }

  &--widgets-view {
    .constructor__content {
      &--mob {
        max-width: calc($sm-larger + $padding);
      }

      &--tab {
        max-width: calc($md-larger + $padding);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    width: 100%;
  }

  &__container {
    width: 100%;
    height: calc(100vh - ($space-lg * 3));
    padding: 0 12px;
  }

  &--no-widget-setting-sidebar {
    .widgets-config {
      margin: 0;
    }

    .constructor__container {
      padding: 0;
    }
  }
}
</style>
