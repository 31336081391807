import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { IPageContentWidget } from "~~/models/page.model";
import {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

export type SourceValue = Record<string, { search: string; change: string }>;

export const applyChangesToFields = (
  sourceValue: SourceValue,
  fields: IWidgetField[]
): void => {
  for (const field of fields) {
    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      applyChangesToFields(sourceValue, childFields as IWidgetField[]);

      continue;
    }

    if (field.options.link?.value) {
      field.options.link.value = changeSimilarMultilangValues(
        sourceValue,
        field.options.link.value
      );
    }

    if (field.options.link?.authorizeValue) {
      field.options.link.authorizeValue = changeSimilarMultilangValues(
        sourceValue,
        field.options.link.authorizeValue
      );
    }
  }
};

export const checkStringPresence = (
  rootValue?: string,
  value?: string
): boolean => {
  if (!rootValue || !value) {
    return false;
  }

  const rootLowered = rootValue.toLocaleLowerCase().trim();
  const valueLowered = value.toLocaleLowerCase().trim();

  return rootLowered.includes(valueLowered);
};

export const compareMultilangValues = (
  sourceValue: SourceValue,
  value?: Record<string, string>
): boolean => {
  for (const langName in sourceValue) {
    const currLangData = sourceValue[langName];

    if (!currLangData.search) {
      continue;
    }

    if (checkStringPresence(value?.[langName], currLangData.search)) {
      return true;
    }
  }

  return false;
};

export const changeSimilarMultilangValues = (
  sourceValue: SourceValue,
  value: Record<string, string>
): Record<string, any> => {
  for (const langName in sourceValue) {
    const currLangData = sourceValue[langName];

    if (!currLangData.search) {
      continue;
    }

    if (checkStringPresence(value?.[langName], currLangData.search)) {
      value[langName] = value[langName].replace(
        currLangData.search,
        currLangData.change.trim()
      );
    }
  }

  return value;
};

export const findSimilaritiesInFields = (
  sourceValue: SourceValue,
  fields: IWidgetField[]
): boolean => {
  for (const field of fields) {
    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      const hasSimilarities = findSimilaritiesInFields(
        sourceValue,
        childFields as IWidgetField[]
      );

      if (hasSimilarities) {
        return true;
      }

      continue;
    }

    const hasSimilarLink = compareMultilangValues(
      sourceValue,
      field.options.link?.value
    );

    const hasSimilarLinkAuth = compareMultilangValues(
      sourceValue,
      field.options.link?.authorizeValue
    );

    if (hasSimilarLink || hasSimilarLinkAuth) {
      return true;
    }
  }

  return false;
};

export const applyChangesToContentFields = (
  sourceValue: SourceValue,
  fields: IWidgetField[]
): void => {
  for (const field of fields) {
    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      applyChangesToFields(sourceValue, childFields as IWidgetField[]);

      continue;
    }

    if (field.options.link?.value) {
      field.options.link.value = changeSimilarMultilangValues(
        sourceValue,
        field.options.link.value
      );
    }

    if (field.options.link?.authorizeValue) {
      field.options.link.authorizeValue = changeSimilarMultilangValues(
        sourceValue,
        field.options.link.authorizeValue
      );
    }
  }
};

export const applyChangesToBreadcrumbs = <
  T extends IWidgetWithFields | IPageContentWidget
>(
  widget: T,
  sourceValue: SourceValue
): T => {
  if (widget.options.breadcrumbs) {
    for (const breadcrumb of widget.options.breadcrumbs) {
      if (!breadcrumb.link.value) {
        continue;
      }

      breadcrumb.link.value = changeSimilarMultilangValues(
        sourceValue,
        breadcrumb.link.value
      );
    }
  }

  return widget;
};

export const applyChangesToDataParams = <
  T extends IWidgetWithFields | IPageContentWidget
>(
  widget: T,
  sourceValue: SourceValue
): T => {
  if (
    widget.options.bindingParams &&
    Object.keys(widget.options.bindingParams).length
  ) {
    const bindingParams = widget.options.bindingParams;

    for (const dataKey in bindingParams) {
      bindingParams[dataKey].value = changeSimilarMultilangValues(
        sourceValue,
        bindingParams[dataKey].value as Record<string, string>
      );
    }

    widget.options.bindingParams = bindingParams;
  }

  return widget;
};

export const findBreadcrumbsSimilarities = (
  widget: IWidgetWithFields | IPageContentWidget,
  sourceValue: SourceValue
): boolean => {
  if (widget.options.breadcrumbs) {
    for (const breadcrumb of widget.options.breadcrumbs) {
      const hasSimilarBreakcrumb = compareMultilangValues(
        sourceValue,
        breadcrumb.link.value
      );

      if (hasSimilarBreakcrumb) {
        return true;
      }
    }
  }

  return false;
};

export const findDataParamsSimilarities = (
  widget: IWidgetWithFields | IPageContentWidget,
  sourceValue: SourceValue
): boolean => {
  if (
    widget.options.bindingParams &&
    Object.keys(widget.options.bindingParams).length
  ) {
    for (const dataKey in widget.options.bindingParams) {
      const hasSimilarDataParam = compareMultilangValues(
        sourceValue,
        widget.options.bindingParams[dataKey].value as Record<string, string>
      );

      if (hasSimilarDataParam) {
        return true;
      }
    }
  }

  return false;
};
