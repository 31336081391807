<template>
  <DNDContainer
    id="drag-n-drop-container"
    :container-id="DndContainerId.WIDGETS"
    show-divider
    :divider-position="dividerPosition"
    @drop="handleBlockDrop"
    @over="handleDragOver"
    @enter="checkDimensions"
    @update-container-position="handleUpdateContainerPosition"
  >
    <section
      v-if="currentWidgetsLayout.length"
      :id="WIDGETS_GRID_ID"
      class="widgets-grid"
      :class="[`widgets-grid--${activeScreen}`]"
    >
      <div
        v-for="cell in currentWidgetsLayout"
        :key="cell.cellId"
        class="widgets-grid__row"
      >
        <WidgetCell
          :id="`cellId_${cell.cellId}`"
          :cell="cell"
        />
      </div>
    </section>
  </DNDContainer>
</template>

<script setup lang="ts">
import { IDividerPosition } from "~~/models/dnd.model";
import { DndContainerId } from "~~/models/common/dropdown.model";
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { useWidgetsDnd } from "~~/components/composables/widgets/useWidgetsDnd";
import { useProject } from "~~/composables/useProject";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const { currentWidgetsLayout, dividerData } = storeToRefs(useGridConfig());
const { activeScreen } = storeToRefs(useUiStore());
const widgetSettingsStore = useWidgetSettingsStore();
const { useHotkeys } = useProject();

const {
  handleBlockDrop,
  handleDragOver,
  handleUpdateContainerPosition,
  checkDimensions,
} = useWidgetsDnd();

const dividerPosition = computed<IDividerPosition>(() => {
  return {
    top: dividerData.value.top,
    left: dividerData.value.left,
    width: dividerData.value.width,
  };
});

const toggleTagWidgets = (): void => {
  widgetSettingsStore.toggleWidgetsTagMode();
};

useHotkeys({
  "ctrl+shift+k": toggleTagWidgets,
  "cmd+shift+k": toggleTagWidgets,
});
</script>

<style lang="scss">
$border-params: 2px dashed $c-grey-15;
$padding: 24px;

.widgets-grid {
  margin: 0 auto;
  border-top: $border-params;
  border-left: $border-params;
  border-right: $border-params;
  min-height: calc(100vh - 200px);

  &--mob {
    max-width: calc($sm-larger + $padding);
  }

  &--tab {
    max-width: calc($md-larger + $padding);
  }

  &--desktop {
    min-width: calc($lg + $padding);
  }

  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 2px dashed $c-grey-15;
  }
}
</style>
