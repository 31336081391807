import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
  TextStyle,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { IPageWidgetDetails } from "~~/models/page.model";
import { generateWidgetField } from "~~/assets/utils/widget";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { TITLE_INITIAL as CARD_TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSize,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  SPORTS_MENU_WIDGET_INITIAL,
  MENU_ITEM_INITIAL,
} from "~~/constants/configs/sports-menu/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { iconMapping } from "~~/constants/icons-mapping";
import { useWidgetsStore } from "~~/store/widgets";
import { useMetaStore } from "~~/store/meta";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useSportsMenuSharedLogic = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  isLive: boolean,
  isFenix?: boolean,
  showTitle = true
) => {
  const widgetsStore = useWidgetsStore();
  const metaStore = useMetaStore();
  const { initWidgetOptions, addFieldInitialOptions } = useCreateFields(
    widget.value
  );

  const { widgetsDetailsCache } = storeToRefs(widgetsStore);

  const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
    () => {
      return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
        widget.value.static_id
      ];
    }
  );

  const fields = useWidgetFields(widget.value);

  const getIcon = (icon: string): string => {
    return iconMapping[icon];
  };

  const itemFirstLevelIcon = computed<string | null>(() => {
    return getIcon(fields.value.menu_level_1.field.options.arrowSettings.value);
  });

  const itemSecondLevelIcon = computed<string | null>(() => {
    return getIcon(
      fields.value.menu_level_2?.field.options.arrowSettings.value
    );
  });

  /*
    Initialize widget with initial options
  */

  const createWidgetFields = (): void => {
    /*
      Create widget fields from list element
    */
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          fieldDetails.name,
          widget.value.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value || fieldDetails.default_value || "",
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget.value, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    if (showTitle) {
      const initialTitleOptions = prefillEmptyOptions(
        fields.value.title.field.options,
        getInitialOptions({
          data: TITLE_INITIAL(),
          exclude: ["link"],
          values: {
            color: {
              color: "#ffffff",
              opacity: 100,
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 12,
              left: 12,
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.title.field,
        initialTitleOptions
      );
    }

    let initialMenuLevel1 = prefillEmptyOptions(
      fields.value.menu_level_1.field.options,
      MENU_ITEM_INITIAL().options
    );

    if (isFenix) {
      initialMenuLevel1 = prefillEmptyOptions(
        fields.value.menu_level_1.field.options,
        getInitialOptions({
          data: MENU_ITEM_INITIAL(),
          values: {
            size: getDefaultSize(),
            verticalSpace: 0,
          },
          additionalData: {
            options: {
              displayMode: DisplayOrientation.VERTICAL,
              elementsDisplayMode: DisplayOrientation.HORIZONTAL,
            },
          },
        }).options
      );
    }

    const initialOptionsData = {
      data: MENU_ITEM_INITIAL(),
      exclude: [""],
      values: {
        padding: {
          top: 15,
          right: 20,
          bottom: 15,
          left: 50,
        },
      },
    };

    if (isLive) {
      initialOptionsData.exclude = ["arrowSettings"];
    }

    if (!showTitle) {
      initialOptionsData.exclude.push("itemIcon");
    }

    if (fields.value.menu_level_2) {
      let initialMenuLevel2 = prefillEmptyOptions(
        fields.value.menu_level_2.field.options,

        getInitialOptions(initialOptionsData).options
      );

      if (isFenix) {
        initialMenuLevel2 = prefillEmptyOptions(
          fields.value.menu_level_2.field.options,
          getInitialOptions({
            data: MENU_ITEM_INITIAL(),
            values: {
              verticalSpace: 0,
            },
            additionalData: {
              options: {
                displayMode: DisplayOrientation.VERTICAL,
              },
            },
          }).options
        );
      }

      widgetsStore.updateFieldOptions(
        fields.value.menu_level_2.field,
        initialMenuLevel2
      );
    }

    if (!isLive && fields.value.menu_level_3 && !isFenix) {
      const initialMenuLevel3 = prefillEmptyOptions(
        fields.value.menu_level_3.field.options,
        getInitialOptions({
          data: MENU_ITEM_INITIAL(),
          values: {
            padding: {
              top: 15,
              right: 20,
              bottom: 15,
              left: 85,
            },
            itemIcon: {
              _active: false,
              size: 14,
              spaceBetween: 8,
              color: {
                color: "#ffffff",
                opacity: 100,
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.menu_level_3.field,
        initialMenuLevel3
      );
    }

    if (isFenix && fields.value.menu_level_3) {
      const initialMenuLevel3Fenix = prefillEmptyOptions(
        fields.value.menu_level_3.field.options,
        getInitialOptions({
          data: MENU_ITEM_INITIAL(),
          values: {
            fill: getDefaultFillValue("#ffffff", 100),
            color: getDefaultFillValue("#000000", 100),
            verticalSpace: 0,
            padding: {
              top: 15,
              right: 20,
              bottom: 15,
              left: 30,
            },
          },
          additionalData: {
            options: {
              displayMode: DisplayOrientation.VERTICAL,
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.menu_level_3.field,
        initialMenuLevel3Fenix
      );
    }

    widgetsStore.updateFieldOptions(
      fields.value.menu_level_1.field,
      initialMenuLevel1
    );

    if (isLive && isFenix) {
      const initialEventContainerOptions = prefillEmptyOptions(
        fields.value.event_container.field.options,
        getInitialOptions({
          data: DESIGN_INITIAL(),
          values: {
            fill: getDefaultFillValue("#ffffff", 100),
          },
          additionalData: {
            options: {
              columns: 1,
              distance: 16,
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_container.field,
        initialEventContainerOptions
      );

      const initialSportEventCardOptions = prefillEmptyOptions(
        fields.value.event_card.field.options,
        getInitialOptions({
          data: DESIGN_INITIAL(),
          values: {
            fill: getDefaultFillValue("#f5f5f5", 100),
          },
          additionalData: {
            options: {
              layoutMode: SportMenuLiveBet9CardLayoutMode.FIRST,
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                  shadow: getDefaultShadow(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                  shadow: getDefaultShadow(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_card.field,
        initialSportEventCardOptions
      );

      const initialHeaderOptions = prefillEmptyOptions(
        fields.value.header.field.options,
        getInitialOptions({
          data: DESIGN_INITIAL(),
          additionalData: {
            options: {
              layout: "vertical",
              leagueNameVerticalPosition: "top",
              leagueNameHorizontalPosition: "left",
              favoriteIconAlignment: VerticalAlignment.TOP,
              verticalSpace: 4,
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.header.field,
        initialHeaderOptions
      );

      const initialFooterOptions = prefillEmptyOptions(
        fields.value.footer.field.options,
        getInitialOptions({
          data: DESIGN_INITIAL(),
          additionalData: {
            options: {
              layout: "vertical",
              verticalSpace: 8,
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.footer.field,
        initialFooterOptions
      );

      const initialFavIconOptions = prefillEmptyOptions(
        fields.value.favourites_button.field.options,
        {
          iconSize: 24,
          value: "ant-design:star-outlined",
          color: {
            color: "#FFC53D",
            opacity: 100,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.favourites_button.field,
        initialFavIconOptions
      );

      const initialEventLeagueOptions = prefillEmptyOptions(
        fields.value.event_league.field.options,
        {
          color: getDefaultFillValue("#595959"),
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: "left",
          displayMode: "fill",
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_league.field,
        initialEventLeagueOptions
      );

      const initialSuspendedOptions = prefillEmptyOptions(
        fields.value.event_suspended_status.field.options,
        getInitialOptions({
          data: {
            options: {
              ...CARD_TITLE_INITIAL().options,
              ...DESIGN_INITIAL().options,
            },
          },
          exclude: ["link", "shadow", "padding", "alignment", "size"],
          values: {
            theme: 13,
            fill: getDefaultFillValue("#FFF1F0"),
            color: getDefaultFillValue("#F62F39"),
            cornerRadius: getDefaultCornerRadiusValue(4),
            border: getDefaultBorderValue({
              fill: getDefaultFillValue("#F62F39"),
            }),
            spacing: {
              padding: getDefaultSpacing({
                left: 8,
                right: 8,
                top: 2,
                bottom: 2,
              }),
              margin: getDefaultSpacing(),
            },
          },
          additionalData: {
            options: {
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                  color: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                  color: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_suspended_status.field,
        initialSuspendedOptions
      );

      const initialStreamIconOptions = prefillEmptyOptions(
        fields.value.stream_icon.field.options,
        {
          color: getDefaultFillValue("#52C41A"),
          iconSize: 20,
          value: "ant-design:play-circle-filled",
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.stream_icon.field,
        initialStreamIconOptions
      );

      const initialEventNameOptions = prefillEmptyOptions(
        fields.value.event_team_player.field.options,
        {
          color: getDefaultFillValue("#595959"),
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: "left",
          displayMode: "fill",
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_team_player.field,
        initialEventNameOptions
      );

      const initialTimeOptions = prefillEmptyOptions(
        fields.value.event_time.field.options,
        getInitialOptions({
          data: TITLE_INITIAL(),
          exclude: ["alignment"],
          additionalData: {
            options: {
              icon: {
                _active: true,
                size: 20,
                color: getDefaultFillValue("#69B1FF"),
                value: "mdi:clock",
              },
              states: {
                [State.HOVER]: {
                  color: getDefaultFillValue(),
                  icon: {
                    color: getDefaultFillValue(),
                  },
                },
                [State.ACTIVE]: {
                  color: getDefaultFillValue(),
                  icon: {
                    color: getDefaultFillValue(),
                  },
                },
              },
            },
          },
          values: {
            theme: 13,
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_time.field,
        initialTimeOptions
      );

      const initialEventPeriodOptions = prefillEmptyOptions(
        fields.value.event_period.field.options,
        {
          color: getDefaultFillValue("#595959"),
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: "left",
          displayMode: "fill",
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_period.field,
        initialEventPeriodOptions
      );

      const initialEventScoreOptions = prefillEmptyOptions(
        fields.value.event_score.field.options,
        getInitialOptions({
          data: {
            options: {
              ...CARD_TITLE_INITIAL().options,
              ...DESIGN_INITIAL().options,
            },
          },
          exclude: ["link", "shadow", "alignment", "size"],
          additionalData: {
            options: {
              states: {
                [State.HOVER]: {
                  color: getDefaultFillValue(),
                  fill: getDefaultFillValue(),
                },
                [State.ACTIVE]: {
                  color: getDefaultFillValue(),
                  fill: getDefaultFillValue(),
                },
              },
            },
          },
          values: {
            theme: 13,
            spacing: {
              padding: getDefaultSpacing({
                top: 8,
                bottom: 8,
                left: 12,
                right: 12,
              }),
              margin: getDefaultSpacing(0),
            },
            fill: getDefaultFillValue("#4096FF"),
            color: getDefaultFillValue("#ffffff"),
            decoration: [TextStyle.BOLD],
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.event_score.field,
        initialEventScoreOptions
      );

      const initialRacingEventCardOptions = prefillEmptyOptions(
        fields.value.racing_event_card.field.options,
        getInitialOptions({
          data: DESIGN_INITIAL(),
          values: {
            fill: getDefaultFillValue("#f5f5f5", 100),
          },
          additionalData: {
            options: {
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                  shadow: getDefaultShadow(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                  shadow: getDefaultShadow(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.racing_event_card.field,
        initialRacingEventCardOptions
      );

      const initialRacingEventNameOptions = prefillEmptyOptions(
        fields.value.racing_event_name.field.options,
        {
          color: getDefaultFillValue("#595959"),
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: "left",
          displayMode: "fill",
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.racing_event_name.field,
        initialRacingEventNameOptions
      );

      const initialRacingStreamIconOptions = prefillEmptyOptions(
        fields.value.racing_stream_icon.field.options,
        {
          color: getDefaultFillValue("#52C41A"),
          iconSize: 20,
          value: "ant-design:play-circle-filled",
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.racing_stream_icon.field,
        initialRacingStreamIconOptions
      );

      const initialRacingStatusOptions = prefillEmptyOptions(
        fields.value.racing_event_status.field.options,
        getInitialOptions({
          data: {
            options: {
              ...CARD_TITLE_INITIAL().options,
              ...DESIGN_INITIAL().options,
            },
          },
          exclude: ["link", "shadow", "padding", "alignment", "size"],
          values: {
            theme: 13,
            fill: getDefaultFillValue("#e6f2fe"),
            color: getDefaultFillValue("#1677FF"),
            cornerRadius: getDefaultCornerRadiusValue(4),
            border: getDefaultBorderValue(),
            spacing: {
              padding: getDefaultSpacing({
                left: 8,
                right: 8,
                top: 2,
                bottom: 2,
              }),
              margin: getDefaultSpacing(),
            },
          },
          additionalData: {
            options: {
              states: {
                [State.HOVER]: {
                  fill: getDefaultFillValue(),
                  color: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                },
                [State.ACTIVE]: {
                  fill: getDefaultFillValue(),
                  color: getDefaultFillValue(),
                  border: getDefaultBorderValue(),
                },
              },
            },
          },
        }).options
      );

      widgetsStore.updateFieldOptions(
        fields.value.racing_event_status.field,
        initialRacingStatusOptions
      );

      const initialRacingFavIconOptions = prefillEmptyOptions(
        fields.value.racing_favourites_icon.field.options,
        {
          iconSize: 24,
          value: "ant-design:star-outlined",
          color: getDefaultFillValue("#FFC53D"),
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        }
      );

      widgetsStore.updateFieldOptions(
        fields.value.racing_favourites_icon.field,
        initialRacingFavIconOptions
      );

      addFieldInitialOptions("racing_event_timer", {
        data: TITLE_INITIAL(),
        exclude: ["link", "alignment"],
        values: {
          theme: getDefaultTheme(13),
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                color: getDefaultFillValue(),
              },
              [State.ACTIVE]: {
                color: getDefaultFillValue(),
              },
            },
          },
        },
      });
    }
  };

  const initWidgetFields = () => {
    if (!widget.value.fields.length) {
      createWidgetFields();
    }

    initWidgetFieldsOptions();
  };

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    ...SPORTS_MENU_WIDGET_INITIAL().options,
  });

  widgetsStore.updateWidgetOptions(widget.value, initialWidgetOptions);

  initWidgetFields();

  return {
    itemFirstLevelIcon,
    itemSecondLevelIcon,
    fields,
  };
};
