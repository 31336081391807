import {
  FillType,
  IBorderControl,
  BorderStyle,
  IFillControl,
  IShadowControl,
  ITextShadowControl,
  TextStyle,
  Align,
  VerticalAlignment,
  IHeadingControl,
  HeadingType,
  BorderStrokeType,
  ICornerRadiusControl,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  Dimension,
  ICellDimensionValue,
  Sizing,
} from "~~/models/grid.interface";
import { FONT_FAMILY_OPTIONS, THEME_OPTIONS } from "~~/constants/widget-config";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const DESIGN_INITIAL = () => {
  return {
    options: {
      fill: {
        color: "",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    },
  };
};

export const WIDGET_INITIAL = () => {
  return {
    options: {
      ...DESIGN_INITIAL().options,
      _isWidget: true,
      size: getDefaultSize(),
      baseDisplaySettings: {
        alignment: VerticalAlignment.TOP,
      },
    },
  };
};

export const getDefaultFillImage = (options?: IWidgetOptions) => {
  return {
    fillType: FillType.COLOR,
    value: {
      color: "",
      opacity: 100,
    },
    position: {
      x: "",
      y: "",
      value: "",
    },
    ...(options || {}),
  };
};

export const getDefaultSizeValue = (
  sizeType: ResizingType = ResizingType.FILL,
  value?: string
): Record<any, ResizingType | ICellDimensionValue> => {
  const currType = value ? ResizingType.FIXED : sizeType;
  const currValue = {
    value: value || "",
    type: Dimension.PX,
  };

  return {
    type: currType,
    value: currValue,
  };
};

export const getDefaultSize = () => {
  return {
    width: getDefaultSizeValue(),
    height: getDefaultSizeValue(ResizingType.HUG),
  };
};

export const getDefaultFillValue = (
  color?: string,
  opacity?: number
): IFillControl => {
  const currColor = color || "";
  const currOpacity = opacity || 100;

  return {
    color: currColor,
    opacity: currOpacity,
  };
};

type SpacingValue = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export const getDefaultSpacing = (
  value?: Partial<SpacingValue> | number
): SpacingValue => {
  if (typeof value === "number") {
    return {
      top: value,
      right: value,
      bottom: value,
      left: value,
    };
  }

  const prefilled = value || {};

  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...prefilled,
  };
};

export const getDefaultBorderValue = (
  data?: Partial<IBorderControl>
): IBorderControl => {
  const currColor = data?.fill || getDefaultFillValue();
  const currStyle = data?.style || BorderStyle.SOLID;
  const currStroke = data?.stroke || {
    width: 1,
    type: BorderStrokeType.DEPENDENT,
  };

  return {
    fill: currColor,
    stroke: currStroke,
    style: currStyle,
  };
};

export const getDefaultCornerRadiusValue = (
  data?: Partial<ICornerRadiusControl> | number | null
): ICornerRadiusControl => {
  let currType;
  let currValue;

  if (typeof data === "number" || typeof data === "string") {
    currType = BorderStrokeType.DEPENDENT;
    currValue = data;
  } else {
    currType = data?.type || BorderStrokeType.DEPENDENT;
    currValue = data?.value || 0;
  }

  return {
    type: currType,
    value: currValue,
  };
};

export const getDefaultShadow = (
  data?: Partial<IShadowControl>
): IShadowControl => {
  const currColor = data?.fill || getDefaultFillValue();
  const currX = data?.x || 0;
  const currY = data?.y || 0;
  const currBlur = data?.blur || 0;
  const currSpread = data?.spread || 0;

  return {
    fill: currColor,
    x: currX,
    y: currY,
    blur: currBlur,
    spread: currSpread,
  };
};

export const getDefaultTextShadow = (
  data?: Partial<ITextShadowControl>
): ITextShadowControl => {
  const currColor = data?.fill || getDefaultFillValue();
  const currX = data?.x || 0;
  const currY = data?.y || 0;
  const currBlur = data?.blur || 0;

  return {
    fill: currColor,
    x: currX,
    y: currY,
    blur: currBlur,
  };
};

export const getDefaultTheme = (
  value?: number | IHeadingControl
): IHeadingControl => {
  if (!value) {
    return {
      type: THEME_OPTIONS[0].value as HeadingType,
      value: THEME_OPTIONS[0].size,
    };
  }

  return {
    type:
      typeof value === "number"
        ? (THEME_OPTIONS[0].value as HeadingType)
        : value?.type,
    value: typeof value === "number" ? value : value?.value,
  };
};

type FontOption = (typeof FONT_FAMILY_OPTIONS)[number];

export const getDefaultFontFamily = (value?: FontOption["value"]): string => {
  return value || FONT_FAMILY_OPTIONS[0].value;
};

export const getDefaultDecoration = (value?: TextStyle[]): TextStyle[] => {
  return value || [];
};

type IconSettings = {
  default_value: string;
  textColor: IFillControl;
  iconSize: number;
  position: string;
  gap: number;
  _active: boolean;
};

export const getDefaultIconSettings = (
  value?: Partial<IconSettings>
): IconSettings => {
  const prefilled = value || {};

  const defaultValue = {
    default_value: "ant-design:arrow-right-outlined",
    textColor: getDefaultFillValue("#ffffff"),
    iconSize: 16,
    position: "right",
    gap: 5,
    _active: true,
  };

  return {
    ...defaultValue,
    ...prefilled,
  };
};

type ButtonTextSettings = {
  _active: boolean;
  textColor: IFillControl;
  theme: IHeadingControl | number;
  fontFamily: string;
  decoration: TextStyle[];
};

export const getDefaultButtonTextSettings = (
  value?: Partial<ButtonTextSettings>
): ButtonTextSettings => {
  const prefilled = value || {};

  const defaultValue = {
    _active: true,
    textColor: getDefaultFillValue("#ffffff"),
    theme: getDefaultTheme(16),
    fontFamily: getDefaultFontFamily(),
    decoration: getDefaultDecoration(),
  };

  return {
    ...defaultValue,
    ...prefilled,
  };
};

// TODO - Maybe refactor
export const getDefaultAlignValue = (data?: Partial<Align>[]): string => {
  const alignString = data?.join(" ") || `${Align.START} ${Align.START}`;

  return alignString;
};

export const getDefaultContentWidth = () => {
  return {
    type: Sizing.ADAPTIVE,
    width: 320,
    alignment: "center",
  };
};
