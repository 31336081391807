<template>
  <div class="betslip-widget__result-container w-100">
    <WidgetField
      v-if="fields.result_container.active"
      :widget="widget"
      :field="fields.result_container.field"
      class="w-100"
    >
      <div :style="resultContainerInlineStyles">
        <WidgetField
          v-if="fields.result_container_total_amounts.active"
          :widget="widget"
          :field="fields.result_container_total_amounts.field"
        >
          <div
            class="betslip-widget__total-amounts-container"
            :class="[
              `betslip-widget__total-amounts-container--${fields.result_container_total_amounts.field.options.position}`,
            ]"
            :style="totalAmountContainerInlineStyles"
          >
            <div class="betslip-widget__result-total-amounts-element">
              <span :style="totalAmountLabelInlineStyles">{{
                t("widgets.BetslipWidget.total_stake")
              }}</span>
              <span :style="totalAmountValueInlineStyles">$ 0,00</span>
            </div>
            <div class="betslip-widget__result-total-amounts-element">
              <span :style="totalAmountLabelInlineStyles">
                {{ t("widgets.BetslipWidget.potential_return") }}
              </span>
              <span :style="totalAmountValueInlineStyles">$ 0,00</span>
            </div>
          </div>
        </WidgetField>

        <WidgetField
          v-if="fields.result_container_warning_message_title.active"
          :widget="widget"
          :field="fields.result_container_warning_message_title.field"
        >
          <div
            class="betslip-widget__result-warning-message"
            :style="warningMessageContainerInlineStyles"
          >
            <CommonIcon
              v-if="
                fields.result_container_warning_message_title.field.options
                  .iconSettings._active
              "
              :style="warningIconInlineStyles"
              name="ant-design:info-circle-filled"
            />
            <span>Warning message</span>
          </div>
        </WidgetField>

        <div class="betslip-widget__result-buttons-container">
          <WidgetField
            v-if="fields.result_container_bet_button.active"
            :widget="widget"
            class="w-100"
            :field="fields.result_container_bet_button.field"
          >
            <BasicTextButtonField
              :field="fields.result_container_bet_button.field"
              :current-state="states.result_container_bet_button"
              :value="t('widgets.BetslipWidget.confirm_bet')"
              :is-button-text-settings="true"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            class="w-100"
            :field="fields.accept_changes_container.field"
          >
            <BetslipAcceptChanges
              :widget="widget"
              :field="fields.accept_changes_container.field"
              :checkbox-field="fields.each_way_bet_checkbox.field"
              :tooltip-field="fields.each_way_bet_tooltip.field"
              :states="states"
            />
          </WidgetField>

          <div class="betslip-widget__result-buttons-container-field w-100">
            <WidgetField
              v-if="fields.result_container_remove_button.active"
              :widget="widget"
              class="betslip-widget__result-button-field"
              :field="fields.result_container_remove_button.field"
            >
              <BasicButtonField
                :field="fields.result_container_remove_button.field"
                :current-state="states.result_container_remove_button"
                :custom-value="t('widgets.BetslipWidget.remove_bets')"
              />
            </WidgetField>
            <WidgetField
              v-if="fields.result_container_copy_button.active"
              :widget="widget"
              class="betslip-widget__result-button-field"
              :field="fields.result_container_copy_button.field"
            >
              <BasicButtonField
                :field="fields.result_container_copy_button.field"
                :current-state="states.result_container_copy_button"
                :custom-value="t('widgets.BetslipWidget.copy_bets')"
              />
            </WidgetField>
          </div>
        </div>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipResultInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipResultInlineStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  warningMessageContainerInlineStyles,
  warningIconInlineStyles,
  totalAmountValueInlineStyles,
  resultContainerInlineStyles,
  totalAmountContainerInlineStyles,
  totalAmountLabelInlineStyles,
} = useBetslipResultInlineStyles(ref(props.fields));
</script>

<style lang="scss">
.betslip-widget {
  &__result-container {
    @include flex(flex-end, unset, 0px);
  }
}
</style>
