import {
  applyChangesToFields,
  findSimilaritiesInFields,
  applyChangesToDataParams,
  SourceValue,
  applyChangesToBreadcrumbs,
  findDataParamsSimilarities,
  findBreadcrumbsSimilarities,
} from "~~/components/config/helpers";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const applyChangesToWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields
): void => {
  applyChangesToDataParams(widget, sourceValue);

  applyChangesToBreadcrumbs(widget, sourceValue);

  applyChangesToFields(sourceValue, widget.fields);
};

const findSimilaritiesInWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields
): boolean => {
  const hasSimilarities = findSimilaritiesInFields(sourceValue, widget.fields);

  if (findDataParamsSimilarities(widget, sourceValue)) {
    return true;
  }

  if (findBreadcrumbsSimilarities(widget, sourceValue)) {
    return true;
  }

  return hasSimilarities;
};

export const useContentPageReplace = () => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const findSimilaritiesInPage = (
    value: SourceValue,
    widgets: IWidgetWithFields[]
  ): IWidgetWithFields[] => {
    return widgets.filter(widget => {
      const hasSimilarities = findSimilaritiesInWidget(value, widget);

      return hasSimilarities;
    });
  };

  const applyChangesToPage = (
    widgets: IWidgetWithFields[],
    value: SourceValue
  ): number => {
    widgetSettingsStore.setActiveElement(null);
    const similarWidgets = findSimilaritiesInPage(value, widgets);

    for (const widget of similarWidgets) {
      applyChangesToWidget(value, widget);
    }

    return similarWidgets.length;
  };

  return {
    applyChangesToPage,
    findSimilaritiesInPage,
  };
};
