import { type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../useWidgetFields";

export const useAcceptChangesConfig = (data: {
  widget: Ref<IWidgetWithFields>;
}) => {
  const { widget } = data;

  const fields = useWidgetFields(widget.value);

  /* 
    Accept changes
  */
  const acceptChangesDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.accept_changes_container.field,
    });
  });

  const acceptChangesContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.accept_changes_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Checkbox",
              value: "each_way_bet_checkbox",
            },
            {
              label: "Tooltip",
              value: "each_way_bet_tooltip",
            },
          ],
        },
      },
    ];
  });

  return {
    acceptChangesDesignControls,
    acceptChangesContentControls,
  };
};
