import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  State,
  ResizingType,
  Alignment,
  DisplayOrientation,
  TextStyle,
  VerticalPosition,
  VerticalAlignment,
  Grid,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  TITLE_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useSportsbookLiveFenixInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { initWidgetOptions, addFieldInitialOptions } = useCreateFields(widget);

  const { widgetCacheData } = useWidgetCacheData(widget);

  const DEFAULT_STATES = {
    events_status: State.ACTIVE_EVENTS,
    title: State.DEFAULT,
    title_button: State.DEFAULT,
    title_hover_button: State.DEFAULT,
    sports_container: State.DEFAULT,
    event_cards: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    racing_event_cards: State.DEFAULT,
    racing_event_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({ ...DEFAULT_STATES });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    bindingParams: {},
    states: {
      [State.ACTIVE_EVENTS]: { _active: true },
      [State.NO_ACTIVE]: { _active: false },
    },
  });

  const createFields = (): void => {
    // Comment if use fake widget data
    if (!widgetCacheData.value) {
      return;
    }
    // end of comment

    const widgetFields: IWidgetField[] = [];

    // Uncomment and replace if use fake widget data
    // for (const key in jsonData.content) {
    //   const fieldDetails = jsonData.content[key];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }
    // Uncomment and replace if use fake widget data
    // widgetsStore.updateWidgetFields(widget, widgetFields);

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialSportsTitleState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue(),
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
      };
    };

    const initialSportsTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "spacing", "alignment", "states.active"],
        values: {
          theme: getDefaultTheme(20),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
          decoration: [TextStyle.BOLD],
        },
        additionalData: {
          options: {
            distanceBetweenItems: 4,
            icon: {
              _active: true,
              textColor: getDefaultFillValue("#000000"),
              iconSize: 20,
            },
            states: {
              [State.HOVER]: initialSportsTitleState(),
            },
          },
        },
      }).options
    );

    const initialSportsContainerState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    const initialSportsContainerOptions = prefillEmptyOptions(
      fields.value.sports_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            distanceBetweenSports: 20,
            distanceBetweenLeagueEvents: 16,
            states: {
              [State.HOVER]: initialSportsContainerState(),
            },
          },
        },
      }).options
    );

    const initialTitleButtonOptions = prefillEmptyOptions(
      fields.value.title_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(),
          padding: getDefaultSpacing(4),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: "center",
          },
          buttonTextSettings: {
            _active: false,
            textColor: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
          iconSettings: {
            _active: true,
            position: Alignment.RIGHT,
            gap: 8,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 32,
          },
        },
      }).options
    );

    const initialHoverButtonOptions = prefillEmptyOptions(
      fields.value.title_hover_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(),
          padding: getDefaultSpacing(),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: "center",
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#595959"),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
          iconSettings: {
            _active: false,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 16,
          },
        },
      }).options
    );

    const initialEventCardsState = () => {
      return {
        color: getDefaultFillValue("#000000", 90),
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_cards.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#FFFFFF"),
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000", 90),
            contentLayout: 1,
            contentItemsDistance: 12,
            states: {
              [State.HOVER]: initialEventCardsState(),
              [State.ACTIVE]: initialEventCardsState(),
              [State.DISABLED]: initialEventCardsState(),
            },
          },
        },
      }).options
    );

    const initialEventHeaderOptions = prefillEmptyOptions(
      fields.value.event_header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          spacing: {
            padding: getDefaultSpacing({
              top: 12,
              left: 12,
              right: 12,
            }),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            contentLayout: DisplayOrientation.VERTICAL,
            leaguePosition: {
              vertical: VerticalPosition.TOP,
              horizontal: Alignment.LEFT,
            },
            iconAlignment: VerticalAlignment.TOP,
            distance: 4,
          },
        },
      }).options
    );

    const initialSportEventsContainerOptions = prefillEmptyOptions(
      fields.value.sport_events_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            columns: 3,
            distance: 12,
            gridSettings: {
              grid: Grid.FIT,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 10,
              minCardsInRow: 1,
            },
          },
        },
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "padding"],
        values: {
          theme: getDefaultTheme(16),
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialLeaguesTitlesOptions = prefillEmptyOptions(
      fields.value.leagues_titles.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "alignment", "spacing"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
        additionalData: {
          options: {
            margins: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialEventLeagueOptions = prefillEmptyOptions(
      fields.value.event_league.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#595959"),
        },
        additionalData: {
          options: {
            resizing: ResizingType.FILL,
          },
        },
      }).options
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favourites_button.field.options,
      {
        _active: true,
        textColor: {
          color: "#FFC53D",
          opacity: 100,
        },
        iconSize: 20,
        value: "ant-design:star-outlined",
      }
    );

    const initialEventStatusOptions = prefillEmptyOptions(
      fields.value.event_suspended_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["shadow", "link", "alignment", "spacing"],
        values: {
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F62F39"),
          theme: getDefaultTheme(12),
          cornerRadius: getDefaultCornerRadiusValue(4),
          padding: getDefaultSpacing({
            top: 2,
            bottom: 2,
            left: 8,
            right: 8,
          }),
          margins: getDefaultSpacing({
            right: 8,
          }),
        },
      }).options
    );

    const initialStreamIconOptions = prefillEmptyOptions(
      fields.value.stream_icon.field.options,
      {
        _active: true,
        textColor: {
          color: "#52C41A",
          opacity: 100,
        },
        iconSize: 20,
        value: "ant-design:play-circle-filled",
      }
    );

    const initialEventTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "alignment"],
        values: {
          color: getDefaultFillValue("#595959"),
          theme: getDefaultTheme(),
        },
        additionalData: {
          options: {
            icon: {
              _active: true,
              size: 20,
              color: getDefaultFillValue("#69B1FF"),
              value: "mdi:clock",
            },
          },
        },
      }).options
    );

    const initialEventPeriodOptions = prefillEmptyOptions(
      fields.value.event_period.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#595959"),
        },
        additionalData: {
          options: {
            resizing: ResizingType.HUG,
          },
        },
      }).options
    );

    const initialEventInfoOptions = prefillEmptyOptions(
      fields.value.event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size", "cornerRadius"],
        values: {
          spacing: {
            padding: getDefaultSpacing({
              left: 12,
              right: 12,
            }),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            displayMode: DisplayOrientation.HORIZONTAL,
            distance: 8,
          },
        },
      }).options
    );

    const initialEventNameOptions = prefillEmptyOptions(
      fields.value.event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#000000", 90),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialEventScoreOptions = prefillEmptyOptions(
      fields.value.event_score.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "alignment", "padding"],
        values: {
          theme: getDefaultTheme(),
          spacing: {
            padding: getDefaultSpacing({
              top: 4,
              bottom: 4,
              left: 8,
              right: 8,
            }),
            margin: getDefaultSpacing(0),
          },
          fill: getDefaultFillValue("#4096FF"),
          color: getDefaultFillValue("#FFFFFF"),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        name: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing"],
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing(8),
            containerMargins: getDefaultSpacing({
              left: 12,
              right: 12,
            }),
            distance: 8,
            displayMode: DisplayOrientation.HORIZONTAL,
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: getDefaultTheme(12),
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: getDefaultTheme(),
              decoration: [TextStyle.BOLD],
            },
            arrow: {
              up: {
                color: getDefaultFillValue("#1677ff"),
              },
              down: {
                color: getDefaultFillValue("#1677ff"),
              },
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
              [State.UP]: initialBetItemState(),
              [State.DOWN]: initialBetItemState(),
              suspended: initialBetItemState(),
            },
          },
        },
      }).options
    );

    const initialEventButtonOptions = prefillEmptyOptions(
      fields.value.event_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(),
          padding: getDefaultSpacing(),
          margins: getDefaultSpacing({
            bottom: 12,
            left: 12,
            right: 12,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#595959"),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
          iconSettings: {
            _active: true,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#595959"),
            iconSize: 20,
            position: Alignment.RIGHT,
            gap: 8,
          },
        },
      }).options
    );

    // Racing
    const initialRacingEventsContainerOptions = prefillEmptyOptions(
      fields.value.racing_events_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            gridSettings: {
              grid: Grid.FIT,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 10,
              minCardsInRow: 1,
            },
          },
        },
      }).options
    );

    const initialRacingEventCardsOptions = prefillEmptyOptions(
      fields.value.racing_event_cards.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#FFFFFF"),
          spacing: {
            padding: getDefaultSpacing(12),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            color: getDefaultFillValue("#595959"),
            contentLayout: DisplayOrientation.VERTICAL,
            contentItemsDistance: 12,
            states: {
              [State.HOVER]: initialEventCardsState(),
              [State.ACTIVE]: initialEventCardsState(),
              [State.DISABLED]: initialEventCardsState(),
            },
          },
        },
      }).options
    );

    const initialRacingEventNameOptions = prefillEmptyOptions(
      fields.value.racing_event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "spacing"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#595959"),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialRicingEventStatusOptions = prefillEmptyOptions(
      fields.value.racing_event_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["shadow", "link", "alignment", "spacing"],
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          theme: getDefaultTheme(12),
          color: getDefaultFillValue("#1677FF"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          padding: getDefaultSpacing({
            top: 2,
            bottom: 2,
            left: 8,
            right: 8,
          }),
          margins: getDefaultSpacing({
            right: 8,
          }),
        },
      }).options
    );

    const initialRacingFavIconOptions = prefillEmptyOptions(
      fields.value.racing_event_favourites_button.field.options,
      {
        _active: true,
        textColor: {
          color: "#FFC53D",
          opacity: 100,
        },
        iconSize: 20,
        value: "ant-design:star-outlined",
      }
    );

    const initialRacingEventButtonOptions = prefillEmptyOptions(
      fields.value.racing_event_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(),
          padding: getDefaultSpacing(),
          margin: getDefaultSpacing({
            bottom: 12,
            left: 12,
            right: 12,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#595959"),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
          iconSettings: {
            _active: true,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#595959"),
            iconSize: 20,
            position: Alignment.RIGHT,
            gap: 8,
          },
        },
      }).options
    );

    const initialRicingStreamIconOptions = prefillEmptyOptions(
      fields.value.racing_event_stream_icon.field.options,
      {
        _active: true,
        textColor: {
          color: "#52C41A",
          opacity: 100,
        },
        iconSize: 20,
        value: "ant-design:play-circle-filled",
      }
    );

    addFieldInitialOptions(
      "no_outcomes_title",
      {
        data: { options: {} },
      },
      {
        value: "Default",
      }
    );

    widgetsStore.updateFieldOptions(
      fields.value.sports_container.field,
      initialSportsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title_button.field,
      initialTitleButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title_hover_button.field,
      initialHoverButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialSportsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_cards.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_header.field,
      initialEventHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.sport_events_container.field,
      initialSportEventsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.leagues_titles.field,
      initialLeaguesTitlesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_league.field,
      initialEventLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourites_button.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_suspended_status.field,
      initialEventStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.stream_icon.field,
      initialStreamIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialEventTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_period.field,
      initialEventPeriodOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info.field,
      initialEventInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_name.field,
      initialEventNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_score.field,
      initialEventScoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialEventButtonOptions
    );
    // racing
    widgetsStore.updateFieldOptions(
      fields.value.racing_events_container.field,
      initialRacingEventsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_cards.field,
      initialRacingEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_name.field,
      initialRacingEventNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_status.field,
      initialRicingEventStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_favourites_button.field,
      initialRacingFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_button.field,
      initialRacingEventButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_stream_icon.field,
      initialRicingStreamIconOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();

      fields.value.no_events_title.field.value =
        useLocalizedValue().transformToLocalizedValue(
          STATE_KEY_LABELS.no_active
        ).localizedValue;
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
