<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="widgets-list">
    <a-popover
      v-for="widget in items"
      :key="widget.uuid"
      :visible="hoveredItemId === widget.uuid"
      placement="right"
      overlay-class-name="widgets-list__popover"
      @mouseenter="setHovereditemId(widget.uuid)"
      @mouseleave="setHovereditemId()"
    >
      <div
        v-draggable="{
          payload: { isGridElement: false, widgetListElement: widget },
          imageSelectorId: 'CardWidget',
          containerId: DndContainerId.WIDGETS,
          onDragStart: handleDragStart,
        }"
        class="widgets-list__item relative"
      >
        <CommonIcon
          name="custom:dnd-marker"
          class="widgets-list__item-draggable-icon absolute"
        />

        <CommonIcon
          v-if="widget.icon"
          :name="widget.icon"
          class="widgets-list__item-icon"
        />

        <p
          class="f-small text-center m-t-8 widgets-list__item-label"
          v-html="widget.nameHighlighted"
        ></p>
      </div>
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";
import { DndContainerId } from "~~/models/common/dropdown.model";

defineProps<{
  items: IWidgetsListElement[];
}>();

const hoveredItemId = ref("");

const handleDragStart = () => setHovereditemId();

const setHovereditemId = (id = "") => {
  hoveredItemId.value = id;
};
</script>

<style lang="scss">
.widgets-list {
  @include flex(flex-start, flex-start, 0, true);

  &__popover {
    padding: $space-m;

    .ant-popover-inner {
      border-radius: 6px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }

  &__item {
    @include flex-column(center, center, 0);

    position: relative;
    margin: 0 15px 15px 0;
    width: 88px;
    height: 92px;
    border: 1px solid $c-grey-15;
    border-radius: 4px;
    background-color: $c-white;
    transition: border-color $base-transition;
    overflow: hidden;

    &-draggable-icon {
      position: absolute;
      top: 6px;
      left: 7px;
      opacity: 0;
      transition: opacity $base-transition;
      color: $c-primary-base;
      font-size: 10px;
    }

    &-icon {
      transition: fill $base-transition;
      font-size: 32px;
    }

    &-label {
      @include text-ellipsis(2);

      transition: color $base-transition;
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    $this: &;
    &:hover {
      cursor: pointer;
      border-color: $c-primary-base;

      #{$this}-icon {
        fill: $c-primary-base;
      }

      #{$this}-label {
        color: $c-primary-base;
      }

      #{$this}-draggable-icon {
        opacity: 1;
      }
    }
  }
}
</style>
