<template>
  <div class="widget-control-container p-t-16 p-b-16 p-l-16 p-r-16">
    <ConfigToggle
      v-if="hasContentPermissions"
      :model-value="modelValue._active"
      size="small"
      :label="label"
      :allow-disable="allowDisable"
      :hide-switch="
        hideSwitch || modelValue._active === undefined || isStatesTab
      "
      is-field-item-toggle
      :show-options="false"
      @update:model-value="handleUpdate('_active', $event)"
    />

    <div
      v-if="
        isStatesTab ||
        modelValue._active === undefined ||
        (typeof modelValue._active === 'boolean' && modelValue._active)
      "
    >
      <div
        v-if="
          !hidePicker &&
          !isStatesTab &&
          (modelValue.value !== undefined ||
            modelValue.default_value !== undefined) &&
          hasContentPermissions
        "
        class="config-icon-settings__select-container p-t-16"
      >
        <IconPicker
          :model-value="modelValue.value || modelValue.default_value"
          @update:model-value="handleUpdate('value', $event)"
        />
      </div>
      <template v-if="modelValue.textColor !== undefined">
        <ConfigColorPickerInput
          :model-value="modelValue.textColor"
          :type="ColorPickerType.TEXT"
          label="Icon color"
          class="config-icon-settings__color-picker"
          @update:model-value="handleUpdate('textColor', $event)"
        />
      </template>
      <template v-if="!isStatesTab && hasDesignPermissions">
        <div
          v-if="modelValue.iconSize !== undefined"
          class="config-icon-settings__inputs-container"
        >
          <span>Size</span>
          <ConfigRangeSlider
            :model-value="modelValue.iconSize"
            :min-value="MIN_ICON_SIZE_INPUT_VALUE"
            :max-value="MAX_ICON_SIZE_INPUT_VALUE"
            class="config-icon-settings__input-element"
            @update:model-value="handleUpdate('iconSize', $event)"
          />
        </div>

        <div
          v-if="modelValue.position !== undefined && hasDesignPermissions"
          class="config-icon-settings__icon-position-container p-t-16"
        >
          <span class="p-t-16">Position</span>
          <a-radio-group
            :value="modelValue.position"
            class="config-icon-settings__radio-group"
            @update:value="handleUpdate('position', $event)"
          >
            <a-radio-button :value="Alignment.LEFT">
              <span class="config-icon-settings__radio-group-icon">
                <CommonIcon name="custom:unordered-list-outlined" />
              </span>
            </a-radio-button>
            <a-radio-button :value="Alignment.RIGHT">
              <span class="config-icon-settings__radio-group-icon r-180">
                <CommonIcon name="custom:unordered-list-outlined" />
              </span>
            </a-radio-button>
          </a-radio-group>
        </div>

        <div
          v-if="modelValue.gap !== undefined && hasDesignPermissions"
          class="config-icon-settings__inputs-container"
        >
          <p>Spacing between text and icon</p>
          <ConfigRangeSlider
            :model-value="modelValue.gap"
            :min-value="MIN_GAP_INPUT_VALUE"
            :max-value="MAX_GAP_INPUT_VALUE"
            class="config-icon-settings__input-element"
            @update:model-value="handleUpdate('gap', $event)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Alignment } from "~~/models/widgets/widget-controls.model";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
  MIN_GAP_INPUT_VALUE,
  MAX_GAP_INPUT_VALUE,
} from "~~/constants/input-validation";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

import IconPicker from "./ConfigIconPicker.vue";

const props = withDefaults(
  defineProps<{
    modelValue: {
      value: string;
      textColor?: { [key: string]: string };
      iconSize?: { [key: string]: string };
      position?: { [key: string]: string };
      gap?: { [key: string]: string };
      _active?: boolean;
      default_value?: string;
    };
    allowDisable?: boolean;
    isStatesTab?: boolean;
    hideSwitch?: boolean;
    hidePicker?: boolean;
    label?: string;
  }>(),
  {
    allowDisable: true,
    isStatesTab: false,
    hideSwitch: false,
    label: "Icon",
  }
);

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: { [key: string]: { [key: string]: string } | boolean | string }
  ): void;
  (e: "update:active", event: boolean): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-icon-settings {
  padding: $space-m;

  &__switch {
    @include flex(center, flex-start, 6px);
    width: 100%;
  }

  &__radio-group {
    margin: 10px 0;
    width: 100%;
    @include flex(center, center, 0);

    .ant-radio-button-wrapper {
      @include flex(center, center, 0);
      width: 50%;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  &__radio-group-icon {
    @include flex(center, center, 0);
  }

  &__inputs-container {
    @include flex-column(flex-start, center, 0);
    width: 100%;
    padding-top: $space-m;
  }

  &__input-element {
    @include flex(center, center, 0);
    width: 100%;
  }

  &__slider {
    flex-basis: 70%;
  }

  &__input {
    flex-basis: 30%;

    .ant-input {
      padding-right: 28px !important;
    }
  }

  &__select-container {
    @include flex(center, space-between, 0);
    width: 100%;
  }

  &__selected-icon {
    border: 1px solid $c-grey-15;
    height: 24px;
    width: 24px;
    @include flex(center, center, 0);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: $c-grey-45;
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &__alignment {
    padding: 0;
    margin-bottom: 15px;
  }

  &__color-picker {
    padding: $space-m 0;
    border-bottom: unset;
  }

  .wconfig-color {
    padding: 0;
    border-bottom: unset;
  }

  .ant-select-selection-item {
    ::before {
      content: "";
      height: 10px;
      width: 2px;
      color: black;
      background-color: black;
    }
  }
}
</style>
