<template>
  <div
    v-if="props.cell.children?.length"
    class="widget-cell"
    :class="widgetCellClasses"
    :style="[widgetCellStyle, widgetContainerInlineStyle]"
  >
    <WidgetLabel
      v-if="widgetSelectorLabel"
      :label="widgetSelectorLabel"
    />
    <WidgetCell
      v-for="childCell in props.cell.children"
      :key="childCell.cellId"
      :cell="childCell"
    />
  </div>

  <div
    v-else
    :data-element-id="cell.cellId"
    :class="{
      'widget-cell--error': isErrorCell,
      'widget-cell--target': isTargetCell && hasDesignPermissions,
      'widget-cell--is-empty': !hasWidgets,
      ...widgetCellClasses,
    }"
    class="widget-cell widget-cell--column widget-cell--is-child"
    :style="[widgetCellStyle, widgetContainerInlineStyle]"
  >
    <WidgetLabel
      v-if="isCellAsCellSelected"
      :label="widgetSelectorLabel"
    />

    <div
      v-if="hasWidgets"
      :style="cellContentStyles"
      class="widget-cell__list"
    >
      <WidgetCellItem
        v-for="(widget, widgetIdx) in cellWidgets"
        :key="widget.id"
        :widget="widget"
        :cell="cell"
        :index="widgetIdx"
      />
    </div>

    <!-- 
      TODO redo to v-else.
      Right not with v-else it gives error in console on devele widgets(when cell becomes empty)
    -->
    <div
      v-if="!hasWidgets"
      class="widget-cell__empty"
    >
      <span
        v-if="isErrorCell"
        class="widget-cell__empty-error-text"
      >
        You can't put a widget in this area
      </span>

      <template v-else>
        <CommonIcon name="ant-design:plus-outlined" />
        Drop widget here
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ICell,
  NestingLevel,
  CellSizeType,
  CellLayout,
} from "~~/models/grid.interface";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useGridConfig } from "~~/store/grid";
import { useWidgetCellStyling } from "~~/composables/widgets/useWidgetCellStyling";
import {
  IWidgetOptions,
  IWidgetWithFields,
  WidgetDefaultSettingsDropdownItem,
} from "~~/models/widgets/widget.core/widget.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getColorFromHex, getFlexAlign } from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { getCellFixedHeightStyle } from "~~/assets/utils/grid/widget.cell-height";
import { toSorted } from "~~/helpers/toSorted";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  cell: ICell;
}>();

const widgetStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const gridStore = useGridConfig();
const { getWidgetCellClasses, getWidgetCellStyle } = useWidgetCellStyling();

const { selectedDropdownItem } = storeToRefs(widgetSettingsStore);

const { cellsOptions } = gridStore;

const cellWidgets = computed<IWidgetWithFields[]>(() => {
  if (!props.cell.widgets) {
    return [];
  }

  return toSorted(props.cell.widgets, (a, b) => a.position - b.position);
});

const hasWidgets = computed<boolean>(() => cellWidgets.value.length > 0);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const cellStyleSettings = computed<IWidgetOptions | undefined>(() => {
  return cellsOptions[props.cell.cellId];
});

const cellHeightStyles = computed<ElementStyle>(() => {
  const settings = cellStyleSettings.value?.height;

  if (!settings) {
    return {
      height: "100%",
      alignSelf: "stretch",
    };
  }

  if (settings.type === CellSizeType.FILL) {
    return {
      minHeight: "100%",
      alignSelf: "stretch",
    };
  }

  if (settings.type === CellSizeType.HUG) {
    return {
      height: "auto",
    };
  }

  return getCellFixedHeightStyle(settings);
});

const cellAlignment = computed<ElementStyle>(() => {
  return getFlexAlign(cellStyleSettings.value?.cellContentSettings?.alignment);
});

const cellContentStyles = computed<ElementStyle>(() => {
  const settings = cellStyleSettings.value?.cellContentSettings;
  const layout = settings?.layout;

  const style: ElementStyle = {};

  style.alignSelf = "stretch";
  style.height = "100%";

  if (!settings) {
    return style;
  }

  style.flexDirection =
    layout === CellLayout.VERTICAL || !layout ? "column" : "row";
  style.flexWrap = layout === CellLayout.WRAP ? "wrap" : "unset";
  style.gap = getPxValueFromNumber(settings.gap);

  style.overflowX = settings.overflow;

  return {
    ...style,
    ...cellAlignment.value,
  };
});

// We need this computed to set styles from container settings
const widgetContainerInlineStyle = computed<Record<string, string | undefined>>(
  () => {
    if (!cellStyleSettings.value) return {};

    const commonStyles = {
      gap: getPxValueFromNumber(cellStyleSettings.value.spaceBetween),
      backgroundColor:
        getColorFromHex(cellStyleSettings.value.backgroundColor) || "",
      marginTop: getPxValueFromNumber(cellStyleSettings.value.margin?.top),
      marginBottom: getPxValueFromNumber(
        cellStyleSettings.value.margin?.bottom
      ),
      marginLeft: getPxValueFromNumber(cellStyleSettings.value.margin?.left),
      marginRight: getPxValueFromNumber(cellStyleSettings.value.margin?.right),
    };
    const alignmentStyle =
      props.cell.settings?.level === NestingLevel.PARENT
        ? { alignItems: cellStyleSettings.value.alignment }
        : { justifyContent: cellStyleSettings.value.alignment };

    return { ...commonStyles, ...alignmentStyle, ...cellHeightStyles.value };
  }
);

/*
  TODO implement error state for cell, based on validation. (Css classes added already)
*/
const isErrorCell = computed<boolean>(() => {
  return false;
});

const isTargetCell = computed<boolean>(() => {
  return props.cell.cellId === widgetStore.dragTargetEmptyWidgetCell?.cellId;
});

const isCellAsParentSelected = computed<boolean>(() => {
  return (
    widgetSettingsStore.selectedContainer?.cellId === props.cell.cellId &&
    selectedDropdownItem.value ===
      WidgetDefaultSettingsDropdownItem.PARENT_SETTINGS
  );
});

const isCellAsChildSelected = computed<boolean>(() => {
  return (
    widgetSettingsStore.selectedColumn?.cellId === props.cell.cellId &&
    selectedDropdownItem.value ===
      WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS
  );
});

const isCellAsCellSelected = computed<boolean>(() => {
  return (
    widgetSettingsStore.selectedWidget?.cell_id === props.cell.cellId &&
    selectedDropdownItem.value ===
      WidgetDefaultSettingsDropdownItem.CELL_SETTINGS
  );
});

const widgetCellStyle = computed<{ [key: string]: string | undefined }>(() => {
  return getWidgetCellStyle(props.cell);
});

const widgetCellClasses = computed<{ [key: string]: string | boolean }>(() => {
  return getWidgetCellClasses(
    props.cell,
    gridStore.cellsOptions[props.cell.cellId],
    isCellAsParentSelected.value,
    isCellAsChildSelected.value,
    isCellAsCellSelected.value
  );
});

const widgetSelectorLabel = computed<string | null>(() => {
  if (isCellAsParentSelected.value) {
    return "Parent";
  }

  if (isCellAsChildSelected.value) {
    return "Child";
  }

  if (isCellAsCellSelected.value) {
    return "Cell";
  }

  return null;
});
</script>

<style lang="scss">
$widget-cell: "widget-cell";
$border-params: 2px dashed $c-grey-15;

.#{$widget-cell} {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-sizing: content-box;

  &--is-empty {
    height: 100%;
  }
  /*
    Share same width for all cells(so cell can not overtake width from other cells)
  */

  /*
    Add border top to cells if there is a space on top
  */
  &--has-margin-top {
    .#{$widget-cell}--is-child {
      border-top: $border-params;
    }
  }

  /*
    Add border bottom to cells if there is a space on bottom
  */
  &--has-margin-bottom {
    .#{$widget-cell}--is-child {
      border-bottom: $border-params;
    }
  }

  /*
    Add border left to cell if it's not first in queue
  */
  &--is-child {
    &:not(:first-child) {
      border-left: $border-params;
    }
  }

  /*
    Add border right to inner cells if there is space between them
  */
  &--has-space-between {
    & > .#{$widget-cell}--is-child {
      border-right: $border-params;
    }
  }

  /*
    Add border right to last in queue child inner cell if there is no space between columns
  */
  &--container:not(&--has-space-between):not(&--root) {
    & > .#{$widget-cell}--is-child:last-child {
      border-right: $border-params;
    }
  }

  /*
    Remove right border from last elements from right side
    (covered 2 flows - for PARENT level block & CHILD block)
  */
  &--root {
    max-width: 100%;

    & > .#{$widget-cell} {
      /*
        Remove right border from last NestingLevel.PARENT level cell
      */
      &:last-child {
        &.#{$widget-cell}--is-child {
          border-right: none;
        }

        /*
          Remove right border from last NestingLevel.CHILD level cell
        */
        .#{$widget-cell}--is-child:last-child {
          border-right: none;
        }
      }

      /*
        Add left border for first child element inside root elements, but not first one
      */
      &:not(:first-child) {
        .#{$widget-cell}--is-child:first-child {
          border-left: $border-params;
        }
      }
    }

    /*
      If there is no space between inside container -
      add right border to not last cell and remove
      left border from first cell on next container
    */
    &:not(.#{$widget-cell}--has-space-between):not(.#{$widget-cell}--is-empty) {
      .#{$widget-cell}--is-child:last-child {
        border-right: none;
      }

      &.#{$widget-cell}--root {
        & > .#{$widget-cell}:not(:last-child) {
          border-right: $border-params;

          & + .#{$widget-cell} {
            border-left: none;

            .#{$widget-cell}--is-child:first-child {
              border-left: none;
            }
          }
        }
      }
    }
  }

  &--active {
    border: 2px solid rgba($c-primary-base, 1);
  }

  &__list {
    @include flex-column(stretch, flex-start, 0);
    width: 100%;
  }

  &.#{$widget-cell}--align-center {
    & > .#{$widget-cell}--is-child {
      .#{$widget-cell}__list {
        justify-content: center;
      }
    }
  }

  &.#{$widget-cell}--align-flex-start {
    & > .#{$widget-cell}--is-child {
      .#{$widget-cell}__list {
        justify-content: flex-start;
      }
    }
  }

  &.#{$widget-cell}--align-flex-end {
    & > .#{$widget-cell}--is-child {
      .#{$widget-cell}__list {
        justify-content: flex-end;
      }
    }
  }

  &__empty {
    @include flex-column(center, center, 0);
    width: 100%;
    padding: 12px;
    text-align: center;
    color: $c-grey-45;
    background-color: $c-grey-02;

    .anticon {
      font-size: 18px;
      margin-bottom: 6px;
    }

    &-error-text {
      max-width: 80px;
    }
  }

  &--error {
    &:after {
      position: absolute;
      top: -2px;
      left: -2px;
      display: block;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: "";
      border: 2px dashed $c-red;
      background-color: $c-light-red;
      z-index: 2;
    }

    .#{$widget-cell}__empty {
      z-index: 3;
      color: $c-red;
    }
  }

  &--target {
    &:after {
      position: absolute;
      top: -2px;
      left: -2px;
      display: block;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: "";
      border: 2px dashed $c-primary-base;
      background-color: $c-light-blue;
      z-index: 2;
    }

    .#{$widget-cell}__empty {
      z-index: 3;
      color: $c-primary-base;
    }
  }

  &--container:not(:last-child) {
    border-bottom: $border-params;
  }

  &__empty {
    height: 100%;
    min-height: 100px;
  }

  &--column {
    max-width: 100%;
    flex-direction: column;
    align-items: stretch;

    &--active {
      border: 2px solid rgba($c-primary-base, 1) !important;
    }
  }

  &--fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
</style>
