// import { useI18n } from "vue-i18n";
import { useWidgetsStore } from "~~/store/widgets";
import {
  Alignment,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";

import { useCreateFields } from "../../common/useCreateFields";
import { STATUS_MESSAGE_INITIAL } from "../../provider-form-fenix/useProviderFormFenixInitFieldsOptions";

const LIST_FILTER_FIELDS = [
  "form",
  "date_from",
  "date_to",
  "transaction_types",
  "transaction_subtypes",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "calendar_styling",
  "datepicker_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  // const { t } = useI18n();

  const DEFAULT_STATES: Record<string, State> = {
    page_type: State.TRANSACTION_HISTORY,
    table_checkboxes_settings: State.SELECTED_DEFAULT,
    action_button: State.DEFAULT,
    items: State.ACTIVE_EVENTS,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.CALENDAR]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
    [FormStyleFieldName.RADIO_GROUP]: State.SELECTED_DEFAULT,
    [FormStyleFieldName.CHECKBOX]: State.SELECTED_DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  /*
    Initialize widget with initial options
  */

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    states: {
      [State.TRANSACTION_HISTORY]: { _active: true },
      [State.BETTING_HISTORY]: { _active: false },
      [State.PENDING_WITHDRAWALS]: { _active: false },
    },
  });

  const init = (): void => {
    initFields();

    // TODO - make translations
    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      exclude: ["margins", "link"],
      values: {
        text: {
          [State.TRANSACTION_HISTORY]: "Transaction History",
          [State.BETTING_HISTORY]: "Betting History",
          [State.PENDING_WITHDRAWALS]: "Pending withdrawals",
        },
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({
          bottom: 16,
        }),
      },
    });

    addFieldInitialOptions("filter", {
      data: DESIGN_INITIAL(),
      values: {
        spacing: {
          margin: getDefaultSpacing({
            bottom: 16,
          }),
        },
      },
      additionalData: {
        options: {
          fillImageColor: getDefaultFillImage(),
        },
      },
    });

    addFieldInitialOptions(
      "filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(20),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
      },
      { value: "Filter" }
    );

    addFieldInitialOptions(
      "pending_description",
      {
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "margins", "spacing"],
        values: {
          color: getDefaultFillValue("#000000", 65),
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      },
      { value: "Pending withdrawal cancel info" }
    );

    addFieldInitialOptions(
      "pending_error_text",
      {
        data: STATUS_MESSAGE_INITIAL(
          "#FFFFFF",
          "#FF4D4F",
          "ant-design:close-circle-filled"
        ),
        additionalData: {
          options: {
            margins: getDefaultSpacing({
              top: 12,
            }),
          },
        },
      },
      { value: "Error Message" }
    );

    addFieldInitialOptions("form", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          distance: 24,
          displayMode: 2,
        },
      },
    });

    addFieldInitialOptions("date_filter", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          distance: 12,
        },
      },
    });

    addFieldInitialOptions("transactions_filter", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          distance: 12,
          itemsType: "dropdown",
        },
      },
    });

    addFieldInitialOptions(
      "date_filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({
            bottom: 4,
          }),
        },
      },
      { value: "By date" }
    );

    addFieldInitialOptions(
      "transactions_filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({
            bottom: 4,
          }),
        },
      },
      { value: "By transactions" }
    );

    addFieldInitialOptions("table", {
      data: {
        options: {
          verticalSize: 12,
          horizontalSize: 12,
          fill: getDefaultFillValue("#ffffff"),
          borderType: "between_rows",
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 6),
          }),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(),
          spacing: {
            margin: getDefaultSpacing(),
            padding: getDefaultSpacing(),
          },
          mode: "table",
        },
      },
    });

    addFieldInitialOptions("table_header_settings", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins", "padding"],
      values: {
        theme: getDefaultTheme(13),
      },
      additionalData: {
        options: {
          fill: getDefaultFillValue("#000000", 2),
          separator: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 6),
          }),
        },
      },
    });

    addFieldInitialOptions("table_row_settings", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins", "padding"],
      values: {
        theme: getDefaultTheme(13),
      },
      additionalData: {
        options: {
          fill: getDefaultFillValue(),
          icon: {
            color: getDefaultFillValue("#1F1F1F"),
            size: 14,
          },
        },
      },
    });

    const initialCheckboxState = (
      fill = "#1677FF"
    ): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(fill),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue(fill, 100),
        }),
        checkIcon: {
          color: getDefaultFillValue("#ffffff"),
        },
      };
    };

    addFieldInitialOptions("table_checkboxes_settings", {
      data: DESIGN_INITIAL(),
      exclude: ["shadow", "cornerRadius", "spacing", "size"],
      values: {
        fill: getDefaultFillValue("#1677FF"),
      },
      additionalData: {
        options: {
          checkIcon: {
            color: getDefaultFillValue("#ffffff"),
          },
          states: {
            [State.SELECTED_HOVER]: initialCheckboxState(),
            [State.SELECTED_DISABLED]: initialCheckboxState(),
            [State.UNSELECTED_DEFAULT]: initialCheckboxState("#F3F3F3"),
            [State.UNSELECTED_HOVER]: initialCheckboxState("#F3F3F3"),
            [State.UNSELECTED_DISABLED]: initialCheckboxState("#F3F3F3"),
          },
        },
      },
    });

    addFieldInitialOptions(
      "no_events_title",
      {
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "spacing"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#000000", 65),
          alignment: Alignment.LEFT,
        },
        additionalData: {
          options: {
            iconSettings: {
              _active: false,
              default_value: "ant-design:ant-design-outlined",
              textColor: getDefaultFillValue("#000000", 65),
              iconSize: 16,
            },
          },
        },
      },
      { value: "There is no active transaction" }
    );

    addFieldInitialOptions(
      "action_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          margins: getDefaultSpacing({ top: 16 }),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 16,
            right: 16,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          iconSettings: {
            default_value: "ant-design:close-outlined",
            textColor: getDefaultFillValue("#FFFFFF"),
            iconSize: 20,
            position: Alignment.LEFT,
            gap: 8,
            _active: true,
          },
        },
      },
      { value: "Cancel button" }
    );

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    DEFAULT_STATES,

    init,
    filterFields,
  };
};
