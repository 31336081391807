import { nextTick } from "vue";
import { useNotification } from "@kyvg/vue3-notification";

import {
  PAYLOAD_DND_KEY,
  RECALCULATE_DIVIDER_FREQUENCY,
} from "~~/constants/dnd";
import {
  getClosestPossibleDragTargetElement,
  getCurrentDatasetElementId,
  parseId,
  throttle,
} from "~~/assets/utils";
import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useGridConfig } from "~~/store/grid";
import { useWidgetsStore } from "~~/store/widgets";
import { IDraggablePayload } from "~~/models/dnd.model";
import { IWidgetDraggable } from "~~/models/widgets/widget.core/widget.model";
import { createWidgetFromListElement } from "~~/assets/utils/widget";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { usePermissions } from "~~/composables/permissions/usePermissions";

import { useGridDnd } from "../grid/useGridDnd";
import { useDivider } from "../grid/useDivider";

export const useWidgetsDnd = () => {
  const getDragTargetInfo = (e: DragEvent) => {
    const target = e.target as HTMLElement;

    const targetElement = getClosestPossibleDragTargetElement(target);
    const targetElementId = getCurrentDatasetElementId(targetElement);
    const isTargetWidget = !!targetElement?.dataset.isWidget;

    let targetCellId: string | number | null | undefined = null;
    let targetWidgetId: string | number | null | undefined = null;

    if (isTargetWidget) {
      targetWidgetId = targetElementId;
      targetCellId = getCurrentDatasetElementId(
        getClosestPossibleDragTargetElement(targetElement.parentElement)
      );
    } else {
      targetCellId = targetElementId;
      targetWidgetId = null;
    }

    return {
      isTargetWidget,
      targetElement,
      targetCellId,
      targetWidgetId,
    };
  };

  const isWidgetDraggedIntoItself = (
    targetWidgetId?: string | number | null
  ) => {
    return (
      targetWidgetId !== undefined &&
      targetWidgetId !== null &&
      targetWidgetId === store.currentDraggingWidgetId
    );
  };

  const store = useWidgetsStore();
  const gridStore = useGridConfig();
  const widgetSettingsStore = useWidgetSettingsStore();
  const { currentWidgetsLayout } = storeToRefs(gridStore);

  const {
    workspaceRootYPosition,
    handleUpdateContainerPosition,
    checkDimensions,
  } = useGridDnd();

  const {
    hideDivider,
    setInsideCellDividerRenderData,
    insertPosition,
    resetInsertPosition,
    calculateRootRowsDimensions,
  } = useDivider(currentWidgetsLayout, false);

  const handleBlockDrop = async (event: DragEvent): Promise<void> => {
    const hasPermission = usePermissions().has(
      usePermissions().Permissions.DESIGN
    );

    if (!hasPermission) {
      useNotification().notify({
        text: "You are not allowed to drag widgets",
        type: "error",
      });

      return;
    }

    try {
      const { isTargetWidget, targetCellId, targetWidgetId } =
        getDragTargetInfo(event);

      if (!targetWidgetId && !targetCellId) return;

      if (isWidgetDraggedIntoItself(targetWidgetId)) return;

      const payloadStringified = event.dataTransfer?.getData(
        PAYLOAD_DND_KEY
      ) as string;

      const payload: IDraggablePayload = JSON.parse(payloadStringified);
      const dndPayload: IWidgetDraggable = payload.payload;
      const { cellId, draggingWidgetId, isGridElement, widgetListElement } =
        dndPayload;

      const fromCell = findCell(currentWidgetsLayout.value, cellId);
      const toCell = findCell(currentWidgetsLayout.value, targetCellId);

      const widgetToMove = isGridElement
        ? store.removeWidget(fromCell!, draggingWidgetId)
        : createWidgetFromListElement(widgetListElement!, toCell?.cellId);

      let insertIndex = toCell?.widgets?.length || 0;

      if (isTargetWidget) {
        insertIndex =
          toCell?.widgets?.findIndex(w => parseId(w.id) === targetWidgetId) +
            insertPosition.value.insertionRule || 0;
      }

      await store.addWidget(toCell!, widgetToMove!, insertIndex, isGridElement);
      widgetSettingsStore.updateSelectedWidgetData("cell_id", toCell?.cellId);
    } finally {
      store.setCurrentDraggingWidgetId(null);
      store.setDragTargetEmptyWidgetCell(null);
      resetInsertPosition();
      await nextTick(() => {
        calculateRootRowsDimensions();
      });
    }
  };

  const handleDragOver = throttle((e: DragEvent) => {
    const { isTargetWidget, targetElement, targetCellId, targetWidgetId } =
      getDragTargetInfo(e);
    /*
      Get current mouseY relative to window(default is relative to viewport)
    */
    const clientY = e.clientY - workspaceRootYPosition.value;

    store.setDragTargetEmptyWidgetCell(null);
    hideDivider();

    // at least should drag over cell
    if (!targetCellId) return;

    if (isWidgetDraggedIntoItself(targetWidgetId)) return;

    if (isTargetWidget) {
      setInsideCellDividerRenderData(
        targetElement,
        clientY,
        workspaceRootYPosition.value
      );
    } else {
      const cell = findCell(currentWidgetsLayout.value, targetCellId);

      if (cell?.widgets?.length) {
        // render divider at the end of last widget of currentCell
        setInsideCellDividerRenderData(
          targetElement!.querySelector(
            `#widgetId_${cell.widgets[cell.widgets.length - 1].id}`
          ),
          clientY,
          workspaceRootYPosition.value
        );
      } else {
        store.setDragTargetEmptyWidgetCell(cell);
      }
    }
  }, RECALCULATE_DIVIDER_FREQUENCY);

  return {
    handleBlockDrop,
    handleDragOver,
    handleUpdateContainerPosition,
    checkDimensions,
  };
};
