<template>
  <div>
    <WidgetField
      v-if="fields.header_tabs_container.active"
      :widget="widget"
      :field="fields.header_tabs_container.field"
    >
      <div
        class="betslip-widget__header-items-container"
        :style="headerContainerInlineStyles"
      >
        <div
          class="betslip-widget__header-item"
          :style="[headerContentGapInlineStyles, headerContentInlineStyles]"
        >
          <div :style="headerLabelInlineDecorationStyles">
            <span>{{ t("widgets.BetslipWidget.betslip") }}</span>
          </div>
          <WidgetField
            v-if="fields.header_tabs_container_counters.active"
            :widget="widget"
            :field="fields.header_tabs_container_counters.field"
          >
            <div :style="headerCounterInlineStyles">1</div>
          </WidgetField>
        </div>

        <div
          class="betslip-widget__header-items-cash-out-container"
          :style="[headerContentInlineStyles]"
        >
          <div
            class="betslip-widget__header-item"
            :style="headerContentGapInlineStyles"
          >
            <div :style="headerLabelInlineDecorationStyles">
              <span>{{ t("widgets.BetslipWidget.mybets") }}</span>
            </div>
            <WidgetField
              v-if="fields.header_tabs_container_counters.active"
              :widget="widget"
              :field="fields.header_tabs_container_counters.field"
            >
              <div :style="headerCounterInlineStyles">1</div>
            </WidgetField>
          </div>

          <WidgetField
            v-if="fields.cashout.active"
            :widget="widget"
            :field="fields.cashout.field"
          >
            <div :style="headerCashOutStyles">Cash out!</div>
          </WidgetField>
        </div>
      </div>
    </WidgetField>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipHeaderInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipHeaderInlineStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  headerContainerInlineStyles,
  headerContentGapInlineStyles,
  headerContentInlineStyles,
  headerCounterInlineStyles,
  headerLabelInlineDecorationStyles,
  headerCashOutStyles,
} = useBetslipHeaderInlineStyles(
  ref(props.fields.header_tabs_container.field.options),
  ref(props.fields.header_tabs_container_counters.field.options),
  toRef(() => props.states.header_tabs_container),
  ref(props.fields.cashout.field.options)
);
</script>

<style lang="scss" scoped>
.betslip-widget__header-items-cash-out-container {
  display: flex;
  flex-direction: column;
}
</style>
