import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useInitWidgetFieldsOptions } from "~~/composables/widgets/betslip-fenix/useBetslipFenixFieldsOptions";

const LIST_FILTER_FIELDS = [
  "bet_amount",
  "win_amount",
  "labels_styling",
  "fields_styling",
  "errors_styling",
];

export const useBetslipInitData = (widget: IWidgetWithFields) => {
  const DEFAULT_STATES = {
    BetslipFenixWidget: State.BETSLIP,
    header_tabs_container: State.DEFAULT,
    betslip_tabs_container: State.DEFAULT,
    bets_container: State.SINGLE_BETS,
    coefficient_title: State.DEFAULT,
    result_container_bet_button: State.DEFAULT,
    result_container_remove_button: State.DEFAULT,
    result_container_copy_button: State.DEFAULT,
    bets_result_save_choice_button: State.DEFAULT,
    bets_result_continue_button: State.DEFAULT,
    my_bets_primary_button: State.DEFAULT,
    my_bets_secondary_button: State.DEFAULT,
    bets_type_title: State.SINGLE_BETS,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    collapse: State.DEFAULT,
    each_way_bet_checkbox: State.SELECTED_DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const { init } = useInitWidgetFieldsOptions(widget, LIST_FILTER_FIELDS);

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    filterFields,
    DEFAULT_STATES,

    init,
  };
};
