<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :value="value"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    class="form-text-input-field"
  >
    <div :style="passwordCssVars">
      <a-input-password
        :placeholder="
          placeholder?._active ? getLocalizedValue(placeholder.value) : ''
        "
        :style="inputStyles"
        :value="getLocalizedValue(field.value)"
        class="form-text-input-field__input w-100"
        readonly
      />
    </div>
  </FormInputBase>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

interface IFormTextInputProp {
  _active: boolean;
  value: string;
}

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const { getLocalizedValue } = useLocalizedValue();

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.FIELD];
});

const {
  inputStyles,
  isErrorVisible,
  isTooltipVisible,
  triggerFieldExample,
  hideFieldExample,
  inputIconStyle,
} = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});

const placeholder = computed<IFormTextInputProp | undefined>(() => {
  return props.field.options?.placeholder;
});

const passwordCssVars = computed<ElementStyle>(() => {
  return getStylesAsVars(inputIconStyle.value);
});
</script>

<style lang="scss">
.form-text-input-field {
  &__input {
    outline: none;
    width: 100%;
    border-radius: 0px;
    border: 1px solid transparent;
    &::placeholder {
      color: $c-grey-25;
    }
  }

  .ant-input-affix-wrapper {
    box-shadow: unset;
    transition: all 0s !important;

    input {
      background: none;
      transition: all 0s !important;
      background-color: var(--backgroundColor) !important;
      color: var(--color) !important;
      font-size: var(--fontSize) !important;
      font-style: var(--fontStyle) !important;
      text-decoration: var(--decoration) !important;
      border-color: var(--borderColor) !important;
      border-width: var(--borderWidth);
      border-style: var(--borderStyle);
      font-weight: var(--fontWeight);
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);

      &::placeholder {
        color: var(--placeholderColor) !important;
        text-decoration-color: var(--textDecorationColor) !important;
      }
    }
  }

  .ant-input-password-icon {
    color: var(--color);
  }
}
</style>
