import type { Ref } from "vue";

import {
  ColorPickerType,
  type IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useSportbookLiveCardInfo } from "../sportbook-live/useSportbookLiveCardInfo";
import { useBetItemsConfig } from "../sportsbook/useBetItemsConfig";
import { useBetsListWidgetControls } from "../bets-list/useBetsListWidgetControls";
import { useBetsListTabsControls } from "../bets-list/useBetsListTabsControls";

import { useLiveBetsListItems } from "./useLiveBetsListItems";
import { useLiveBetsEventCardsControls } from "./useLiveBetsEventCardsControls";

export const useLiveBetsListConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const { DROPDOWN_ITEMS, TABS } = useLiveBetsListItems(widget);

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const { widgetDesignControls, widgetContentControls } =
    useBetsListWidgetControls(widget, fields);

  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const widgetDataControls = computed(() => [
    {
      section: generateContentSectionName("Sports"),
      controls: [
        {
          componentPath: "ConfigItemsMultiSelect",
          valuePath: "options.bindingParams.sportIds.value",
          valueSource: selectedWidget.value,
        },
      ],
    },
    {
      section: generateContentSectionName("Events"),
      controls: [
        {
          componentPath: "content/ConfigNumberInput",
          valuePath: "options.bindingParams.maxEvents.value",
          valueSource: selectedWidget.value,
          options: {
            label: "Maximum number of events",
          },
        },
      ],
    },
  ]);

  const {
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
    arrowDesignControls,
    arrowContentControls,
    arrowStatesControls,
  } = useBetsListTabsControls(statesValuePath, states, emit);

  const {
    betItemsDesignControls,
    betItemsContentControls,
    liveBetStates,
    currentBetItemPath,
  } = useBetItemsConfig(states, emit, true);

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            ...liveBetStates.value,

            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
            {
              label: "Suspended",
              value: "suspended",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        visible:
          (states.value[selectedField.value!.name] as string) !== "suspended",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        visible: [State.UP, State.DOWN, State.HOVER, State.ACTIVE].includes(
          states.value[selectedField.value!.name]
        ),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.up.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !==
              State.DOWN,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Up color",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.down.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !== State.UP,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Down color",
            },
          },
        ],
      },
    ];
  });

  const { eventCardsDesignControls, eventCardsContentControls } =
    useLiveBetsEventCardsControls(fields, states, emit);

  const {
    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventTimeControls,
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventScoreContentControls,
    eventScoreDesignControls,
  } = useSportbookLiveCardInfo(widget);

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          isBold: true,
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventLeagueControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const {
    buttonStatesControls: showMoreStateControls,
    buttonDesignControls: showMoreDesignControls,
    buttonContentControls: showMoreContentControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.show_more_title),
      stateHandler: (state: State) => {
        emit("update-state", { state: "show_more_title", value: state });
      },
      valueSource: toRef(fields.value.show_more_title.field),
      exclude: {
        content: ["ConfigLinkType"],
      },
    },
    emit
  );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,

    titleControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    eventCardsDesignControls,
    eventCardsContentControls,

    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventLeagueControls,
    eventTimeControls,
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventScoreContentControls,
    eventScoreDesignControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    showMoreStateControls,
    showMoreDesignControls,
    showMoreContentControls,

    arrowDesignControls,
    arrowContentControls,
    arrowStatesControls,
  };
};
