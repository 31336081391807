<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <ControlsList
            :widget="widget"
            :controls="widgetDataControls"
          />
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #tabs-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardsDesignControls"
          />
        </template>

        <template #event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardsContentControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="startTimeDateControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>

        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStateControls"
          />
        </template>

        <template #arrow-design>
          <ControlsList
            :widget="widget"
            :controls="arrowDesignControls"
          />
        </template>

        <template #arrow-content>
          <ControlsList
            :widget="widget"
            :controls="arrowContentControls"
          />
        </template>

        <template #arrow-states>
          <ControlsList
            :widget="widget"
            :controls="arrowStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetsListConfig } from "~~/composables/widgets/bets-list/useBetsListConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "betDisplayMode": {
      label: "Bet display mode",
      sources: ["custom"],
      description: "Possible values: Name | Code",
      required: true,
    },
    "fullNameActive": {
      label: "Full name active",
      sources: ["custom"],
      description: "Possible values: Yes | No",
      required: true,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "sportIds": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "maxEvents": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "sorting": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "eventsInterval": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  /* 
      Controls
    */
  widgetDesignControls,
  widgetContentControls,
  widgetDataControls,

  titleControls,

  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,

  eventCardsDesignControls,
  eventCardsContentControls,

  startTimeDateControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventButtonLinkDesignControls,
  eventButtonLinkContentControls,
  eventButtonLinkStateControls,

  arrowDesignControls,
  arrowContentControls,
  arrowStatesControls,
} = useBetsListConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
