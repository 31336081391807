<template>
  <div
    class="widget-wrapper position-relative"
    :class="{
      'widget-wrapper--loading': widgetIdDetailsLoading === widget.id,
      'widget-wrapper--not-visible': !isVisible,
    }"
  >
    <CommonSpinner v-if="widgetIdDetailsLoading === widget.id" />

    <component
      :is="widgetComponent"
      v-else
      :cell="cell"
      :widget="widget"
    />
  </div>
</template>

<script lang="ts" setup>
import { type Component, defineAsyncComponent } from "vue";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useLanguagesStore } from "~~/store/languages";
import { useWidgetsStore } from "~~/store/widgets";

const widgetsStore = useWidgetsStore();

const props = defineProps<{
  cell: ICell;
  widget: IWidgetWithFields;
}>();

const { widgetIdDetailsLoading } = storeToRefs(widgetsStore);

const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);

const isVisible = computed<boolean>(() => {
  return props.widget.options._isVisible?.[currentLanguageCode.value];
});

const widgetComponent = computed<Component>(() => {
  return defineAsyncComponent(
    () =>
      import(
        `~~/components/widgets/widget-components/${props.widget.widget_id}.vue`
      )
  );
});
</script>

<style lang="scss">
.widget-wrapper {
  height: 100%;

  &--loading {
    min-height: 30px;
  }

  &--not-visible {
    display: none;
  }
}
</style>
