import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { generateIconCssString } from "~~/assets/utils/widget-css/widgets/racing-sportsbook-live-widget-css";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import {
  generateIconCssString as generateTooltipIconCssString,
  generateTooltipStyling,
} from "../utils/form-helper-functions";
import { generateClassName } from "../utils/generate-class-name";
import {
  generateSuccessMessageCssString,
  generateMessageCssString,
  generateResultTotalAmountsCssString,
  generateHeaderTabsCssString,
  generateCoefficientCssString,
  generateCloseIconCssString,
  generateLabelCopyIconCssString,
  generateResultContainerCssString,
  generateBetsContainerCssString,
  generateBetsTypeTitleCssString,
} from "../utils/betslip-helpers";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateErrorCssString,
  generateFieldsWithStatesCssString,
  generateFormCssString,
  generateIconRightCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import {
  generateBorderStyle,
  generateFillColorStyle,
  generateFlex,
  generateMarginStyle,
  generateTextColor,
  generateWidth,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import { getPxValueFromNumber } from "../..";
import { getColorFromHex } from "../../widget-settings";

export const generateCollapseString =
  (
    field: IWidgetOptions,
    cardsContainerField: IWidgetOptions,
    betsContainer: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
      gap: field.options.display?.distance,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: flex + "cursor:pointer;",
    });

    const cardFlex = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: "column",
      gap: betsContainer.options?.distance,
    });

    const cardContainerFlex = generateFlex({
      flex: "flex",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: cardsContainerField.options.display?.distance,
    });

    cssString += generateCssClassWithContent({
      className: cardsContainerField.options._cssClass,
      content: cardContainerFlex,
    });

    cssString += generateCssClassWithContent({
      className: field.options._contentCssClass,
      content: cardFlex,
    });

    cssString += generateCssClassWithContent({
      className: field.options._wrapperCssClass,
      content: cardFlex,
    });

    for (const state in field.options.states) {
      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName: `:${state} ${field.options.arrowSettings._cssClass}`,
        content: generateTextColor(field.options.arrowSettings.textColor),
      });
    }

    return cssString;
  };

export const generateBetslipFormCssString =
  (form: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass} .formkit-outer-override:not(:last-child)`,
      content: `margin-bottom: ${
        form.options.display.layout === DisplayOrientation.VERTICAL
          ? getPxValueFromNumber(form.options.display.distance)
          : 0
      };`,
    });

    cssString += generateCssClassWithContent({
      className: form.options._cssClass,
      customFunction: generateMarginStyle,
      content: form.options.margin,
    });

    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass}`,
      content: "display: block;",
    });

    if (form.options.display.layout === DisplayOrientation.HORIZONTAL) {
      cssString += generateCssClassWithContent({
        className: `${form.options._cssClass} .formkit-form`,
        content: generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          gap: form.options.display.distance,
        }),
      });
    }

    return cssString;
  };

export const generateStakeInfoCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "stretch",
        justify: "flex-start",
        direction: "column",
        gap: field.options.display.distance,
      }),
    });

    return cssString;
  };

export const generateEachWayCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "flex-start",
        gap: field.options.display.distance,
      }),
    });

    return cssString;
  };

export const generateTotalStakeCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "flex-start",
        gap: field.options.display.distance,
      }),
    });

    cssString += generateCssClassWithContent({
      className: field.options.label._cssClass,
      content: generateDefaultStyles(field.options.label),
    });

    cssString += generateCssClassWithContent({
      className: field.options.value._cssClass,
      content: generateDefaultStyles(field.options.value),
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: " > *",
      content: "flex: 1;",
    });

    return cssString;
  };

const gatherLabelOptions = (obj: IWidgetOptions): IWidgetOptions => {
  const res: IWidgetOptions = {};

  if (obj.theme) {
    res.theme = obj.theme;
  }

  if (obj.fontFamily) {
    res.fontFamily = obj.fontFamily;
  }

  if (obj.decoration) {
    res.decoration = obj.decoration;
  }

  if (obj.color) {
    res.color = obj.color;
  }

  return res;
};
export const generateCheckboxContentCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          gap: options.display.distance,
        }) + "cursor:pointer;",
    });

    cssString += generateCssClassWithContent({
      className: options._labelCssClass,
      content: generateDefaultStyles(
        gatherLabelOptions({ ...field.options, color: null })
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="true"] .${options._labelCssClass}`,
      content: generateDefaultStyles(gatherLabelOptions(options)),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="true"]:hover  .${options._labelCssClass}`,
      content: generateDefaultStyles(
        gatherLabelOptions(options.states.selected_hover)
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="false"]  .${options._labelCssClass}`,
      content: generateDefaultStyles(
        gatherLabelOptions(options.states.unselected_default)
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="false"]:hover  .${options._labelCssClass}`,
      content: generateDefaultStyles(
        gatherLabelOptions(options.states.unselected_hover)
      ),
    });

    return cssString;
  };

export const generateCheckboxCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    /* 
      Container styles
    */
    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="true"]`,
      content: generateDefaultStyles(options),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="true"]:hover`,
      content: generateDefaultStyles(options.states.selected_hover),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="false"]`,
      content: generateDefaultStyles(options.states.unselected_default),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-is-checked="false"]:hover`,
      content: generateDefaultStyles(options.states.unselected_hover),
    });

    const checkboxCheckedDefaultCssContent = [
      generateBorderStyle(options.check.border),
      generateFillColorStyle(options.check.fill),
    ].join("");

    const checkboxCheckedHoverCssContent = [
      generateBorderStyle(options.states["selected_hover"].check.border),
      generateFillColorStyle(options.states["selected_hover"].check.fill),
    ].join("");

    const checkboxUncheckedDefaultCssContent = [
      generateBorderStyle(options.states["unselected_default"].check.border),
      generateFillColorStyle(options.states["unselected_default"].check.fill),
    ].join("");

    const checkboxUncheckedHoverCssContent = [
      generateBorderStyle(options.states["unselected_hover"].check.border),
      generateFillColorStyle(options.states["unselected_hover"].check.fill),
    ].join("");

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      pseudoClassName: " button.base-checkbox",
      content: `width: ${getPxValueFromNumber(
        options.size
      )}; height: ${getPxValueFromNumber(options.size)};border-radius:2px;`,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      childClassName: "base-checkbox:hover",
      content: checkboxUncheckedHoverCssContent,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      childClassName: "base-checkbox",
      content: checkboxUncheckedDefaultCssContent,
    });

    cssString += `.${
      options._cssClass
    } button.base-checkbox[data-state='checked'] {${checkboxCheckedDefaultCssContent}}.${
      options._cssClass
    }  button.base-checkbox[data-state='checked'] .base-checkbox__icon{border-color: ${getColorFromHex(
      options.check?.icon
    )};}`;

    cssString += `.${
      options._cssClass
    } button.base-checkbox[data-state='checked']:hover{${checkboxCheckedHoverCssContent}}.${
      options._cssClass
    }  button.base-checkbox[data-state='checked']:hover .base-checkbox__icon{border-color: ${getColorFromHex(
      options.states["selected_hover"].check?.icon
    )};}`;

    if (!options.check._active) {
      cssString += generateCssClassWithContent({
        className: `${options._cssClass}`,
        pseudoClassName: " button.base-checkbox",
        content: `display:none!important;`,
      });
    }

    return cssString;
  };

export const generateBetslipFenixWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const betAmountsContainer = widget.content["bet_amounts_container"];
  const betNameTitle = widget.content["bet_name_title"];
  const betsContainer = widget.content["bets_container"];
  const betsResultContinueButton =
    widget.content["bets_result_continue_button"];
  const betsResultSaveChoiceButton =
    widget.content["bets_result_save_choice_button"];
  const betsTypeTitle = widget.content["bets_type_title"];
  const betslipTabsContainer = widget.content["betslip_tabs_container"];
  const cardsContainer = widget.content["cards_container"];
  const closeIcon = widget.content["close_icon"];
  const coefficientTitle = widget.content["coefficient_title"];
  const eventNameTitle = widget.content["event_name_title"];
  const betslipForm = widget.content["form"];
  const headerTabsContainer = widget.content["header_tabs_container"];
  const headerTabsContainerCounters =
    widget.content["header_tabs_container_counters"];
  const liveStatusTitle = widget.content["live_status_title"];
  const marketTitle = widget.content["market_title"];
  const myBetsPrimaryButton = widget.content["my_bets_primary_button"];
  const myBetsSecondaryButton = widget.content["my_bets_secondary_button"];
  const myBetsWarningMessage = widget.content["my_bets_warning_message"];
  const noBetsDescription = widget.content["no_bets_description"];
  const notAvailableStatusTitle = widget.content["not_available_status_title"];
  const resultContainer = widget.content["result_container"];
  const resultContainerBetButton =
    widget.content["result_container_bet_button"];
  const resultContainerCopyButton =
    widget.content["result_container_copy_button"];
  const resultContainerRemoveButton =
    widget.content["result_container_remove_button"];
  const resultContainerTotalAmounts =
    widget.content["result_container_total_amounts"];
  const resultContainerWarningMessageTitle =
    widget.content["result_container_warning_message_title"];
  const successMessageTitle = widget.content["success_message_title"];
  const timeTitle = widget.content["time_title"];
  const customFields = widget.options._customFields;
  const eventContainer = widget.content["event_container"];

  const collapse = widget.content["collapse"];
  const multibetTooltip = widget.content["multibet_tooltip"];

  const stake_info = widget.content["stake_info"];
  const each_way_bet = widget.content["each_way_bet"];
  const total_stake = widget.content["total_stake"];
  const each_way_bet_checkbox = widget.content["each_way_bet_checkbox"];
  const each_way_bet_tooltip = widget.content["each_way_bet_tooltip"];
  const cash_out = widget.content["cashout"];
  const accept_changes_container = widget.content["accept_changes_container"];

  const bets_result_system_name = widget.content["bets_result_system_name"];

  // Widget class name
  widget.options._cssClass = generateClassName("betslipWidgetFenix");

  /* 
    new
  */

  collapse.options._cssClass = generateClassName("collapse");
  collapse.options._contentCssClass = generateClassName("collapse_content");
  collapse.options._wrapperCssClass = generateClassName("collapse_wrapper");
  collapse.options.arrowSettings._cssClass =
    generateClassName("collapse_arrow");

  multibetTooltip.options._cssClass = generateClassName("multibet-tooltip");
  multibetTooltip.options.textChooseIcon._cssClass = generateClassName(
    "multibet-tooltip-text-choose-icon"
  );

  // Header class name
  headerTabsContainer.options._cssClass = generateClassName("headerContainer");
  headerTabsContainer.options.headerElementsSettings._cssClass =
    generateClassName("headerContainerElement");
  headerTabsContainerCounters.options._cssClass =
    generateClassName("headerCounters");
  betslipTabsContainer.options._cssClass = generateClassName("betslipTabs");
  betslipTabsContainer.options.headerElementsSettings._cssClass =
    generateClassName("betslipTabsElement");

  // Form class name
  betslipForm.options._cssClass = generateClassName("betslipForm");
  customFields.errors_styling.options._cssClass =
    generateClassName("errorsStyling");
  customFields.fields_styling.options._cssClass =
    generateClassName("fieldsStyling");
  customFields.labels_styling.options._cssClass =
    generateClassName("labelsStyling");
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("labelIcon");

  // Cards class name
  cardsContainer.options._cssClass = generateClassName("cardsContainer");
  liveStatusTitle.options._cssClass = generateClassName("liveStatusTitle");
  marketTitle.options._cssClass = generateClassName("marketTitle");
  notAvailableStatusTitle.options._cssClass = generateClassName("notAvailable");
  timeTitle.options._cssClass = generateClassName("timeTitle");
  betNameTitle.options._cssClass = generateClassName("betNameTitle");
  betsContainer.options._cssClass = generateClassName("betsContainer");
  betsTypeTitle.options._cssClass = generateClassName("betsTypeTitle");
  betsTypeTitle.options._systemCssClass = generateClassName(
    "betsTypeSystemTitle"
  );
  betsTypeTitle.options._multiCssClass =
    generateClassName("betsTypeMultiTitle");
  closeIcon.options._cssClass = generateClassName("closeIcon");
  coefficientTitle.options._cssClass = generateClassName(
    "coefficientTitleDefault"
  );
  coefficientTitle.options._upCssClass =
    generateClassName("coefficientTitleUp");
  coefficientTitle.options._downCssClass = generateClassName(
    "coefficientTitleDown"
  );
  eventNameTitle.options._cssClass = generateClassName("eventNameTitle");
  eventContainer.options._cssClass = generateClassName("eventContainer");

  // Messages class names
  successMessageTitle.options._cssClass = generateClassName(
    "successMessageTitle"
  );
  successMessageTitle.options.iconSettings._cssClass =
    generateClassName("successMessageIcon");
  successMessageTitle.options.printSettings._cssClass =
    generateClassName("successMessageLink");
  successMessageTitle.options._wrapperCssClass = generateClassName(
    "successMessageTitleWrapper"
  );
  const successMessageTitleWrapperStyles = generateFillColorStyle(
    betsContainer.options.fill
  );

  resultContainerWarningMessageTitle.options._cssClass =
    generateClassName("resultWarningTitle");
  resultContainerWarningMessageTitle.options.iconSettings._cssClass =
    generateClassName("resultWarningIcon");

  myBetsWarningMessage.options._cssClass = generateClassName(
    "myBetsWarningMessage"
  );
  myBetsWarningMessage.options.iconSettings._cssClass =
    generateClassName("myBetsWarningIcon");

  // Buttons class names
  myBetsPrimaryButton.options._cssClass = generateClassName(
    "myBetsPrimaryButton"
  );
  myBetsSecondaryButton.options._cssClass = generateClassName(
    "myBetsSecondaryButton"
  );
  betsResultContinueButton.options._cssClass = generateClassName(
    "betsResultContinueButton"
  );
  betsResultSaveChoiceButton.options._cssClass = generateClassName(
    "betsResultSaveChoiceButton"
  );
  resultContainerBetButton.options._cssClass = generateClassName(
    "resultContainerBetButton"
  );
  resultContainerCopyButton.options._cssClass = generateClassName(
    "resultContainerCopyButton"
  );
  resultContainerRemoveButton.options._cssClass = generateClassName(
    "resultContainerRemoveButton"
  );

  // Buttons icons class names
  resultContainerRemoveButton.options.iconSettings._cssClass =
    generateClassName("resultContainerRemoveButtonIcon");
  resultContainerCopyButton.options.iconSettings._cssClass = generateClassName(
    "resultContainerCopyButtonIcon"
  );
  betsResultSaveChoiceButton.options.iconSettings._cssClass = generateClassName(
    "betsResultSaveChoiceButtonIcon"
  );
  betsResultContinueButton.options.iconSettings._cssClass = generateClassName(
    "betsResultContinueButtonIcon"
  );

  // Description class name
  noBetsDescription.options._cssClass = generateClassName("noBetsDescription");

  // Bet Amounts
  betAmountsContainer.options._rootCssClass =
    generateClassName("betAmountsRoot");
  betAmountsContainer.options._cssClass = generateClassName(
    "betAmountsContainer"
  );
  betAmountsContainer.options.labelSettings._cssClass =
    generateClassName("betAmountsLabel");
  betAmountsContainer.options.valueSettings._cssClass =
    generateClassName("betAmountsValue");

  // Total Amounts
  resultContainerTotalAmounts.options._rootCssClass = generateClassName(
    "resultRootTotalAmounts"
  );
  resultContainerTotalAmounts.options._cssClass =
    generateClassName("resultTotalAmounts");
  resultContainerTotalAmounts.options.labelSettings._cssClass =
    generateClassName("resultTotalAmountsLabel");
  resultContainerTotalAmounts.options.valueSettings._cssClass =
    generateClassName("resultTotalAmountsValue");

  // Result container class name
  resultContainer.options._cssClass = generateClassName("resultContainer");

  bets_result_system_name.options._cssClass = generateClassName(
    "bets_result_system_name"
  );

  stake_info.options._cssClass = generateClassName("stake_info");
  each_way_bet.options._cssClass = generateClassName("each_way_bet");

  total_stake.options._cssClass = generateClassName("total_stake");
  total_stake.options.label._cssClass = generateClassName("total_stake_label");
  total_stake.options.value._cssClass = generateClassName("total_stake_value");

  each_way_bet_tooltip.options._cssClass = generateClassName(
    "each_way_bet_tooltip"
  );

  each_way_bet_tooltip.options.textChooseIcon._cssClass = generateClassName(
    "each_way_bet_tooltip_icon"
  );

  each_way_bet_checkbox.options._cssClass = generateClassName(
    "each_way_bet_checkbox"
  );

  each_way_bet_checkbox.options._labelCssClass = generateClassName(
    "each_way_bet_checkbox"
  );
  // each_way_bet_checkbox.options._checkoxCssClass =
  //   generateClassName("checkbox");
  // each_way_bet_checkbox.options._checkboxLabelCssClass =
  //   generateClassName("checkbox_label");
  // each_way_bet_checkbox.options._checkboxLabelCssClass =
  //   generateClassName("checkbox_label");
  // each_way_bet_checkbox.options.check.icon._cssClass =
  //   generateClassName("check_icon");

  if (cash_out) {
    cash_out.options._cssClass = generateClassName("cash_out");
  }

  accept_changes_container.options._cssClass = generateClassName(
    "accept_changes_container"
  );

  return pipeSync<string>(
    // Widget
    generateStringDefault(widget),
    // Buttons
    generateStageButtonCssString(myBetsPrimaryButton),
    generateStageButtonCssString(myBetsSecondaryButton),
    generateStageButtonCssString(betsResultContinueButton),
    generateStageButtonCssString(betsResultSaveChoiceButton),
    generateStageButtonCssString(resultContainerBetButton),
    generateStageButtonCssString(resultContainerCopyButton),
    generateStageButtonCssString(resultContainerRemoveButton),
    // Messages
    generateSuccessMessageCssString(
      successMessageTitle,
      successMessageTitleWrapperStyles
    ),
    generateMessageCssString(resultContainerWarningMessageTitle),
    generateMessageCssString(myBetsWarningMessage),
    // Description
    generateStringDefault(noBetsDescription),
    // Result
    generateResultContainerCssString(resultContainer),
    generateResultTotalAmountsCssString(resultContainerTotalAmounts),
    // Bet amounts container
    generateResultTotalAmountsCssString(betAmountsContainer),
    // Header container
    generateHeaderTabsCssString(betslipTabsContainer),
    generateHeaderTabsCssString(
      headerTabsContainer,
      headerTabsContainerCounters
    ),
    // Card container
    generateStringDefault(cardsContainer),
    generateStringDefault(betNameTitle),
    generateCloseIconCssString(closeIcon),
    generateBetsContainerCssString(betsContainer),
    generateBetsTypeTitleCssString(betsTypeTitle),
    generateStringDefault(liveStatusTitle),
    generateStringDefault(marketTitle, [], "word-break:break-word;"),
    generateStringDefault(notAvailableStatusTitle),
    generateStringDefault(timeTitle),
    generateStringDefault(eventNameTitle, [], "word-break:break-word;"),
    generateStringDefault(eventContainer),
    // Form container
    generateFormCssString(betslipForm, widget),
    generateBetslipFormCssString(betslipForm),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(
      customFields.labels_styling,
      [],
      "word-break:break-word;"
    ),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget
      // customFields.labels_styling,
      // customFields.field_success_styling
    ),
    generateLabelCopyIconCssString(customFields, betslipForm),
    // Coefficient
    generateCoefficientCssString(coefficientTitle),
    generateIconRightCssString(customFields.fields_styling.options),
    generateCollapseString(collapse, cardsContainer, betsContainer),
    generateIconCssString({
      options: collapse.options.arrowSettings,
    }),
    generateStringDefault(bets_result_system_name),

    generateTooltipStyling(multibetTooltip),
    generateTooltipIconCssString(multibetTooltip.options.textChooseIcon),

    generateStringDefault(stake_info),
    generateStakeInfoCssString(stake_info),

    generateStringDefault(each_way_bet),
    generateEachWayCssString(each_way_bet),

    generateStringDefault(accept_changes_container),
    generateEachWayCssString(accept_changes_container),

    generateStringDefault(total_stake),
    generateTotalStakeCssString(total_stake),

    generateTooltipStyling(each_way_bet_tooltip),
    generateTooltipIconCssString(each_way_bet_tooltip.options.textChooseIcon),
    generateCheckboxCssString(each_way_bet_checkbox),
    generateCheckboxContentCssString(each_way_bet_checkbox),
    generateStringDefault(cash_out, [], generateWidth(100, "%"))
  )("");
};
