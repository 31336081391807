import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

type TypographyArgs = {
  source: IWidgetField | null;
  except?: string[];
  addElements?: ControlProp[];
  visible?: boolean;
  isOptionPath?: boolean;
};

export const typography = (
  { source, except, addElements, visible, isOptionPath = true }: TypographyArgs,
  title?: string
): ControlProp => {
  const controls = [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: isOptionPath ? "options.color" : "color",
      valueSource: source,
      options: {
        placeholder: "Enter text",
        label: "Text color",
        type: ColorPickerType.TEXT,
      },
    },
    {
      componentPath: "ConfigThemeSelect",
      valuePath: isOptionPath ? "options.theme" : "theme",
      valueSource: source,
      options: {
        label: "Font size",
      },
    },
    {
      componentPath: "ConfigFontFamilySelect",
      valuePath: isOptionPath ? "options.fontFamily" : "fontFamily",
      valueSource: source,
      options: {
        placeholder: "Select",
        label: "Font family",
      },
    },
    {
      componentPath: "ConfigDecoration",
      valuePath: isOptionPath ? "options.decoration" : "decoration",
      valueSource: source,
      options: {
        placeholder: "Select",
        label: "Decoration",
      },
    },
    {
      componentPath: "ConfigAlignment",
      valuePath: isOptionPath ? "options.alignment" : "alignment",
      valueSource: source,
      options: {
        label: "Alignment",
      },
    },
  ].filter(control => !except?.includes(control.componentPath));

  return {
    section: generateDesignSectionName(title || "Typography"),
    controls: [...controls, ...(addElements || [])],
    visible: typeof visible === "boolean" ? visible : true,
  };
};
