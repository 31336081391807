<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    :current-state="states.widget"
  >
    <div class="card-widget">
      <CardWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
        :description-fields="descriptionFields"
        :title-link-state="states.title"
        :widget-state="states.widget"
        :button-1-state="states.button_1"
        :button-2-state="states.button_2"
        @update-title-link-state="
          handleStateUpdate({ state: 'title', value: $event })
        "
        @update-widget-state="
          handleStateUpdate({ state: 'widget', value: $event })
        "
        @update-button_1-state="
          handleStateUpdate({ state: 'button_1', value: $event })
        "
        @update-button_2-state="
          handleStateUpdate({ state: 'button_2', value: $event })
        "
        @reset-states="resetStates"
      />

      <BasicCardField
        ref="cardField"
        :widget="widget"
        :button-state-1="states.button_1"
        :button-state-2="states.button_2"
      />
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FillType, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
  BUTTON_INITIAL,
  IMAGE_INITIAL,
  BUTTONS_GROUP_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { useStates } from "~~/composables/widgets/common/useStates";
import {
  getDefaultBorderValue,
  getDefaultFillImage,
} from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import BasicCardField from "~~/components/fields/basic/BasicCardField.vue";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();
const widgetsStore = useWidgetsStore();
const { initWidgetOptions } = useCreateFields(props.widget);

const fields = useWidgetFields(props.widget);
const { selectedWidget } = storeToRefs(widgetSettingsStore);

const cardField = ref<InstanceType<typeof BasicCardField>>();

const DEFAULT_STATES = {
  widget: State.DEFAULT,
  title: State.DEFAULT,
  button_1: State.DEFAULT,
  button_2: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const descriptionFields = computed(() => {
  return props.widget.fields.filter(field =>
    field.name.includes("description")
  );
});

/*
  Initialize widget with initial options
*/

const initialWidgetOptions = initWidgetOptions(["fill"], {
  layoutPosition: {
    position: "bottom",
    alignment: null,
  },
  fillImageColor: getDefaultFillImage(),
  link: {
    type: null,
    value: null,
    authorizeValue: null,
    hasAuthorizeValue: false,
    isNewTabOpen: null,
  },
  states: {
    [State.HOVER]: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
    },
    [State.ACTIVE]: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
    },
  },
});

const initialTitleOptions = prefillEmptyOptions(
  fields.value.title.field.options,
  TITLE_INITIAL().options
);

const initialDescriptionOptions = prefillEmptyOptions(
  fields.value.description_1.field.options,
  DESCRIPTION_INITIAL().options
);

const initialButtonsGroupOptions = prefillEmptyOptions(
  fields.value.buttons_group.field.options,
  BUTTONS_GROUP_INITIAL().options
);

const initialButton1Options = prefillEmptyOptions(
  fields.value.button_1.field.options,
  BUTTON_INITIAL().options
);

const initialButton2Options = prefillEmptyOptions(
  fields.value.button_2.field.options,
  BUTTON_INITIAL().options
);

const initialImageOptions = prefillEmptyOptions(
  fields.value.image.field.options,
  IMAGE_INITIAL().options
);

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
widgetsStore.updateFieldOptions(fields.value.title.field, initialTitleOptions);
widgetsStore.updateFieldOptions(
  fields.value.description_1.field,
  initialDescriptionOptions
);

widgetsStore.updateFieldOptions(
  fields.value.buttons_group.field,
  initialButtonsGroupOptions
);

widgetsStore.updateFieldOptions(
  fields.value.button_1.field,
  initialButton1Options
);

widgetsStore.updateFieldOptions(
  fields.value.button_2.field,
  initialButton2Options
);
widgetsStore.updateFieldOptions(fields.value.image.field, initialImageOptions);
</script>
