import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateWidgetWidth } from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import {
  generateCustomStyles,
  generateStringDefault,
} from "../utils/pipe-helper-functions";
import {
  generateIconCssString,
  generateIconSizeCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import { getFlexPosition } from "../../widget-settings";
import {
  generateItemsGroupCssString,
  itemsGroupClassNameGenerator,
} from "../compiler/items-css-compiler";

import { generateAccountNumberStyles } from "./user-id-widget-css";
import { generateDropdownContainerCssString } from "./timezones-widget-css";
import { generateBalanceItemsCssString as generateBalanceItemCssString } from "./profile-dropdown-widget-css";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const isVertical = options.display.layout === DisplayOrientation.VERTICAL;

    const widgetStyles = generateFlex({
      flex: "flex",
      align: isVertical
        ? getFlexPosition(options.display?.alignment)
        : "initial",
      justify: getFlexPosition(options.display?.alignment),
      direction: isVertical ? "column" : "row",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    const widgetAuthContainerStyles = generateFlex({
      flex: "flex",
      align: "initial",
      justify: "initial",
      direction: isVertical ? "column" : "row",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: widgetAuthContainerStyles,
    });

    return cssString;
  };

export const generateAccountInfoCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const fieldStyles = generateFlex({
      flex: "flex",
      align: "initial",
      justify: "initial",
      direction: "column",
      gap: options.display.distance,
    });

    if (options.display?.resizing?.type === ResizingType.FIXED) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: generateWidgetWidth(options.display.resizing),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: fieldStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateUserIdCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const fieldStyles = generateFlex({
      flex: "flex",
      align: getFlexPosition(options.display?.alignment),
      justify: "initial",
      direction: "column",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: fieldStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateBalanceCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const isVertical = options.display.layout === DisplayOrientation.VERTICAL;

    const fieldStyles = generateFlex({
      flex: "flex",
      align: isVertical
        ? getFlexPosition(options.displayItems?.alignment)
        : "center",
      justify: isVertical
        ? "initial"
        : getFlexPosition(options.displayItems?.alignment),
      direction: isVertical ? "column" : "row",
      gap: options.display.distance,
    });
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: fieldStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateBalanceItemsCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const isVertical =
      options.displayItems.layout === DisplayOrientation.VERTICAL;

    const fieldStyles = generateFlex({
      flex: "flex",
      align: isVertical
        ? getFlexPosition(options.displayItems?.alignment)
        : "center",
      justify: "initial",
      direction: isVertical ? "column" : "row",
      gap: options.displayItems.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: fieldStyles,
    });

    return cssString;
  };

export const generateAccountMenuCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const fieldStyles = generateFlex({
      flex: "flex",
      align: getFlexPosition(options.display?.alignment),
      justify: "initial",
      direction: "column",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: fieldStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateProfileDropdownFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._containerCssClass = generateClassName(
    `${widget.name}_container`
  );

  const prefilled = prefillWithClasses(widget.content);

  prefilled.deposit.options.iconSettings._cssClass = generateClassName(
    `${prefilled.deposit.name}_icon`
  );
  prefilled.logout.options.iconSettings._cssClass = generateClassName(
    `${prefilled.logout.name}_icon`
  );
  prefilled.login.options.iconSettings._cssClass = generateClassName(
    `${prefilled.login.name}_icon`
  );
  prefilled.registration.options.iconSettings._cssClass = generateClassName(
    `${prefilled.registration.name}_icon`
  );

  prefilled.dropdown_container.options.text._cssClass = generateClassName(
    `${prefilled.dropdown_container.name}_text`
  );
  prefilled.dropdown_container.options.arrow._cssClass = generateClassName(
    `${prefilled.dropdown_container.name}_arrow`
  );
  prefilled.dropdown_container.options.icon._cssClass = generateClassName(
    `${prefilled.dropdown_container.name}_icon`
  );

  prefilled.balance.options._itemCssClass = generateClassName(
    `${prefilled.balance.name}_item`
  );
  prefilled.balance.options.text._cssClass = generateClassName(
    `${prefilled.balance.name}_text`
  );
  prefilled.balance.options.primaryText._cssClass = generateClassName(
    `${prefilled.balance.name}_primary`
  );
  prefilled.balance.options.icon._cssClass = generateClassName(
    `${prefilled.balance.name}_icon`
  );
  // Balance items
  prefilled.bonus.options._itemCssClass =
    prefilled.balance.options._itemCssClass;
  prefilled.bonus.options.text._cssClass =
    prefilled.balance.options.text._cssClass;
  prefilled.bonus.options.primaryText._cssClass =
    prefilled.balance.options.primaryText._cssClass;
  prefilled.bonus.options.icon._cssClass =
    prefilled.balance.options.icon._cssClass;
  prefilled.real.options._itemCssClass =
    prefilled.balance.options._itemCssClass;
  prefilled.real.options.text._cssClass =
    prefilled.balance.options.text._cssClass;
  prefilled.real.options.primaryText._cssClass =
    prefilled.balance.options.primaryText._cssClass;
  prefilled.real.options.icon._cssClass =
    prefilled.balance.options.icon._cssClass;

  // @ts-ignore
  prefilled.account_menu.value[0].options.itemsIcon._cssClass =
    generateClassName("items_icon");
  // @ts-ignore
  prefilled.account_menu.value[0].options.itemsText._cssClass =
    generateClassName("items_text");

  itemsGroupClassNameGenerator(prefilled.account_menu);

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStageButtonCssString(prefilled.deposit as IWidgetField),
    generateStageButtonCssString(
      prefilled.login as IWidgetField,
      false,
      `.${prefilled.login.options.iconSettings._cssClass}{display:flex;}`
    ),
    generateStageButtonCssString(prefilled.registration as IWidgetField),
    generateStageButtonCssString(prefilled.logout as IWidgetField),
    generateDropdownContainerCssString(prefilled.dropdown_container, widget),
    generateIconCssString(
      prefilled.dropdown_container.options.icon,
      "textColor"
    ),
    generateIconSizeCssString(
      prefilled.dropdown_container.options.icon,
      "iconSize"
    ),
    generateStringDefault(prefilled.dropdown_menu),
    generateStringDefault(prefilled.account),
    generateAccountInfoCssString(prefilled.account_info as IWidgetField),
    generateUserIdCssString(prefilled.user_id as IWidgetField),
    generateBalanceCssString(prefilled.balance as IWidgetField),
    generateBalanceItemsCssString(prefilled.balance as IWidgetField),
    generateBalanceItemCssString(prefilled.balance, false),
    generateStringDefault(prefilled.username),
    generateStringDefault(prefilled.account_number),
    generateAccountNumberStyles(prefilled.account_number),
    generateCustomStyles(prefilled.account_number.options._cssClass, {
      cursor: "pointer",
    }),
    generateAccountMenuCssString(prefilled.account_menu as IWidgetField),
    generateItemsGroupCssString(
      // @ts-ignore
      prefilled.account_menu.value[0],
      { ...prefilled.account_menu.options.display, resizing: ResizingType.FILL }
    )
  )("");
};
