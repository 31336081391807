<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsMenuWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      :menu-fields="menuFields"
      is-fenix
      is-live
      @reset-states="resetStates"
    />

    <div class="sports-menu-live-fenix-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <div :style="itemsStyles">
        <WidgetField
          :widget="widget"
          :field="fields.menu_level_1.field"
          class="sports-menu-live-fenix-widget__field-menu-1"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-fenix-widget__container_1">
              <div class="sports-menu-live-fenix-widget__element_1">
                <a-sub-menu
                  key="sub1"
                  class="sports-menu-live-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-fenix-widget__container_1">
              <div class="sports-menu-live-fenix-widget__element_1">
                <a-sub-menu
                  key="sub2"
                  class="sports-menu-live-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-fenix-widget__container_1">
              <div class="sports-menu-live-fenix-widget__element_1">
                <a-sub-menu
                  key="sub3"
                  class="sports-menu-live-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div
              v-if="!isSportItemsVertical"
              class="sports-menu-live-fenix-widget__container_1"
            >
              <div class="sports-menu-live-fenix-widget__element_1">
                <a-sub-menu
                  key="sub4"
                  class="sports-menu-live-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Horses and greyhounds</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="fields.menu_level_2.field.options._active"
          :widget="widget"
          :field="fields.menu_level_2.field"
          class="sports-menu-live-fenix-widget__field-menu-2"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-fenix-widget__container_2">
              <div class="sports-menu-live-fenix-widget__element_2">
                <a-sub-menu
                  key="sub2-1"
                  class="sports-menu-live-fenix-widget__menu_level_2"
                >
                  <template #title>League</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-fenix-widget__container_2">
              <div class="sports-menu-live-fenix-widget__element_2">
                <a-sub-menu
                  key="sub2-2"
                  class="sports-menu-live-fenix-widget__menu_level_2"
                >
                  <template #title>League</template>

                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-fenix-widget__container_2">
              <div class="sports-menu-live-fenix-widget__element_2">
                <a-sub-menu
                  key="sub2-3"
                  class="sports-menu-live-fenix-widget__menu_level_2"
                >
                  <template #title>League</template>

                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="fields.event_container.field.options._active"
          :widget="widget"
          :field="fields.event_container.field"
          class="sports-menu-live-fenix-widget__field-menu-3"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-fenix-widget__container_3">
              <div class="sports-menu-live-fenix-widget__element_3">
                <a-menu-item
                  key="sub3-3-1"
                  class="sports-menu-live-fenix-widget__menu_level_3"
                >
                  <SportsMenuLiveFenixEventCardsList
                    v-slot="{ card }"
                    :widget="widget"
                    :container-field="fields.event_container.field"
                    :card-field="fields.event_card.field"
                    :card-state="states['event_card']"
                    :data="sportCards"
                    :container-styles="sportsMenuContainerStyles"
                  >
                    <SportsMenuliveFenixEventSportCard
                      :data="card"
                      :widget="widget"
                      :state="states['event_card']"
                      :header-field="fields.header.field"
                      :footer-field="fields.footer.field"
                      :card-field="fields.event_card.field"
                      :favorite-icon-field="fields.favourites_button.field"
                      :league-name-field="fields.event_league.field"
                      :event-status-field="fields.event_suspended_status.field"
                      :stream-icon-field="fields.stream_icon.field"
                      :event-name-field="fields.event_team_player.field"
                      :event-time-field="fields.event_time.field"
                      :event-period-field="fields.event_period.field"
                      :event-score-field="fields.event_score.field"
                    />
                  </SportsMenuLiveFenixEventCardsList>
                </a-menu-item>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="isSportItemsVertical"
          :widget="widget"
          :field="fields.menu_level_1.field"
          class="sports-menu-live-fenix-widget__field-menu-1"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-fenix-widget__container_1">
              <div class="sports-menu-live-fenix-widget__element_1">
                <a-sub-menu
                  key="sub1"
                  class="sports-menu-live-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Horses and greyhounds</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="fields.event_container.field.options._active"
          :widget="widget"
          :field="fields.event_container.field"
          class="sports-menu-live-fenix-widget__field-menu-3"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-fenix-widget__container_3">
              <div class="sports-menu-live-fenix-widget__element_3">
                <a-menu-item
                  key="sub3-3-2"
                  class="sports-menu-live-fenix-widget__menu_level_3"
                >
                  <SportsMenuLiveFenixEventCardsList
                    v-slot="{ card }"
                    :widget="widget"
                    :container-field="fields.event_container.field"
                    :card-field="fields.racing_event_card.field"
                    :card-state="states['racing_event_card']"
                    :data="racingCards"
                    :container-styles="sportsMenuContainerStyles"
                  >
                    <SportsMenuliveFenixEventRacingCard
                      :data="card"
                      :widget="widget"
                      :card-field="fields.racing_event_card.field"
                      :favorite-icon-field="fields.racing_favourites_icon.field"
                      :event-status-field="fields.racing_event_status.field"
                      :stream-icon-field="fields.racing_stream_icon.field"
                      :event-name-field="fields.racing_event_name.field"
                      :timer-field="fields.racing_event_timer.field"
                      :state="states['racing_event_card']"
                    />
                  </SportsMenuLiveFenixEventCardsList>
                </a-menu-item>
              </div>
            </div>
          </a-menu>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSportsMenuStyles } from "~~/composables/widgets/sports-menu/useSportsMenuStyles";
import { useSportsMenuSharedLogic } from "~~/composables/widgets/sports-menu/useSportsMenuSharedLogic";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import { getSpacing } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const DEFAULT_STATES = {
  menu_level_1: State.DEFAULT,
  menu_level_2: State.DEFAULT,
  event_card: State.DEFAULT,
  racing_event_card: State.DEFAULT,
  header: State.DEFAULT,
  footer: State.DEFAULT,
  event_league: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const { itemsStyles } = useSportsMenuStyles(props.widget, states, true, true);

const selectedKeys = ref<string[]>([""]);
const openKeys = ref<string[]>(["sub3", "sub2-3", "sub3-3-1", "sub3-3-2"]);
const menuFields = ["menu_level_1", "menu_level_2", "event_container"];

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { itemFirstLevelIcon, itemSecondLevelIcon, fields } =
  useSportsMenuSharedLogic(ref(props.widget), states, true, true);

const sportCards = [
  {
    leagueName: "League Name",
    status: "Status",
    eventName: "Team / Player",
    teamPlayer1: "Team / Player #1",
    teamPlayer2: "Team / Player #2",
    time: "00:00",
    period: "Period",
    score1: 0,
    score2: 0,
  },
  {
    leagueName: "League Name",
    status: "Status",
    eventName: "Team / Player",
    teamPlayer1: "Team / Player #1",
    teamPlayer2: "Team / Player #2",
    time: "00:00",
    period: "Period",
    score1: 0,
    score2: 0,
  },
];

const racingCards = [
  {
    status: "Status",
    eventName: "Venue — Race distance",
  },
  {
    status: "Status",
    eventName: "Venue — Race distance",
  },
];

const isSportItemsVertical = computed(
  () => fields.value.menu_level_1.field.options.displayMode === "vertical"
);

const sportsMenuContainerStyles = computed<ElementStyle>(() => {
  const container = fields.value.event_container.field;
  const margin = getSpacing(
    container.options.spacing.margin ||
      container.options.margin ||
      container.options.margins,
    SpacingKeyName.MARGIN
  );
  const padding = getSpacing(
    container.options.spacing.padding ||
      container.options.padding ||
      container.options.padding,
    SpacingKeyName.PADDING
  );

  return {
    ...margin,
    ...padding,
  };
});
</script>

<style lang="scss">
.sports-menu-live-fenix-widget {
  &__field-menu-1 {
    & > .ant-menu-root {
      display: flex !important;
      flex-direction: var(--menu_level_1-flexDirection) !important;
      gap: var(--menu_level_1-marginBottom) !important;
    }
  }

  &__field-menu-2 {
    & > .ant-menu-root {
      display: flex !important;
      flex-direction: var(--menu_level_2-flexDirection) !important;
      gap: var(--menu_level_2-marginBottom) !important;
    }
  }

  .ant-menu::before,
  .ant-menu::after {
    display: none !important;
  }

  .ant-menu,
  .ant-sub-menu {
    background-color: transparent !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    max-width: 100% !important;
    height: initial !important;
    line-height: 1 !important;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-menu {
    border: none !important;
    overflow-x: auto !important;
  }

  .ant-menu-item {
    &::after {
      display: none !important;
    }
  }

  .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled,
  .ant-menu-submenu-title {
    cursor: pointer !important;
    transition: all 0s !important;
    line-height: 1 !important;
  }

  .ant-menu-title-content {
    margin: 0 !important;
  }

  &__menu_level_1 {
    width: var(--menu_level_1-width) !important;
    height: var(--menu_level_1-height) !important;

    & > .ant-menu-submenu-title {
      background-color: var(--menu_level_1-backgroundColor) !important;
      padding-top: var(--menu_level_1-padding-top) !important;
      padding-left: var(--menu_level_1-padding-left) !important;
      padding-bottom: var(--menu_level_1-padding-bottom) !important;
      padding-right: var(--menu_level_1-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_1-borderRadius) !important;
      border-width: var(--menu_level_1-borderWidth) !important;
      border-color: var(--menu_level_1-borderColor) !important;
      border-style: var(--menu_level_1-borderStyle) !important;
      box-shadow: var(--menu_level_1-boxShadow) !important;
      flex-direction: var(--menu_level_1-elementsFlexDirection) !important;

      /* Content */
      color: var(--menu_level_1-color) !important;
      font-size: var(--menu_level_1-fontSize) !important;
      font-family: var(--menu_level_1-fontFamily) !important;
      font-weight: var(--menu_level_1-fontWeight) !important;
      font-style: var(--menu_level_1-fontStyle) !important;
      text-decoration: var(--menu_level_1-textDecoration) !important;
      gap: var(--menu_level_1-spaceBetweenTextAndIcon) !important;

      svg {
        fill: var(--menu_level_1-iconColor) !important;
        width: var(--menu_level_1-iconSize) !important;
        height: var(--menu_level_1-iconSize) !important;
      }

      & > .sports-menu-live-fenix-widget__expand-icon {
        font-size: var(--menu_level_1-fontSize) !important;
        right: var(--menu_level_1-padding-right) !important;
        transform: rotate(-90deg) !important;
        color: var(--menu_level_1-arrowIconColor) !important;

        &--last-item {
          transform: translateY(0) !important;
        }
      }
    }
  }

  &__menu_level_2 {
    & > .ant-menu-submenu-title {
      background-color: var(--menu_level_2-backgroundColor) !important;
      padding-top: var(--menu_level_2-padding-top) !important;
      padding-left: var(--menu_level_2-padding-left) !important;
      padding-bottom: var(--menu_level_2-padding-bottom) !important;
      padding-right: var(--menu_level_2-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_2-borderRadius) !important;
      border-width: var(--menu_level_2-borderWidth) !important;
      border-color: var(--menu_level_2-borderColor) !important;
      border-style: var(--menu_level_2-borderStyle) !important;
      box-shadow: var(--menu_level_2-boxShadow) !important;

      /* Content */
      color: var(--menu_level_2-color) !important;
      font-size: var(--menu_level_2-fontSize) !important;
      font-family: var(--menu_level_2-fontFamily) !important;
      font-weight: var(--menu_level_2-fontWeight) !important;
      font-style: var(--menu_level_2-fontStyle) !important;
      text-decoration: var(--menu_level_2-textDecoration) !important;
      gap: var(--menu_level_2-spaceBetweenTextAndIcon) !important;

      svg {
        fill: var(--menu_level_2-iconColor) !important;
        width: var(--menu_level_2-iconSize) !important;
        height: var(--menu_level_2-iconSize) !important;
      }

      & > .sports-menu-live-fenix-widget__expand-icon {
        font-size: var(--menu_level_2-fontSize) !important;
        right: var(--menu_level_2-padding-right) !important;
        transform: rotate(-90deg) !important;
        color: var(--menu_level_2-arrowIconColor) !important;

        &--last-item {
          transform: translateY(0) !important;
        }
      }
    }
  }

  &__menu_level_3 {
    background-color: transparent !important;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
</style>
