import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";

import { generateClassName } from "../utils/generate-class-name";
import { generateHeaderTabsCssString } from "../utils/betslip-helpers";
import { generateStringDefault } from "../utils/pipe-helper-functions";

export const generateBetslipStatusFenixWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const headerTabsContainerCounters = widget.content["betslip_status"];

  widget.options._cssClass = generateClassName("BetslipStatusFenixWidget");
  widget.options.headerElementsSettings._cssClass = generateClassName(
    "headerContainerElement"
  );
  headerTabsContainerCounters.options._cssClass =
    generateClassName("headerCounters");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateHeaderTabsCssString(widget, headerTabsContainerCounters)
  )("");
};
