<template>
  <div class="widget-control-container p-t-16 p-b-16 p-l-16 p-r-16">
    <div
      v-if="!props.meta?.hideToggle"
      class="config-arrow-settings__content"
    >
      <ConfigToggle
        size="small"
        :model-value="modelValue._active"
        :label="'Arrow'"
        :allow-disable="allowDisable"
        :show-options="false"
        :hide-switch="isStatesTab"
        is-field-item-toggle
        @update:model-value="handleUpdate('_active', $event)"
      />
    </div>

    <div
      v-if="
        isStatesTab ||
        modelValue._active ||
        typeof modelValue._active !== 'boolean'
      "
      class="m-t-16"
    >
      <div
        v-if="!isStatesTab && hasContentPermissions"
        class="config-arrow-settings__icons"
      >
        <div
          v-for="arrowIcon in ARROW_OPTIONS"
          :key="arrowIcon.value"
          class="config-arrow-settings__icon-wrapper"
          :class="{
            'config-arrow-settings__icon-wrapper--active':
              modelValue.value === arrowIcon.value,
          }"
          @click="handleUpdateValue(arrowIcon.value as ArrowIcon)"
        >
          <span class="config-arrow-settings__icon">
            <CommonIcon :name="iconMapping[arrowIcon.value]" />
          </span>
        </div>
      </div>

      <div
        v-if="!hideColor"
        :class="isStatesTab ? 'p-t-0' : 'p-t-16'"
      >
        <ConfigColorPickerInput
          :model-value="modelValue.textColor"
          :type="ColorPickerType.TEXT"
          label="Color"
          class="config-arrow-settings__color-picker"
          @update:model-value="handleUpdate('textColor', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IFillControl } from "~~/models/widgets/widget-controls.model";
import {
  ArrowIcon,
  iconMapping,
  rotationIcons,
} from "~~/constants/icons-mapping";
import { ARROW_OPTIONS } from "~~/constants/widget-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

type ArrowSettings = {
  value: ArrowIcon;
  textColor: IFillControl;
  _active?: boolean;
  rotate?: boolean;
};

const props = defineProps<{
  modelValue: ArrowSettings;
  allowDisable?: boolean;
  isStatesTab?: boolean;
  hideColor?: boolean;
  meta?: {
    hideToggle?: boolean;
  };
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: ArrowSettings): void;
  (e: "update:active", event: boolean): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleUpdateValue = (value: ArrowIcon) => {
  const rotationData = Object.hasOwn(props.modelValue, "rotate")
    ? {
        rotate: rotationIcons[value] || false,
      }
    : {};

  emit("update:modelValue", {
    ...props.modelValue,
    value: value,
    ...rotationData,
  });
};

const handleUpdate = (
  key: keyof ArrowSettings,
  value: string | number | boolean
) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-arrow-settings {
  padding: $space-m;

  &__switch {
    @include flex(center, flex-start, 6px);
    width: 100%;
  }

  &__color-picker {
    padding: 0;
    border-bottom: unset;
  }

  &__icons {
    display: flex;
    gap: $space-s;
  }

  &__icon-wrapper {
    cursor: pointer;
    padding: 8px;
    border: 2px solid $c-grey-15;
    border-radius: 6px;

    &--active {
      border-color: $c-primary-base;
    }
  }

  &__icon {
    display: flex;
    background: $c-light-blue;
    padding: 8px;
    border-radius: 4px;
    svg {
      color: $c-primary-base-hover;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
