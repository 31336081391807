<template>
  <BaseWidgetNew
    ignore-style-states
    :widget="widget"
    :cell="cell"
    :current-state="activeView"
    :is-selected="isWholeWidgetSelected"
    :non-state-options="['fillImageColor']"
  >
    <CommonFormWidget
      :widget="widget"
      :states="currentStates"
      :screens="SCREENS"
      :active-view="activeView"
      @update-active-view="handleUpdateActiveView"
      @update-button-state="handleUpdateButtonState"
      @reset-states="handleResetStates"
    />
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FormScreen } from "~~/models/widgets/form.model";
import { useForm } from "~~/composables/widgets/form/useForm";

const SCREENS = [FormScreen.SUCCESS];

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const {
  currentStates,
  isWholeWidgetSelected,
  handleUpdateButtonState,
  handleResetStates,
  activeView,
  handleUpdateActiveView,
} = useForm(ref(props.widget), SCREENS, "ForgotPasswordFormWidget");
</script>
