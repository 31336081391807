export enum FieldName {
  TITLE = "title",
  DESCRIPTION = "description",
  DESCRIPTION_1 = "description_1",
  IMAGE = "image",
  BUTTON = "button",
  BUTTON_1 = "button_1",
  BUTTON_2 = "button_2",
  BUTTONS_GROUP = "buttons_group",
  ITEMS_GROUP = "itemsGroup",
  ACCOUNT_MENU = "account_menu",
  ITEMS = "items",
  ITEM = "item",
  SLIDE = "slide",

  DEPOSIT = "deposit",
  LOGIN = "login",
  REGISTRATION = "registration",
  LOGOUT = "logout",
  DROPDOWN = "dropdown",
  DROPDOWN_CONTAINER = "dropdownContainer",
  BALANCE = "balance",
  REAL = "real",
  BONUS = "bonus",

  LIVE = "live",
  COMING_EVENTS = "comingEvents",

  EVENTS = "events",
  TOP_LEAGUES = "topLeagues",
  ALL_LEAGUES = "allLeagues",
  LEAGUE_WINNER = "leagueWinner",
}
