import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";

export const ButtonStatesControls = (
  states: Ref<Record<string, State>>,
  emit: any,
  statesValuePath: ComputedRef<string>,
  selectedField: Ref<IWidgetField | null>
): ControlProp[] => {
  return [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${statesValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${statesValuePath.value}.border`,
      valueSource: selectedField.value,
      options: {
        label: "Border",
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${statesValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${statesValuePath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ];
};

export const ButtonWithoutIconDesign = (
  source: IWidgetField | null
): ControlProp[] => {
  return [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: source,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: source,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ];
};

export const ButtonWithoutIconContent = (
  source: IWidgetField | null
): ControlProp[] => {
  return [
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigButtonTextSettings",
          valuePath: "options.buttonTextSettings",
          className: "m-t-8",
          valueSource: source,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },
      ],
    },
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: source,
      options: {
        showTitle: true,
      },
    },
  ];
};

export const ButtonWithIconContent = (
  source: IWidgetField | null
): ControlProp[] => {
  return [
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigButtonTextSettings",
          valuePath: "options.buttonTextSettings",
          className: "m-t-8",
          valueSource: source,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },
      ],
    },
    {
      componentPath: "ConfigIconSettings",
      valuePath: "options.iconSettings",
      valueSource: source,
      options: {
        isStatesTab: false,
        allowDisable: true,
      },
    },
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: source,
      options: {
        showAlignmentOnFillOnly: false,
      },
    },
  ];
};

export const ButtonWithIconDesign = (
  source: IWidgetField | null
): ControlProp[] => {
  return [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: source,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: source,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ];
};
