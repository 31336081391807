import { useI18n } from "vue-i18n";

import { useWidgetsStore } from "~~/store/widgets";
import {
  State,
  ResizingType,
  Alignment,
  TextStyle,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL as PROMO_TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import {
  TITLE_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  ELEMENTS_INITIAL,
  HOVER_CONTENT_INITIAL,
  ICON_INITIAL,
  TAG_INITIAL,
} from "~~/constants/configs/casino/initial-constants";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  PROMO_BUTTON_INITIAL,
  PROMO_CARD_INITIAL,
  PROMO_GROUP_LIST_INITIAL,
  PROMO_LIST_INITIAL,
} from "~~/constants/configs/promotions-list/initial-constants";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const { t } = useI18n();

  const DEFAULT_STATES = {
    page_type: State.CASINO_LIST,
    back_button: State.DEFAULT,
    sorting: State.DEFAULT,
    search: State.DEFAULT,
    games_category: State.DEFAULT,
    games_category_title: State.DEFAULT,
    games_category_button: State.DEFAULT,
    games_category_hover_button: State.DEFAULT,
    games_status_category: State.WITH_ACTIVE_GAMES,
    games_status_allgames: State.WITH_ACTIVE_GAMES,
    game_cover: State.DEFAULT,
    show_more_button: State.DEFAULT,
    promo_tag: State.DEFAULT,
    play_money_button: State.DEFAULT,
    play_free_button: State.DEFAULT,
    promo_cards: State.DEFAULT,
    promo_button_1: State.DEFAULT,
    promo_button_2: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  /*
    Initialize widget with initial options
  */

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    states: {
      [State.CASINO_LIST]: { _active: true },
      [State.CASINO_SEARCH]: { _active: false },
    },
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions(
      "page_title",
      {
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["spacing", "link"],
        values: {
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            icon: {
              _active: true,
              default_value: "fluent:playing-cards-20-filled",
              textColor: getDefaultFillValue("#000000"),
              iconSize: 32,
              gap: 4,
            },
          },
        },
      },
      { value: "Page Title" }
    );

    addFieldInitialOptions(
      "back_button",
      {
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#000000", 90),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration([TextStyle.BOLD]),
          },
          iconSettings: {
            _active: true,
            default_value: "ant-design:left-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 20,
            position: Alignment.LEFT,
            gap: 8,
          },
          padding: getDefaultSpacing(),
          margins: getDefaultSpacing({
            bottom: 16,
          }),
        },
      },
      { value: "Back button" }
    );

    addFieldInitialOptions("header", {
      data: DESIGN_INITIAL(),
      values: {
        spacing: {
          padding: getDefaultSpacing(),
          margin: getDefaultSpacing({
            bottom: 16,
          }),
        },
      },
      additionalData: {
        options: {
          layout: 3,
          distance: 16,
        },
      },
    });

    addFieldInitialOptions(
      "search_result",
      {
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["spacing", "link"],
        values: {
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            query: {
              _active: true,
              theme: getDefaultTheme(24),
              fontFamily: getDefaultFontFamily(),
              decoration: getDefaultDecoration([TextStyle.BOLD]),
              color: getDefaultFillValue("#1677FF"),
              value:
                useLocalizedValue().transformToLocalizedValue("Search query")
                  .localizedValue,
            },
          },
        },
      },
      { value: "Search Result" }
    );

    const sortingState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#595959"),
        decoration: getDefaultDecoration(),
      };
    };

    addFieldInitialOptions("sorting", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#FFFFFF"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        spacing: {
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 16,
            right: 16,
          }),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          color: getDefaultFillValue("#595959"),
          theme: getDefaultTheme(),
          fontFamily: getDefaultFontFamily(),
          decoration: getDefaultDecoration(),
          states: {
            [State.HOVER]: sortingState(),
            [State.ACTIVE]: sortingState(),
            [State.DISABLED]: sortingState(),
          },
        },
      },
    });

    const searchState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        shadow: getDefaultShadow(),
        text: {
          color: getDefaultFillValue("#494949"),
        },
        placeholder: {
          color: getDefaultFillValue("#C7C7C7"),
        },
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
      };
    };

    addFieldInitialOptions("search", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#FFFFFF"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        spacing: {
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 16,
            right: 16,
          }),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          text: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          placeholder: {
            _active: true,
            value:
              useLocalizedValue().transformToLocalizedValue("Search")
                .localizedValue,
            color: getDefaultFillValue("#C7C7C7"),
          },
          arrow: {},
          icon: {
            _active: true,
            value: "ant-design:search-outlined",
            textColor: getDefaultFillValue("#C7C7C7"),
            iconSize: 20,
            position: Alignment.LEFT,
            gap: 16,
          },
          states: {
            [State.HOVER]: searchState(),
            [State.ACTIVE]: searchState(),
            [State.DISABLED]: searchState(),
          },
        },
      },
    });

    addFieldInitialOptions("games_list", {
      data: DESIGN_INITIAL(),
      exclude: ["fill"],
      additionalData: {
        options: {
          fillImageColor: getDefaultFillImage(),
          distance: 20,
        },
      },
    });

    const gamesCategoryState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    addFieldInitialOptions("games_category", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 12,
          states: {
            [State.HOVER]: gamesCategoryState(),
          },
        },
      },
    });

    addFieldInitialOptions("games_category_list", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          mode: 1,
          maxGamesRow: 5,
          gap: {
            row: 12,
            col: 12,
          },
        },
      },
    });

    addFieldInitialOptions(
      "games_category_nogames_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["padding"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            description: {
              _active: true,
              value: useLocalizedValue().transformToLocalizedValue(
                "Try to change the query"
              ).localizedValue,
              textColor: getDefaultFillValue("#000000", 65),
              theme: getDefaultTheme(16),
            },
          },
        },
      },
      { value: "There are no games" }
    );

    const initialGamesCategoryTitleState = (): Record<
      string,
      IWidgetOptions
    > => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue(),
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
      };
    };

    addFieldInitialOptions("games_category_title", {
      data: {
        options: {
          ...DESIGN_INITIAL().options,
          ...TITLE_INITIAL().options,
        },
      },
      exclude: ["link", "spacing", "alignment", "states.active"],
      values: {
        theme: getDefaultTheme(20),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
      },
      additionalData: {
        options: {
          distanceBetweenItems: 4,
          icon: {
            _active: true,
            textColor: getDefaultFillValue("#000000", 90),
            iconSize: 20,
            value: "mdi:dice-5",
          },
          states: {
            [State.HOVER]: initialGamesCategoryTitleState(),
          },
        },
      },
    });

    addFieldInitialOptions("games_category_button", {
      data: BUTTON_INITIAL(),
      exclude: ["link"],
      values: {
        fill: getDefaultFillValue(),
        cornerRadius: getDefaultCornerRadiusValue({ value: 0 }),
        padding: getDefaultSpacing(4),
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.LEFT,
        },
        buttonTextSettings: {
          _active: false,
          textColor: getDefaultFillValue("#000000"),
          theme: getDefaultTheme(16),
          fontFamily: getDefaultFontFamily(),
          decoration: getDefaultDecoration(),
        },
        iconSettings: {
          _active: true,
          position: Alignment.RIGHT,
          gap: 8,
          default_value: "ant-design:right-outlined",
          textColor: getDefaultFillValue("#000000"),
          iconSize: 20,
        },
      },
    });

    addFieldInitialOptions("games_category_hover_button", {
      data: BUTTON_INITIAL(),
      exclude: ["link"],
      values: {
        fill: getDefaultFillValue(),
        cornerRadius: getDefaultCornerRadiusValue({ value: 0 }),
        padding: getDefaultSpacing(0),
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.LEFT,
        },
        buttonTextSettings: {
          _active: true,
          textColor: getDefaultFillValue("#595959"),
          theme: getDefaultTheme(16),
          fontFamily: getDefaultFontFamily(),
          decoration: getDefaultDecoration(),
        },
        iconSettings: {
          _active: false,
          position: Alignment.RIGHT,
          gap: 8,
          default_value: "ant-design:right-outlined",
          textColor: getDefaultFillValue("#000000"),
          iconSize: 16,
        },
      },
    });

    addFieldInitialOptions("all_games", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 12,
        },
      },
    });

    addFieldInitialOptions("all_games_list", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          mode: 1,
          maxGamesRow: 5,
          gap: {
            row: 12,
            col: 12,
          },
        },
      },
    });

    addFieldInitialOptions(
      "all_games_nogames_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["padding"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            description: {
              _active: true,
              value: useLocalizedValue().transformToLocalizedValue(
                "Try to change the query"
              ).localizedValue,
              color: getDefaultFillValue("#000000", 65),
              theme: getDefaultTheme(16),
            },
          },
        },
      },
      { value: "There are no games" }
    );

    addFieldInitialOptions(
      "all_games_title",
      {
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "alignment", "spacing"],
        values: {
          theme: getDefaultTheme(20),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          color: getDefaultFillValue("#000000", 90),
        },
      },
      { value: "All Games" }
    );

    addFieldInitialOptions(
      "show_more_button",
      {
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          padding: getDefaultSpacing(),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#595959"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration([TextStyle.BOLD]),
          },
          iconSettings: {
            _active: false,
            position: Alignment.RIGHT,
            gap: 5,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 16,
          },
        },
      },
      { value: "Show more games" }
    );

    addFieldInitialOptions(
      "game_name",
      {
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          color: getDefaultFillValue("#000000", 90),
        },
      },
      { value: "Game Name" }
    );

    addFieldInitialOptions("game_cards", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          distance: 4,
          padding: getDefaultSpacing(),
        },
      },
    });

    const initialGamesState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 6),
        }),
        shadow: getDefaultShadow(),
      };
    };

    const initialGamesStateHover = (): Record<string, IWidgetOptions> => {
      return {
        ...initialGamesState(),
        overlay: getDefaultFillValue("#000000", 60),
      };
    };

    addFieldInitialOptions("game_cover", {
      data: {
        options: {
          ...DESIGN_INITIAL().options,
          ...ELEMENTS_INITIAL().options,
        },
      },
      exclude: ["spacing"],
      values: {
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        fill: getDefaultFillValue("#FFFFFF"),
        padding: getDefaultSpacing(16),
      },
      additionalData: {
        options: {
          overlay: getDefaultFillValue("#000000", 60),
          image: {
            cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
            padding: getDefaultSpacing(8),
          },
          states: {
            [State.HOVER]: initialGamesStateHover(),
            [State.ACTIVE]: initialGamesState(),
          },
        },
      },
    });

    addFieldInitialOptions("hover_content", {
      data: HOVER_CONTENT_INITIAL(),
    });

    addFieldInitialOptions("favorite_icon", {
      data: ICON_INITIAL(),
      additionalData: {
        options: {
          default_value: "ant-design:star-outlined",
        },
      },
    });

    addFieldInitialOptions("promo_tag", {
      data: TAG_INITIAL(),
    });

    addFieldInitialOptions(
      "game_title",
      {
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#FFFFFF"),
          theme: getDefaultTheme(20),
          alignment: Alignment.CENTER,
        },
      },
      { value: t("widgets.CasinoGamesListWidget.game_title") }
    );

    addFieldInitialOptions(
      "play_money_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      },
      { value: t("widgets.CasinoGamesListWidget.play_for_money") }
    );

    addFieldInitialOptions(
      "play_free_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          fill: getDefaultFillValue("#ffffff"),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      },
      { value: t("widgets.CasinoGamesListWidget.play_for_free") }
    );

    addFieldInitialOptions("promo_group_list", {
      data: PROMO_GROUP_LIST_INITIAL(),
    });

    addFieldInitialOptions("promo_cards", {
      data: PROMO_CARD_INITIAL(),
    });

    addFieldInitialOptions("promo_image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
    });

    addFieldInitialOptions("promo_title", {
      data: PROMO_TITLE_INITIAL(),
      exclude: ["link", "margins", "alignment"],
      values: {
        padding: {
          top: 16,
          right: 16,
          bottom: 4,
          left: 16,
        },
      },
    });

    addFieldInitialOptions("promo_description", {
      data: PROMO_TITLE_INITIAL(),
      exclude: ["link", "margins", "alignment"],
      values: {
        theme: 12,
        padding: {
          top: 0,
          right: 16,
          bottom: 0,
          left: 16,
        },
      },
    });

    addFieldInitialOptions("promo_button_group", {
      data: PROMO_LIST_INITIAL(),
      exclude: ["fillImageColor"],
      additionalData: {
        options: {
          fill: {
            color: "#ffffff",
            opacity: 100,
          },
          layout: DisplayOrientation.HORIZONTAL,
          alignment: Alignment.CENTER,
        },
      },
    });

    addFieldInitialOptions(
      "promo_button_1",
      {
        data: PROMO_BUTTON_INITIAL(),
      },
      {
        type: WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
        value: "Default",
      }
    );

    addFieldInitialOptions(
      "promo_button_2",
      {
        data: PROMO_BUTTON_INITIAL(),
      },
      {
        type: WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
        value: "Default",
      }
    );

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,

    init,
  };
};
