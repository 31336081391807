import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useLiveBetsListItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Tabs", "tabs"),
    generateDropdownItem("Event cards", "event_cards"),
    generateDropdownItem(
      "Suspended status",
      "event_suspended_status",
      "event_cards"
    ),
    generateDropdownItem("Time", "event_time", "event_cards"),
    generateDropdownItem("League", "event_league", "event_cards"),
    generateDropdownItem("Event info", "event_info", "event_cards"),
    generateDropdownItem("Bet items", "bet_items", "event_cards"),
    generateDropdownItem("Team / player", "event_team_player", "event_info"),
    generateDropdownItem("Score", "event_score", "event_info"),
    generateDropdownItem("Show more", "show_more_title"),
    generateDropdownItem("Arrow settings", "arrow"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_suspended_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    show_more_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    arrow: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
