import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getSpacing,
  getDecorationColor,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";

import { IFields } from "../../useWidgetFields";

export const useBetslipFormInlineStyles = (
  fields: ComputedRef<IFields>,
  states: Ref<Record<string, State>>,
  isErrorVisible: ComputedRef<boolean>,
  inputState?: ComputedRef<State>
) => {
  const activeCoefficientStateOptions = computed<IWidgetOptions>(() => {
    if (states.value.coefficient_title === State.DEFAULT) {
      return fields.value.coefficient_title.field.options;
    }

    return fields.value.coefficient_title.field.options.states[
      states.value.coefficient_title
    ];
  });

  const currentInputState = computed<State>(() => {
    if (inputState) {
      return inputState.value;
    }

    return states.value.fields_styling;
  });

  const activeFieldStateOptions = computed<IWidgetOptions>(() => {
    const inputFieldOptions = fields.value.fields_styling.field.options;

    if (currentInputState.value === State.DEFAULT) {
      return inputFieldOptions || {};
    }

    return inputFieldOptions.states[currentInputState.value];
  });

  const formInputInlineStyles = computed<ElementStyle>(() => {
    const inputFieldOptions = fields.value.fields_styling.field.options;
    if (!inputFieldOptions) {
      return {};
    }

    const placeholderStyle = {
      placeholderColor: getColorFromHex(
        activeFieldStateOptions.value.placeholderColor
      ),
      ...getDecorationValue(activeFieldStateOptions.value.decoration),
      ...getDecorationColor(activeFieldStateOptions.value.placeholderColor),
    } as ElementStyle;

    let styles = {
      ...getSpacing(inputFieldOptions.padding, SpacingKeyName.PADDING),
      ...getCornerRadiusStyle(inputFieldOptions.cornerRadius),
      ...getFontSizeStyle(inputFieldOptions.theme),
      ...getFontFamilyStyle(inputFieldOptions.fontFamily),
      ...getBackgroundColorStyle(activeFieldStateOptions.value.fill),
      ...getBoxShadowStyle(activeFieldStateOptions.value.shadow),
      ...getDecorationValue(activeFieldStateOptions.value.decoration),
      ...getColorStyle(activeFieldStateOptions.value.color),
      ...getStylesAsVars(placeholderStyle),
    };

    if (isErrorVisible.value) {
      styles = {
        ...styles,
        ...getBorderStyle(fields.value.errors_styling.field.options.border),
      };
    } else {
      styles = {
        ...styles,
        ...(activeFieldStateOptions.value.border.fill.color
          ? getBorderStyle(activeFieldStateOptions.value.border)
          : { border: "unset!important" }),
      };
    }

    return styles;
  });

  const formErrorStyles = computed<ElementStyle>(() => {
    const errorFieldOptions = fields.value.errors_styling.field.options;
    return {
      ...getColorStyle(errorFieldOptions.color),
      ...getFontSizeStyle(errorFieldOptions.theme),
      ...getFontFamilyStyle(errorFieldOptions.fontFamily),
      ...getDecorationValue(errorFieldOptions.decoration),
      textAlign: errorFieldOptions.alignment,
    };
  });

  const formLabelsInlineStyle = computed<ElementStyle>(() => {
    const formLabelsOptions = fields.value.labels_styling.field.options;
    return {
      color: getColorFromHex(formLabelsOptions.color)!,
      fontSize: `${formLabelsOptions.theme.value}px`,
      fontFamily: `${formLabelsOptions.fontFamily}`,
      ...getDecorationValue(formLabelsOptions.decoration),
      gap: `${fields.value.fields_styling.field.options.iconRight.gap}px`,
    };
  });

  const formLabelIconInlineStyle = computed<ElementStyle>(() => {
    const fieldStylingOptions = activeFieldStateOptions.value.iconRight;
    return {
      color: getColorFromHex(fieldStylingOptions.color)!,
    };
  });

  const coefficientInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(activeCoefficientStateOptions.value),
      ...getSpacing(
        fields.value.coefficient_title.field.options.margins,
        SpacingKeyName.MARGIN
      ),
      color: getColorFromHex(activeCoefficientStateOptions.value.color)!,
      fontSize: `${fields.value.coefficient_title.field.options.theme}px`,
      fontFamily: fields.value.coefficient_title.field.options.fontFamily,
      ...getDecorationValue(
        fields.value.coefficient_title.field.options.decoration
      ),
    };
  });

  const betslipFormContainerInlineStyles = computed<ElementStyle>(() => {
    const betslipFormContainerOptions = fields.value.form.field.options;
    return {
      ...getCommonStyles(betslipFormContainerOptions),
      ...getBorderStyle(betslipFormContainerOptions.border),
      ...getCornerRadiusStyle(betslipFormContainerOptions.cornerRadius),
    };
  });

  return {
    formErrorStyles,
    formInputInlineStyles,
    formLabelsInlineStyle,
    formLabelIconInlineStyle,
    coefficientInlineStyles,
    betslipFormContainerInlineStyles,
  };
};
