import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { generateIconCssString } from "~~/assets/utils/widget-css/widgets/racing-sportsbook-live-widget-css";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateMaxWidth } from "../helpers";
import {
  generateIconSizeCssString,
  generateStageButtonCssString,
  generateIconCssString as generateIconColorCssString,
} from "../utils/form-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";

import {
  generateEventInfoCssString,
  generateFillOrHugCssString,
  generateRacingSportEventCardCssString,
  generateSportEventCardCssString,
  generateSportEventHeaderCssString,
  generateSportEventsContainerCssString,
  generateTextFillOrHugCssString,
} from "./sportsbook-mobile-live-fenix-widget-css";
import { generateListStartTimeCssString } from "./sportsbook-widget-css";
import { generateBetItemsCssString } from "./sportsbook-live-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";
import { generateBetItemAdditionalCssString } from "./live-betslist-widget-css";

export const generateSuspendedBetItemCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: generateDefaultStyles(options.states.suspended),
      pseudoClassName: `[data-suspended="true"]`,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      childClassName: options.name._cssClass,
      content: generateDefaultStyles(options.states.suspended?.name, ["theme"]),
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      childClassName: options.coef._cssClass,
      content: generateDefaultStyles(options.states.suspended?.coef, ["theme"]),
    });

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
      direction: "column",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: `[data-suspended="true"]`,
      content: flex + generateMaxWidth("100%", true) + "margin: 0;",
    });

    return cssString;
  };

export const generateTitleCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: field.options.distanceBetweenItems || field.options.icon?.gap || "",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: flex,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: `:hover .${field.options.icon._cssClass}`,
      content: generateDefaultStyles(field.options.states.hover.icon),
    });

    return cssString;
  };

const generateEventNameCssString =
  (field: IWidgetOptions, eventInfo: IWidgetOptions) =>
  (cssString: string): string => {
    const contentLayout = eventInfo.options.displayMode;
    const isHorizontal = contentLayout === DisplayOrientation.HORIZONTAL;

    const lastChildContent = isHorizontal ? "text-align:right;" : "";

    cssString += generateCssClassWithContent({
      className: eventInfo.options._itemCssClass,
      pseudoClassName: ":last-child",
      childClassName: field.options._cssClass,
      content: lastChildContent,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateDefaultStyles(field.options) + "width:100%;",
    });

    return cssString;
  };

const generateSportsContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: options.distanceBetweenSports || "",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: ":hover",
      content: generateDefaultStyles(options.states.hover),
    });

    // Leagues container
    const flexLeague = generateFlex({
      flex: "flex",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: options.distanceBetweenLeagueEvents || "",
    });

    cssString += generateCssClassWithContent({
      className: options._leaguesContainerCssClass,
      content: flexLeague,
    });

    return cssString;
  };

export const generateSportsbookLiveFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.title.options.icon._cssClass = generateClassName("title_icon");

  prefilled.title_button.options.iconSettings._cssClass =
    generateClassName("title_button_icon");

  prefilled.title_hover_button.options.iconSettings._cssClass =
    generateClassName("title_hover_button_icon");

  prefilled.sports_container.options._leaguesContainerCssClass =
    generateClassName("sport_leagues_container");

  prefilled.event_header.options._detailsCssClass = generateClassName(
    "event_header_details"
  );

  prefilled.event_header.options._topCssClass =
    generateClassName("event_header_top");
  prefilled.event_header.options._topDetailsCssClass = generateClassName(
    "event_header_top_details"
  );

  prefilled.event_cards.options._primaryCssClass =
    generateClassName("event_cards");
  prefilled.event_cards.options._marketContainerCssClass =
    generateClassName("event_cards_market");

  prefilled.event_time.options.icon._cssClass =
    generateClassName("event_time_icon");

  prefilled.event_info.options._itemCssClass =
    generateClassName("event_info_item");

  prefilled.bet_items.options._itemCssClass =
    generateClassName("bet_items_item");
  prefilled.bet_items.options._itemSelectedCssClass =
    prefilled.bet_items.options._itemCssClass;
  prefilled.bet_items.options._itemUpCssClass =
    prefilled.bet_items.options._itemCssClass;
  prefilled.bet_items.options._itemDownCssClass =
    prefilled.bet_items.options._itemCssClass;

  prefilled.bet_items.options.name._cssClass =
    generateClassName("bet_items_name");
  prefilled.bet_items.options.coef._cssClass =
    generateClassName("bet_items_coef");

  prefilled.event_button.options._containerCssClass = generateClassName(
    "event_button_container"
  );
  prefilled.event_button.options.iconSettings._cssClass =
    generateClassName("event_button_icon");

  prefilled.racing_event_cards.options._topCssClass = generateClassName(
    "racing_event_cards_top"
  );

  prefilled.racing_event_button.options._containerCssClass = generateClassName(
    "racing_event_button_container"
  );

  prefilled.racing_event_button.options.iconSettings._cssClass =
    generateClassName("racing_event_button_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateSportsContainerCssString(prefilled.sports_container),
    generateStringDefault(prefilled.no_events_title),
    generateTitleCssString(prefilled.title),
    generateStringWithStates(prefilled.title, false),
    generateIconColorCssString(prefilled.title.options.icon),
    generateIconSizeCssString(prefilled.title.options.icon),

    generateStageButtonCssString(prefilled.title_button as IWidgetField),
    generateStageButtonCssString(prefilled.title_hover_button as IWidgetField),
    generateStringDefault(prefilled.leagues_titles),
    generateSportEventsContainerCssString(
      prefilled.sport_events_container,
      prefilled.sports_container,
      true
    ),
    generateEventNameCssString(prefilled.event_name, prefilled.event_info),
    generateSportEventCardCssString(
      prefilled.event_cards,
      prefilled.event_info,
      prefilled.event_name
    ),
    generateStringWithStates(prefilled.event_cards, false),
    generateStringDefault(prefilled.event_suspended_status),

    generateStringDefault(prefilled.event_league),
    generateTextFillOrHugCssString(
      prefilled.event_league.options._cssClass,
      prefilled.event_league.options.resizing
    ),
    generateIconCssString(prefilled.stream_icon),
    generateIconCssString(prefilled.favourites_button),
    generateListStartTimeCssString(prefilled.event_time as IWidgetField),
    generateStringDefault(prefilled.event_period),
    generateTextFillOrHugCssString(
      prefilled.event_period.options._cssClass,
      prefilled.event_period.options.resizing,
      [prefilled.event_header.options._detailsCssClass]
    ),
    generateFillOrHugCssString(
      prefilled.event_header.options._topDetailsCssClass,
      prefilled.event_header.options._topDetailsCssClass,
      prefilled.event_league.options.resizing
    ),

    generateSportEventHeaderCssString(
      prefilled.event_header,
      prefilled.favourites_button
    ),
    generateStringDefault(prefilled.event_info),
    generateEventInfoCssString(
      prefilled.event_info,
      prefilled.event_score,
      prefilled.event_name
    ),
    generateStringDefault(prefilled.event_score),
    generateBetItemsCssString(prefilled.bet_items as IWidgetField, [
      "templateColumns",
      // "columnGap",
    ]),
    generateSuspendedBetItemCssString(prefilled.bet_items),
    generateBetItemAdditionalCssString(prefilled.bet_items),
    generateStageButtonCssString(prefilled.event_button as IWidgetField),
    generateFillOrHugCssString(
      prefilled.event_button.options._cssClass,
      prefilled.event_button.options._containerCssClass,
      prefilled.event_button.options.buttonDisplaySettings.resizing
    ),
    /* 
      Racing
    */
    generateSportEventsContainerCssString(
      prefilled.racing_events_container,
      prefilled.sports_container,
      true
    ),
    generateRacingSportEventCardCssString(
      prefilled.racing_event_cards,
      prefilled.racing_event_name
    ),
    generateStringWithStates(prefilled.racing_event_cards, false),
    generateIconCssString(prefilled.racing_event_stream_icon),
    generateStringDefault(prefilled.racing_event_name),
    generateStringDefault(prefilled.racing_event_status),
    generateIconCssString(prefilled.racing_event_favourites_button),
    generateStageButtonCssString(prefilled.racing_event_button as IWidgetField),
    generateFillOrHugCssString(
      prefilled.racing_event_button.options._cssClass,
      prefilled.racing_event_button.options._containerCssClass,
      prefilled.racing_event_button.options.buttonDisplaySettings.resizing
    ),
    generateGridLayoutCssString(prefilled.racing_events_container, false),
    generateGridLayoutCssString(prefilled.sport_events_container, false)
  )("");
};
