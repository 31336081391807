import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateBorderStyle,
  generateDecorationStyle,
  generateFillColorStyle,
  generateFlex,
  generateFlexAlignment,
  generateFontFamily,
  generateFontSize,
  generateGap,
  generatePaddingStyle,
  generateTextColor,
  generateWidth,
} from "../helpers";
import { getColorFromHex } from "../../widget-settings";

import { CssGenerator } from "./css-generator";

export const generateSuccessMessageCssString =
  (element: IWidgetOptions, wrapperStyles?: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const customContent = generateFlex({
      flex: "flex",
      justify:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "center"
          : "space-between",
      align:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "center"
          : element.options.alignment,
      direction:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "row"
          : "column",
      gap: "6",
    });
    `justify-content:space-between;`;

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: customContent,
    });

    const iconSettingsContent = [
      generateGap(element.options.iconSettings.gap),
      generateFontSize(element.options.iconSettings.iconSize),
      generateTextColor(element.options.iconSettings.textColor),
    ];

    cssString += generateCssClassWithContent({
      className: element.options.iconSettings._cssClass,
      content: iconSettingsContent.join(""),
    });

    cssString += generateCssClassWithContent({
      className: element.options.printSettings._cssClass,
      content: element.options.printSettings,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      content: wrapperStyles || "",
    });

    return cssString;
  };

export const generateMessageCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const iconSettingsContent = [
      generateGap(element.options.iconSettings.gap),
      generateFontSize(element.options.iconSettings.iconSize),
      generateTextColor(element.options.iconSettings.textColor),
    ];

    cssString += generateCssClassWithContent({
      className: element.options.iconSettings._cssClass,
      content: iconSettingsContent.join(""),
    });

    return cssString;
  };

export const generateResultTotalAmountsCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const isHorizontal =
      element.options.position === DisplayOrientation.HORIZONTAL;

    const rootCssContent = [
      generateFlex({
        flex: "flex",
        align: isHorizontal ? "unset" : "center",
        justify: isHorizontal ? "unset" : "space-between",
        direction: isHorizontal ? "column" : "",
        gap: element.options.gap || "",
      }),
      generateWidth(100, "%"),
      "margin-left:auto;margin-right:auto;",
      generatePaddingStyle(element.options.padding),
    ];

    cssString += generateCssClassWithContent({
      className: element.options._rootCssClass,
      content: rootCssContent.join(""),
    });

    const elementCssContent = [
      generateFlex({
        flex: "flex",
        align: "unset",
        justify: isHorizontal ? "space-between" : "unset",
        direction: isHorizontal ? "" : "column",
      }),
      generateWidth(100, "%"),
      "margin-left:auto;margin-right:auto;",
    ];

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementCssContent.join(""),
    });

    cssString += generateDefaultStyles(element.options);

    const elementContent = generateFontFamily(element.options.fontFamily);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementContent,
    });

    cssString += generateCssClassWithContent({
      className: element.options.labelSettings._cssClass,
      content: element.options.labelSettings,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options.valueSettings._cssClass,
      content: element.options.valueSettings,
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

export const generateHeaderTabsCssString =
  (element: IWidgetOptions, counterElement?: IWidgetOptions) =>
  (cssString: string): string => {
    const headerElemCssClass = element.options.headerElementsSettings._cssClass;

    const isDisplayFill =
      element.options.buttonDisplaySettings.resizing === ResizingType.FILL;

    const widthValue = isDisplayFill ? "100%" : "unset";

    const justifyContent = isDisplayFill
      ? element.options.buttonDisplaySettings.alignment
      : "unset";

    const alignSelf = isDisplayFill
      ? "unset"
      : generateFlexAlignment(element.options.buttonDisplaySettings.alignment);

    const buttonDisplayCssString = [
      generateWidth(widthValue, null),
      generateFlex({
        flex: "flex !important",
        align: "center",
        justify: justifyContent,
        gap: element.options.gapBetweenTextAndCounter
          ? element.options.gapBetweenTextAndCounter
          : 0,
        alignSelf,
      }),
    ].join("");

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      content: buttonDisplayCssString,
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header__cash-out-tab-label-container",
      content: buttonDisplayCssString,
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header__cash-out-tab-container",
      content:
        generateFlex({
          flex: "flex",
          direction: "column",
          align: "center",
          justify: "flex-start",
          gap: "4",
        }) + generateWidth(100, "%"),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles({
        ...element.options,
        color: null,
        decoration: null,
      }),
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header-label",
      content: element.options.decoration,
      customFunction: generateDecorationStyle,
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header-label",
      content: "text-align:left;",
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      content: {
        ...element.options.headerElementsSettings,
        color: element.options.color,
      },
      customFunction: generateDefaultStyles,
    });

    if (!isDisplayFill) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button.base-tabs__trigger`,
        content: "width:max-content;",
      });

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: `justify-content:${element.options.buttonDisplaySettings.alignment};`,
      });
    }

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ` button.base-tabs__trigger:hover .${headerElemCssClass}`,
      content: {
        ...element.options.states.hover.headerElementsSettings,
        color: element.options.states.hover.color,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} button.base-tabs__trigger[data-active="true"] .${headerElemCssClass}`,
      content: {
        ...element.options.states.active.headerElementsSettings,
        color: element.options.states.active.color,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} button.base-tabs__trigger[data-disabled] .${headerElemCssClass}`,
      content: {
        ...element.options.states.disabled.headerElementsSettings,
        color: element.options.states.disabled.color,
      },
      customFunction: generateDefaultStyles,
    });

    if (counterElement) {
      cssString += generateCssClassWithContent({
        className: counterElement.options._cssClass,
        content: counterElement.options,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button.base-tabs__trigger:hover .${counterElement.options._cssClass}`,
        content: counterElement.options.states.hover,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-active="true"] .${counterElement.options._cssClass}`,
        content: counterElement.options.states.active,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-disabled] .${counterElement.options._cssClass}`,
        content: counterElement.options.states.disabled,
        customFunction: generateDefaultStyles,
      });
    }

    return cssString;
  };

export const generateCoefficientCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const downStyles = [
      generateDefaultStyles(element.options, ["fill", "border", "color"]),
      generateBorderStyle(element.options.states.down.border, true),
      generateFillColorStyle(element.options.states.down.fill, true),
      generateTextColor(element.options.states.down.color, true),
    ];
    cssString += generateCssClassWithContent({
      className: element.options._downCssClass,
      content: downStyles.join(""),
    });

    const upStyles = [
      generateDefaultStyles(element.options, ["fill", "border", "color"]),
      generateBorderStyle(element.options.states.up.border, true),
      generateFillColorStyle(element.options.states.up.fill, true),
      generateTextColor(element.options.states.up.color, true),
    ];
    cssString += generateCssClassWithContent({
      className: element.options._upCssClass,
      content: upStyles.join(""),
    });

    cssString += CssGenerator.init()
      .className(`${element.options._upCssClass}-triangle--up`)
      .openClassBody()
      .property("position", "relative")
      .property("overflow", "hidden")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._upCssClass}-triangle--up`)
      .pseudoSelector("after")
      .openClassBody()
      .property("content", "''")
      .property("display", "block")
      .property("position", "absolute")
      .property("right", "-10px")
      .property("top", "-7px")
      .property("width", "22px")
      .property("height", "15px")
      .property(
        "background-color",
        `${getColorFromHex(element.options.states.up.arrow?.color)!} !important`
      )
      .property("z-index", "2")
      .property("transform", "rotate(45deg)")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._downCssClass}-triangle--down`)
      .openClassBody()
      .property("position", "relative")
      .property("overflow", "hidden")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._downCssClass}-triangle--down`)
      .pseudoSelector("after")
      .openClassBody()
      .property("content", "''")
      .property("display", "block")
      .property("position", "absolute")
      .property("right", "-10px")
      .property("top", "initial")
      .property("bottom", "-7px")
      .property("width", "22px")
      .property("height", "15px")
      .property(
        "background-color",
        `${getColorFromHex(
          element.options.states.down.arrow?.color
        )!} !important`
      )
      .property("z-index", "2")
      .property("transform", "rotate(-45deg)")
      .closeClassBody()
      .toString();

    return cssString;
  };

export const generateCloseIconCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(element.options.color),
      generateFontSize(element.options.iconSize),
    ];

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: content.join(""),
    });

    return cssString;
  };

export const generateLabelCopyIconCssString =
  (customFields: IWidgetOptions, form: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: customFields.fields_styling.options.iconRight._cssClass,
      content: generateFontSize(customFields.labels_styling.options.theme),
    });

    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass} .formkit-label-wrapper-override`,
      content: customFields.fields_styling.options.iconRight.gap,
      customFunction: generateGap,
    });

    return cssString;
  };

export const generateBetslipFormCssString =
  (form: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass} .formkit-outer-override:not(:last-child)`,
      content: "margin-bottom: 8px;",
    });

    return cssString;
  };

export const generateResultContainerCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const resultContainerDisplayContent = [
      generateFlex({
        flex: "flex !important",
        align: "unset",
        justify: "unset",
        direction: "column",
      }),
    ].join("");

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: resultContainerDisplayContent,
    });

    return cssString;
  };

export const generateBetsContainerCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const betsContainerDisplayContent = generateFlex({
      flex: "flex !important",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: element.options.spaceBetween || element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: betsContainerDisplayContent,
    });

    return cssString;
  };

export const generateBetsTypeTitleCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const defaultTypeStyles =
      generateFontSize(element.options.theme) +
      generatePaddingStyle(element.options.padding) +
      generateDecorationStyle(element.options.decoration);

    cssString += generateCssClassWithContent({
      className: element.options._systemCssClass,
      content: element.options.states.system_bets,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._multiCssClass,
      content: element.options.states.multi_bets,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._systemCssClass,
      content: defaultTypeStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._multiCssClass,
      content: defaultTypeStyles,
    });

    return cssString;
  };
