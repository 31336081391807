import { defineStore } from "pinia";

import { IWidgetsStore } from "~~/models/stores/widgets-store.model";
import {
  IWidgetWithFields,
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IGroupResponse,
  ICategoryResponse,
  IWidgetResponse,
  IContentImageUploadResponse,
  IMediaUploadResponse,
} from "~~/models/widgets/widgets-list.model";
import { ICell, ICellOptions } from "~~/models/grid.interface";
import {
  ICommonMenuItem,
  SearchDropdownType,
  DropdownSearchItem,
} from "~~/models/common/dropdown.model";
import {
  IPageWidgetResponse,
  IPageWidgetDetails,
  IPageFieldDetails,
} from "~~/models/page.model";
import http from "~~/services/http";
import { deepCopy, generateId, parseId } from "~~/assets/utils";
import { deepCopyWidgetWithFields, getFieldById } from "~~/assets/utils/widget";
import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useGridConfig } from "~~/store/grid";
import { usePagesStore } from "~~/store/pages";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { RuntimeForm } from "~~/assets/utils/form-data-runtime";
import {
  IFormDataBackgroundImageDTO,
  IFormDataContentImageDTO,
} from "~~/models/common/form-data-dto";
// TODO make lang dynamic
import { useLocalisedField } from "~~/composables/fields/useLocalisedField";

import { useMetaStore } from "./meta";
import { useLanguagesStore } from "./languages";

const languagesStore = useLanguagesStore();
const metaStore = useMetaStore();

export const useWidgetsStore = defineStore("widgets", {
  state: (): IWidgetsStore => ({
    groups: [],
    selectedCategoryOrGroup: null,
    widgetSearch: "",
    availableWidgets: [],
    initialWidgetsLoading: false,
    widgetsDetailsCache: {},
    isLoadingWidgetId: null,
    activeWidget: null,
    activeWidgetElement: {} as IWidgetField,
    hoveredElements: {
      widget: null,
      field: null,
    },
    dragTargetEmptyWidgetCell: null,
    currentDraggingWidgetId: null,
    activeStatePath: null,
    sportsItems: [],
  }),
  getters: {
    cacheWidgetData({
      widgetsDetailsCache,
    }): Partial<IPageWidgetDetails> | null {
      const activeWidgetId = useWidgetSettingsStore().selectedWidget?.static_id;

      if (!activeWidgetId) {
        return null;
      }

      return (
        widgetsDetailsCache[metaStore.interfaceLang!]?.[activeWidgetId] || null
      );
    },

    widgetIdDetailsLoading({ isLoadingWidgetId }): string | number | null {
      return isLoadingWidgetId;
    },
  },
  actions: {
    updateCellStyle(
      cellId: string | number,
      cellStyle: Partial<ICellOptions>
    ): void {
      const cell = findCell(useGridConfig().currentWidgetsLayout, cellId);

      if (!cell) {
        return;
      }

      const currentStyle: ICellOptions = cell.settings.style || {};

      cell.settings.style = {
        ...currentStyle,
        ...cellStyle,
      };
    },

    updateWidgetOptions(
      widget: IWidgetWithFields,
      options: Partial<IWidgetOptions>
    ) {
      widget.options = options;
    },

    updateActiveWidgetFields(fields: IWidgetField[]): void {
      if (!useWidgetSettingsStore().selectedWidget) {
        return;
      }

      useWidgetSettingsStore().selectedWidget!.fields = fields;
    },

    updateWidgetFields(
      widget: IWidgetWithFields,
      fields: IWidgetField[]
    ): void {
      widget.fields = fields;
    },

    // This function updates the value of a specific field option within the settings of a widget.
    // Arguments: value, control, and path, it finds the corresponding field in the widget's settings by given path
    // and updates the specified option with the provided value.
    updateValue(value: any, path: string, target: any, finalField: string) {
      const keys = path.split(".");
      const finalKey = keys.pop();

      if (!finalKey) return;

      let currentObj = { ...target };
      keys.forEach(key => {
        if (!currentObj[key]) {
          currentObj[key] = {};
        }
        currentObj = currentObj[key];
      });

      if (keys.length) {
        currentObj[finalKey] = value;
      } else {
        target[finalField] = value;
      }
    },

    updateFieldProperty(
      field: IWidgetField,
      value: any,
      propertyName: keyof IWidgetField
    ): void {
      // @ts-ignore
      field[propertyName] = value;
    },

    updateFieldOptions(field: IWidgetField, options: IWidgetOptions) {
      field.options = options;
    },

    updateFieldOptionValue(
      value: any,
      path: string,
      fieldId?: string | number,
      widget?: IWidgetWithFields
    ) {
      const currentWidget = widget || useWidgetSettingsStore().selectedWidget;
      const targetField = fieldId
        ? getFieldById(currentWidget!, fieldId)
        : useWidgetSettingsStore().selectedField;

      const options = targetField?.options || [];

      if (!options) {
        return;
      }

      this.updateValue(value, path, options, path.split(".").pop() as string);
    },

    updateWidgetOptionValue(
      value: any,
      path: string,
      widget?: IWidgetWithFields
    ) {
      const currWidget = widget || useWidgetSettingsStore().selectedWidget;
      const options = currWidget?.options || [];

      if (!options) {
        return;
      }

      this.updateValue(value, path, options, path.split(".").pop() as string);
    },

    updateFieldValue(
      fieldId: string | number,
      value: any,
      isFieldActive = false,
      widget: IWidgetWithFields | undefined = undefined
    ): void {
      const field = isFieldActive
        ? useWidgetSettingsStore().selectedField
        : getFieldById(
            widget || useWidgetSettingsStore().selectedWidget!,
            fieldId
          );

      if (!field) {
        return;
      }

      field.value = value;
    },

    getWidgetCell(widget: IWidgetWithFields | null): ICell | null {
      if (!widget) {
        return null;
      }

      if (widget.id === useWidgetSettingsStore().selectedWidget?.id) {
        return useWidgetSettingsStore().selectedCell;
      }

      return findCell(useGridConfig().currentWidgetsLayout, widget.cell_id!);
    },

    setSelectedCategoryOrGroup(value: DropdownSearchItem): void {
      if (!value) {
        this.selectedCategoryOrGroup = null;
        return;
      }

      this.selectedCategoryOrGroup = {
        id: value.id,
        type: value.type,
      };

      this.initialWidgetsLoading = true;
      this.fetchWidgetsList().finally(() => {
        this.initialWidgetsLoading = false;
      });
    },

    replaceGroup(index: number, group: ICommonMenuItem): void {
      this.groups.splice(index, 1, group);
    },

    fetchAvailableGroups(): Promise<void> {
      return http.get("/widget-groups").then((response: IGroupResponse) => {
        const groups: ICommonMenuItem[] = response.data.map<ICommonMenuItem>(
          group => {
            return {
              label: group.name,
              value: group.uuid,
              id: group.uuid,
              children: [],
              type: SearchDropdownType.GROUP,
            };
          }
        );

        this.groups = groups;
      });
    },

    async fetchWidgetsList(title: string = ""): Promise<void> {
      let params = `${this.selectedCategoryOrGroup?.type}=${this.selectedCategoryOrGroup?.id}&`;

      if (title) {
        params += `title=${title}`;
      }

      return http
        .get(`/widgets/search?${params}`)
        .then(async (response: IWidgetResponse) => {
          this.availableWidgets = response.data.items.map(widgetItem => {
            return {
              uuid: widgetItem.uuid,
              name: widgetItem.name,
              title: widgetItem.title,
              value: widgetItem.uuid,
              content: widgetItem.content,
            };
          });
          /*
            This code is used for creating new widgets with mock data
          */
          if (
            import.meta.env.DEV ||
            import.meta.env.MODE.toLowerCase() === "development"
          ) {
            const emptyFakeWidget = {
              "name": "FakeWidget",
              "title": "Fake Widget Boilerplate",
              "uuid": "12345678-50e4-7e76-baad-3b96cb8516f22",
              "value": "12345678-50e4-7e76-baad-3b96cb8516f22",
              "content": {},
            };

            this.availableWidgets = [emptyFakeWidget, ...this.availableWidgets];
          }
        });
    },

    fetchCategories(name: string): Promise<void> {
      return http
        .get(`/widget-categories/search?name=${name}`)
        .then((response: ICategoryResponse) => {
          response.data.forEach(category => {
            const groupIndex = this.groups.findIndex(
              el => el.id === category.group_uuid
            );

            if (groupIndex === -1) {
              return;
            }

            const group = this.groups[groupIndex];

            const children: ICommonMenuItem[] = [...(group.children || [])];

            const exists = children.find(el => el.id === category.uuid);

            if (exists) {
              return;
            }

            const menuItem: ICommonMenuItem = {
              label: category.name,
              value: category.uuid,
              id: category.uuid,
              type: SearchDropdownType.CATEGORY,
            };

            children.push(menuItem);

            this.replaceGroup(groupIndex, {
              ...group,
              children,
            });
          });
        });
    },

    setDragTargetEmptyWidgetCell(cell: ICell | null): void {
      this.dragTargetEmptyWidgetCell = cell;
    },

    setCurrentDraggingWidgetId(id: string | number | null): void {
      this.currentDraggingWidgetId = id;
    },

    updateCellWidgetsPositions(cell: ICell, startIdx: number): void {
      cell.widgets?.slice(startIdx).forEach(widget => {
        widget.position = startIdx++;
      });
    },

    duplicateWidget(widget: IWidgetWithFields, cell: ICell): void {
      const widgetIdx = cell.widgets?.findIndex(
        currWidget => currWidget.id === widget.id
      );

      if (typeof widgetIdx !== "number" || widgetIdx < 0) {
        return;
      }

      cell.widgets?.splice(
        widgetIdx + 1,
        0,
        deepCopyWidgetWithFields(cell.widgets[widgetIdx])
      );

      this.updateCellWidgetsPositions(cell, widgetIdx);
    },

    deleteWidget(widget: IWidgetWithFields, cell: ICell): void {
      const widgetIdx = cell.widgets?.findIndex(
        currWidget => currWidget.id === widget.id
      );

      if (typeof widgetIdx !== "number" || widgetIdx < 0) {
        return;
      }

      cell.widgets = cell.widgets?.filter(
        currWidget => currWidget.id !== widget.id
      );

      this.updateCellWidgetsPositions(cell, widgetIdx);
    },

    replaceWidgetByAnotherWidget(args: {
      cell: ICell;
      widgetArrayIndex: number;
      newWidget: IWidgetWithFields;
    }): void {
      if (
        !Array.isArray(args.cell.widgets) &&
        !args.cell.widgets?.[args.widgetArrayIndex]
      ) {
        throw new Error(
          `Widget with index "${args.widgetArrayIndex}" in cell "${args.cell.cellId}" not found`
        );
      }

      const oldWidget = args.cell.widgets[args.widgetArrayIndex];

      args.newWidget.cell_id = oldWidget.cell_id;
      args.newWidget.parent_id = oldWidget.parent_id;
      args.newWidget.page_template_id = oldWidget.page_template_id;
      args.newWidget.position = oldWidget.position;

      args.cell.widgets[args.widgetArrayIndex] = args.newWidget;

      const insertedWidget = args.cell.widgets[args.widgetArrayIndex];

      useWidgetSettingsStore().setActiveElement(
        insertedWidget,
        null,
        args.cell
      );
    },

    removeWidget(
      cell: ICell,
      widgetId: string | number
    ): IWidgetWithFields | undefined {
      const widgetIndex = cell.widgets!.findIndex(w => w.id === widgetId);

      this.updateCellWidgetsPositions(cell, widgetIndex);

      return cell.widgets?.splice(widgetIndex, 1)[0];
    },

    async addWidget(
      cell: ICell,
      widget: IWidgetWithFields,
      insertIndex: number,
      isGridElement: boolean
    ): Promise<void> {
      if (!cell.widgets?.length) cell.widgets = [];

      const widgetCopy = deepCopy(widget);

      /*
        Fetch details if widget was dragged from sidebar
      */

      if (!isGridElement) {
        const { fields } = await this.fetchWidgetDetails(
          widgetCopy.id,
          widgetCopy.static_id
        );

        widgetCopy.fields = fields
          ? Object.values(fields).map(field => {
              const upcastedField = field as IWidgetField;

              let name = upcastedField.name;
              const options = upcastedField.options || {};

              // If no id, field is coming from DnD
              if (typeof upcastedField.id === "undefined") {
                if (upcastedField.type === "FormField") {
                  name = "form";
                }

                options._active = true;
              }

              return useLocalisedField().toLocalised({
                ...upcastedField,
                name,
                options,
                parent_id: cell.cellId,
                // TODO: remove it after BE fix (need to add `id` field to `WidgetField` entity)
                id: generateId(),
              });
            })
          : [];
      }

      cell.widgets?.splice(insertIndex, 0, widgetCopy);

      this.updateCellWidgetsPositions(cell, insertIndex);

      const insertedWidget = cell.widgets[insertIndex];

      useWidgetSettingsStore().setActiveElement(insertedWidget, null, cell);
    },

    fetchWidgetDetails(
      widgetId: string | number,
      widgetStaticId: string
    ): Promise<Partial<IPageWidgetDetails>> {
      const config = {
        headers: {
          "Accept-Language": languagesStore.currentLanguage?.codeWithRegion,
        },
      };
      // FIXME: this code duplicating object
      // const cacheData =
      //   this.widgetsDetailsCache[metaStore.interfaceLang!]?.[widgetStaticId];

      // if (cacheData) {
      //   return Promise.resolve(cacheData);
      // }

      this.isLoadingWidgetId = widgetId;

      /*
        parseId returns numeric id for newly added widgets
        and string id for existing widgets.
      */
      const query =
        typeof parseId(widgetId) === "string"
          ? `id=${widgetId}`
          : `static_id=${widgetStaticId}`;

      return http
        .get(`widgets/info?${query}`, config)
        .then((res: IPageWidgetResponse) => {
          const data = res.data;

          const fields = (data.fields || []).reduce((result, field) => {
            result[field.name] = useLocalisedField().toLocalised({
              ...field,
              default_value: field.default_value,
              hint: field.hint,
              name: field.name,
              title: field.title,
              placeholder: field.placeholder,
              options: field.options || {
                bindingParams: {},
              },
              value: field.value || field.default_value,
              type: field.type,
            });

            return result;
          }, {} as Record<string, IPageFieldDetails>);

          const currLangData = {
            ...(this.widgetsDetailsCache[metaStore.interfaceLang!] || {}),
          };

          this.widgetsDetailsCache = {
            [metaStore.interfaceLang!]: {
              ...currLangData,
              [data.staticId]: {
                fields,
                data,
              },
            },
          };

          return { fields, name: data.name };
        })
        .catch(() => {
          return { fields: undefined };
        })
        .finally(() => {
          this.isLoadingWidgetId = null;
        });
    },

    contentImageUpload(
      formData: IFormDataContentImageDTO
    ): Promise<IContentImageUploadResponse> {
      const pagesStore = usePagesStore();

      const runtimeForm = new RuntimeForm(formData).formData();

      return http.post(
        `/widgets/file-upload/${pagesStore.selectedPageId}`,
        runtimeForm
      );
    },

    mediaUpload(
      formData: IFormDataBackgroundImageDTO
    ): Promise<IMediaUploadResponse> {
      const runtimeForm = new RuntimeForm(formData).formData();

      return http.post("/projects/media-upload", runtimeForm);
    },

    async fetchSportList(): Promise<void> {
      const config = {
        headers: {
          "Accept-Language": languagesStore.currentLanguage?.codeWithRegion,
        },
      };

      const { data } = await http.get("/betting/sports", config);
      this.sportsItems = data.items;
    },
  },
});
