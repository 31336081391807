import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useTimezonesItems } from "~~/composables/widgets/timezones/useTimezonesItems";
import { useDropdownContainerControls } from "~~/composables/widgets/timezones/useDropdownContainerControls";
import { useDropdownMenuControls } from "~~/composables/widgets/timezones/useDropdownMenuControls";
import { useSearchFieldControls } from "~~/composables/widgets/timezones/useSearchFieldControls";
import { useMenuItemsControls } from "~~/composables/widgets/timezones/useMenuItemsControls";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useTimezonesConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useTimezonesItems(widget);

  const {
    dropdownContainerDesignControls,
    dropdownContainerContentControls,
    dropdownContainerStatesControls,
  } = useDropdownContainerControls(widget, states, emit);

  const { dropdownMenuControls } = useDropdownMenuControls(widget);

  const {
    searchFieldDesignControls,
    searchFieldContentControls,
    searchFieldStatesControls,
  } = useSearchFieldControls(widget, states, emit);

  const {
    menuItemsDesignControls,
    menuItemsContentControls,
    menuItemsStatesControls,
  } = useMenuItemsControls(widget, states, emit);

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigCornerRadius"],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.display",
        valueSource: widget.value,
        options: {
          label: "Display mode",
          showTitle: false,
          isBold: true,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */

    widgetDesignControls,
    widgetContentControls,

    dropdownContainerDesignControls,
    dropdownContainerContentControls,
    dropdownContainerStatesControls,

    dropdownMenuControls,

    searchFieldDesignControls,
    searchFieldContentControls,
    searchFieldStatesControls,

    menuItemsDesignControls,
    menuItemsContentControls,
    menuItemsStatesControls,
  };
};
