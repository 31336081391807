import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidthConfig } from "../common/useWidthConfig";
import { IFields } from "../useWidgetFields";

import { useBetsListItems } from "./useBetsListItems";

export const useBetsListWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedWidget } = storeToRefs(widgetSettingsStore);
  const { isWidthConfigDisabled } = useWidthConfig();
  const { WIDGET_CONTENT_ITEMS } = useBetsListItems();

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: widget.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: widget.value,
            options: {
              label: "Margin",
              meta: {
                isBold: true,
              },
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "content/ConfigTextInputWithSettings",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "config-group-last-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          itemName: "title",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: WIDGET_CONTENT_ITEMS,
        },
      },
    ];
  });

  const widgetDataControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Sports"),
        controls: [
          {
            componentPath: "ConfigItemsMultiSelect",
            valuePath: "options.bindingParams.sportIds.value",
            valueSource: selectedWidget.value,
          },
        ],
      },
      {
        section: generateContentSectionName("Events"),
        controls: [
          {
            componentPath: "content/ConfigNumberInput",
            valuePath: "options.bindingParams.maxEvents.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Maximum number of events",
            },
          },
          {
            componentPath: "common/ConfigCommonSelect",
            valuePath: "options.bindingParams.sorting.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Sorting",
              selectOptions: [
                {
                  label: "By date",
                  value: "by_date",
                },
                {
                  label: "By price",
                  value: "by_price",
                },
                {
                  label: "By popularity",
                  value: "by_popular",
                },
              ],
            },
          },
          {
            componentPath: "content/ConfigNumberInput",
            valuePath: "options.bindingParams.eventsInterval.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Time interval for events",
              suffixContent: "hours",
              showTooltip: true,
              tooltipContent:
                "If enter 6(h), in the Front-End App will be displayed events which will take place in the next 6 hours.",
            },
          },
        ],
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,
  };
};
