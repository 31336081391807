<template>
  <div
    v-if="hasDesignPermissions"
    class="config-border widget-control-container p-t-16 p-b-16"
  >
    <ConfigColorPickerInput
      :model-value="modelValue.fill"
      :is-bold="isBold"
      :label="label || ''"
      class="config-border__color"
      :class="{
        'config-border__color--has-value': modelValue.fill.color,
      }"
      :type="ColorPickerType.BACKGROUND"
      @update:model-value="handleUpdateColor"
    />

    <div
      v-if="modelValue.fill.color"
      class="config-border__params"
    >
      <ConfigRadioTextElements
        :model-value="modelValue.style"
        :items="[
          { label: 'Solid', value: BorderStyle.SOLID },
          { label: 'Dashed', value: BorderStyle.DOTTED },
        ]"
        class="border-bottom-none p-b-0"
        label="Style"
        @update:model-value="handleUpdateStyleValue('style', $event)"
      />

      <ConfigRadioTextElements
        :model-value="modelValue.stroke.type"
        :items="[
          { label: 'Dependent', value: BorderStrokeType.DEPENDENT },
          { label: 'Custom', value: BorderStrokeType.CUSTOM },
        ]"
        class="border-bottom-none p-t-0 p-b-0"
        label="Stroke"
        @update:model-value="handleUpdateStrokeType($event)"
      />

      <div
        v-if="modelValue.stroke.type === BorderStrokeType.DEPENDENT"
        class="p-l-16 p-r-16 p-t-8 config-border__input-container"
      >
        <span
          class="config-border__square-element config-border__square-element--single"
        >
        </span>
        <a-input-number
          v-number
          :value="modelValue.stroke?.width"
          :min="0"
          :max="MAX_NUMBER_INPUT_VALUE"
          :controls="false"
          placeholder="None"
          size="small"
          class="widget-control__input config-border__input"
          @update:value="handleUpdateStrokeWidth($event)"
        />
      </div>

      <div
        v-if="modelValue.stroke.type === BorderStrokeType.CUSTOM"
        class="p-l-16 p-r-16 p-t-8 config-border__inputs-container"
      >
        <div class="config-border__input-container">
          <span
            class="config-border__square-element config-border__square-element--top-left"
          >
          </span>

          <a-input-number
            v-number
            :value="(modelValue.stroke.width as IBorderWidthComplex).left"
            :min="0"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input config-border__input"
            @update:value="handleUpdateStrokeWidth({ left: $event })"
          />
        </div>

        <div class="config-border__input-container">
          <span
            class="config-border__square-element config-border__square-element--top-right"
          >
          </span>
          <a-input-number
            v-number
            :value="(modelValue.stroke.width as IBorderWidthComplex).top"
            :min="0"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input config-border__input"
            @update:value="handleUpdateStrokeWidth({ top: $event })"
          />
        </div>

        <div class="config-border__input-container">
          <span
            class="config-border__square-element config-border__square-element--bottom-right"
          >
          </span>
          <a-input-number
            v-number
            :value="(modelValue.stroke.width as IBorderWidthComplex).right"
            :min="0"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input config-border__input"
            @update:value="
              handleUpdateStrokeWidth({
                right: $event,
              })
            "
          />
        </div>

        <div class="config-border__input-container">
          <span
            class="config-border__square-element config-border__square-element--bottom-left"
          >
          </span>
          <a-input-number
            v-number
            :value="(modelValue.stroke.width as IBorderWidthComplex).bottom"
            :min="0"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input config-border__input"
            @update:value="
              handleUpdateStrokeWidth({
                bottom: $event,
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  BorderStyle,
  IBorderControl,
  IFillControl,
  IBorderWidthComplex,
  BorderStrokeType,
} from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: IBorderControl;
    label?: string;
    isBold?: boolean;
  }>(),
  {
    label: "Border",
  }
);

const emit = defineEmits<{
  (e: "update:model-value", value: IBorderControl): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdateStyleValue = (propertyName: string, value: any) => {
  emit("update:model-value", {
    ...props.modelValue,
    [propertyName]: value,
  });
};

const handleUpdateStrokeWidth = (value: any): void => {
  if (typeof value === "object") {
    const currWidth = props.modelValue?.stroke?.width || {};

    handleUpdateStyleValue("stroke", {
      ...props.modelValue.stroke,
      width: {
        ...currWidth,
        ...value,
      },
    });

    return;
  }

  handleUpdateStyleValue("stroke", {
    ...props.modelValue.stroke,
    width: value,
  });

  return;
};

const handleUpdateStrokeType = (value: BorderStrokeType): void => {
  if (value === BorderStrokeType.DEPENDENT) {
    handleUpdateStyleValue("stroke", {
      type: BorderStrokeType.DEPENDENT,
      width: 1,
    });
    return;
  }

  handleUpdateStyleValue("stroke", {
    type: BorderStrokeType.CUSTOM,
    width: {
      left: 1,
      right: 1,
      top: 1,
      bottom: 1,
    },
  });
};

const handleUpdateColor = (fill: IFillControl) => {
  if (!fill.color || !fill.opacity) {
    emit("update:model-value", {
      ...props.modelValue,
      fill,
    });
    return;
  }

  emit("update:model-value", {
    ...props.modelValue,
    fill,
  });
};
</script>

<style lang="scss">
.config-border {
  &__params {
    @include flex-column(stretch, flex-start, 5px);
  }

  &__input {
    width: 100% !important;
  }

  &__input-container {
    @include flex(center, flex-start, 6px);
  }

  &__inputs-container {
    @include flex(center, flex-start, 4px);
    flex-wrap: wrap;

    & > div {
      flex-basis: calc(50% - 2px);
    }
  }

  &__input-icon {
    color: $c-grey-45;
    font-size: 24px;
  }

  &__styling {
    flex-basis: 50%;
  }

  &__color {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;

    &--has-value {
      padding-bottom: 0;
    }
  }

  &__square-element {
    min-width: 19px;
    min-height: 19px;
    display: inline-block;
    border: 2px dotted $c-grey-45;
    position: relative;

    &--single {
      border-style: solid;
    }

    &--top-left {
      border-left-style: solid;
    }

    &--top-right {
      border-top-style: solid;
    }

    &--bottom-right {
      border-right-style: solid;
    }

    &--bottom-left {
      border-bottom-style: solid;
    }
  }
}
</style>
