<template>
  <div class="sport-menu-live-bet9-racing-card">
    <WidgetField
      v-if="streamIconField.options._active"
      :widget="widget"
      :field="streamIconField"
      :style="streamIconStyles"
    >
      <CommonIcon
        :size="streamIconField.options.iconSize"
        :name="streamIconField.options.value"
        :style="streamIconStyles"
      />
    </WidgetField>

    <WidgetField
      class="sport-menu-live-bet9-racing-card__event-name"
      :field="eventNameField"
      :widget="widget"
    >
      <BasicTitleField
        :field="eventNameField"
        :value="data.eventName"
        :state="state"
      />
    </WidgetField>

    <WidgetField
      v-if="timerField"
      :field="timerField"
      :widget="widget"
    >
      <BasicTitleField
        :field="timerField"
        :state="state"
      >
        00:00
      </BasicTitleField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="eventStatusField"
      :style="statusStyles"
    >
      <SportsBookLiveSuspended
        :field="eventStatusField"
        :value="data.status"
        :state="state"
      />
    </WidgetField>

    <WidgetField
      v-if="isFavoriteIconVisible"
      :widget="widget"
      :field="favoriteIconField"
      :style="favoriteIconStyles"
    >
      <CommonIcon
        :size="favoriteIconField.options.iconSize"
        :name="iconName || favoriteIconField.options.value"
      />
    </WidgetField>
  </div>
</template>

<script setup lang="ts">
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getColorStyle } from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  data: {
    status: string;
    eventName: string;
  };
  widget: IWidgetWithFields;
  cardField: IWidgetField;
  favoriteIconField: IWidgetField;
  eventStatusField: IWidgetField;
  streamIconField: IWidgetField;
  eventNameField: IWidgetField;
  timerField?: IWidgetField;
  state: State;
  iconName?: string;
}>();

const isFavoriteIconVisible = computed(
  () => props.favoriteIconField.options._active
);

const favoriteIconStyles = computed(() => {
  return {
    ...getColorStyle(
      props.state === State.DEFAULT
        ? props.favoriteIconField.options.color
        : props.favoriteIconField.options.states[props.state].color
    ),
    "marginLeft": "auto",
    "flexShrink": 0,
  };
});

const statusStyles = computed(() => {
  return {};
});

const streamIconStyles = computed(() => {
  return getColorStyle(
    props.state === State.DEFAULT
      ? props.streamIconField.options.color
      : props.streamIconField.options.states[props.state].color
  );
});
</script>

<style lang="scss">
.sport-menu-live-bet9-racing-card {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  &__event-name {
    flex-grow: 1;
    min-width: 3ch;
  }
}
</style>
