<template>
  <div
    class="racing-sports-table-cards"
    :style="containerStyles"
    :class="{
      'racing-sports-table-cards--wrap': headerTitles,
      'racing-sports-table-cards--table': mode === 'table',
      'racing-sports-table-cards--is-mobile':
        activeScreen === Breakpoint.MOBILE,
    }"
  >
    <WidgetField
      v-if="headerField.options._active"
      :widget="widget"
      :field="headerField"
    >
      <div
        :style="headerStyles"
        class="racing-sports-table-cards__header"
      >
        <p
          v-for="headerItem in headerList"
          :key="headerItem.name"
          :style="{
            ...headerTextStyles,
            width: headerItem.width ? `${headerItem.width}px` : '',
            maxWidth: headerItem.width ? `${headerItem.width}px` : '',
          }"
          class="racing-sports-table-cards__value"
          :class="{
            'racing-sports-table-cards__value--fill': !headerItem.width,
          }"
        >
          {{ headerItem.label }}
        </p>
      </div>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="rowsField"
    >
      <div
        v-if="mode === 'wrap' && sections"
        :style="containerStyles"
        class="racing-sports-table-cards__list"
      >
        <div
          v-for="(row, index) in rows"
          :key="index"
          :style="cardStyles"
          class="racing-sports-table-cards__element"
        >
          <div
            v-for="headerKey in headerTitles"
            :key="headerKey"
            :style="itemsDistanceStyle"
            class="racing-sports-table-cards__column"
          >
            <div
              v-for="elementName in sections[headerKey]"
              :key="elementName"
              :style="{
                width: headerKeys[elementName].width
                  ? `${headerKeys[elementName].width}px`
                  : '',
                maxWidth: headerKeys[elementName].width
                  ? `${headerKeys[elementName].width}px`
                  : '',
              }"
              :class="{
                'racing-sports-table-cards__value--fill':
                  !headerKeys[elementName].width,
              }"
              class="racing-sports-table-cards__value"
            >
              <slot
                :name="elementName"
                :value="row[elementName]"
                >{{ row[elementName] }}
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        :style="containerStyles"
        class="racing-sports-table-cards__list"
      >
        <div
          v-for="(row, index) in rows"
          :key="index"
          :style="{
            ...cardStyles,
            ...itemsDistanceStyle,
          }"
          class="racing-sports-table-cards__element"
        >
          <div
            v-for="headerItem in tableHeader"
            :key="headerItem.name"
            :class="{
              'racing-sports-table-cards__value--fill': !headerItem.width,
            }"
            class="racing-sports-table-cards__column"
          >
            <div
              :style="{
                width: headerItem.width ? `${headerItem.width}px` : '',
                maxWidth: headerItem.width ? `${headerItem.width}px` : '',
              }"
              class="racing-sports-table-cards__value"
            >
              <slot
                :name="headerItem.name"
                :value="row[headerItem.name]"
                >{{ row[headerItem.name] }}
              </slot>
            </div>
          </div>
        </div>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { ElementStyle } from "~~/models/common";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getTextAlignStyle } from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { useUiStore } from "~~/store/ui";
import { Breakpoint } from "~~/models/breakpoints.model";

type TableHeader = {
  label: string;
  name: string;
  width?: number;
};

type Row = Record<string, string>;

const props = defineProps<{
  widget: IWidgetWithFields;
  tableHeader: TableHeader[];
  rows: Row[];
  field: IWidgetField;
  headerField: IWidgetField;
  rowsField: IWidgetField;
  headerTitles?: string[];
  sections?: Record<string, string[]>;
  mode: string;
  states: Record<string, State>;
}>();

const { activeScreen } = storeToRefs(useUiStore());

const headerKeys = computed<Record<string, TableHeader>>(() => {
  return props.tableHeader.reduce((result, curr) => {
    return {
      ...result,
      [curr.name]: curr,
    };
  }, {});
});

const itemsDistanceStyle = computed<ElementStyle>(() => {
  const distance = props.field.options.displayMode.distanceBetweenItems;

  return {
    gap: getPxValueFromNumber(distance),
  };
});

const widgetSpacingVars = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      pl: getPxValueFromNumber(props.widget.options.spacing.padding.left),
      pr: getPxValueFromNumber(props.widget.options.spacing.padding.right),
      ml: getPxValueFromNumber(props.widget.options.spacing.margin.left),
      mr: getPxValueFromNumber(props.widget.options.spacing.margin.right),
    },
    "racing"
  );
});

const containerStyles = computed<ElementStyle>(() => {
  const distance = props.field.options.displayMode.distanceBetweenCards;

  return {
    gap: getPxValueFromNumber(distance),
    ...widgetSpacingVars.value,
  };
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.headerField.options),
    ...itemsDistanceStyle.value,
  };
});

const headerTextStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(props.headerField.options),
    ...getTextAlignStyle(props.headerField.options.alignment),
  };
});

const cardStyles = computed<ElementStyle>(() => {
  if (props.states[props.rowsField.name] === State.DEFAULT) {
    return getCommonStyles(props.rowsField.options);
  }

  return getCommonStyles({
    ...props.rowsField.options,
    ...props.rowsField.options.states[props.states[props.rowsField.name]],
  });
});

const headerList = computed<TableHeader[]>(() => {
  if (!props.headerTitles) {
    return props.tableHeader;
  }

  return props.tableHeader.filter(item =>
    (props.headerTitles || []).includes(item.name)
  );
});
</script>

<style lang="scss">
$name: "racing-sports-table-cards";

.#{$name} {
  @include flex-column(stretch, flex-start, 0);

  &--is-mobile {
    max-width: calc(
      $sm-larger - 4px -
        (
          var(--racing-pl) + var(--racing-pr) + var(--racing-mr) +
            var(--racing-ml)
        )
    );
  }

  &__header {
    @include flex-column(flex-start, flex-start, 0);
  }

  &__element {
    @include flex-column(flex-start, flex-start, 0);
  }

  &__list {
    @include flex-column(stretch, flex-start, 0);
  }

  &__value {
    overflow: hidden;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--fill {
      flex: 1;
    }
  }

  &--wrap {
    .#{$name}__header {
      flex-direction: row;
      justify-content: space-between;
    }

    .#{$name}__element {
      flex-direction: row;
      justify-content: space-between;
    }

    .#{$name}__column {
      @include flex-column(flex-start, flex-start, 0);
    }
  }

  &--table {
    .#{$name}__header {
      flex-direction: row;
      justify-content: flex-start;
    }

    .#{$name}__element {
      flex-direction: row;
    }
  }
}
</style>
