import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";

import {
  generateMenuItemCssString,
  generateSportsMenuWidgetSpacingVarsCssString,
} from "./sports-menu-widget-css";

export const generatePopularLeaguesWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const menu_level_1 = widget.content["menu_level_1"];
  const no_events_message = widget.content["no_events_message"];

  widget.options._cssClass = generateClassName("PopularLeaguesWidget");
  menu_level_1.options._cssClass = generateClassName("menu_level_1");
  no_events_message.options._cssClass = generateClassName("no_events_message");

  if (title) {
    title.options._cssClass = generateClassName("title");
  }

  menu_level_1.options.itemIcon._cssClass = generateClassName("itemIcon");

  const titleCss = title ? [generateStringDefault(title)] : [];

  return pipeSync<string>(
    generateStringDefault(widget),
    generateMenuItemCssString(menu_level_1 as IWidgetField),
    generateSportsMenuWidgetSpacingVarsCssString(widget.options),
    generateStringDefault(no_events_message),
    ...titleCss
  )("");
};
