import { LinkType } from "~~/models/widgets/widget-controls.model";

function isURL(url: string): boolean {
  try {
    const urlTest = new URL(url);

    return Boolean(urlTest);
  } catch (e) {
    // URL constructor throws error if link not valid
    return false;
  }
}

type IValidator = (maybeLink: string) => boolean;

const linkValidator: IValidator = (maybeLink: string): boolean => {
  const isLinkToResource =
    maybeLink.startsWith("http:") ||
    maybeLink.startsWith("https:") ||
    maybeLink.startsWith("/");

  if (!isLinkToResource) {
    return false;
  }

  if (isURL(maybeLink)) {
    return true;
  }

  const isInternalLink = maybeLink.startsWith("/");

  const internalToAbsolute = `https://domain.com/${maybeLink}`; // URL constructor method can work only with absolute URLs

  return isInternalLink && isURL(internalToAbsolute);
};

const emailValidator: IValidator = (maybeEmail: string): boolean => {
  const validator = new RegExp(
    /^([a-zA-Z0-9.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
  );

  return validator.test(maybeEmail);
};

const phoneNumberValidator: IValidator = (
  maybePhoneNumber: string
): boolean => {
  const validator = new RegExp(/^\+[0-9]{1,}-?[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/);

  return validator.test(maybePhoneNumber);
};

const triggerValidator = (value: string) => {
  return Boolean(value);
};

const linkValidators: Record<LinkType, IValidator> = {
  [LinkType.EXTERNAL]: linkValidator,
  [LinkType.MODAL]: linkValidator,
  [LinkType.PHONE]: phoneNumberValidator,
  [LinkType.EMAIL]: emailValidator,
  [LinkType.TRIGGER]: triggerValidator,
};

/**
 * Checks if the link is valid
 * @param {string} value - string to check
 * @param {LinkType} type - link type to check
 * @returns `true`, if link is valid, else `false`
 */

export function isValidLink(value: string, type: LinkType): boolean {
  const validator = linkValidators[type];

  if (!validator) {
    // TODO: capture error to logger here (when error logging will be implemented)
    console.error("Link validator not found", type);
    return false;
  }

  return validator(value);
}
