import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  State,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultBorderValue,
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, State> = {
    dropdown_container: State.DEFAULT,
    menu_items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
  Initialize widget with initial options
*/

  const initialWidgetOptions = initWidgetOptions([], {
    display: { resizing: "hug", alignment: "left" },
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const dropdownStateValue = () => {
    return {
      fill: getDefaultFillValue(),
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
      text: {
        color: getDefaultFillValue("#000000"),
      },
      arrow: {
        textColor: getDefaultFillValue("#000000"),
      },
    };
  };

  const initWidgetFieldsOptions = (): void => {
    const initialDropdownContainerOptions = prefillEmptyOptions(
      fields.value.dropdown_container.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            text: {
              ...TITLE_INITIAL().options,
              theme: 13,
            },
            icon: {
              _active: true,
              iconSize: 12,
              gap: 12,
            },
            arrow: {
              _active: true,
              value: "Down",
              default_value: "ant-design:arrow-down-outlined",
              rotate: true,
              textColor: getDefaultFillValue("#000000"),
            },
          },
        },
        exclude: ["text.link", "text.alignment", "size"],
        additionalData: {
          options: {
            states: {
              [State.HOVER]: dropdownStateValue(),
              [State.ACTIVE]: dropdownStateValue(),
            },
          },
        },
        values: {
          cornerRadius: getDefaultCornerRadiusValue(4),
          fill: getDefaultFillValue("#ffffff"),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 10),
          }),
          spacing: {
            padding: getDefaultSpacing({
              left: 16,
              right: 16,
              top: 4,
              bottom: 4,
            }),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialMenuOptions = prefillEmptyOptions(
      fields.value.dropdown_menu.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size", "_isWidget"],
        additionalData: {
          options: {
            baseDisplaySettings: {
              alignment: VerticalAlignment.TOP,
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          spacing: {
            padding: getDefaultSpacing(4),
            margin: getDefaultSpacing({
              top: 4,
            }),
          },
        },
      }).options
    );

    const dropdownMenuItemStateValue = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
      };
    };

    const initialDropdownMenuItemOptions = prefillEmptyOptions(
      fields.value.menu_items.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "spacing", "size"],
        additionalData: {
          options: {
            distance: 4,
            padding: getDefaultSpacing(),
            text: {
              ...TITLE_INITIAL().options,
              theme: 13,
            },
            icon: {
              _active: true,
              iconSize: 12,
              gap: 12,
            },
            states: {
              [State.HOVER]: dropdownMenuItemStateValue(),
              [State.ACTIVE]: dropdownMenuItemStateValue(),
            },
          },
        },
        values: {
          padding: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 4,
            bottom: 4,
          }),
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.dropdown_container.field,
      initialDropdownContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.dropdown_menu.field,
      initialMenuOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.menu_items.field,
      initialDropdownMenuItemOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,
    initFields,
    initWidgetFieldsOptions,
  };
};
