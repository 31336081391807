import { CellId } from "~~/models/grid.interface";
import { IDraggable } from "~~/models/dnd.model";
import { InnerFieldValue } from "~~/models/page.model";
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";

export enum SettingType {
  VISUAL = "visual",
  FUNCTIONAL = "functional",
}

export enum SettingCategory {
  DESIGN = "design",
  CONTENT = "content",
}

export enum SettingInputType {
  DROPDOWN = "dropdown",
  INPUT = "input",
}

export interface IDropdownValue {
  value: string | number | boolean;
  label: string;
}

export enum WidgetMode {
  SIDEBAR = "sidebar",
  WORKSPACE = "workspace",
}

export enum WidgetType {
  ELEMENT = "element",
  BASIC = "basic",
  BETTING = "betting",
}

export enum ColorPickerType {
  TEXT = "TextColor",
  BACKGROUND = "BackgroundColor",
  BORDER = "BorderColor",
}

export interface IWidgetFieldOption {
  initialValue: { [key: string]: string | number };
  configControlComponent: string;
  type?: ColorPickerType;
}

/* NEW MODELS */

export enum WidgetDefaultSettingsDropdownItem {
  WIDGET_SETTINGS = "widgetSettings",
  PARENT_SETTINGS = "parentSettings",
  CELL_SETTINGS = "cellSettings",
  CHILD_SETTINGS = "childSettings",
}

const SOURCES = ["pageRoute", "custom", "widgetData"] as const;

type ISource = (typeof SOURCES)[number];

export type IRawWidgetBindingParam =
  | IWidgetBindingPageRouteParam
  | IWidgetBindingCustomParam
  | IWidgetBindingWidgetDataParam;

export type IWidgetBindingParam = IRawWidgetBindingParam & {
  label: string;
  sources: ISource[];
  description?: string;
  required?: boolean;
  children?: Record<string, IWidgetBindingParamsObject>;
  isHidden?: boolean;
};

export type IWidgetBindingParamsObject = Record<string, IWidgetBindingParam>;

export interface IWidgetBindingPageRouteParam {
  source: "pageRoute";
  value: number | null;
}

export interface IWidgetBindingCustomParam {
  source: "custom";
  value: Record<string, any> | null;
}

export interface IWidgetBindingWidgetDataParam {
  source: "widgetData";
  value: {
    entity_type: string;
    entity_variable: string;
  } | null;
}

export interface ICustomField extends IWidgetField {
  fields?: IWidgetField[];
}

export interface IWidgetFieldItem {
  label: Record<string, string>;
  value: any;
}

/* In most of the cases widget `fields` will be mapped to widget elements;
but they also can exist indepenenlty, just keeping some widget setting value */
export interface IWidgetField {
  id: number | string;
  parent_id: number | string; // identifier for parent widget
  type: string; // TextField, FileUploadField, DropdownField etc. (FE can decide what to put here)
  name: string; // input field identifier; sometimes BE uses it as a validation type (in the context of current widget)
  title?: string; // translated label
  placeholder?: string; // input field attribute
  hint?: string | null; // optional; e.g. for photo input - info about max size allowed or file formats
  default_value?: string | number | ICustomField[] | InnerFieldValue; // value which will be displayed by default and used on empty widget initialization, e.g. on adding it to grid
  value?:
    | string
    | number
    | ICustomField[]
    | InnerFieldValue
    | null
    | Record<string, any>; // value of field
  items?: IWidgetFieldItem[];
  form?: ICustomField[];
  validation: {
    required?: boolean;
    max?: number; // max length of text input
    maxSize?: number; // max file size for upload input
    accept?: string[]; // accepted formats for upload input
  };
  readonly?: boolean;
  // options are object that will be stored on BE as JSON and containing style settings for a component
  options: {
    _active?: boolean; // switch to know if we need to show this field
    _custom?: boolean; // field, that stores data only on FE side. Saved inside widget.options._customFields

    // TODO review options types
    [key: string]: any;
    // [key: string]: IWidgetFieldOption;
  };
}

/* TODO remove */
export enum WidgetControlType {
  FIELD = "field",
  OPTION = "option",
  WIDGET_OPTION = "widget_option",
}

export interface IWidgetOptions {
  bindingParams?: IWidgetBindingParamsObject;
  [key: string]: any;
}

export interface IWidgetMetaOptions {
  [key: string]: IWidgetOptions;
}

export interface IWidgetMetaFieldsOptions {
  [key: string]: IWidgetMetaOptions;
}

export interface IWidgetSettingsTabs {
  [tabName: string]: {
    label: string;
    dropdownItem: string;
  };
}
export interface BaseDropdownItem {
  label: string;
  // when we need to show dropown item without ability to change to other dropdown items
  isSingle?: boolean;

  /*
    Open field settings on
    current dropdown item close
  */
  parentFieldName?: string;
}

/*
  TODO REMOVE
*/
export interface IWidgetSettingsMetaData {
  [key: string]: any;
}
export interface IWidgetMetaField {
  _customFieldsNames?: string[];
  _isDropdownDynamic?: boolean;
  /*
    Render field settings by field type
    (instead of field name)
  */
  _renderSettingsByType?: boolean;
}

/*
  TODO REMOVE
*/
export interface IWidgetMetaData {
  [key: string]: any;
}

/*
  TODO REMOVE
*/
export interface IWidgetMeta {
  [key: string]: IWidgetMetaData;
}

/* This is what we can get on requesting individual widget data by id when user selects it from a grid */
export interface IWidgetWithFields {
  id: string | number; // unique identifier for a widget in connection to a page and a grid cell
  static_id: string; // identifier of an abstract widget entity from BE; maps to a widget type, like TextWidget, CardWidget etc
  parent_id: string | number | null; // identifier for parent widget if is part of composed widget
  widget_id: string; // static identifier for a widget (like TextWidget)
  page_template_id?: string;
  cell_id?: CellId;
  position: number; // for ordering widgets added into one cell
  name: string; // name associated with widget_id
  type: WidgetType;
  children?: IWidgetWithFields[]; // for composed widgets like TextWidget or Banner
  fields: IWidgetField[]; // matches to the settings controls the value of which should be validated on BE and (potentially) can be searchable in database
  /* Options object will be stored on BE as plain JSON; FE free to structure and use it in any suitable way */
  options: IWidgetOptions;
}

export type PageIdType = string | number | null;

export interface IWidgetDraggable extends IDraggable {
  draggingWidgetId: string | number;
  cellId: CellId;
  widgetListElement?: IWidgetsListElement;
}

export interface IWidgetFieldDraggable extends IDraggable {
  id: number;
}

export interface IWidgetFieldsDefaultData {
  label: string;
  type: string;
}

export enum IVisibleFor {
  AUTHORIZED = "authorized",
  UN_AUTHORIZED = "un_authorized",
}
