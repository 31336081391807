import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { Align, Overflow } from "~~/models/widgets/widget-controls.model";

import { Breakpoint } from "./breakpoints.model";
import { IDraggable } from "./dnd.model";

export enum BlockType {
  CONTAINER = "container",
  COLUMN = "column",
}

export enum Sizing {
  FIXED = "fixed",
  ADAPTIVE = "adaptive",
}

export enum Tag {
  HEADER = "header",
  FOOTER = "footer",
  ASIDE = "aside",
}

export enum NestingLevel {
  PARENT = 1,
  CHILD,
}

export type CellId = number | string;

export enum CellPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export interface ICellPosition {
  fixed: boolean;
  position: CellPosition;
}

export interface ICellOverflow {
  overflowX: Overflow;
}

export enum CellLayout {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
  WRAP = "wrap",
}

export enum Dimension {
  PX = "px",
  PERCENT = "%",
  VH = "vh",
  VW = "vw",
  SVH = "svh",
  SVW = "svw",
  CALC = "calc",
}

export interface ICellDimensionValue {
  value: string;
  type: Dimension;
}

export enum CellSizeType {
  FIXED = "fixed",
  FILL = "fill",
  HUG = "hug",
}
export interface ICellHeight {
  height?: ICellDimensionValue;
  type?: CellSizeType;
  isMax?: boolean;
  isMin?: boolean;
}

export interface ICellContentSettings {
  layout?: CellLayout;
  gap?: string;
  alignment?: Align;
  overflow?: ICellOverflow["overflowX"];
}

export interface ICellOptions {
  alignment?: string | null;
  margin?: {
    top?: number | string | null;
    bottom?: number | string | null;
    left?: number | string | null;
    right?: number | string | null;
  };
  spaceBetween?: number | null;
  backgroundColor?: {
    color: string;
    opacity: number;
  };
  childIds?: Array<CellId>;
  parentId?: CellId;
  position?: ICellPosition;
  maxHeight?: string;
  height?: ICellHeight;
  cellContentSettings?: ICellContentSettings;
}

export interface ICellSettings {
  sizing: Sizing;
  blockType?: BlockType;
  width?: number; // px for fixed | % for adaptive, with default 100%
  tag?: Tag;
  level?: NestingLevel;
  style?: ICellOptions;
}

export interface ICell {
  cellId: CellId;
  parentId: CellId | null;
  children: ICell[];
  settings: ICellSettings;
  widgets?: IWidgetWithFields[];
  position?: number;
}

export interface ICellNullable extends Omit<ICell, "cellId" | "children"> {
  cellId: CellId | null;
  children: ICellNullable[];
}

export interface IGridWidthParams {
  sizing: Sizing;
  width?: number;
}

export interface ICellDimension {
  top: number;
  left: number;
  height: number;
  bottom: number;
  width: number;
  cellId: CellId;
}

export type Grid = {
  [key in Breakpoint]?: ICell[];
};

export type GridRootDimension = {
  [key in Breakpoint]?: ICellDimension[];
};

export enum InsertionRule {
  BEFORE = 0,
  AFTER = 1,
}

export enum MoveDirection {
  UP = -1,
  DOWN = 1,
}

export interface IGridDraggable extends IDraggable {
  columns: number;
  draggingCellId: CellId;
}

export interface IInsertPosition {
  index: number;
  insertionRule: InsertionRule;
}

export interface ICreateCellSettings {
  columns: number;
  level: NestingLevel;
  indexToInsert?: number;
}

export type WidthParams = {
  sizing: Sizing;
  width: number | string;
};

export interface IParentData {
  parentId: CellId | null;
  parentCell?: ICell;
  parentSizing?: Sizing;
  parentWidth?: number;
}

export type TemplateQueryType = string | undefined | null;
