<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @blur-tab="$emit('reset-states')"
        @change="$emit('reset-states')"
        @close="$emit('reset-states')"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #event_card-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsDesignControls"
          />
        </template>

        <template #event_card-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsContentControls"
          />
        </template>

        <template #event_card-states>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsStatesControls"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #footer-design>
          <ControlsList
            :widget="widget"
            :controls="footerDesignControls"
          />
        </template>

        <template #footer-content>
          <ControlsList
            :widget="widget"
            :controls="footerContentControls"
          />
        </template>

        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="leagueNameControls"
          />
        </template>

        <template #event_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedStatusDesignControls"
          />
        </template>

        <template #event_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedStatusContentControls"
          />
        </template>

        <template #stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>

        <template #event_team_player>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_period>
          <ControlsList
            :widget="widget"
            :controls="eventPeriodControls"
          />
        </template>

        <template #event_score-design>
          <ControlsList
            :widget="widget"
            :controls="eventScoreDesignControls"
          />
        </template>

        <template #event_score-content>
          <ControlsList
            :widget="widget"
            :controls="eventScoreContentControls"
          />
        </template>

        <template #racing_event_card-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsDesignControls"
          />
        </template>

        <template #racing_event_card-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsContentControls"
          />
        </template>

        <template #racing_event_card-states>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsStatesControls"
          />
        </template>

        <template #racing_stream_icon>
          <ControlsList
            :widget="widget"
            :controls="racingStreamIconControls"
          />
        </template>

        <template #racing_event_name>
          <ControlsList
            :widget="widget"
            :controls="racingEventNameControls"
          />
        </template>

        <template #racing_event_status-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusDesignControls"
          />
        </template>

        <template #racing_event_status-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusContentControls"
          />
        </template>

        <template #racing_favourites_icon>
          <ControlsList
            :widget="widget"
            :controls="racingFavIconControls"
          />
        </template>

        <template #racing_event_timer>
          <ControlsList
            :widget="widget"
            :controls="racingEventTimerControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-design`"
          #[`${menuField}-design`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemDesignControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-content`"
          #[`${menuField}-content`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemContentControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-states`"
          #[`${menuField}-states`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/sports-menu/useSportsMenuWidgetConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  useWidgetDynamicParams,
  IParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  menuFields: Array<string>;
  isLive: boolean;
  isRacing?: boolean;
  isFenix?: boolean;
}>();

defineEmits<{
  (e: "reset-states"): void;
}>();

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  titleControls,
  widgetDesignControls,
  widgetContentControls,
  menuItemDesignControls,
  menuItemContentControls,
  menuItemStatesControls,
  sportEventCardsDesignControls,
  sportEventCardsContentControls,
  sportEventCardsStatesControls,
  headerDesignControls,
  headerContentControls,
  footerDesignControls,
  footerContentControls,
  favIconControls,
  leagueNameControls,
  eventSuspendedStatusDesignControls,
  eventSuspendedStatusContentControls,
  streamIconControls,
  eventNameControls,
  eventTimeControls,
  eventPeriodControls,
  eventScoreDesignControls,
  eventScoreContentControls,
  racingEventCardsDesignControls,
  racingEventCardsContentControls,
  racingEventCardsStatesControls,
  racingStreamIconControls,
  racingEventNameControls,
  racingEventStatusDesignControls,
  racingEventStatusContentControls,
  racingFavIconControls,
  racingEventTimerControls,
} = useConfig(
  ref(props.widget),
  states,
  props.isLive,
  props.isRacing,
  props.isFenix
);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const bindingParamsArg = computed<IParams>(() => {
  if (props.isFenix) {
    return {
      ...(props.isLive
        ? {
            "useEventsLength": {
              label: "Use events length",
              description: "Possible values: yes | no. Empty === yes",
              sources: ["custom"],
              required: false,
            },
          }
        : {}),
      "sportPageURL": {
        label: "Sport page URL",
        sources: ["custom"],
        required: true,
      },
      ...(props.isLive
        ? {}
        : {
            "leaguePageURL": {
              label: "League page URL",
              sources: ["custom"],
              required: false,
            },
          }),
      "sportItemFunction": {
        label: "Sport item function",
        description: "Possible values: link | collapser | both",
        sources: ["custom"],
        required: false,
      },
      ...(props.isLive
        ? {
            "eventPageURL": {
              label: "Event page URL",
              sources: ["custom"],
              required: false,
            },
          }
        : {
            "countryPageURL": {
              label: "Country page URL",
              sources: ["custom"],
              required: false,
            },
            "countryItemFunction": {
              label: "Country item function",
              description: "Possible values: link | collapser | both",
              sources: ["custom"],
              required: false,
            },
          }),
    } as IParams;
  }

  return {
    "leaguePageURL": {
      label: "League page URL",
      sources: ["custom"],
      required: true,
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});
</script>
