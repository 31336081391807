import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateStageButtonCssString,
  generateCardCssString,
  generateCheckboxCssString,
  generateDropdownMenuCssString,
  generateFormCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarWrapperCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateTextChooseIconCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
  generateDropdownMenuItemStyling,
  generateFlexibleImageCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFlexAlignment,
  generateFontSize,
  generateTextColor,
} from "../helpers";

export const generateStatusMessageCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const customContent = generateFlex({
      flex: "flex",
      justify: "unset",
      align:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? element.options.verticalAlignment
          : generateFlexAlignment(element.options.horizontalAlignment),
      direction:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "row"
          : "column",
      gap: element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: customContent,
    });

    const iconSettingsContent = [
      generateFontSize(element.options.icon.iconSize),
      generateTextColor(element.options.icon.textColor),
    ];

    cssString += generateCssClassWithContent({
      className: element.options.icon._cssClass,
      content: iconSettingsContent.join(""),
    });

    cssString += generateCssClassWithContent({
      className: element.options._textCssClass,
      content: "flex-grow:1;width:100%;",
    });

    return cssString;
  };

export const generateCustomCardStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " #widget-container",
      content: "max-width: 400px !important;",
    });

    return cssString;
  };

const additionalInfoCustomCss =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " p",
      content: "margin: 1em 0;",
    });

    return cssString;
  };

export const generatePaymentProviderFormFenixWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const formSubmitButton = widget.content["form_submit"];
  const formBackButton = widget.content["form_back_button"];
  const providerCard = widget.content["provider_card"];
  const additionalInfo = widget.content["additional_info"];
  const providerDescription = widget.content["provider_description_1"];
  const providerImage = widget.content["provider_image"];
  const providerTitle = widget.content["provider_title"];
  const successMessage = widget.content["success_message"];
  const failMessage = widget.content["fail_message"];
  const pendingMessage = widget.content["pending_message"];
  const depositFormDescription = widget.content["deposit_form_description"];
  const form = widget.content["form"];
  const customFields = widget.options._customFields;

  widget.options._cssClass = generateClassName("PaymentProviderFormFenix");

  formBackButton.options._cssClass = generateClassName("formBackButton");
  formBackButton.options.iconSettings._cssClass =
    generateClassName("backButtonIcon");
  formSubmitButton.options._cssClass = generateClassName("formSubmitButton");
  formSubmitButton.options.iconSettings._cssClass =
    generateClassName("succesButtonIcon");

  providerCard.options._cssClass = generateClassName("providerCard");
  providerCard.options._textContainerCssClass = generateClassName(
    "providerTextContainer"
  );
  providerDescription.options._cssClass = generateClassName(
    "providerDescription"
  );
  providerImage.options._cssClass = generateClassName("providerImage");
  providerTitle.options._cssClass = generateClassName("providerTitle");
  additionalInfo.options._cssClass = generateClassName("additionalInfo");
  depositFormDescription.options._cssClass = generateClassName(
    "depositFormDescription"
  );

  successMessage.options._cssClass = generateClassName("successMessage");
  successMessage.options.icon._cssClass =
    generateClassName("successMessageIcon");
  successMessage.options._textCssClass =
    generateClassName("successMessageText");
  failMessage.options._cssClass = generateClassName("failMessage");
  failMessage.options.icon._cssClass = generateClassName("failMessageIcon");
  failMessage.options._textCssClass = generateClassName("failMessageText");
  pendingMessage.options._cssClass = generateClassName("pendingMessage");
  pendingMessage.options.icon._cssClass =
    generateClassName("pendingMessageIcon");
  pendingMessage.options._textCssClass =
    generateClassName("pendingMessageText");

  form.options._cssClass = generateClassName("form");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");

  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.checkboxes_styling) {
    customFields.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    customFields.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");

    customFields.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (customFields.checkboxes_styling.options.check.icon) {
      customFields.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options._cssClass =
      generateClassName("dropdown");
    customFields.dropdown_menu_styling.options._cssClass =
      generateClassName("dropdownMenu");
    customFields.dropdown_menu_items_styling.options._cssClass =
      generateClassName("dropdownItems");
  }

  const checkboxes =
    customFields.checkboxes_styling &&
    customFields.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          customFields.checkboxes_styling,
          customFields.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(customFields.checkboxes_styling);

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  if (customFields.calendar_styling) {
    customFields.calendar_styling.options._wrapperCssClass =
      generateClassName("calendarWrapper");
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    customFields.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");

    customFields.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");

    customFields.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStageButtonCssString(formSubmitButton, true),
    generateStageButtonCssString(formBackButton, true),
    generateCardCssString(providerCard),
    generateCustomCardStyles(widget),
    generateStringDefault(providerDescription),
    generateStringDefault(providerTitle),
    generateStringDefault(depositFormDescription),
    generateFlexibleImageCssString(providerImage),
    generateStatusMessageCssString(successMessage),
    generateStatusMessageCssString(failMessage),
    generateStatusMessageCssString(pendingMessage),
    generateFormCssString(form, widget),
    generateStringDefault(additionalInfo),
    additionalInfoCustomCss(additionalInfo),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    checkboxes,
    generateIconRightCssString(customFields.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
