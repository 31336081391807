import { DESIGN_INITIAL } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 90;

const generateField = () => ({
  type: "UnknownField",
  value: null,
  options: {
    ...DESIGN_INITIAL().options,
    display: {
      distance: 12,
    },
  },
  name: "accept_changes_container",
  validation: {},
});

const v79WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "BetslipFenixWidget" &&
    !widget.content.accept_changes_container
  ) {
    widget.content.accept_changes_container = generateField();
  }

  return widget;
};
export default v79WidgetMigration;
