<template>
  <div class="widgets-tree-tab">
    <div class="widgets-tree-tab__search-container">
      <a-input
        v-model:value="searchValue"
        placeholder="Search widget by name or tag"
        class="widgets-tree-tab__search-input ant-input--has-suffix-clickable"
      >
        <template #suffix>
          <span class="common-search__icon">
            <CommonIcon
              name="ant-design:search-outlined"
              class="widgets-tree-tab__search-icon"
            />

            <span
              v-if="searchValue"
              class="pointer widgets-tree-tab__search-icon"
              @click="searchValue = ''"
            >
              <CommonIcon name="custom:close-field-icon" />
            </span>
          </span>
        </template>
      </a-input>
    </div>

    <div class="widgets-tree-tab__list p-l-16 p-r-16">
      <div v-if="!searchResultWidgetsTree.length">
        <p class="text-center widgets-tree-tab__empty">No widgets found</p>
      </div>

      <div
        v-for="widget in searchResultWidgetsTree"
        :key="widget.id"
        class="widgets-tree-tab__item-wrapper"
        :class="{
          'widgets-tree-tab__item-wrapper--active':
            widgetSettingsStore.selectedWidget?.id === widget.id,
        }"
        @click="handleWidgetSelect(widget)"
      >
        <span
          class="widgets-tree-tab__item-text"
          :class="{
            'widgets-tree-tab__item-text--not-visible':
              !widget.options?._isVisible?.[currentLanguageCode],
          }"
        >
          {{ widget.name }}
        </span>

        <div class="widgets-tree-tab__icons-container">
          <CommonIcon
            v-if="widget.options?._tag"
            name="ant-design:global-outlined"
            :title="`This widget is global with tag ${widget.options._tag}`"
          />

          <CommonIcon
            :name="
              widget.options?._isVisible?.[currentLanguageCode]
                ? 'ant-design:eye-outlined'
                : 'ant-design:eye-invisible-outlined'
            "
            class="widgets-tree-tab__copy"
            @click.stop="handleWidgetVisibleChange(widget)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGridConfig } from "~~/store/grid";
import { getWidgetsFromLayout } from "~~/assets/utils";
import { useLanguagesStore } from "~~/store/languages";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const searchValue = ref<string>("");

const languagesStore = useLanguagesStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { currentLanguageCode } = storeToRefs(languagesStore);
const { currentWidgetsLayout } = storeToRefs(useGridConfig());

const searchResultWidgetsTree = computed<IWidgetWithFields[]>(() => {
  const searchLower = searchValue.value.toLowerCase();

  const allWidgetsToCurrentBreakPoint = getWidgetsFromLayout(
    currentWidgetsLayout.value
  );

  return allWidgetsToCurrentBreakPoint.filter(widget => {
    const widgetName = widget.name.toLocaleLowerCase();
    const widgetTag = widget.options._tag?.toLocaleLowerCase();

    return widgetName.includes(searchLower) || widgetTag.includes(searchLower);
  });
});

const handleWidgetVisibleChange = (widget: IWidgetWithFields) => {
  widget.options._isVisible[currentLanguageCode.value] =
    !widget.options._isVisible[currentLanguageCode.value];
};

const handleWidgetSelect = (widget: IWidgetWithFields) => {
  widgetSettingsStore.selectWidget(widget);
};
</script>

<style lang="scss">
.widgets-tree-tab {
  width: $propertybar-width;

  &__search-container {
    padding: 0 $space-m;
  }

  &__empty {
    font-style: italic;
    color: $c-grey-45;
    font-size: 14px;
    margin: 0;
  }

  &__search-input {
    margin: $space-m 0;
    @include flex(center, space-between, 4px);

    .ant-input-suffix {
      position: static;
      transform: none;
      user-select: auto;
      user-select: initial;
      pointer-events: all;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;
  }

  &__list {
    overflow: auto;
    height: calc(100vh - 255px);
    width: 100%;
  }

  &__item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--not-visible {
      text-decoration: line-through;
    }
  }

  &__item-wrapper {
    @include flex(center, space-between, 0px);
    padding: $space-s;
    margin: 4px 0;
    border-radius: 8px;
    height: 40px;
    background-color: $c-grey-02;
    cursor: pointer;

    &--active,
    &:hover {
      background-color: $c-light-blue !important;
      color: $c-primary-base;
    }
  }

  &__search-icon {
    color: $c-grey-25;
  }

  &__icons-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
