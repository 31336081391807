import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  TextPosition,
  LayoutPositionComplex,
} from "~~/models/widgets/widget-controls.model";
import { FRONTEND_APP_CONTAINER_ID } from "~~/constants";
import { FormScreen } from "~~/models/widgets/form.model";
import { ElementStyle } from "~~/models/common";

import {
  generateDefaultStyles,
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateWidth,
  generateGap,
  generateTextColor,
  generateBorderStyle,
  generateDecorationStyle,
  generateFontFamily,
  generateFontSize,
  generateTextAlignStyle,
  generateFlex,
  generateFillColorStyle,
  generateCornerRadiusStyle,
  generateObjectFit,
  generateFlexAlignment,
  generateMaxWidth,
  generateJustifyContent,
  generateShadowStyle,
  generatePaddingStyle,
  generateMinWidth,
  generateTextDecorationColor,
  generateMaxHeight,
  generateMarginStyle,
} from "../helpers";
import { getPxValueFromNumber, joinStrings, styleObjectToString } from "../..";
import { getColorFromHex, getFlexPosition } from "../../widget-settings";

import { generateHeight } from "./../helpers";
import { generateClassName } from "./generate-class-name";

export const generateButtonCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const buttonCssContent = [
      generateDefaultStyles(element.options),
      generateWidth(100, "%"),
    ].join("");

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: buttonCssContent,
    });
    return cssString;
  };

export const generateDropdownMenuCssString =
  (dropdownMenu: IWidgetOptions, menuItem: IWidgetOptions) =>
  (cssString: string): string => {
    const dropdownCssContent = [
      generateDefaultStyles(dropdownMenu.options),
      generateWidth(100, "%"),
      generateMaxHeight("400px"),
    ].join("");

    const menuItemCssContent = generateFlex({
      flex: "flex",
      align: "unset",
      justify: "flex-start",
      direction: "column",
      gap: menuItem.options.spaceBetween,
    });

    cssString += generateCssClassWithContent({
      className: dropdownMenu.options._cssClass,
      content: [dropdownCssContent, menuItemCssContent].join(""),
    });
    return cssString;
  };

export const generateFormCssString =
  (form: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const isHorizontal =
      widget.options._customFields?.labels_styling?.options?.position ===
      DisplayOrientation.HORIZONTAL;

    cssString += generateCssClassWithContent({
      className: form.options._cssClass,
      content: form.options,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: form.options._cssClass,
      pseudoClassName: ":empty",
      content: "display: none;",
    });

    cssString += generateCssClassWithContent({
      className: form.options._cssClass,
      content: form.options.verticalSpace,
      customFunction: generateGap,
    });

    const formCssContent = [
      generateFlex({
        flex: "flex",
        align: "unset",
        justify: "center",
        direction: "column",
        gap: form.options.verticalSpace,
      }),
      generateWidth(100, "%"),
      "margin-left:auto;margin-right:auto;",
    ];

    cssString += generateCssClassWithContent({
      className: form.options._cssClass,
      content: formCssContent.join(""),
    });

    cssString += generateUploadFieldCssString(widget);

    const fieldLabelContainerFlexDirection = isHorizontal ? "row" : "column";

    const fieldLabelContainerGap = isHorizontal ? "8" : "0";

    if (isHorizontal) {
      const fieldLabelContainerContent = generateFlex({
        flex: "flex",
        align: "unset",
        justify: "unset",
        direction: fieldLabelContainerFlexDirection,
        gap: fieldLabelContainerGap,
      });

      cssString += `.${
        form.options._cssClass
      } .formkit-wrapper-override{${fieldLabelContainerContent}}
      .${
        form.options._cssClass
      } .formkit-label-wrapper-override{${generateWidth(
        50,
        "%"
      )}${generateJustifyContent("space-between")}overflow-wrap:anywhere;}
      .${form.options._cssClass} .formkit-messages-override{margin-left:50%;}`;
    }

    cssString += `.${
      form.options._cssClass
    } .formkit-label-wrapper-override{${generateGap(
      widget.options._customFields?.tooltips_styling?.options?.textChooseIcon
        ?.gap
    )}${generatePaddingStyle(
      widget.options._customFields?.labels_styling?.options?.containerPadding
    )}}`;

    return cssString;
  };

const generateUploadIconCssString = (
  uploadIconOptions: IWidgetOptions,
  parentOptions: IWidgetOptions,
  marginSide: string
): string => {
  let css = "";

  let fileIconCssContent =
    generateTextColor(uploadIconOptions.textColor) +
    generateFontSize(uploadIconOptions.iconSize) +
    `margin-${marginSide}: ${getPxValueFromNumber(uploadIconOptions.gap)};`;

  if (marginSide === "left") {
    fileIconCssContent += "cursor:pointer;";
  }
  css += generateCssClassWithContent({
    className: uploadIconOptions._cssClass,
    content: fileIconCssContent,
  });

  for (const state in parentOptions.states) {
    const currentStateCss = generateTextColor(
      parentOptions.states[state].textColor
    );

    if (!currentStateCss) continue;
    css += generateCssClassWithContent({
      className: uploadIconOptions._cssClass,
      content: currentStateCss,
      pseudoClassName: `:${state}`,
    });
  }

  return css;
};

const generateUploadItemsCssString = (itemOptions: IWidgetOptions): string => {
  let css = "";

  const listItemTextStyle = generateDefaultStyles(itemOptions.text);

  css += generateCssClassWithContent({
    className: itemOptions._cssClass,
    content: listItemTextStyle,
  });

  css += generateCssClassWithContent({
    className: itemOptions.fileIcon._cssClass,
    content: {
      color: itemOptions.fileIcon.textColor,
    },
    customFunction: generateDefaultStyles,
  });

  const fileIconSettings = itemOptions.fileIcon;
  const deleteIconSettings = itemOptions.deleteIcon;

  css += generateUploadIconCssString(fileIconSettings, itemOptions, "right");
  css += generateUploadIconCssString(deleteIconSettings, itemOptions, "left");

  css += generateDefaultStylesWithStates({ options: itemOptions }, false);

  for (const state in itemOptions.states) {
    const currentTextStateCss = generateDefaultStyles(
      itemOptions.states[state].text
    );

    css += generateCssClassWithContent({
      className: itemOptions._cssClass,
      content: currentTextStateCss,
      pseudoClassName: `:${state}`,
    });
  }

  const itmemsContainerStyles = [
    generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    }),
    generateWidth(100, "%"),
  ].join("");

  css += generateCssClassWithContent({
    className: itemOptions._cssClass,
    content: itmemsContainerStyles,
  });

  return css;
};

export const generateUploadFieldCssString = (
  widget: IWidgetOptions
): string => {
  let css = "";
  const customFields = widget.options._customFields;

  /*
    If no upload listing, no need to generate css
  */
  if (!customFields.upload_list_styling) {
    return "";
  }

  customFields.upload_caption_styling.options._cssClass = generateClassName(
    "form_upload_caption_styling"
  );
  customFields.upload_list_styling.options._cssClass = generateClassName(
    "form_upload_list_styling"
  );
  customFields.upload_items_styling.options._cssClass = generateClassName(
    "form_upload_items_styling"
  );

  customFields.upload_items_styling.options.fileIcon._cssClass =
    generateClassName("form_upload_items_styling_fileIcon");

  customFields.upload_items_styling.options.deleteIcon._cssClass =
    generateClassName("form_upload_items_styling_deleteIcon");

  customFields.upload_button_styling.options._cssClass = generateClassName(
    "form_upload_button_styling"
  );

  css += generateCssClassWithContent({
    className: customFields.upload_caption_styling.options._cssClass,
    content: customFields.upload_caption_styling.options,
    customFunction: generateDefaultStyles,
  });

  css += generateStageButtonCssString(customFields.upload_button_styling)(css);

  const isDisplayFill =
    customFields.upload_button_styling.options.buttonDisplaySettings
      .resizing === ResizingType.FILL;

  const widthValue = isDisplayFill ? "100%" : "fit-content";

  css += generateCssClassWithContent({
    className: customFields.upload_button_styling.options._cssClass,
    content: `width:${widthValue};cursor:pointer;`,
  });

  css += generateCssClassWithContent({
    className: customFields.upload_list_styling.options._cssClass,
    content: customFields.upload_list_styling.options,
    customFunction: generateDefaultStyles,
  });

  css += generateCssClassWithContent({
    className: customFields.upload_list_styling.options._cssClass,
    content: generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      direction: "column",
      gap: customFields.upload_list_styling.options.distance,
    }),
  });

  css += generateUploadItemsCssString(
    customFields.upload_items_styling.options
  );

  return css;
};

export const generateIconCssString =
  (icon: IWidgetOptions, keyName = "color") =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: icon._cssClass,
      content: icon[keyName],
      customFunction: generateTextColor,
    });

    cssString += generateCssClassWithContent({
      className: icon._cssClass,
      content: "gap",
      customFunction: generateMarginStyle,
    });

    return cssString;
  };

export const generateIconSizeCssString =
  (icon: IWidgetOptions, keyName = "size") =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: icon._cssClass,
      content: icon[keyName],
      customFunction: generateFontSize,
    });

    return cssString;
  };

export const generateFlexContainerCssString =
  (el: IWidgetOptions, gap = "4") =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: el.options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "flex-start",
        gap: gap,
      }),
    });

    return cssString;
  };

export const generateIconRightCssString =
  (fieldStyleOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: fieldStyleOptions.iconRight._cssClass,
      content: fieldStyleOptions.iconRight.color,
      customFunction: generateTextColor,
    });

    cssString += generateCssClassWithContent({
      className: fieldStyleOptions.iconRight._cssClass,
      content: `display: inline-block; margin-left: ${getPxValueFromNumber(
        fieldStyleOptions.iconRight.gap || 4
      )}`,
    });

    for (const state in fieldStyleOptions.states) {
      const currentState = fieldStyleOptions.states[state];

      cssString += generateCssClassWithContent({
        className: "formkit-wrapper-override",
        childClassName: fieldStyleOptions.iconRight._cssClass,
        content: currentState.iconRight.color,
        customFunction: generateTextColor,

        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

export const generateTextChooseIconCssString =
  (icon: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const fieldStyleOptions =
      widget.options._customFields?.fields_styling.options;
    cssString += generateCssClassWithContent({
      className: icon._cssClass,
      content: icon.color,
      customFunction: generateTextColor,
    });

    if (
      widget.options._customFields?.labels_styling?.options.position ===
      DisplayOrientation.HORIZONTAL
    ) {
      cssString += `.${icon._cssClass}{margin-bottom:${
        fieldStyleOptions.padding.bottom + fieldStyleOptions.border.width
      }px;align-self:flex-end;}`;
    } else {
      cssString += `.${widget.options._customFields?.labels_styling?.options._cssClass}{max-width: max-content;
        word-break: break-all;}`;
    }
    return cssString;
  };

export const generateFieldsWithStatesCssString =
  (
    field: IWidgetOptions,
    errorStyling: IWidgetOptions,
    widget: IWidgetOptions,
    labelsStyling?: IWidgetOptions,
    successStyling?: IWidgetOptions
  ) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(field, false, [
      "disabled",
      "active",
    ]);

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: field.options.states.active,
      customFunction: generateDefaultStyles,
      pseudoClassName: `:has(input:focus)`,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: field.options.states.disabled,
      customFunction: generateDefaultStyles,
      pseudoClassName: `:has([disabled])`,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateTextColor(field.options.states.disabled.color),
      pseudoClassName: `:has([disabled]) svg`,
    });

    const fieldCssContent = [
      generateFlex({
        flex: "flex",
        align: "center",
        justify: "space-between",
      }),
      "position:relative;",
    ];

    if (
      widget.options._customFields?.labels_styling?.options.position ===
      DisplayOrientation.HORIZONTAL
    ) {
      fieldCssContent.push(`${generateWidth(50, "%")}align-self:flex-end;`);
    } else {
      fieldCssContent.push(generateWidth(100, "%"));
    }

    cssString += `.${
      field.options._cssClass
    } .formkit-dropdown-selector-override{${
      generateTextColor(field.options.color) +
      generateDecorationStyle(field.options.decoration)
    }}`;

    cssString += `.${field.options._cssClass} .form-tel-input-override{${
      generateTextColor(field.options.color) +
      generateDecorationStyle(field.options.decoration)
    }}`;

    cssString += `.${field.options._cssClass} .formkit-input-override{${
      generateTextColor(field.options.color) +
      generateDecorationStyle(field.options.decoration)
    }}
    .${field.options._cssClass} input::placeholder{${
      generateTextColor(field.options.placeholderColor) +
      generateTextDecorationColor(field.options.placeholderColor) +
      generateDecorationStyle(field.options.decoration)
    }}
    .${field.options._cssClass} input{text-overflow:ellipsis;}`;

    cssString += `.${
      field.options._cssClass
    } .formkit-placeholder-selector-override{${
      generateTextColor(field.options.placeholderColor) +
      generateTextDecorationColor(field.options.placeholderColor) +
      generateDecorationStyle(field.options.decoration)
    }}
    .${
      field.options._cssClass
    }:has([disabled]) .formkit-placeholder-selector-override{${
      generateTextColor(field.options.states.disabled.placeholderColor) +
      generateTextDecorationColor(field.options.placeholderColor) +
      generateDecorationStyle(field.options.states.disabled.decoration)
    }}`;

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: fieldCssContent.join(""),
    });

    // TODO: temporary solution, not suported for firefox - delete after demo
    cssString += `.${
      field.options._cssClass
    }:has(input[aria-describedby*="-rule_"]){${generateBorderStyle(
      errorStyling.options.border
    )}}`;
    cssString += `.${
      field.options._cssClass
    }:has(button[aria-describedby*="-rule_"]){${generateBorderStyle(
      errorStyling.options.border
    )}}`;

    for (const state in field.options.states) {
      const currentState = field.options.states[state];
      const currentStateCss = generateTextColor(currentState.textIcon.color);
      if (!currentStateCss) continue;
      cssString += generateCssClassWithContent({
        className: field.options.textIcon._cssClass,
        content: currentStateCss,
        pseudoClassName: `:${state}`,
      });
      cssString += `.${
        field.options._cssClass
      } .formkit-input-override:${state}::placeholder{${
        generateTextColor(currentState.placeholderColor) +
        generateDecorationStyle(currentState.decoration) +
        generateTextDecorationColor(currentState.placeholderColor)
      }}
      .${
        field.options._cssClass
      } .formkit-dropdown-selector-override:${state} .formkit-placeholder-selector-override{${
        generateTextColor(currentState.placeholderColor) +
        generateDecorationStyle(currentState.decoration) +
        generateTextDecorationColor(currentState.placeholderColor)
      }}
      .${
        field.options._cssClass
      } .formkit-input-override:${state}{${generateTextColor(
        currentState.color
      )}${generateDecorationStyle(currentState.decoration)}}
      .${
        field.options._cssClass
      } .formkit-dropdown-selector-override:${state}{${generateTextColor(
        currentState.color
      )}${generateDecorationStyle(currentState.decoration)}}`;

      /* 
        Label
      */

      if (labelsStyling && successStyling) {
        cssString += generateCssClassWithContent({
          className: widget.options._cssClass,
          content: generateTextColor(labelsStyling.options.states.error.color),
          pseudoClassName: ` div[data-invalid="true"] .${labelsStyling.options._cssClass}`,
        });

        cssString += generateCssClassWithContent({
          className: widget.options._cssClass,
          content: generateTextColor(
            labelsStyling.options.states.success.color
          ),
          pseudoClassName: ` div[data-complete="true"] .${labelsStyling.options._cssClass}`,
        });

        cssString += generateCssClassWithContent({
          className: widget.options._cssClass,
          content: generateBorderStyle(successStyling.options.border),
          pseudoClassName: ` div[data-complete="true"] .${field.options._cssClass}`,
        });
      }

      if (state === "disabled") {
        cssString += `.${field.options._cssClass} .formkit-dropdown-selector-override:${state}{cursor:default;}`;
      }
    }
    return cssString;
  };

export const generateErrorCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;
    const errorCssString = [
      generateTextAlignStyle(options.alignment),
      generateTextColor(options.color),
      generateDecorationStyle(options.decoration),
      generateFontFamily(options.fontFamily),
      generateFontSize(options.theme),
    ].join("");

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: errorCssString,
    });
    return cssString;
  };

const generateCheckboxContainerCssString = (
  options: IWidgetOptions
): string => {
  const wrapperStyles = [
    generateFillColorStyle(options.fill),
    generateBorderStyle(options.border),
    generateShadowStyle(options.shadow),
  ].join("");

  return wrapperStyles;
};

const generateCheckboxLabelCssString = (options: IWidgetOptions): string => {
  let css = "";

  if (options.color) {
    css += generateTextColor(options.color);
  }

  if (options.theme) {
    css += generateFontSize(options.theme);
  }

  if (options.decoration) {
    css += generateDecorationStyle(options.decoration || []);
  }

  if (options.fontFamily) {
    css += generateFontFamily(options.fontFamily);
  }

  return css;
};

const generateCheckboxContainerDataAttrs = (state: State): string => {
  let result = "";
  if (
    state === State.SELECTED_DEFAULT ||
    state === State.SELECTED_DISABLED ||
    state === State.SELECTED_HOVER
  ) {
    result += `[data-checked="true"]`;
  }

  if (
    state === State.SELECTED_DISABLED ||
    state === State.UNSELECTED_DISABLED
  ) {
    result += `[data-disabled="true"]`;
  }

  return result;
};

export const generateCheckboxCssString =
  (element: IWidgetOptions, checkIcon?: IWidgetOptions) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const { options } = element;
    const checkboxCheckedDefaultCssContent = [
      generateBorderStyle(options.check.border),
      generateFillColorStyle(options.check.fill),
    ].join("");

    const checkboxUncheckedDefaultCssContent = [
      generateCornerRadiusStyle(2),
      generateBorderStyle(options.states["unselected_default"].check.border),
      generateFillColorStyle(options.states["unselected_default"].check.fill),
    ].join("");

    const checkboxUncheckedHoverCssContent = [
      generateBorderStyle(options.states["unselected_hover"].check.border),
      generateFillColorStyle(options.states["unselected_hover"].check.fill),
    ].join("");

    const checkboxUncheckedDisabledCssContent = [
      generateBorderStyle(options.states["unselected_disabled"]?.check.border),
      generateFillColorStyle(options.states["unselected_disabled"]?.check.fill),
    ].join("");

    if (options._checkboxOptionsCssClass) {
      cssString += generateCssClassWithContent({
        className: options._checkboxOptionsCssClass,
        content: generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          wrap: "wrap",
          gap: options.display.distance,
        }),
      });

      cssString += generateCssClassWithContent({
        className: options._checkboxOptionsCssClass,
        pseudoClassName: " .formkit-option",
        content: "margin: 0;",
      });
    }

    if (!options.check._active) {
      cssString += generateCssClassWithContent({
        className: options._checkoxCssClass,
        content: "display: none !important;",
      });
    }

    cssString += generateCssClassWithContent({
      className: `${options._checkoxCssClass}:hover`,
      content: checkboxUncheckedHoverCssContent,
    });

    cssString += generateCssClassWithContent({
      className: `${options._checkoxCssClass}:disabled`,
      content: checkboxUncheckedDisabledCssContent,
    });

    cssString += generateCssClassWithContent({
      className: `${options._checkoxCssClass}`,
      content: checkboxUncheckedDefaultCssContent,
    });

    const wrapperDefaultStyles = [
      generateCornerRadiusStyle(options.cornerRadius),
      generatePaddingStyle(options.padding),
      generatePaddingStyle(options.padding),
    ].join("");

    const wrapperCheckedDefaultStyles = [
      generateFillColorStyle(options.fill),
      generateBorderStyle(options.border),
      generateShadowStyle(options.shadow),
    ].join("");

    cssString += `.${options._checkboxContainerCssClass} {${wrapperDefaultStyles};cursor:pointer;}`;

    cssString += `.${options._checkboxContainerCssClass}[data-checked="true"] {${wrapperCheckedDefaultStyles};}`;

    const labelCssContent =
      generateCheckboxLabelCssString({
        ...options,
        color: null,
        decoration: null,
      }) + generateWidth(100, "%");

    cssString += generateCssClassWithContent({
      className: `${options._checkboxLabelCssClass}`,
      content: labelCssContent + `margin-left: 0;`,
    });

    if (options.check._active) {
      cssString += generateCssClassWithContent({
        className: `${options._checkboxLabelCssClass}`,
        content: `margin-left: ${getPxValueFromNumber(
          options.check.distance
        )} !important;`,
      });
    }

    const labelCheckedCssContent = generateCheckboxLabelCssString({
      ...options,
      theme: null,
      fontFamily: null,
    });

    cssString += generateCssClassWithContent({
      className: `${options._checkboxContainerCssClass}[data-checked="true"]`,
      pseudoClassName: ` .${options._checkboxLabelCssClass}`,
      content: labelCheckedCssContent,
    });

    const statesList = [
      State.UNSELECTED_DEFAULT,
      State.UNSELECTED_HOVER,
      State.SELECTED_HOVER,
      State.UNSELECTED_DISABLED,
      State.SELECTED_DISABLED,
    ];

    for (const state of statesList) {
      const stateOptions = options.states[state];

      const isHover =
        state === State.SELECTED_HOVER || state === State.UNSELECTED_HOVER;

      const dataAttrs = generateCheckboxContainerDataAttrs(state as State);

      cssString += generateCssClassWithContent({
        className: `${options._checkboxContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: dataAttrs,
        content: generateCheckboxContainerCssString(stateOptions),
      });

      cssString += generateCssClassWithContent({
        className: `${options._checkboxContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: `${dataAttrs} .${options._checkboxLabelCssClass}`,
        content: generateCheckboxLabelCssString(stateOptions),
      });
    }

    cssString += `.checkbox-wrapper[data-checked="true"] .${options._checkoxCssClass}{${checkboxCheckedDefaultCssContent}}.${options._checkoxCssClass}>.icon{color:${options.check?.icon};font-size:13px;}`;

    cssString += generateCssClassWithContent({
      className: options._checkoxCssClass,
      content:
        generateWidth(options.size, "px") +
        generateHeight(options.size) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "center",
        }),
    });

    if (checkIcon) {
      cssString += generateCssClassWithContent({
        className: options._checkoxCssClass,
        pseudoClassName: " .icon",
        content: checkIcon,
        customFunction: generateTextColor,
      });

      cssString += generateCssClassWithContent({
        className: `${options._checkoxCssClass}:hover`,
        content: options.states["selected_hover"].check.icon,
        customFunction: generateTextColor,
      });
    }

    return cssString;
  };

const generateRadioIconDisplayCssString = (options: IWidgetOptions): string => {
  let result = "";

  result += generateCssClassWithContent({
    className: `${options._radioContainerCssClass}`,
    pseudoClassName: `[data-checked="true"] .${options._radioCssClass}:after`,
    content: "display:block !important;",
  });

  return result;
};

export const generateRadioCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const { options } = element;

    /* 
      Radios container
    */
    if (options._radioOptionsCssClass) {
      cssString += generateCssClassWithContent({
        className: options._radioOptionsCssClass,
        content: generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          wrap: "wrap",
          gap: options.display.distance,
        }),
      });

      cssString += generateCssClassWithContent({
        className: options._radioOptionsCssClass,
        pseudoClassName: " .formkit-option",
        content: "margin: 0;",
      });
    }

    if (!options.radio._active) {
      cssString += generateCssClassWithContent({
        className: options._radioCssClass,
        content: "display: none !important;",
      });
    }

    /* 
      Default checked radio styles
    */
    const radioBtnDefaultStyles = [
      generateFillColorStyle(options.radio.fill),
      generateBorderStyle(options.radio.border),
      generateShadowStyle(options.radio.shadow),
    ].join("");

    cssString += generateCssClassWithContent({
      className: `${options._radioContainerCssClass}`,
      pseudoClassName: `[data-checked="true"] .${options._radioCssClass}`,
      content: radioBtnDefaultStyles,
    });

    /* 
      Radio item wrapper styles
    */
    const wrapperDefaultStyles = [
      generateCornerRadiusStyle(options.cornerRadius),
      generatePaddingStyle(options.padding),
      generatePaddingStyle(options.padding),
    ].join("");

    const wrapperCheckedDefaultStyles = [
      generateFillColorStyle(options.fill),
      generateBorderStyle(options.border),
      generateShadowStyle(options.shadow),
    ].join("");

    cssString += `.${options._radioContainerCssClass} {${wrapperDefaultStyles};cursor:pointer;display:flex;align-items:center;}`;

    cssString += generateCssClassWithContent({
      className: `${options._radioContainerCssClass}`,
      pseudoClassName: " svg",
      content: "display: none !important;",
    });

    cssString += `.${options._radioContainerCssClass}[data-checked="true"] {${wrapperCheckedDefaultStyles};}`;

    /* 
      Label styles
    */
    const labelCssContent =
      generateCheckboxLabelCssString({
        ...options,
        color: null,
        decoration: null,
      }) + generateWidth(100, "%");

    cssString += generateCssClassWithContent({
      className: `${options._radioLabelCssClass}`,
      content: labelCssContent + `margin-left: 0;`,
    });

    if (options.radio._active) {
      cssString += generateCssClassWithContent({
        className: `${options._radioLabelCssClass}`,
        content: `margin-left: ${getPxValueFromNumber(
          options.radio.distance
        )} !important;`,
      });

      cssString += generateRadioIconDisplayCssString(options);

      cssString += generateCssClassWithContent({
        className: `${options._radioContainerCssClass}[data-checked="true"]`,
        pseudoClassName: ` .${options._radioCssClass}:after`,
        content: generateFillColorStyle(options.radio.icon),
      });
    }

    const labelCheckedCssContent = generateCheckboxLabelCssString({
      ...options,
      theme: null,
      fontFamily: null,
    });

    cssString += generateCssClassWithContent({
      className: `${options._radioContainerCssClass}[data-checked="true"]`,
      pseudoClassName: ` .${options._radioLabelCssClass}`,
      content: labelCheckedCssContent,
    });

    const statesList = [
      State.UNSELECTED_DEFAULT,
      State.UNSELECTED_HOVER,
      State.SELECTED_HOVER,
      State.UNSELECTED_DISABLED,
      State.SELECTED_DISABLED,
    ];

    for (const state of statesList) {
      const stateOptions = options.states[state];

      const isHover =
        state === State.SELECTED_HOVER || state === State.UNSELECTED_HOVER;

      const dataAttrs = generateCheckboxContainerDataAttrs(state as State);

      cssString += generateCssClassWithContent({
        className: `${options._radioContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: dataAttrs,
        content: generateCheckboxContainerCssString(stateOptions),
      });

      cssString += generateCssClassWithContent({
        className: `${options._radioContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: `${dataAttrs} .${options._radioLabelCssClass}`,
        content: generateCheckboxLabelCssString(stateOptions),
      });

      cssString += generateCssClassWithContent({
        className: `${options._radioContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: `${dataAttrs} .${options._radioCssClass}`,
        content: [
          generateFillColorStyle(stateOptions.radio.fill),
          generateBorderStyle(stateOptions.radio.border),
        ].join(""),
      });

      cssString += generateCssClassWithContent({
        className: `${options._radioContainerCssClass}${
          isHover ? ":hover" : ""
        }`,
        pseudoClassName: `${dataAttrs} .${options._radioCssClass}:after`,
        content: [generateFillColorStyle(stateOptions.radio.icon)].join(""),
      });
    }

    // cssString += `.radio-wrapper[data-checked="true"] .${options.radioCssClass}{${radioCheckedDefaultCssContent}}.${options._radioCssClass}>.icon{color:${options.radio?.icon};font-size:13px;}`;

    cssString += `.${options._radioCssClass}{${generateHeight(
      options.size
    )}${generateWidth(options.size, "px")}${generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
    })}}`;

    cssString += generateCssClassWithContent({
      className: `${options._radioCssClass}`,
      content: "border-radius: 50%;",
    });

    cssString += generateCssClassWithContent({
      className: `${options._radioCssClass}`,
      pseudoClassName: ":after",
      content: `content: "";width: 50%;height: 50%;border-radius: 50%;display:none;`,
    });

    // if (checkIcon) {
    //   cssString += generateCssClassWithContent({
    //     className: options._checkoxCssClass,
    //     pseudoClassName: " .icon",
    //     content: checkIcon,
    //     customFunction: generateTextColor,
    //   });

    //   cssString += generateCssClassWithContent({
    //     className: `${options._checkoxCssClass}:hover`,
    //     content: options.states["selected_hover"].check.icon,
    //     customFunction: generateTextColor,
    //   });
    // }

    return cssString;
  };

export const generateTooltipStyling =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += `.${element.options._cssClass} {${generateDefaultStyles(
      element.options
    )}fill:${getColorFromHex(element.options.fill)}}
    .${element.options._cssClass} svg{fill:${getColorFromHex(
      element.options.fill
    )};}`;
    return cssString;
  };

export const generateDropdownMenuItemStyling =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(element, false);

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass}[aria-selected="true"]`,
      content: element.options.states?.[State.ACTIVE],
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

export const generateCardPositionCss = (
  layoutPosition: LayoutPositionComplex
): string => {
  const flexDirections: Record<TextPosition, string> = {
    [TextPosition.BOTTOM]: "flex-direction: column;",
    [TextPosition.TOP]: "flex-direction: column-reverse;",
    [TextPosition.RIGHT]: "flex-direction: row;",
    [TextPosition.LEFT]: "flex-direction: row-reverse;",
  };

  const alignStyle =
    layoutPosition.position !== TextPosition.BOTTOM &&
    layoutPosition.position !== TextPosition.TOP
      ? `align-items:${layoutPosition.alignment};`
      : "";

  const positionCssContent =
    generateFlex({
      flex: "flex",
      align: "unset",
      justify: "flex-start",
    }) +
    (flexDirections[layoutPosition.position as TextPosition] || "") +
    alignStyle;

  return positionCssContent;
};

export const generateCardCssString =
  (widget: IWidgetOptions, additionalData?: string) =>
  (cssString: string): string => {
    if (!widget) {
      return cssString;
    }

    const { layoutPosition, spaceBetween } = widget.options;

    cssString += generateDefaultStylesWithStates(widget, false);

    const flexDirections: Record<TextPosition, string> = {
      [TextPosition.BOTTOM]: "flex-direction: column;",
      [TextPosition.TOP]: "flex-direction: column-reverse;",
      [TextPosition.RIGHT]: "flex-direction: row;",
      [TextPosition.LEFT]: "flex-direction: row-reverse;",
    };

    const widgetPositionCssContent =
      generateFlex({
        flex: "flex",
        align: "unset",
        justify: "flex-start",
        gap: spaceBetween,
      }) +
      (flexDirections[layoutPosition.position as TextPosition] || "") +
      (layoutPosition.position !== TextPosition.BOTTOM ||
      layoutPosition.position !== TextPosition.TOP
        ? `align-items:${layoutPosition.alignment};`
        : "") +
      ([TextPosition.TOP, TextPosition.BOTTOM].includes(
        layoutPosition.position as TextPosition
      ) && layoutPosition.horizontalAlignment
        ? `align-items:${getFlexPosition(layoutPosition.horizontalAlignment)};`
        : "");
    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: widgetPositionCssContent,
    });

    if (additionalData) {
      cssString += additionalData;
    }

    if (widget.options._textContainerCssClass) {
      cssString += generateCssClassWithContent({
        className: widget.options._textContainerCssClass,
        content: generateFlex({
          flex: "flex",
          direction: "column",
          align: "unset",
          justify: "unset",
          gap: spaceBetween,
        }),
      });
    }

    return cssString;
  };

export const generateStageButtonCssString =
  (button: IWidgetField, isParentVertical = false, additionalStyles?: string) =>
  (cssString: string): string => {
    if (!button) return cssString;

    const { options } = button;

    cssString += generateDefaultStylesWithStates(button, false);
    /*  */
    if (options.states.disabled) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content:
          generateDefaultStyles(options.states.disabled) +
          "cursor: not-allowed;",
        pseudoClassName: `[disabled]`,
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "line-height:1.5715;",
    });

    if (additionalStyles) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: additionalStyles,
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options.buttonTextSettings,
      customFunction: generateDefaultStyles,
    });

    if (options.iconSettings && options.iconSettings._cssClass) {
      const iconFontCssContent =
        generateTextColor(options.iconSettings.textColor) +
        generateFontSize(options.iconSettings.iconSize) +
        `order: ${options.iconSettings?.position === "left" ? -1 : 1}`;

      cssString += generateCssClassWithContent({
        className: options.iconSettings._cssClass,
        content: iconFontCssContent,
      });
    }

    if (options.buttonDisplaySettings) {
      const isDisplayFill =
        options.buttonDisplaySettings.resizing === ResizingType.FILL;

      const widthValue = isDisplayFill
        ? isParentVertical
          ? "unset"
          : "100%"
        : isParentVertical
        ? "fit-content"
        : "unset";

      const justifyContent = isDisplayFill
        ? options.buttonDisplaySettings.alignment
        : "center";

      const alignSelf = isDisplayFill
        ? "unset"
        : generateFlexAlignment(options.buttonDisplaySettings.alignment);

      const buttonDisplayCssString = [
        generateWidth(widthValue, null),
        generateFlex({
          flex: "flex !important",
          align: "center",
          justify: justifyContent,
          gap: options.iconSettings?.gap || 0,
          alignSelf,
        }),
        generateTextAlignStyle(options.buttonDisplaySettings.alignment),
      ].join("");

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: buttonDisplayCssString,
      });
    }

    for (const state in options.states) {
      if (state === State.HOVER) {
        cssString += `@media (hover: hover) and (pointer: fine) {.${
          options._cssClass
        }:hover{${generateDefaultStyles(
          options.states[state].buttonTextSettings || {}
        )}${generateDecorationStyle(
          options.buttonTextSettings?.decoration || []
        )}}}`;
        cssString += `.${
          options._cssClass
        }:focus-visible{${generateDefaultStyles(
          options.states[state].buttonTextSettings || {}
        )}}`;
        if (options.iconSettings && options.iconSettings._cssClass) {
          const currentStateCss = generateTextColor(
            options.states[state].iconSettings.textColor
          );
          if (!currentStateCss) continue;
          cssString += generateCssClassWithContent({
            className: options._cssClass,
            content: currentStateCss,
            pseudoClassName: `:${state}`,
            childClassName: options.iconSettings._cssClass,
          });
        }
        continue;
      }

      if (options.buttonTextSettings) {
        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: options.states[state].buttonTextSettings,
          customFunction: generateDefaultStyles,
          pseudoClassName: `:${state}`,
        });
        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: options.buttonTextSettings.decoration,
          customFunction: generateDecorationStyle,
          pseudoClassName: `:${state}`,
        });

        if (state === State.DISABLED) {
          cssString += generateCssClassWithContent({
            className: options._cssClass,
            content: options.states[state].buttonTextSettings,
            customFunction: generateDefaultStyles,
            pseudoClassName: `[${state}]`,
          });

          cssString += generateCssClassWithContent({
            className: options._cssClass,
            content: options.buttonTextSettings.decoration,
            customFunction: generateDecorationStyle,
            pseudoClassName: `[${state}]`,
          });
        }
      }

      if (options.iconSettings) {
        const currentStateCss = generateTextColor(
          options.states[state].iconColor ??
            options.states[state].iconSettings?.textColor
        );
        if (!currentStateCss) continue;
        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: currentStateCss,
          pseudoClassName: `:${state}`,
          childClassName: options.iconSettings._cssClass,
        });
        /*  */
        if (state === State.DISABLED) {
          cssString += generateCssClassWithContent({
            className: options._cssClass,
            content: currentStateCss,
            pseudoClassName: `[${state}]`,
            childClassName: options.iconSettings._cssClass,
          });
        }
      }
    }

    return cssString;
  };

export const generateImageCssString =
  (image: IWidgetField) =>
  (cssString: string): string => {
    const { options } = image;
    const containerSize = options.containerSize;
    const widthValue =
      containerSize.resizing === ResizingType.FILL ? "100%" : "unset";
    const objectFitValue =
      containerSize.resizing === ResizingType.FILL ? "cover" : "contain";

    const getMargin =
      containerSize.alignment === Alignment.LEFT
        ? "margin-right: auto;"
        : containerSize.alignment === Alignment.RIGHT
        ? "margin-left: auto;"
        : "margin: 0 auto";

    const imageCssContent = [
      generateDefaultStyles(options),
      generateHeight(containerSize.size),
      generateWidth(widthValue, null),
      generateObjectFit(objectFitValue),
      generateFlex({
        flex: "flex",
        align: "center",
        justify: "center",
      }),
      generateMaxWidth("100%"),
      getMargin,
    ].join("");

    cssString += generateCssClassWithContent({
      className: `${options._cssClass} img`,
      content: imageCssContent,
    });

    return cssString;
  };

const getContainerSizeCssString = (options: IWidgetOptions): string => {
  const containerSize = options.containerSize;
  const resizing = options.resizing;
  let style: ElementStyle = {};

  if (containerSize.width.type === ResizingType.FIXED) {
    // style["min-width"] = getPxValueFromNumber(containerSize.width.value);
    style["width"] = getPxValueFromNumber(containerSize.width.value);
  } else {
    style.width = "100%";
  }

  if (containerSize.height.type === ResizingType.FIXED) {
    style.height = getPxValueFromNumber(containerSize.height.value);
  } else {
    style.height = "100%";
  }

  const position = resizing.position.split(" ");

  if (resizing.type === ResizingType.FIT) {
    const align = getFlexPosition(position[0]);
    const justify = getFlexPosition(position[1]);

    style["align-items"] = align;
    style["justify-content"] = justify;
    style["display"] = "flex";
    style["flex-direction"] = "row !important";
  }

  style = {
    ...style,
  };

  return styleObjectToString(style);
};

const getImageResizingCssString = (options: IWidgetOptions): string => {
  const resizing = options.resizing;

  const styles: ElementStyle = {
    "object-fit": resizing.type === ResizingType.FIT ? "contain" : "cover",
    width: resizing.type === ResizingType.FILL ? "100%" : "unset",
  };

  if (resizing.type === ResizingType.FILL) {
    styles["object-position"] = `${resizing.x}% ${resizing.y}%`;
    styles["height"] = "100%";
  }

  if (resizing.type === ResizingType.FIT) {
    styles["max-height"] = "100%";
  }

  return styleObjectToString(styles);
};

export const generateFlexibleImageCssString =
  (image: IWidgetField) =>
  (cssString: string): string => {
    const { options } = image;

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      content:
        getContainerSizeCssString(options) +
        generateDefaultStyles({
          ...options,
          cornerRadius: null,
          shadow: null,
        }) +
        `overflow: hidden;` +
        `max-width: 100%;`,
    });

    const imageCssContent = [
      generateDefaultStyles({ cornerRadius: options.cornerRadius }),
      getImageResizingCssString(options),
      generateMaxWidth("100%"),
    ].join("");

    cssString += generateCssClassWithContent({
      className: `${options._cssClass} img`,
      content: imageCssContent,
    });

    return cssString;
  };

export const generateCalendarWrapperCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const content = joinStrings(
      generateFillColorStyle(
        element.options.fill?.color
          ? element.options.fill
          : { color: "#000000", opacity: 0 }
      ),
      generateBorderStyle(
        element.options.border?.width && element.options.border?.fill?.color
          ? element.options.border
          : {
              fill: { color: "#000000", opacity: 0 },
              width: 0,
              style: element.options.border?.style,
            }
      ),
      generateShadowStyle(
        element.options.shadow?.fill?.color
          ? element.options.shadow
          : {
              ...element.options.shadow,
              fill: { color: "#000000", opacity: 0 },
            }
      ),
      generateCornerRadiusStyle(element.options.cornerRadius),
      generatePaddingStyle(element.options.padding),
      generateMinWidth("320px"),
      "left:0;",
      "overflow: hidden;",
      "font-size: initial !important;"
    );

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content,
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      pseudoClassName: " .formkit-week",
      content:
        "display: grid;grid-auto-flow: column; grid-template-columns: repeat(auto-fit, minmax(8px, 1fr));",
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      pseudoClassName: " .formkit-week-days",
      content:
        "display: grid;grid-auto-flow: column; grid-template-columns: repeat(auto-fit, minmax(8px, 1fr));",
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      pseudoClassName: " .formkit-day",
      content: "margin-left: auto !important;margin-right: auto !important;",
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      pseudoClassName: " .formkit-week-day",
      content: "margin-left: auto !important;margin-right: auto !important;",
    });

    return cssString;
  };

export const generateCalendarHeaderWrapperCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._headerWrapperCssClass,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content:
        "border-bottom:none !important;position:relative;display:flex !important;align-items:center;justify-content:space-between;margin-bottom:32px;",
    });

    cssString += generateCssClassWithContent({
      className: element.options._headerWrapperCssClass,
      pseudoClassName: "::after",
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: `
      content: "";
      position: absolute;
      bottom: 0;
      left: -100px;
      right: -100px;
      ${generateBorderStyle(
        element.options.border?.width && element.options.border?.fill?.color
          ? element.options.border
          : {
              fill: { color: "#000000", opacity: 0 },
              width: 0,
              style: element.options.border?.style,
            }
      )}
      border-top: none !important;
      `,
    });
    return cssString;
  };

export const generateCalendarCellCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const generateDayCellStateContent = (options: IWidgetOptions) =>
      joinStrings(
        generateFillColorStyle(
          options.contentFill?.color
            ? options.contentFill
            : { color: "#000000", opacity: 0 }
        ),
        generateBorderStyle({
          ...options.contentBorder,
          fill: options.contentBorder?.fill?.color
            ? options.contentBorder?.fill
            : { color: "#000000", opacity: 0 },
        }),
        generateTextColor(options.color)
      );

    const defaultContent = joinStrings(
      generateCornerRadiusStyle(element.options.contentCornerRadius),
      generateFontFamily(element.options.fontFamily),
      generateDayCellStateContent(element.options),
      "width:fit-content;",
      "padding:6px;",
      "margin: 4px;",
      "cursor:pointer;"
    );

    cssString += generateCssClassWithContent({
      className: `${element.options._cellCssClass}`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: defaultContent,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellCssClass}:hover`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(element.options.states[State.HOVER]),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellWrapperCssClass}[aria-selected="true"]`,
      childClassName: `${element.options._cellCssClass}`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.SELECTED_DEFAULT]
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellWrapperCssClass}[aria-selected="true"]`,
      childClassName: `${element.options._cellCssClass}:hover`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.SELECTED_HOVER]
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellWrapperCssClass}[data-is-extra="true"]`,
      childClassName: `${element.options._cellCssClass}`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.INACTIVE]
      ),
    });
    cssString += generateCssClassWithContent({
      className: `${element.options._cellWrapperCssClass}[data-disabled="true"]`,
      childClassName: `${element.options._cellCssClass}`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.DISABLED]
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellCssClass}[aria-selected="true"]`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.SELECTED_DEFAULT]
      ),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cellCssClass}[aria-selected="true"]:hover`,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDayCellStateContent(
        element.options.states[State.SELECTED_HOVER]
      ),
    });

    return cssString;
  };

export const generateCalendarWeekdayCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._weekDayCssClass,
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: `${generateTextColor(element.options.weekDaysColor)}
      font-weight: bold;
      text-transform: capitalize;`,
    });

    return cssString;
  };

export const generateCalendarNavCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._navCssClass,
      content: element.options.color,
      customFunction: generateTextColor,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._navCssClass}:hover`,
      content: element.options.states.hover.color,
      customFunction: generateTextColor,
    });
    return cssString;
  };

export const generateDynamicFormWidgetSize = (
  element: IWidgetOptions,
  widgetName: string,
  screens: string[]
) => {
  element.options.screens[`_cssClass_${FormScreen.FORM}`] = generateClassName(
    `${widgetName}_${FormScreen.FORM}`
  );

  screens.forEach(screenName => {
    const propName = `_cssClass_${screenName}`;

    element.options.screens[propName] = generateClassName(
      `${widgetName}_${screenName}`
    );
  });
};

export const generateScreensCssString =
  (element: IWidgetOptions, widgetName: string, screens: string[]) =>
  (cssString: string): string => {
    const formKeyname = `_cssClass_${FormScreen.FORM}`;

    element.options.states[formKeyname] = generateClassName(
      `${widgetName}_${FormScreen.FORM}`
    );

    cssString += `.${
      element.options.states[formKeyname]
    } {${generateDefaultStyles({
      ...element.options.states[FormScreen.FORM],
    })}}`;

    screens.forEach(screenName => {
      const propName = `_cssClass_${screenName}`;

      element.options.states[propName] = generateClassName(
        `${widgetName}_${screenName}`
      );

      cssString += `.${
        element.options.states[propName]
      } {${generateDefaultStyles({
        ...element.options.states[screenName],
      })}}`;
    });

    return cssString;
  };
