import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
  generateCheckboxCssString,
  generateRadioCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateWidgetWidth } from "../helpers";

import {
  generateCheckboxCssString as generateTableCheckboxCssString,
  generateEmptyTextStyles,
  generateFilterItemsStyles,
  generateFilterStyles,
  generateTableHeaderStyles,
  generateTableRowStyles,
  generateTableStyles,
} from "./transaction-history-widget-css";
import { generateStatusMessageCssString } from "./payment-provider-form-fenix-widget-css";

const generateFilterInnerStyles =
  (field: IWidgetOptions, filterOptions?: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) + generateWidgetWidth(options.width),
    });

    const direction = filterOptions?.displayMode === 3 ? "column" : "row";

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      gap: options.distance,
      direction,
    });

    cssString += generateCssClassWithContent({
      className: options._itemsCssClass,
      content: flex,
    });

    return cssString;
  };

export const generateTransactionHistoryFenixCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);
  const prefilledCustom = prefillWithClasses(widget.options._customFields);

  prefilled.table.options._noContentRowCssClass =
    generateClassName("no_content_row");

  prefilled.table.options._containerCssClass =
    generateClassName("table_container");

  prefilled.date_filter.options._itemsCssClass = generateClassName(
    "date_filter_controls"
  );
  prefilled.transactions_filter.options._itemsCssClass = generateClassName(
    "transactions_filter_controls"
  );

  prefilled.pending_error_text.options.icon._cssClass = generateClassName(
    "pending_error_text_icon"
  );
  prefilled.pending_error_text.options._textCssClass = generateClassName(
    "pending_error_text_description"
  );

  prefilled.action_button.options.iconSettings._cssClass =
    generateClassName("action_button_icon");

  // Calendar class names
  prefilledCustom.calendar_styling.options._wrapperCssClass =
    generateClassName("calendar_wrapper");
  prefilledCustom.calendar_styling.options._dayCellCssClass =
    generateClassName("day_cell");

  prefilledCustom.calendar_styling.options._navCssClass =
    generateClassName("calendarNav");

  prefilledCustom.datepicker_styling.options._datepickerCssClass =
    generateClassName("datepicker");
  // Icons class names
  prefilledCustom.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (prefilledCustom.dropdowns_styling) {
    prefilledCustom.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdown_icon");
  }

  prefilledCustom.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("text_choose_icon");

  if (prefilledCustom.calendar_styling) {
    // Calendar class names
    prefilledCustom.calendar_styling.options._wrapperCssClass =
      generateClassName("calendar_wrapper");
    prefilledCustom.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    prefilledCustom.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    prefilledCustom.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    prefilledCustom.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");
    prefilledCustom.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    prefilledCustom.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const dropdowns = prefilledCustom.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          prefilledCustom.dropdown_menu_styling,
          prefilledCustom.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          prefilledCustom.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = prefilledCustom.calendar_styling
    ? [
        generateCalendarWrapperCssString(prefilledCustom.calendar_styling),
        generateCalendarHeaderWrapperCssString(
          prefilledCustom.calendar_styling
        ),
        generateCalendarCellCssString(prefilledCustom.calendar_styling),
        generateCalendarWeekdayCssString(prefilledCustom.calendar_styling),
        generateCalendarNavCssString(prefilledCustom.calendar_styling),
      ]
    : [];

  if (prefilledCustom.checkboxes_styling) {
    prefilledCustom.checkboxes_styling.options._checkboxOptionsCssClass =
      generateClassName("checkbox_options");

    prefilledCustom.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    prefilledCustom.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");
    prefilledCustom.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (prefilledCustom.checkboxes_styling.options.check.icon) {
      prefilledCustom.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  if (prefilledCustom.radio_group_styling) {
    prefilledCustom.radio_group_styling.options._radioOptionsCssClass =
      generateClassName("radio_options");

    prefilledCustom.radio_group_styling.options._radioContainerCssClass =
      generateClassName("radio_container");

    prefilledCustom.radio_group_styling.options._radioCssClass =
      generateClassName("radio");

    prefilledCustom.radio_group_styling.options._radioLabelCssClass =
      generateClassName("radioLabel");

    if (prefilledCustom.radio_group_styling.options.radio.icon) {
      prefilledCustom.radio_group_styling.options.radio.icon._cssClass =
        generateClassName("radioIcon");
    }
  }

  const checkboxes =
    prefilledCustom.checkboxes_styling &&
    prefilledCustom.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          prefilledCustom.checkboxes_styling,
          prefilledCustom.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(prefilledCustom.checkboxes_styling);

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringWithStates(prefilled.title, true),
    generateStringDefault(prefilled.filter),
    generateStringDefault(prefilled.filter_title),
    generateFilterInnerStyles(prefilled.date_filter),
    generateFilterInnerStyles(
      prefilled.transactions_filter,
      prefilled.form.options
    ),
    generateStringDefault(prefilled.date_filter_title),
    generateStringDefault(prefilled.transactions_filter_title),
    generateStageButtonCssString(prefilled.action_button as IWidgetField),
    generateTableStyles(prefilled.table),
    generateTableHeaderStyles(prefilled.table_header_settings, prefilled.table),
    generateTableRowStyles(prefilled.table_row_settings, prefilled.table),
    generateStringDefault(prefilled.pending_description),
    generateEmptyTextStyles(prefilled.no_events_title),
    generateTableCheckboxCssString(prefilled.table_checkboxes_settings),
    generateStatusMessageCssString(prefilled.pending_error_text),
    /* 
      Form
    */
    checkboxes,
    generateRadioCssString(prefilledCustom.radio_group_styling),
    generateFormCssString(prefilled.form, widget),
    generateFilterStyles(prefilled.form.options),
    generateFilterItemsStyles(prefilled.form.options),
    generateTooltipStyling(prefilledCustom.tooltips_styling),
    generateIconCssString(
      prefilledCustom.tooltips_styling.options.textChooseIcon
    ),
    generateErrorCssString(prefilledCustom.errors_styling),
    generateStringDefault(prefilledCustom.labels_styling),
    generateFieldsWithStatesCssString(
      prefilledCustom.fields_styling,
      prefilledCustom.errors_styling,
      widget,
      prefilledCustom.labels_styling,
      prefilledCustom.field_success_styling
    ),
    generateIconRightCssString(prefilledCustom.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
