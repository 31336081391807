<template>
  <div class="betslip-widget__form-input-container">
    <a-input
      placeholder="0.00"
      :style="formInputInlineStyles"
      class="betslip-widget__form-input w-100"
      type="text"
      readonly
    >
    </a-input>
    <p
      v-if="isErrorVisible"
      :style="formErrorStyles"
      class="betslip-widget__error"
    >
      Error text goes here
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useBetslipFormInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipFormInlineStyles";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);

const isErrorVisible = computed<boolean>(() => {
  if (!selectedField.value) return false;

  return (
    selectedField.value.name === "errors_styling" &&
    selectedWidget.value?.id === props.widget.id
  );
});

const inputState = computed<State>(() => {
  return State.DISABLED;
});

const { formErrorStyles, formInputInlineStyles } = useBetslipFormInlineStyles(
  ref(props.fields),
  ref(props.states),
  isErrorVisible,
  inputState
);
</script>
