import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../useWidgetFields";

export const useDropdownContainerControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const dropdownContainerDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.dropdown_container.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.dropdown_container.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.dropdown_container.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.dropdown_container.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.dropdown_container.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.dropdown_container.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const dropdownContainerContentControls = computed(() => [
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.text.color",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.text.theme",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Theme",
          },
        },
        {
          componentPath: "ConfigFontFamilySelect",
          valuePath: "options.text.fontFamily",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Font family",
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: "options.text.decoration",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Arrow"),
      controls: [
        {
          componentPath: "ConfigArrowSettings",
          valuePath: "options.arrow",
          valueSource: selectedField.value,
          className: "p-t-0",
          options: {
            label: "Color",
            isBold: true,
            allowDisable: true,
            showOptions: false,
            meta: {
              hideToggle: true,
            },
          },
        },
      ],
    },
  ]);

  const currentDropdownContainerPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }
    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const dropdownContainerStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentDropdownContainerPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentDropdownContainerPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentDropdownContainerPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentDropdownContainerPath.value}.text.color`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Arrow"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentDropdownContainerPath.value}.arrow.textColor`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  return {
    dropdownContainerDesignControls,
    dropdownContainerContentControls,
    dropdownContainerStatesControls,
  };
};
