<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
    :style="containerStyle"
    class="betslip-accept-changes"
  >
    <WidgetField
      :widget="widget"
      :field="checkboxField"
    >
      <FormCheckboxField
        :widge="widget"
        :styling-fields="[checkboxField]"
        :states="states"
        value="Accept changes"
        state-name="each_way_bet_checkbox"
        custom-field-name="each_way_bet_checkbox"
      />
    </WidgetField>

    <a-tooltip
      :overlay-style="tooltipStyles"
      :visible="selectedField?.name === tooltipField.name"
      :get-popup-container="getMenuContainer"
      placement="bottom"
      overlay-class-name="betslip-fenix-widget__tooltip"
    >
      <template #title> Tooltip text </template>

      <span v-if="tooltipIconComponent">
        <CommonIcon
          :name="tooltipIconComponent"
          :style="tooltipIconColorStyle"
        />
      </span>
    </a-tooltip>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  getColorFromHex,
  getColorStyle,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import { ElementStyle, StyleValue } from "~~/models/common";
import { IFillControl, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  betType?: string;
  field: IWidgetField;
  checkboxField: IWidgetField;
  tooltipField: IWidgetField;
}>();

const { selectedField } = storeToRefs(useWidgetSettingsStore());

const containerStyle = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});

const tooltipStyles = computed<ElementStyle>(() => {
  const textColor = getColorFromHex(
    props.tooltipField.options.color
  ) as StyleValue;

  const styles = {
    ...getCommonStyles(props.tooltipField.options),
    ...getTextCommonStyles(props.tooltipField.options),
    textColor,
  };

  return getStylesAsVars(styles);
});

const tooltipIconColorStyle = computed<ElementStyle>(() => {
  if (!tooltipIconOptions.value || !props.tooltipField) {
    return {};
  }

  return getColorStyle(tooltipIconOptions.value.color);
});

const getMenuContainer = (): HTMLElement | null => {
  return document.querySelector(`#${WIDGETS_GRID_ID}`);
};

const tooltipIconOptions = computed<{
  icon: string;
  color: IFillControl;
  gap: number;
} | null>(() => {
  if (!props.tooltipField) {
    return null;
  }

  return props.tooltipField.options.textChooseIcon;
});

const tooltipIconComponent = computed<string | null>(() => {
  if (!tooltipIconOptions.value) {
    return null;
  }

  return tooltipIconOptions.value.icon || "ant-design:question-circle-outlined";
});
</script>

<style lang="scss">
.betslip-accept-changes {
  @include flex(center, flex-start, 0);

  &__tooltip {
    font-size: 12px;
    flex-grow: 1;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        text-align: var(--textAlign);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
    &.remove-arrow {
      &[class*="ant-tooltip-placement"] {
        padding: 0 !important;
        margin-top: 10px !important;
      }
      .ant-tooltip-arrow-content {
        display: none;
      }
    }
  }
}
</style>
