import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  generateTextColor,
  generateFontSize,
  generateFlex,
  generateGap,
  generateSize,
} from "~~/assets/utils/widget-css/helpers";
import { getPxValueFromNumber } from "~~/assets/utils";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
/*  */
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";

export const generateMenu1LevelItemCssString =
  (menuItem: IWidgetField) =>
  (cssString: string): string => {
    const { options } = menuItem;

    const isElementsVertical =
      options.elementsDisplayMode === DisplayOrientation.VERTICAL;
    const isVertical = options.displayMode === DisplayOrientation.VERTICAL;
    const itemIcon = options.itemIcon;
    const arrowSettings = options.arrowSettings;

    if (itemIcon) {
      cssString += generateCssClassWithContent({
        className: options.itemIcon._cssClass,
        content:
          generateTextColor(itemIcon.color) + generateFontSize(itemIcon.size),
      });

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}`,
        pseudoClassName: `[data-active="true"] .${itemIcon._cssClass}`,
        content: generateTextColor(options.states.active.itemIcon.color, true),
      });

      for (const stateName in options.states) {
        cssString += generateCssClassWithContent({
          className: `${options._cssClass}`,
          pseudoClassName: `:${stateName} .${itemIcon._cssClass}`,
          content: generateTextColor(options.states[stateName].itemIcon.color),
        });
      }
    }

    if (arrowSettings) {
      const theme = options.theme.value ?? options.theme;
      cssString += generateCssClassWithContent({
        className: arrowSettings._cssClass,
        content:
          generateTextColor(arrowSettings.textColor) + generateFontSize(theme),
      });

      for (const stateName in options.states) {
        cssString += generateCssClassWithContent({
          className: `${options._cssClass}`,
          pseudoClassName: `:${stateName} .${arrowSettings._cssClass}`,
          content: generateTextColor(
            options.states[stateName].arrowSettings.textColor
          ),
        });
      }

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}`,
        pseudoClassName: `[data-active="true"] .${arrowSettings._cssClass}`,
        content: generateTextColor(
          options.states.active.arrowSettings.textColor,
          true
        ),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateGap(options.itemIcon.spaceBetween) +
        `${
          isElementsVertical ? "flex-direction:column;" : "flex-direction:row;"
        }` +
        `${
          isVertical
            ? "justify-content:space-between;"
            : "justify-content:unset;"
        }` +
        "width:100%;" +
        generateSize(options.size),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: '[data-active="true"]',
      content: {
        ...options.states.active,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._rootCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "unset",
          justify: "space-between",
          direction: isVertical ? "column" : "row",
          gap: options.verticalSpace,
        }) +
        "overflow-x: auto;" +
        "width:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._linkCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          direction: isElementsVertical ? "column" : "row",
          gap: options.itemIcon.spaceBetween,
        }) +
        "flex:auto;" +
        `text-align: ${options.alignment};`,
    });

    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          direction: isElementsVertical ? "column" : "row",
          gap: options.verticalSpace,
        }) + "overflow-x: auto;",
    });

    cssString += generateCssClassWithContent({
      className: options._linkCssClass,
      pseudoClassName: " .accordion-item-fenix-element__title",
      content: "overflow:visible;white-space:normal;",
    });

    cssString += generateDefaultStylesWithStates(menuItem, false);

    return cssString;
  };

export const generateMenu2LevelItemCssString =
  (menu2Item: IWidgetField, menu3Item?: IWidgetField) =>
  (cssString: string): string => {
    const { options } = menu2Item;

    const isElementsVertical =
      options.elementsDisplayMode === DisplayOrientation.VERTICAL;

    const isContentElementsVertical =
      menu3Item?.options.displayMode === DisplayOrientation.VERTICAL;

    const isVertical = options.displayMode === DisplayOrientation.VERTICAL;
    const arrowSettings = options.arrowSettings;

    if (arrowSettings) {
      const theme = options.theme.value ?? options.theme;
      cssString += generateCssClassWithContent({
        className: arrowSettings._cssClass,
        content:
          generateTextColor(arrowSettings.textColor) + generateFontSize(theme),
      });

      for (const stateName in options.states) {
        cssString += generateCssClassWithContent({
          className: `${options._cssClass}`,
          pseudoClassName: `:${stateName} .${arrowSettings._cssClass}`,
          content: generateTextColor(
            options.states[stateName].arrowSettings.textColor
          ),
        });
      }

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}`,
        pseudoClassName: `[data-state="open"] .${arrowSettings._cssClass}`,
        content: generateTextColor(
          options.states.active.arrowSettings.textColor
        ),
      });

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}`,
        pseudoClassName: `[data-active="true"] .${arrowSettings._cssClass}`,
        content: generateTextColor(
          options.states.active.arrowSettings.textColor,
          true
        ),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateGap(options.itemIcon.spaceBetween) +
        `${
          isElementsVertical ? "flex-direction:column;" : "flex-direction:row;"
        }` +
        `${
          isVertical
            ? "justify-content:space-between;"
            : "justify-content:unset;"
        }` +
        "width:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: '[data-active="true"]',
      content: {
        ...options.states.active,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: '[data-state="open"]',
      content: {
        ...options.states.active,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._rootCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "unset",
          justify: "space-between",
          direction: isVertical ? "column" : "row",
          gap: options.verticalSpace,
        }) +
        "overflow-x: auto;" +
        "width:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._linkCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          direction: isElementsVertical ? "column" : "row",
          gap: options.itemIcon.spaceBetween,
        }) + "flex:auto;",
    });

    if (menu3Item)
      cssString += generateCssClassWithContent({
        className: options._contentCssClass,
        content:
          generateFlex({
            flex: "flex",
            align: "center",
            justify: "space-between",
            direction: isContentElementsVertical ? "column" : "row",
            gap: menu3Item.options.verticalSpace,
          }) + "overflow-x: auto;",
      });

    cssString += generateDefaultStylesWithStates(menu2Item, false);

    return cssString;
  };

export const generateMenu3LevelItemCssString =
  (menuItem: IWidgetField) =>
  (cssString: string): string => {
    const { options } = menuItem;

    const isElementsVertical =
      options.elementsDisplayMode === DisplayOrientation.VERTICAL;
    const isVertical = options.displayMode === DisplayOrientation.VERTICAL;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateGap(options.itemIcon.spaceBetween) +
        `${
          isElementsVertical ? "flex-direction:column;" : "flex-direction:row;"
        }` +
        `${
          isVertical
            ? "justify-content:space-between;"
            : "justify-content:unset;"
        }` +
        "width:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: '[data-active="true"]',
      content: {
        ...options.states.active,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateDefaultStylesWithStates(menuItem, false);

    return cssString;
  };

const getOppositeMarginsToPadding = (padding: Record<string, number>) => {
  return Object.keys(padding).reduce((res, curr) => {
    return {
      ...res,
      [curr]: -padding[curr],
    };
  }, {});
};

const generateNumberValues = (spacing: Record<string, number>) => {
  const result = `${getPxValueFromNumber(spacing.top)} ${getPxValueFromNumber(
    spacing.right
  )} ${getPxValueFromNumber(spacing.bottom)} ${getPxValueFromNumber(
    spacing.left
  )}`;

  return result;
};

export const generateSportsMenuWidgetSpacingVarsCssString =
  (widgetOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: widgetOptions._cssClass,
      content:
        `--accordion-content-padding: ${generateNumberValues(
          widgetOptions.spacing.padding
        )};` +
        `--accordion-content-margin: ${generateNumberValues(
          getOppositeMarginsToPadding(widgetOptions.spacing.padding)
        )};`,
    });

    return cssString;
  };

export const generateSportsMenuPrematchFenixWidgetCssString = (
  widget: IPageContentWidget,
  prefix: string = ""
): string => {
  const title = widget.content["title"];
  const menu_level_1 = widget.content["menu_level_1"];
  const menu_level_2 = widget.content["menu_level_2"];
  const menu_level_3 = widget.content["menu_level_3"];

  widget.options._cssClass = generateClassName(`SportsMenu${prefix}Widget`);

  menu_level_1.options._cssClass = generateClassName(`${prefix}menu_level_1`);
  menu_level_1.options._rootCssClass = generateClassName(
    `${prefix}root-menu_level_1`
  );
  menu_level_1.options._linkCssClass = generateClassName(
    `${prefix}link-menu_level_1`
  );
  menu_level_1.options._contentCssClass = generateClassName(
    `${prefix}content-menu_level_1`
  );

  menu_level_2.options._cssClass = generateClassName(`${prefix}menu_level_2`);
  menu_level_2.options._rootCssClass = generateClassName(
    `${prefix}root-menu_level_2`
  );
  menu_level_2.options._linkCssClass = generateClassName(
    `${prefix}link-menu_level_2`
  );
  menu_level_2.options._contentCssClass = generateClassName(
    `${prefix}content-menu_level_2`
  );

  title.options._cssClass = generateClassName(`${prefix}title`);

  menu_level_3.options._cssClass = generateClassName(`${prefix}menu_level_3`);

  menu_level_3.options.itemIcon._cssClass = generateClassName(
    `${prefix}itemIcon`
  );
  menu_level_3.options.arrowSettings._cssClass = generateClassName(
    `${prefix}arrowSettings`
  );

  menu_level_1.options.itemIcon._cssClass = generateClassName(
    `${prefix}itemIcon`
  );
  menu_level_1.options.arrowSettings._cssClass = generateClassName(
    `${prefix}arrowSettings`
  );

  if (menu_level_2.options.itemIcon) {
    menu_level_2.options.itemIcon._cssClass = generateClassName(
      `${prefix}itemIcon`
    );
  }

  if (menu_level_2.options.arrowSettings) {
    menu_level_2.options.arrowSettings._cssClass =
      generateClassName("arrowSettings");
  }

  const titleCss = title ? [generateStringDefault(title)] : [];

  return pipeSync<string>(
    generateStringDefault(widget),
    generateMenu1LevelItemCssString(menu_level_1 as IWidgetField),
    generateMenu2LevelItemCssString(
      menu_level_2 as IWidgetField,
      menu_level_3 as IWidgetField
    ),
    generateMenu3LevelItemCssString(menu_level_3 as IWidgetField),
    generateSportsMenuWidgetSpacingVarsCssString(widget.options),
    ...titleCss
  )("");
};
