<template>
  <div
    v-if="
      states[widget.name] === State.BETSLIP ||
      states[widget.name] === State.MY_BETS ||
      states[widget.name] === State.BETS_RESULT
    "
    class="betslip-widget__tabs-container"
  >
    <WidgetField
      v-if="fields.betslip_tabs_container.active"
      :widget="widget"
      :field="fields.betslip_tabs_container.field"
    >
      <div
        v-if="states[widget.name] === State.BETSLIP"
        class="betslip-widget__header-items-container"
        :style="tabsContainerInlineStyles"
      >
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.single")
          }}</span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.multi")
          }}</span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.system")
          }}</span>
        </div>
      </div>
      <div
        v-else-if="
          states[widget.name] === State.MY_BETS ||
          states[widget.name] === State.BETS_RESULT
        "
        class="betslip-widget__header-items-container"
        :style="tabsContainerInlineStyles"
      >
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.cash_out")
          }}</span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.all_bets")
          }}</span>
        </div>
      </div>
    </WidgetField>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipTabsInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipTabsInlineStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  tabsContainerInlineStyles,
  tabsAlignmentStyles,
  tabsContentInlineStyles,
} = useBetslipTabsInlineStyles(ref(props.fields), ref(props.states));
</script>
