<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <TransactionHistoryFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="transaction-history">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="transaction-history__field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :value="fields.title.field.options.text[states.page_type]"
        />
      </WidgetField>

      <WidgetField
        v-if="states.page_type === State.PENDING_WITHDRAWALS"
        :widget="widget"
        :field="fields.pending_description.field"
      >
        <BasicTitleField :field="fields.pending_description.field" />
      </WidgetField>

      <WidgetField
        v-if="states.page_type !== State.PENDING_WITHDRAWALS"
        :widget="widget"
        :field="fields.filter.field"
        no-overflow
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.filter.field"
        >
          <WidgetField
            v-if="fields.filter_title.active"
            :widget="widget"
            :field="fields.filter_title.field"
            class="transaction-history__field"
          >
            <BasicTitleField :field="fields.filter_title.field" />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.form.field"
          >
            <TransactionHistoryFenixFilter
              :widget="widget"
              :field="fields.form.field"
              :fields="filterFields"
              :states="states"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.table.field"
        :is-custom-selected="isTableFieldSelected"
      >
        <WidgetTable
          v-if="states.items === State.ACTIVE_EVENTS"
          :table-header="TABLE_HEADER"
          :rows="TABLE_CONTENT"
          :field="fields.table.field"
          :header-field="fields.table_header_settings.field"
          :rows-field="fields.table_row_settings.field"
          :hidden="hiddenTableFields"
        >
          <template #reference="data">
            <span class="transaction-history__col-text">
              <span> {{ data.value }} </span>
              <span>
                <CommonIcon name="ant-design:copy-outlined" />
              </span>
            </span>
          </template>

          <template #status="data">
            <span class="transaction-history__col-text">
              <span
                :class="`transaction-history__col-status--${data.value}`"
                class="transaction-history__col-status"
              >
              </span>

              <span> {{ data.value ? "Won Status" : "Lost Status" }} </span>
            </span>
          </template>

          <template #checkbox>
            <div
              :style="checkboxStyles"
              class="transaction-history__checkbox-container"
            >
              <a-checkbox :checked="isCheckboxChecked" />
            </div>
          </template>

          <template #header-checkbox>
            <div
              :style="checkboxStyles"
              class="transaction-history__checkbox-container"
            >
              <a-checkbox :checked="isCheckboxChecked" />
            </div>
          </template>
        </WidgetTable>
        <BasicDesignWrapperField
          v-else
          :widget="widget"
          :field="fields.no_events_title.field"
        >
          <BasicTitleField :field="fields.no_events_title.field" />
        </BasicDesignWrapperField>
      </WidgetField>

      <template v-if="states.page_type === State.PENDING_WITHDRAWALS">
        <WidgetField
          :widget="widget"
          :field="fields.pending_error_text.field"
          :style="statusMessageStyles"
        >
          <CommonIcon
            :style="statusMessageIconStyles"
            :name="fields.pending_error_text.field?.options.icon.value"
          />

          <span :style="statusMessageTextStyles">{{
            localizedStatusValue
          }}</span>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.action_button.field"
        >
          <BasicButtonField
            :field="fields.action_button.field"
            :current-state="states.action_button"
            class="transaction-history__cancel-button"
          />
        </WidgetField>
      </template>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/transaction-history/fenix/useTransactionHistoryFenixInitData";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  getBorderStyle,
  getBackgroundColorStyle,
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { createCustomField } from "~~/assets/utils/widget";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useWidgetsStore } from "~~/store/widgets";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetsSettingsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField } = storeToRefs(widgetsSettingsStore);
const widgetsStore = useWidgetsStore();

const { t } = useI18n();

const hiddenTableFields = computed<string[]>(() => {
  if (fields.value.table.field.options.mode === "table") {
    return [];
  }

  return ["checkbox"];
});

const {
  states,
  filterFields,
  DEFAULT_STATES,

  init,
} = useInit(props.widget);

const TABLE_HEADER = computed(() => {
  let header = null;
  switch (states.value.page_type) {
    case State.BETTING_HISTORY:
      header = [
        { label: "", name: "checkbox" },
        { label: t("widgets.TransactionHistoryWidget.date"), name: "date" },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.description"),
          name: "description",
        },
        { label: t("widgets.TransactionHistoryWidget.status"), name: "status" },
        {
          label: t("widgets.TransactionHistoryWidget.operation_amount"),
          name: "operation_amount",
        },
        {
          label: t("widgets.TransactionHistoryWidget.status_winner"),
          name: "winning",
        },
      ];
      break;
    case State.PENDING_WITHDRAWALS:
      header = [
        { label: "", name: "checkbox" },
        { label: t("widgets.TransactionHistoryWidget.date"), name: "date" },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.provider"),
          name: "provider",
        },
        {
          label: t("widgets.TransactionHistoryWidget.operation_amount"),
          name: "operation_amount",
        },
      ];
      break;
    case State.TRANSACTION_HISTORY:
    default:
      header = [
        { label: "", name: "checkbox" },
        { label: t("widgets.TransactionHistoryWidget.date"), name: "date" },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.description"),
          name: "description",
        },
        {
          label: t("widgets.TransactionHistoryWidget.transaction_type"),
          name: "transaction_type",
        },
        { label: t("widgets.TransactionHistoryWidget.status"), name: "status" },
        {
          label: t("widgets.TransactionHistoryWidget.operation_amount"),
          name: "operation_amount",
        },
      ];
      break;
  }
  return header;
});

const TABLE_CONTENT = computed(() => {
  let content = null;
  switch (states.value.page_type) {
    case State.BETTING_HISTORY:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: true,
          operation_amount: "+ 1,07",
          winning: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: true,
          operation_amount: "+ 1,07",
          winning: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: false,
          operation_amount: "- 2,25",
          winning: "0,00",
        },
      ];
      break;
    case State.PENDING_WITHDRAWALS:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          operation_amount: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          operation_amount: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          operation_amount: "0,00",
        },
      ];
      break;
    case State.TRANSACTION_HISTORY:
    default:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: false,
          operation_amount: "+ 1,07",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: true,
          operation_amount: "+ 1,07",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: false,
          operation_amount: "+ 1,07",
        },
      ];
      break;
  }
  return content;
});

const currentCheckboxStateOptions = computed<IWidgetOptions>(() => {
  if (states.value.table_checkboxes_settings === State.SELECTED_DEFAULT) {
    return fields.value.table_checkboxes_settings.field.options;
  }

  return fields.value.table_checkboxes_settings.field.options.states[
    states.value.table_checkboxes_settings
  ];
});

const checkboxStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...(getBorderStyle(
        currentCheckboxStateOptions.value.border
      ) as ElementStyle),
      ...getBackgroundColorStyle(currentCheckboxStateOptions.value.fill),
      ...getColorStyle(currentCheckboxStateOptions.value.checkIcon.color),
    },
    "checkbox"
  );
});

const statusMessageStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getCommonStyles(field.options),
    display: "flex",
    gap: getPxValueFromNumber(field.options.distance),
    flexDirection:
      field.options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    alignItems:
      field.options.layout === DisplayOrientation.HORIZONTAL
        ? field.options.verticalAlignment
        : generateFlexAlignment(field.options.horizontalAlignment),
  };
});

const statusMessageIconStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getColorStyle(field.options.icon.textColor),
    ...getFontSizeStyle(field.options.icon.iconSize),
  };
});

const statusMessageTextStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getTextCommonStyles(field.options),
    flexGrow: 1,
    width: "100%",
  };
});

const localizedStatusValue = computed(() => {
  return useLocalizedValue().getLocalizedValue.value(
    fields.value.pending_error_text.field.value
  );
});

const isCheckboxChecked = computed<boolean>(() => {
  const currState = states.value.table_checkboxes_settings;

  if (
    currState === State.SELECTED_DEFAULT ||
    currState === State.SELECTED_HOVER ||
    currState === State.SELECTED_DISABLED
  ) {
    return true;
  }

  return false;
});

const isTableFieldSelected = computed<boolean>(() => {
  const tableFields = [
    fields.value.table.field,
    fields.value.table_header_settings.field,
    fields.value.table_row_settings.field,
    fields.value.table_checkboxes_settings.field,
  ];

  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  return (
    tableFields.filter(field => selectedField.value?.id === field.id).length > 0
  );
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["page_type"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();

[
  FormStyleFieldName.LABEL,
  FormStyleFieldName.TOOLTIP,
  FormStyleFieldName.CHECKBOX,
  FormStyleFieldName.RADIO_GROUP,
  FormStyleFieldName.ERROR,
  FormStyleFieldName.FIELD,
].forEach(styleName => {
  if (fields.value[styleName]) {
    return;
  }

  const initial = STYLING_FIELDS[styleName]();

  const stylingField = createCustomField(
    {
      ...initial.options,
    },
    props.widget.id,
    styleName,
    initial.title
  );

  widgetsStore.updateWidgetFields(props.widget, [
    ...props.widget.fields,
    stylingField,
  ]);
});
</script>

<style lang="scss">
.transaction-history {
  &__col-text {
    & > span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;

      &:first-child {
        margin-right: 8px;
      }
    }

    .common-icon {
      display: inline;
    }
  }

  &__col-status {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    &--true {
      background-color: #52c41a;
    }

    &--false {
      background-color: #ff4d4f;
    }
  }

  &__checkbox-container {
    .ant-checkbox-inner {
      border-radius: 5px;
      background-color: var(--checkbox-backgroundColor) !important;
      border: none;
      border: var(--checkbox-borderWidth, 0) var(--checkbox-borderStyle)
        var(--checkbox-borderColor, transparent);

      &::after {
        border-color: var(--checkbox-color);
      }
    }

    .ant-checkbox {
      &::after {
        display: none !important;
      }
    }
  }

  &__cancel-button {
    justify-content: flex-start;
  }
}
</style>
